/*
Template: Sofbox - Responsive Bootstrap 4 Admin Dashboard Template
Author: iqonicthemes.in
Design and Developed by: iqonicthemes.in
NOTE: This file contains the styling for responsive Template.
*/
@media(max-width:1699px) {
	.email-form .select2-container { width: 100% !important; }
	.sign-in-page {height: 100%;}
	.sign-in-detail{height: 100%;}
	.gallery_media .card-subtitle{font-size: 12px;}
	.change_tag {
		width: 120px;
		font-size: 12px;
	}

}

@media(max-width:1399px) {
	.animation-card .an-img .bodymovin { margin-bottom: -28px; }
	.animation-card .an-img { margin-top: 0; }
}
@media(max-width:1299px) {
	.iq-circle-progressbar .percent { font-size: 1.3rem !important; }
	.iq-circle-progressbar svg { width: 80px !important; height: 80px !important; }
	.iq-circle-progressbar { margin: 0 auto; width: 80px !important; height: 80px !important; }
	.content-page, body.sidebar-main .content-page { margin-left: 0; }
	.iq-top-navbar .iq-sidebar-logo .top-logo { display: block; }
	.iq-footer, body.sidebar-main .iq-footer { padding: 15px 10px; margin-left: 0; }
	.iq-sidebar { display: inline-block; z-index: 99; left: -290px; }
	.iq-top-navbar .iq-navbar-custom .navbar-breadcrumb { display: none; }
	.iq-sidebar-logo { width: auto; }
	.iq-top-navbar .iq-navbar-custom .iq-menu-bt { display: flex; position: absolute; left: 10px; opacity: 1; }
	body.sidebar-main .iq-top-navbar .iq-sidebar-logo a span { opacity: 1; display: inline-block; }
	body.sidebar-main .iq-sidebar { width: 240px; left: 0;background: #ecf3fc; }
	body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li a span { display: inline-block; opacity: 1; }
	body.sidebar-main .iq-sidebar-menu .iq-menu li a .iq-arrow-right { display: inline-block; }
	.iq-email-to-list ul li { margin: 0 2px 0 0; }
	.an-img-two{    width: 600px;    top: -118px;}
	.iq-sidebar-menu .iq-menu .iq-menu-title{display: none;}
	body.sidebar-main .iq-sidebar-logo{width: auto;}
	.iq-sidebar{padding-top: 2px;}
	.gallery_media .card-subtitle{font-size: 12px;}
	.change_tag{width: 130px}
	.orgCodeMobileView:nth-child(2) .nav-tzcc {border: none;}
	.side_menu{ padding-top: 0px;}
}
@media(max-width:1199px) {
	.table { width: 100%; max-width: 100%; margin-bottom: 1rem; overflow-x: auto; }
	.an-img-two{display: none;}
	.iq-booking-no li .iq-seat { width: 35px; height: 45px; }
	.iq-booking-no li { width: 4.7%; }
	.iq-email-to-list { overflow-x: scroll; }
	.iq-email-to-list ul li { margin: 0 5px 0 0; }
	.iq-email-to-list .justify-content-between { float: left; width: 900px; }
	.sign-in-from {padding: 0 30px;}
	.sign-in-detail{padding: 100px 50px;}
}

@media(max-width:992px) {
	.an-img-two .bodymovin{display: none;}
	.display-1 { font-size: 4rem; font-weight: 300; }
	.display-2 { font-size: 3.5rem; font-weight: 300; }
	.display-3 { font-size: 3rem; font-weight: 300; }
	.display-4 { font-size: 2.5rem; font-weight: 300; }
	.display-5 { font-size: 2rem; font-weight: 300; }
	.display-6 { font-size: 1.5rem; font-weight: 300; }
	.iq-top-navbar .navbar { position: relative; display: inherit; }
	.iq-top-navbar .navbar-toggler { right: 85px; top: 18px; position: absolute; color: #0560fd; background: #e5f2ff; border: none; padding: 7px 10px; border-radius: 5px; font-size: 24px; }
	.navbar-collapse { position: absolute; top: 75px; left: 0; width: 100%; background: #fff; -webkit-box-shadow: 0px 5px 12px 0px rgba(55, 73, 72, 0.15); -moz-box-shadow: 0px 5px 12px 0px rgba(55, 73, 72, 0.15); box-shadow: 0px 5px 12px 0px rgba(55, 73, 72, 0.15); }
	.navbar-nav.navbar-list { float: right; display: inline-block; }
	.iq-top-navbar .iq-navbar-custom .iq-menu-bt {right: 65px; top: 8px; left: auto;} 
	.animation-card .an-img .bodymovin { margin-bottom: 0; margin-left: 0; width: 100%; }
	.iq-footer, .iq-footer .col-lg-6.text-right { text-align: center !important; }
	.iq-booking-index { overflow-x: scroll; }
	.seat-booking { width: 980px; position: relative; }
	.seat-booking .col-sm-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; width: 8.333333%; }
	.seat-booking .col-sm-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; width: 83.333333%; }
	.iq-booking-offer { padding: 30px; }
	.offer-an-img { position: static; width: 100%; }
	.sign-in-from {padding: 0 20px;}
	.iq-maintenance .col-lg-4 { margin-bottom: 15px;}
	
}
@media(max-width:979px) {
	.iq-circle-progressbar .percent { font-size: 2.5rem !important; }
	.iq-circle-progressbar svg { width: 200px !important; height: 200px !important; }
	.iq-circle-progressbar { margin: 0 auto; width: 200px !important; height: 200px !important; }
	
}
@media(max-width: 850px){
	.btn_cell{min-width: 285px;}
	.emp_name{min-width: 240px;}
	.emp_email{min-width: 250px;}
	.login_cell,.desg_Cell{min-width: 100px;}
}
@media(max-width:767px) {
	.display-1 { font-size: 2.5rem; font-weight: 500; }
	.display-2 { font-size: 2.3rem; font-weight: 500; }
	.display-3 { font-size: 2rem; font-weight: 500; }
	.display-4 { font-size: 1.6rem; font-weight: 500; }
	.display-5 { font-size: 1.3rem; font-weight: 500; }
	.display-6 { font-size: 1rem; font-weight: 500; }
	.search-box .search-input { width: 280px; }
	.navbar-nav.navbar-list { display: inline-block; width: 100%; }
	.navbar-nav.navbar-list li { float: right; }
	.sign-info{text-align: center;margin-bottom: 30px;}
	.iq-social-media{width: 100%;}
	.iq-social-media li{float: none; display: inline-block;}
	.sign-in-from button {margin-top: 10px;}
	.sign-in-from .custom-control.custom-checkbox {display: block !important;}
	.sign-in-detail {padding: 50px 20px;}
	.sign-in-from{padding: 20px;}
	.iq-error h1{font-size: 12rem;}
	/* .contentsec h4{font-size: 20px !important;} */
}
@media(max-width:479px) {
	.display-1 { font-size: 2.0rem; font-weight: 500; }
	.display-2 { font-size: 1.8rem; font-weight: 500; }
	.display-3 { font-size: 1.6rem; font-weight: 500; }
	.display-4 { font-size: 1.4rem; font-weight: 500; }
	.display-5 { font-size: 1.2rem; font-weight: 500; }
	.iq-circle-progressbar .percent { font-size: 2rem !important; }
	.iq-circle-progressbar svg { width: 150px !important; height: 150px !important; }
	.iq-circle-progressbar { margin: 0 auto; width: 150px !important; height: 150px !important; }
	.iq-card-body { padding: 15px; }
	.iq-sidebar-logo a span { display: none; }
	body.sidebar-main .iq-top-navbar .iq-sidebar-logo a span { opacity: 0; display: none; }
	.iq-error h1{font-size: 8rem;}
	.profle_img{width: 130px;}
	/* .contentsec h4{font-size: 12px !important;} */
}
@media(max-width:359px) { 
	.mat-paginator-range-actions{display: block !important; margin: 0 auto; text-align: center;}
	.mat-paginator-range-label{margin: 0 !important;}
}
