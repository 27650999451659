.iq-profile-avatar.status-primary:before { background-color: #6a75ca; }
.iq-profile-avatar.status-blue:before { background-color: #468bd8; }
.iq-profile-avatar.status-info:before { background-color: #17a2b8; }
.iq-profile-avatar.status-danger:before { background-color: #ea5455; }
.bg-blue { background-color: #468bd8 !important; }
.dash-hover-gradient { background-color: #fff; min-height: 200px; }
.dash-hover-gradient:hover { cursor: pointer; background: #0560fd; color: #fff; }
.dash-hover-scale { min-height: 200px; transition: all 200ms ease-in-out; }
.dash-hover-scale:hover { cursor: pointer; transform: scale(1.1); transition: all 200ms ease-in-out; }
.dash-hover-gradient:hover .iq-card-body *, .dash-hover-gradient:hover .iq-card-header i { color: #fff; }


.transformY-min-15 { transform: translateY(-15px); }
.transformY-min-45 { transform: translateY(-45px); }
.text-gray { color: #aaa; }
.font-size-22 { font-size: 22px; }
.font-size-24 { font-size: 24px; }
.font-size-26 { font-size: 26px; }
.font-size-28 { font-size: 28px; }
.font-size-30 { font-size: 30px; }
.font-size-32 { font-size: 32px; }
.font-size-34 { font-size: 34px; }
.font-size-36 { font-size: 36px; }
.font-size-38 { font-size: 38px; }
.font-size-40 { font-size: 40px; }
.height-25 { height: 25px !important; }
.height-50 { height: 50px !important; }
.height-75 { height: 75px !important; }
.height-100 { height: 100px !important; }
.height-125 { height: 125px !important; }
.height-150 { height: 150px !important; }
.chartjs-pie-chart { display: inline-block; vertical-align: middle; width: 100%; }
.chart-legend { margin: 0; padding: 0; }
.chart-legend { display: inline-block; vertical-align: middle; width: 80%; font-size: 14px; }
.chart-legend ul { padding: 0px; }
.chart-legend li { line-height: 1.3em; list-style: none; margin: 0 0 .3em 0; min-height: 1.3em; }
.chart-legend li span { border-radius: .3em; display: inline-block; height: 1.3em; left: 0; margin-right: .5em; vertical-align: middle; width: 1.3em; }
.apexcharts-gridlines-horizontal { display: none !important; }

/*  Css for the Dashboard 2 */
.iq-border-radius-5 { border-radius: 5px; }
.iq-border-radius-10 { border-radius: 10px; }
.iq-border-radius-15 { border-radius: 15px; }
.iq-border-radius-20 { border-radius: 20px; }
.iq-border-r-5 { border-right: 5px solid; }
.iq-border-l-5 { border-left: 5px solid; }
.iq-shadow { box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15) !important; }
.iq-profile-card { overflow: visible; }
.left-section { }
.right-section { min-height: 80vh; border-radius: 50px; }
.iq-icon-shape { padding: 12px; text-align: center; display: inline-flex; align-items: center; justify-content: center; width: 3rem; height: 3rem; }

/* Project 7 Dashboard */
.kanban-drag, .kanban-item { padding: 5px !important; }
.kanban-drag { height: 600px; overflow: hidden; overflow-y: scroll; }
.gantt-container { height: 350px; }
.bg-banner-image { background-size: 100% 100% !important; background-repeat: no-repeat !important; height: 250px; }
.banner-bg-color-primary { width: 100%; height: 100%; background: rgba(106, 117, 202, 0.8); border-radius: inherit; }
.iq-mt--50 { margin-top: -50px; }
.full-map { height: 100vh; z-index: 0; }
.map-profile-card { margin-right: 30px; border: 1px solid #ddd; }
.track { display: -webkit-inline-box; display: -moz-inline-box; overflow-x: scroll; }
::-webkit-scrollbar { width: 8px; height: 8px; border-radius: 20px; }

/* Track */
::-webkit-scrollbar-track { background: #f1f1f1; border-radius: 20px; }

/* Handle */
::-webkit-scrollbar-thumb { background: #888; border-radius: 20px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #555; border-radius: 20px; }
.dash-tracking-icon { height: 100px; }
.transformX-min-1 { transform: scaleX(-1); }

.iq-page-primary>li>a, .iq-page-primary>li>span{
  background-color: #077bff;
  color: #fff;
}
.iq-page-primary>.page-item.active .page-link{
  border-color: #0062cc;
  background-color: #0062cc;
}
.iq-page-primary>.page-item.disabled .page-link{
  background-color: #077bff;
  color: #fff;
}

.iq-page-success>li>a, .iq-page-success>li>span{
  background-color: #00ca00;
  color: #fff;
}
.iq-page-success>.page-item.active .page-link{
  border-color: #009500;
  background-color: #009500;
}
.iq-page-success>.page-item.disabled .page-link{
  background-color: #00ca00;
  color: #fff;
}

.iq-page-info>li>a, .iq-page-info>li>span{
  background-color: #00d0ff;
  color: #fff;
}
.iq-page-info>.page-item.active .page-link{
  border-color: #008fb5;
  background-color: #008fb5;
}
.iq-page-info>.page-item.disabled .page-link{
  background-color: #00d0ff;
  color: #fff;
}

/*treemap_chart_new*/



.nav_bar .up {
  font: bold 14px/1 sans-serif;
  color: white;
  width: 92px;
  padding: 8px;
  background-color: #3b9d3b; /*rgba(230, 26, 60, 0.8); */
  float: left;
  cursor: pointer;
}
.nav_bar .up:hover {
  background-color:  #3b9d3b;
}

.feature {
  position: relative;
  width: calc(100% - 0 * 2 * 1px);
  height: calc(100vh - 150px);
  margin: 0px;
  overflow: hidden;
  margin-bottom: 20px;
}

.node {
  position: absolute;
  background: transparent no-repeat 50%/cover;
  overflow: hidden;
  opacity: 0.8;
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
  cursor: pointer;
}
.node .label {
  display: inline;
  font-family: sans-serif;
  color: rgba(255, 255, 255, 0.6);
  position: absolute;
  padding: 0;
  margin: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  -webkit-transition: color 0.4s, opacity 0.8s, -webkit-filter 0.8s;
  transition: color 0.4s, opacity 0.8s, -webkit-filter 0.8s;
  transition: color 0.4s, opacity 0.8s, filter 0.8s;
  transition: color 0.4s, opacity 0.8s, filter 0.8s, -webkit-filter 0.8s;
}
.node.hide {
  opacity: 0;
  pointer-events: none;
}
.node.hide .label {
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.node:hover .label {
  color: white;
}
.node.level-0 {
  z-index: 4;
  font-size: 10vmin;
  display: none;
}
.node.level-1 {
  z-index: 3;
  font-size: 3vmin;
}
.node.level-2 {
  z-index: 2;
  font-size: 2.5vmin;
}
.node.level-3 {
  z-index: 1;
  font-size: 2.5vmin;
}
/*treemap_end */