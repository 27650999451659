/* @import url('https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Oswald:200,300,400,500,600,700|Poppins:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Questrial&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Lora:wght@400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Orbitron:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');

* {
      outline: none !important;
}

body {
      letter-spacing: normal;
      background: #ecf3fc;
      font-weight: 500;
      font-family: 'Roboto', Verdana, sans-serif !important;
      word-break: break-word;
      letter-spacing: 0.3px;
      position: relative;
}

a {
      color: #0560fd;
}

a:hover {
      color: #003cc0;
}

.btn {
      transition: all ease 0.75s;
}

.btn:focus {
      box-shadow: none !important;
}

.grayimg {
      filter: grayscale(100%);
}

tr:hover {
      transition: all ease 0.75s;
}

.regifrm .form-control {
      border-radius: 6px;
      background: #f9f9f7;
      border: none;
      font-size: 14px;
      padding: 20px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      transition: all ease 0.75s;
      letter-spacing: 2px;
}

.regifrm .form-control:focus {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.fROsnav .navbar-brand img {
      height: 48px;
}

.fROsnav.navbar {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      position: fixed;
      width: 100%;
      z-index: 3;
      background-color: #fff;
}

.fROsnav.navbar .navbar-nav a {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      padding: .5rem 1.2rem !important;
      color: #212325;
      transition: all ease 0.75s;
}

.fROsnav.navbar .navbar-nav a:hover {
      color: #0560fd;
}

.btn {
      border-radius: 15px;
      transition: all ease 0.75s;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
      position: relative;
      min-height: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
}

.badge {
      position: relative;
}

.btn::before,
.badge::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0;
      height: 0;
      transition: all ease 0.5s;
      border-radius: 50%;
      opacity: 0;
}

.btn-primary {
      color: #fff !important;
      background-color: #0560fd;
      border-color: #0560fd;
}

@keyframes fade {
      0% {
            opacity: 0
      }

      50% {
            opacity: 1
      }

      100% {
            opacity: 0
      }
}

.btn:hover::before,
.badge:hover::before {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      left: 0;
      top: 0;
      animation: fade 1.5s linear;
}

.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
      color: #fff !important;
      background-color: #003cc0 !important;
      border-color: #003cc0 !important;
      box-shadow: none !important;
}

.btn-gradiant {
      color: #4d4d4d !important;
      background: #ecf3fc !important;
      border-color: #cad5e4 !important;
}

.btn-gradiant:hover,
.btn-gradiant.focus,
.btn-gradiant:focus {
      color: #fff !important;
      background: #0560fd !important;
      border-color: #0560fd !important;
}

.btn-outline-secondary::before,
.btn-secondary::before {
      background: #6c757d;
}

.btn-outline-danger::before,
.btn-danger::before {
      background: #f22c3b;
}

.btn-outline-success::before,
.btn-success::before {
      background: #28b116;
}

.slidersection {
      position: relative;
}

.slidersection .carousel-item {
      height: 100vh;
}

.slidersection .carousel-item .container {
      padding-top: 125px;
}

.slidersection .carousel-item .container h1 {
      font-size: 35px;
      line-height: 50px;
}

.avaicons {
      position: absolute;
      bottom: 20px;
      left: 0px;
      width: 100%;
      font-weight: 500;
      line-height: 30px;
}

.avaicons .container {
      background: rgba(255, 255, 255, 0.9);
      border-radius: 6px;
      padding: 20px 15px;
      text-align: center;
      transition: all ease 0.75s;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.iconlist {
      font-weight: 800;
      display: inline-block;
      padding: 0 15px;
      transition: all ease 0.75s;
}

.iconlist img {
      height: 28px;
      float: left;
      margin-right: 10px;
      transition: all ease 0.75s;
}

.avaicons:hover .container {
      background: #0560fd;
      color: #fff;
}

.avaicons:hover .iconlist {
      color: #fff;
}

.avaicons:hover .iconlist img {
      filter: brightness(0) invert(1);
}

.contentsec {
      padding: 75px 0px;
}

.contentsec h4,
.contentsec h3 {
      font-size: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 500;
      margin: 0px;
      padding: 30px 0;
      word-break: break-word;
}

.russian .contentsec h4,
.russian .contentsec h3 {
      font-size: 20px;
}

.lftrgtcontent {
      margin-bottom: 100px;
      line-height: 30px;
      display: block;
}

.lftrgtcontent h5 {
      letter-spacing: 2px;
      font-weight: 400;
      margin: 20px 0;
      display: block;
      font-size: 20px;
}

.lrctxt {
      padding-left: 40px;
}

.contentsec h5 {
      font-size: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 450;
      margin: 0px;
      padding: 30px 0;
      word-break: break-word;
}

.dotbg {
      background: url('/assets/images/home-dots-bg.png') repeat-y;
      background-position: top center;
}

.innerpagebg {
      background: url('/assets/images/innerpage-bg.jpg') repeat-y;
      background-position: top center;
}

.innerpagebg .carousel-item {
      height: auto
}

.innerbg_new {
      background: url('/assets/images/innerpage-bg2.jpg') repeat-y;
      background-position: center;
}

.createaccountsec {
      padding: 50px 0px;
      background: url('/assets/images/home-create-account-bg.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      color: #fff;
      letter-spacing: 3px;
      line-height: 30px;
}

.createaccountsec h5 {
      font-size: 25px;
}

.createaccountsec p {
      margin: 0px;
}

.regbtnsec {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      height: 100%;
}

.regbtnsec a {
      font-size: 25px;
      text-transform: uppercase;
      font-weight: 800;
      width: 300px;
}

.whtshadbox {
      background-color: #f9fcfe;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
      padding: 10px 15px;
      border-radius: 6px;
      display: inline-block;
      width: 100%;
      border: 2px solid #fff;
}

.dropdown-toggle {
      position: relative;
}

.dropdown-toggle::after {
      position: absolute;
      right: 7px;
      top: 3px;
      content: "\f107";
      color: #212325;
      font-family: FontAwesome;
      border: none;
      font-size: 20px;
}

.footersec {
      padding: 30px 0 10px 0;
      margin-top: 50px;
}

.footersec .dropdown-toggle {
      width: 125px;
      text-align: left;
}

.footlinks {
      padding: 30px 0 0;
}

.footlinks a {
      padding: 0px 15px;
      border-right: 1px solid #555;
      color: #212325;
      font-size: 12px;
      text-decoration: none;
      transition: all ease 0.75s;
      margin-bottom: 15px;
      display: inline-block;
}

.footlinks a:hover,
.footlinks a:focus {
      color: #0560fd;
}

.footlinks a:last-child {
      border: none;
}

.table {
      white-space: nowrap;
}

.tabletitle {
      background: #f9fcfe;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
      padding: 10px 30px;
      border-radius: 6px;
      text-transform: none;
      color: #212325;
      margin-bottom: 15px;
      border: 2px solid #fff;
}

.tableplatformlist {
      background: #f9fcfe;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
      padding: 15px 15px;
      border-radius: 6px;
      color: #212325;
      margin-bottom: 15px;
      border: 2px solid #fff;
      position: relative;
}

.tableplatformlist .listcontent {
      border-bottom: 1px solid #e7e7ef;
      line-height: 48px;
      min-height: 48px;
      color: #212325;
      padding: 0px 15px;
      transition: all ease 0.75s;
      text-transform: none;
}

.tableplatformlist .listcontent .btn {
      text-transform: none;
      font-size: 12px;
      line-height: normal;
}

.tableplatformlist .listcontent:hover {
      background: #f9f9f7;
}

.tableplatformlist .listcontent:last-child {
      border: none;
}

.imgpritxt {
      padding: 15px;
}

.imgpritxt img {
      margin-bottom: 15px;
}

.imgpritxt h6 {
      font-size: 20px;
}

.imgpritxt h5 {
      font-size: 45px;
}

.toptxt {
      font-size: 15px;
      position: relative;
      top: -20px;
}

.bottomtxt {
      color: #959595;
      font-size: 14px;
}

.imgpritxt ul {
      padding-left: 50px;
}

.imgpritxt ul li {
      padding: 7px 0px;
}

.imgpritxt ul li i {
      color: #0178b2;
      background: -webkit-linear-gradient(#1c9cea, #0178b2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}

.pricebox .btn {
      text-transform: uppercase;
      font-weight: 500;
      padding: 10px 30px;
}

.subscripclk {
      padding: 0px;
}

.subscripclk .imgpritxt {
      background: #e7edef;
      padding: 15px;
      text-align: left !important;
}

.subscripclk .imgpritxt img {
      float: left;
      height: 60px;
      margin-right: 15px;
}

.subscripclk .imgpritxt h5 {
      font-size: 34px;
}

.subscripclk .imgpritxt h6 {
      font-size: 15px;
}

.subscripclk .imgpritxt ul {
      padding-left: 85px;
}

.subscripclk .subscribe {
      display: none
}

.regifrm {
      padding: 30px;
      display: none;
}

.subscripclk .regifrm {
      display: block;
}

.registerbtn {
      position: relative;
      bottom: -55px;
      margin-top: -30px;
}

.fROsnav .navbar-toggler {
      outline: 0 !important;
      width: 30px;
      height: 30px;
      padding: 0;
}

.fROsnav .navbar-toggler .mobbar.fas_c,
.navbar-toggler .mobbar.fas_t {
      margin-bottom: 5px
}

.mobbar {
      width: 30px;
      height: 3px;
      background: #333;
      display: block;
      border-radius: 30px;
      transition: all ease .5s;
      position: relative
}

.fROsnav .navbar-toggler .mobbar.fas_c {
      width: 0;
      opacity: 0
}

.fROsnav .navbar-toggler.collapsed .mobbar.fas_c {
      width: 20px;
      opacity: 1
}

.fROsnav .navbar-toggler .mobbar.fas_t {
      transform: rotate(45deg);
      top: 8px
}

.fROsnav .navbar-toggler .mobbar.fas_b {
      transform: rotate(-45deg);
      bottom: 8px
}

.fROsnav .navbar-toggler.collapsed .mobbar.fas_b,
.fROsnav .navbar-toggler.collapsed .mobbar.fas_t {
      transform: rotate(0);
      top: 0;
      bottom: 0
}

.successms {
      padding: 30px 15px;
      overflow: hidden;
      position: relative;
      display: none;
}

.successms h6 {
      line-height: 25px;
      margin-bottom: 30px;
}

.successms p {
      font-size: 12px;
      line-height: 25px;
}

.successms::before {
      content: "\f058";
      position: absolute;
      right: -110px;
      bottom: -185px;
      font-size: 400px;
      color: #71c66e;
      font-family: FontAwesome;
      opacity: 0.1;
}

.susbox {
      min-height: 400px;
}

.about_box {
      width: 733px;
      margin: 0 auto;
}

.cms_content h3 {
      font-size: 1.953em;
}

.soon_txt {
      display: block;
      font-size: 11px;
      position: absolute;
      font-weight: normal;
      bottom: -20px;
      left: 50px;
}

.gradient_txt {
      background: linear-gradient(to right, #1c9cea 0%, #0178b2 99%);
      background: -webkit-linear-gradient(to right, #1c9cea 0%, #0178b2 99%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}

.progress_div {
      margin-top: 15px;
      height: 0.5rem;
      margin-bottom: 20px;
}

.progress_div.report_progress {
      margin-top: 8px;
      height: 0.6rem;
      margin-bottom: 0px;
      font-size: 10px;
      min-width: 175px;
      max-width: 175px;
}

.timeline_div {
      max-width: 100%;
      white-space: nowrap;
      width: auto !important;
}

fcsro-horizontal-calendar .timeline_div {
      overflow: hidden;
}

fcsro-time-bar-v3 .timeline_div {
      overflow-x: auto;
}

.date_only {
      max-width: 300px;
}

.inner_weekly {
      border: 1px solid #0560fd;
      border-radius: 4px;
      padding: 5px 10px;
}

.timeline_div .hours {
      padding-left: 12px;
      border-right: 1px solid #e5eaef;
      padding-right: 12px;
}

.border_right_none {
      border-right: none !important;
}

.activity {
      position: absolute;
      left: 0;
      width: 150px;
      z-index: 1;
      top: 0;
      bottom: 0;
}

.timeline_div .hours_div .hours {
      min-width: 35px;
      font-size: 12px;
      text-align: center;
      line-height: 18px;
      padding: 10px 0px !important;
      z-index: 2;
      position: relative;
}

.activity.online {
      background-color: rgb(209, 255, 202);
      border: 1px solid #b9e8b2;
}

.activity.idle_time {
      background-color: #e5eaef
}

.activity.offline_time {
      background-color: #555555
}

.activity.shift_time {
      background-color: #0560fd;
      z-index: 2 !important;
}

.ngx-slider .ngx-slider-tick {
      background: #0560fd !important;
}

.timeline_div .hours.days {
      font-size: 12px;
      cursor: pointer;
}

.under_const {
      font-size: 15px;
      vertical-align: middle;
      margin-left: 10px;
}

.weekly_div {
      min-width: 300px;
}

.team_padd span .badge {
      margin-right: 5px;
}

.team_padd span:last-child .badge {
      margin-right: 0px !important;
}

.loader_gif {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
}

/*-----------------------------Tooltip CSS------------------------------------ */
a.tip {
      position: relative;
}

a.tip span {
      display: none;
      position: absolute;
      top: -5px;
      left: 60px;
      width: 125px;
      padding: 5px;
      z-index: 100;
      background: #0560fd;
      color: #fff;
      border-radius: 5px;
      -moz-border-radius: 5px;
      text-align: center;
      font-size: 12px;
      -webkit-border-radius: 5px;
}

a.tip span:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid #0560fd;
      left: -8px;
      top: 7px;
}

a:hover.tip {
      font-size: 99%;
}

a:hover.tip span {
      display: block;
}

/*****************************************/
.emp_name {
      min-width: 240px;
}

.emp_email {
      min-width: 240px;
}

.emp_new {
      min-width: 150px;
}

.login_cell {
      min-width: 155px;
}

.login_cell.mat-column-lastLogin {
      min-width: 120px;
}

.btn_cell {
      min-width: 190px;
}

.login_icon {
      font-size: 75px !important;
}

.login_div h6 {
      font-size: 20px;
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
      box-shadow: none
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
      border-color: #0560fd
}

.border_btm {
      border-bottom: 1px solid #f4f4f4;
}

.fnt_sz_14 {
      font-size: 14px;
}

.movie-list {
      min-height: 840px;
}

.mx_hght170 {
      min-height: 170px;
}

.screen_count_div,
.d_inline_block {
      display: inline-block;
}

.iq-submenu li a {
      padding-left: 0 !important;
      font-size: 11px !important;
}

.packages {
      margin: 30px 8px;
      padding-bottom: 1.5em;
      padding-top: 1.5em;
      min-height: 673px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border-radius: 20px;
      box-shadow: 0 19px 38px rgba(30, 35, 33, 0.2), 0 15px 12px rgba(30, 35, 33, 0.2);
      flex-wrap: wrap;
      color: #333333;
}

.strike_div {
      text-decoration: line-through;
}

.packages h1 {
      font-size: 2em;
      background: linear-gradient(to right, #1c9cea 0%, #0178b2 99%);
      background: -webkit-linear-gradient(to right, #1c9cea 0%, #0178b2 99%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}

.packages h5 {
      margin-top: 10px;
}

.list li {
      font-size: 12px;
      list-style: none;
      border-bottom: 1px solid #f4f4f4;
      padding-inline-start: 0;
      border-width: 1px;
      padding: 10px;
}

.first {
      margin-top: 20px;
      border-top: 1px solid #f4f4f4;
}

.mat-radio-button {
      display: inline-block;
      background: #fff;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
      padding: 10px 15px;
      border-radius: 6px;
      text-transform: none;
      color: #212325;
      margin-bottom: 15px;
}

.mat-radio-button label {
      margin: 0;
}

.emp_dbHostPort {
      min-width: 90px;
}

.mat-column-organisationCode {
      min-width: 120px;
}

.mw-150px {
      max-width: 150px;
}

.bg-transparent .mat-form-field-flex {
      background: transparent !important;
}

.mat-checkbox-layout .mat-checkbox-label {
      word-break: break-word;
      white-space: normal;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
      background-color: #0560fd !important;
}

.mat-datepicker-toggle-active {
      color: #0560fd !important;
}

.fixtdcell .table tr td:first-child,
.fixtdcell .table tr td:nth-child(2) {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 30px;
}

.mat-expansion-panel-header-description {
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      margin-right: 0px;
}

.gallery_div {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
}

.gallery_div .tabletitle {
      min-height: 149.78px;
      padding: 0px;
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      text-transform: none;
      position: relative;
      z-index: 0;
}

.gallery_div .tabletitle.thrblksec {
      min-height: 149.78px;
}

.gallery_div .tabletitle .mat-form-field {
      padding: 0px 15px;
}

.gallery_div .mat-form-field-infix {
      width: 150px !important;
}

.etttile {
      padding: 10px 15px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
}

.tamil .etttile {
      font-size: 11px;
}

.etttile .mat-icon-button {
      width: 25px;
      height: 25px;
      font-size: 25px;
      opacity: 0.5;
      line-height: 25px;
}

.etttile .mat-icon-button:hover {
      opacity: 1;
}

.gallery_div .tabletitle .mat-form-field-wrapper {
      padding-bottom: 0px !important;
}

.todaymonth {
      background: #edf3ff;
      padding: 10px 15px;
      width: 98%;
      margin: 0 auto 15px;
}

.todaymonth>.row>div>div>h5 {
      margin: 0 !important;
}

.todaymonth>.row>div>div>h5>a {
      color: #0862fd;
}

.todaymonth>.row {
      align-items: center;
      justify-content: center;
}

.todaymonth h1 {
      font-size: 25px;
      color: #0560fd;
      margin: 0px;
      text-transform: lowercase;
}

.todaymonth h2 {
      font-size: 20px;
      color: #0560fd;
      margin: 0px;
      text-transform: lowercase;
      font-weight: 400;
}

.gallery_div .qtext {
      color: #000;
}

.curtimezone {
      padding: 10px 15px;
      background: #d8e6ff;
      width: 100%;
      font-size: 12px;
      border-radius: 0 0 16px 16px;
      text-transform: none;
}

.curtimezone span {
      color: #0560fd;
      font-weight: bold;
}

.thrblksec {
      padding: 15px !important;
}

.thrblksec p {
      margin-bottom: 0px;
}

.thrblksec h5 {
      font-size: 20px;
      margin: 5px 0px;
      font-weight: 500;
}

.thrblksec .etttile {
      padding: 0px;
}

.gallery_div .tabletitle fcsro-focus-spread {
      float: none;
}

.gallery_div .tabletitle p {
      font-size: 12px;
      line-height: 15px;
      margin-top: 5px;
}

.thrblksec h5 .small {
      color: #000;
      font-size: 12px;
      display: block;
}

.clr00498b {
      color: #0560fd;
}

.clr0080ff {
      color: #0560fd;
}

.arrowslr {
      width: 50px;
      height: 25px;
}

.month_chose .mat-form-field-wrapper {
      margin-bottom: 0px !important;
}

.bg-primary,
.badge-primary {
      background-color: #0560fd !important;
      font-weight: 500 !important;
}

.tableplatformlist+.table.mat-table td,
.tableplatformlist+.table-responsive .table.mat-table td {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 6px;
}

.border-primary {
      border-color: #0560fd !important;
}

.custom-control-input:checked~.custom-control-label::before {
      color: #fff;
      border-color: #0560fd;
      background-color: #0560fd;
}

.mat-expansion-panel-header-title .custom-control {
      display: flex;
      align-items: center;
}

.iq-top-navbar .navbar-toggler {
      padding: 0px !important;
}

.contentsec h4 small,
.contentsec h3 small {
      padding: 5px 10px;
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: 600 !important;
      line-height: normal;
}

.contentsec h4 .fa-question-circle-o,
.contentsec h3 .fa-question-circle-o {
      font-size: 18px;
      position: relative;
      top: 3px;
}

fcsro-horizontal-calendar+fcsro-time-bar {
      max-width: 100%;
}

.mat-tab-label {
      opacity: 1 !important;
}

fcsro-timelog-report .mat-checkbox-label {
      text-transform: none;
      font-size: 12px;
}

.logo-icon {
      background: #f9fbfe;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
      display: inline-block;
      width: 200px;
      padding: 10px;
      margin-bottom: 30px;
      border-radius: 10px;
      height: auto;
      border-radius: 15px;
      border: 2px solid #fff;
}

.logo-icon img {
      max-width: 100%;
}

.loginbox {
      transition: all ease 0.75s;
      padding: 18px 15px 15px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0);
      border-radius: 15px;
      background: #f9fbfe;
      border: 2px solid #fff;
}

.loginbox:hover {
      background: #fff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.loginbox img {
      max-width: 84px;
}

.sign-in-from .form-group a {
      font-size: 11px;
      letter-spacing: 1px;
}

.sign-in-from .btn-gradiant {
      line-height: 30px;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 15px;
}

.darkblue {
      background: #003cbf;
      height: 100vh;
      box-shadow: 0px 0px 15px rgba(0, 60, 192, 0.5);
}

.darkblue .sign-in-detail {
      background: url(/assets/images/login/2.jpg) no-repeat 0 0;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
}

.owl-carousel .owl-item .item {
      min-width: 100%;
      width: 100%;
      padding: 0px 30px;
}

.exheg {
      min-height: 140px;
      border-radius: 0px 0px 4px 4px;
}

.brdrign {
      border-left: 1px solid #e2eeff;
}

/* French */
.french .tableplatformlist .col-11 p,
.russian .iq-sidebar-menu .iq-menu li a {
      font-size: 12px !important;
}

.french .iq-sidebar-menu .iq-menu li a,
.german .iq-sidebar-menu .iq-menu li a {
      font-size: 12px !important;
}

.french .btn.btn-gradiant {
      font-size: 11px;
}

.french .iq-card-header .card-title {
      font-size: 18px;
}

.french .custom-control-label {
      font-size: 15px;
}

.tamil .iq-sidebar-menu .iq-menu li a,
.spanish .iq-sidebar-menu .iq-menu li a,
.catalan .iq-sidebar-menu .iq-menu li a {
      font-size: 11px !important;
}

.tamil .table .btn.btn-gradiant,
.russian .table .btn.btn-gradiant,
.hindi .table .btn.btn-gradiant,
.spanish .table .btn.btn-gradiant,
.arabic .table .btn.btn-gradiant,
.catalan .table .btn.btn-gradiant,
.german .table .btn.btn-gradiant,
.english .table .btn.btn-gradiant {
      font-size: 11px;
      margin: 5px auto;
}

.tamil .contentsec h4,
.tamil .contentsec h3 {
      font-size: 20px;
}

.tamil .custom-control-label {
      font-size: 14px;
}

.tamil .tableplatformlist .col-11 p,
.tamil .mat-tab-label .mat-tab-label-content {
      font-size: 12px;
}

.tamil .mat-checkbox-layout .mat-checkbox-label {
      font-size: 10px;
}

.tamil em.text-muted {
      font-size: 11px;
}

.tamil .contentsec .tableplatformlist .tableplatformlist:first-child p {
      font-size: 10px;
      font-weight: bold;
}

.iq-card-body .border-primary .custom-control {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
}

.chooselanguage {
      padding: 1px 5px !important;
}

select.chooselanguage option {
      padding: 0 15px !important;
      font-size: 12px !important;
}

.classificationVisualisation .tooltip_report {
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      top: 0px;
}

.classificationVisualisation .focusro-text {
      font-size: 10px;
}

.classificationVisualisation .tooltip_report .percentage {
      font-weight: bold;
      color: #0560fd;
      font-size: 18px;
}

.dashbox {
      display: inline-flex;
      flex-direction: column;
      text-transform: uppercase;
}

.dashbox .dash_icon,
.hd h4 span {
      float: none;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      font-size: 20px !important;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: 10px;
      padding: 0;
      color: #fff;
      border: transparent;
}

.ora-grad {
      color: #ffddd3;
      background: conic-gradient(#ff8f6b 90deg 0deg, #d35230 0deg 270deg, #ed6c47 270deg 360deg);
}

.green-grad {
      color: #cbffe7;
      background: conic-gradient(#33c481 90deg 0deg, #195d38 0deg 270deg, #21a366 270deg 360deg);
}

.blue-grad {
      color: #cbffe7;
      background: conic-gradient(#0560fd 90deg 0deg, #195d38 0deg 270deg, #21a366 270deg 360deg);
}

.dashbox::after {
      content: '';
      position: absolute;
      right: 0px;
      top: 50%;
      height: 100px;
      width: 1px;
      background: #e5eaef;
      margin-top: -50px;
}

.dashbox.last::after {
      display: none;
}

.dashbox fcsro-classification-distribution {
      float: left;
}

.dashboxtxt {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
}

.dashboxtxt .dash_icon {
      margin-right: 20px;
}

.fcsroclassdis {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 100%;
}

.fcsroclassdis p.text_transform_none {
      margin-bottom: 0px;
      margin-top: 15px;
}

.dashbox p.text_transform_none {
      color: #333333;
      font-size: 14px;
}

.dashbox .dashcount {
      font-size: 20px;
      font-weight: 500;
      padding-left: 55px;
      line-height: normal;
      margin-bottom: 8px !important;
      text-align: center;
}

.mtg p {
      margin-bottom: 8px !important;
      text-align: center;
      font-weight: normal;
}

.dashboxtit {
      display: inline-flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      transition: all ease 0.75s;
      font-size: 16px;
      color: #333333;
      text-transform: uppercase;
      font-weight: 500 !important;
      margin: 0;
}

.dashboxtit .dash_icon {
      display: none;
}

.fcsroclassdis .tooltip_report .name {
      display: none !important;
}

.fcsroclassdis .classificationVisualisation {
      transform: scale(0.77);
      margin-top: -30px;
}

.dashbox.fcsroclassdis .activitybars {
      margin-top: -30px;
      margin-bottom: 15px;
      padding: 0px !important;
      justify-content: center;
      align-items: center;
}

.dashbox.fcsroclassdis .activity.shift_time {
      background-color: #e5eaef;
}

.dashbox.fcsroclassdis .activity.shift_time {
      background-color: #555555;
}

.dashbox.fcsroclassdis .activity.online {
      background-color: #0560fd;
}

.dashbox.fcsroclassdis .classificationVisualisation .tooltip_report .percentage {
      font-size: 30px;
}

.dashbox.fcsroclassdis .classificationVisualisation .tooltip_report {
      top: 0px;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
}

.empgallery .classificationVisualisation .tooltip_report {
      top: 0px;
      height: 100%;
      font-size: 11px;
      padding: 0px 30px;
      line-height: normal;
      flex-direction: column;
      align-items: center;
      justify-content: center;
}

.tamil .btn {
      font-size: 11px;
}

.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
      font-size: 12px;
}

fcsro-dashboard .col-xl-4.col-lg-4.col-md-6.col-sm-12.mb-3 .tableplatformlist {
      min-height: 100%;
}

.activitybars .color_div {
      width: 10px !important;
      height: 10px !important;
      margin-right: 7px;
}

.dashlists {
      padding: 10px;
      transition: all ease 0.75s;
}

.dashlists:nth-child(odd) {
      background: #f1f6ff;
}

.dashlists:hover {
      background: #d8e6ff;
}

.fcsrocaltime {
      padding: 0px;
      width: 100%;
}

.dashlists .text-primary {
      transition: all ease 0.75s;
      text-transform: uppercase;
}

.dashlists:hover .text-primary {
      color: #000 !important;
}

.profile_badge {
      background: #0560fd;
      color: white;
      padding: 0 15px;
      margin-right: 15px;
}

.version_div {
      width: 240px;
      font-weight: bold;
      padding: 9px !important;
      transition: all ease 0.75s;
      border-top: 1px solid #e5eaef;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: all ease 0.75s;
      position: absolute;
      left: 0;
      bottom: 0 !important;
      background: rgb(222 237 254);
      z-index: 2;
}

.version_div a {
      color: black;
      text-decoration: none;
      display: flex;
      justify-items: center;
      align-items: center;
      white-space: nowrap;
      margin-bottom: 5px;
}

.version_div a p {
      margin: 0;
      font-weight: 500;
}

.version_div a,
.version_div p {
      font-weight: 500;
}

.version_div a:hover {
      color: #0560fd;
}

.version_div a i {
      font-size: 18px;
}

.sidebar-main .version_div {
      width: 100%;
      font-size: 12px;
}

.org_toper_no {
      min-height: 500px;
      position: relative;
      z-index: 2;
}

fcsro-time-bar .tableplatformlist.timeline_div {
      overflow-x: auto;
      margin-bottom: 5px;
}

.activitybars {
      display: inline-flex !important;
      align-items: center;
      color: #000;
      padding: 0px 15px;
      font-size: 14px;
      flex-wrap: wrap;
}

.activitybars>div {
      margin-bottom: 5px;
}

.spanish .table thead th.tble_hdr {
      font-size: 11px;
}

fcsro-profile .custom-select,
fcsro-profile .custom-select option {
      padding: 7px 30px 7px 10px !important;
      text-transform: capitalize !important;
}

select option {
      text-transform: capitalize
}

.german fcsro-employees .table thead th.tble_hdr,
.catalan fcsro-employees .table thead th.tble_hdr,
.french fcsro-employees .table thead th.tble_hdr {
      font-size: 11px;
}

.sminp {
      position: relative;
      top: 0;
      text-transform: none;
      font-size: 12px;
}

fcsro-employees .tableplatformlist+.table-responsive .table.mat-table td {
      max-width: inherit;
      text-overflow: unset;
      overflow: visible;
      padding-right: 0px;
}

.gallery_div fcsro-time-bar-v2 {
      display: inline-block;
}

fcsro-report .tabletitle {
      min-height: auto !important;
}

input.mat-input-element {
      font-size: 14px;
}

.chooselanguage {
      min-height: 25px;
      width: auto;
      height: auto;
}

.screenimgs {
      transition: all ease 0.75s;
      display: flex;
      flex-direction: column;
      background: #edf3ff;
      padding: 5px;
      border-radius: 6px;
      border: 1px solid #d9e8f7;
      height: 100%;
}

.screenimgs>p:empty {
      display: none;
}

.popup-modal {
      z-index: 1000;
}

.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
      z-index: 0;
}

.screenimgs:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      background: #fff;
}

.actvidlebox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
}

.actvbox,
.idlebox {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      flex-direction: column;
      padding: 5px 0px;
      margin: 0px 1px;
      transition: all ease 0.75s;
}

.actvbox {
      background: #d8e6ff;
}

.idlebox {
      background: #e5eaef;
}

.actvboxligh {
      background: #edf3ff;
}

.actvboxligh h5 {
      font-size: 14px;
}

.actvbox .brdrign,
.actvboxligh .brdrign {
      border-left: 1px solid #d4dee8;
}

.actvbox span:first-child,
.idlebox span:first-child {
      font-weight: normal;
}

.text-primary.emp_name:hover {
      color: #000 !important;
}

.mat-table tr {
      transition: all ease 0.75s;
}

.mat-table tr:nth-child(even) {
      background: #f7f7f7;
}

.mat-table tr:hover {
      background: #d8e6ff !important;
}

.mat-table tr:hover .actvbox {
      background: #fff;
}

fcsro-timelog .container-fluid {
      padding: 0px;
}

.mat-cell,
.mat-footer-cell {
      font-size: 12px;
}

.desg_Cellptxt {
      margin-bottom: 0px;
      padding: 5px 0px;
}

.adretable135 {
      min-width: 135px;
      max-width: 135px !important;
}

.adretable {
      max-width: inherit !important;
      min-width: 100px;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
      padding-left: 10px !important;
}

.top-tooltip {
      color: #0057a6 !important;
      margin: 0px 0px 0px -80px !important;
      font-weight: bold;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.emplabdeta {
      position: relative;
}

.emplabdetabox {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-height: 115px;
      position: relative;
      padding: 0px;
      overflow: hidden;
}

.emplabdeta .emplabdetabox {
      transition: all ease 0.75s;
}

.emplabdeta .emplabdetabox:hover {
      background: #edf3ff;
}

.empicon {
      font-size: 45px;
      min-height: 115px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      background: #edf3ff;
      box-shadow: 2px 0px 5px rgb(199, 215, 242);
      margin-right: 30px;
      color: #0560fd
}

.empdeta {
      font-size: 16px;
      color: #111;
      text-transform: none;
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: flex-start;
      text-align: left;
}

.badge-primary:focus,
.badge-primary:hover {
      color: #fff;
      background-color: #0052df !important;
}

.empdatabtns {
      text-align: right;
}

.btn-outline-primary span,
.btn-primary span,
.btn-outline-danger span,
.btn-outline-danger span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
}

.groupimgbox {
      transition: all ease 0.75s;
      position: relative;
      min-height: 250px;
      padding: 10px 15px;
      border-radius: 16px;
      border: 1px solid #d9e8f7;
      background: #edf3ff;
}

.groupimgbox:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      top: 0px;
      background: #fff;
}

fcsro-tags .data-wrapper table {
      white-space: normal;
}

fcsro-tags td.mat-cell:first-of-type,
fcsro-tags td.mat-footer-cell:first-of-type,
fcsro-tags th.mat-header-cell:first-of-type {
      padding-right: 10px;
}

.tasklist {
      cursor: pointer;
}

.tbcon {
      display: flex;
      flex-direction: row;
}

.tbbtns {
      width: 35px;
      text-align: center;
}

.tbcon .dots {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-right: 15px;
      word-break: break-word;
      flex-direction: column;
      line-height: 20px;
}

.tbbtns .btn {
      margin-right: 5px;
}

.tbbtns .btn:last-child {
      margin-right: 0px
}

;

.russian .table thead th.tble_hdr {
      font-size: 10px;
}

.spanish fcsro-designations table tr td .btn,
.catalan fcsro-designations table tr td .btn,
.german fcsro-designations table tr td .btn,
.indonesian fcsro-designations table tr td .btn {
      width: 35px !important;
}

.german fcsro-employees table tr td .btn {
      font-size: 12px;
}

.french fcsro-employees table tr td .btn,
.tamil .table thead th.tble_hdr {
      font-size: 10px;
}

.tamil fcsro-employees table tr td .btn {
      font-size: 9px;
}

.spanish fcsro-employees table tr td .btn,
.german fcsro-employees table tr td .btn,
.catalan fcsro-employees table tr td .btn {
      font-size: 11px;
}

.modal-body .tableplatformlist {
      box-shadow: none;
      padding: 0px;
}

.modal-header {
      background: #0560fd;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.modal-header h4 {
      color: #fff;
}

.modal-header .close {
      text-shadow: none;
}

fcsro-timelog-report .cdk-column-teamName {
      color: #000 !important;
}

.table thead th {
      border-top: none;
      text-transform: uppercase;
}

fcsro-login-report .mat-form-field-wrapper {
      padding-bottom: 0 !important;
}

.badge-outline-primary {
      border: 1px solid #0560fd;
}

.badge-outline-primary:hover {
      background: #0560fd;
      color: #fff;
}

.tpmaxh {
      margin-bottom: 0px !important;
      max-height: calc(100vh - 330px) !important;
      padding-right: 15px;
}

.mCSB_inside>.mCSB_container {
      margin-right: 15px;
}

.mCSB_scrollTools {
      opacity: 0.1;
}

.comingsoon {
      min-height: calc(100vh - 140px);
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
}

.tamil .mat-column-resetPassword .btn,
.french .mat-column-resetPassword .btn,
.spanish .mat-column-resetPassword .btn,
.catalan .mat-column-resetPassword .btn,
.german .mat-column-resetPassword .btn,
.indonesian .mat-column-resetPassword .btn,
.russian .mat-column-resetPassword .btn,
.romanian .mat-column-resetPassword .btn   {
      display: block;
      margin: 5px auto !important;
      width: 100% !important;
}

.russian .mat-column-resetPassword .btn {
      font-size: 11px;
}

.english fcsro-teams .emp_name~td.text-center .btn {
      width: 100px;
}

fcsro-teams .emp_name~td.text-center .btn {
      width: 125px;
}

.russian fcsro-teams .emp_name~td.text-center .btn {
      width: 210px;
}


.iq-waves-effect.grand-child~app-list-style1 ul li a {
      padding-left: 15px !important;
}

.iq-waves-effect.grand-child~app-list-style1 ul {
      background: #ecf3fc;
}

.h-100vh {
      min-height: calc(100vh - 400px);
}

fcsro-profile .iq-card {
      box-shadow: none;
      margin-bottom: 0px;
      min-height: 200px;
}

.comingsoon h4 {
      letter-spacing: 15px;
      font-size: 40px;
      font-weight: 600;
      color: #0560fd;
}

.comingsoon p {
      color: #212325;
      font-size: 16px;
}

.comingsoon img {
      max-width: 100%;
      width: auto;
}

fcsro-gallery-media mat-tab fcsro-place-holder .tableplatformlist {
      box-shadow: none;
}

.empreportht {
      height: 295px !important
}

.ws-normal {
      white-space: normal;
}

.nav-tzcc {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 15px;
      height: 100%;
      border-right: 1px solid #e5eaef;
}

.nav-tzcc i {
      margin-right: 6px;
      font-size: 17px;
      color: #0660fe;
}

.nav-tzcc>span {
      color: #495058 !important;
}

.mat-icon-button {
      color: #a0a6b1;
}

.hours.days.selectable-date.current-date-marker.active-date {
      background: #d1ffca;
      color: #138402;
      border-color: #8fe382;
}

.mat-paginator {
      background: transparent;
}

.created_time {
      margin: 0px !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 10px;
}

.created_time i {
      margin-right: 5px;
      color: #0560fd;
}

.form-group label,
.custom-control-input+.custom-control-label {
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 500;
}

.form-group label+span.pull-right {
      font-size: 11px;
      letter-spacing: 1px;
}

.iq-sidebar-menu .iq-menu li.menu-open a .iq-arrow-right {
      color: #0560fd;
}

.table td,
.table th {
      font-size: 12px;
}

.mat-tab-group,
.mat-select,
.mat-option,
.mat-form-field-label,
.table td,
.table th,
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger,
.js-plotly-plot .plotly,
.js-plotly-plot .plotly div {
      font-family: 'Roboto', Verdana, sans-serif !important;
      text-transform: none;
}

.mat-form-field-label {
      font-size: 12px !important;
}

.clsbrdimg {
      border-radius: 16px;
      overflow: hidden;
}

.groupimgbox .card-subtitle {
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      margin-bottom: 6px !important;
}

.groupimgbox .card-subtitle .ri-calendar-event-fill {
      margin-right: 5px;
      color: #0560fd;
}

.groupimgbox .card-subtitle .ri-time-line {
      margin: 0px 5px 0px 15px;
      color: #0560fd;
}

.mat-button {
      border-radius: 15px !important;
      text-transform: none;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
      border-radius: 6px 0 0 6px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-radius: 0 6px 6px 0 !important;
}

.mat-calendar-body-cell-content,
.mat-datepicker-content,
.mat-autocomplete-panel,
.mat-select-panel {
      border-radius: 15px !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
      background: transparent !important;
}

.mat-tooltip {
      color: #000 !important;
      background: #f9fcfe !important;
      box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2) !important;
      border: 2px solid #fff !important;
      border-radius: 15px !important;
      padding: 10px 15px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-start {
      min-width: 15px !important;
}

#intergramRoot div {
      background: #0560fd !important;
      transition: all ease 0.75s;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
      color: #fff !important;
      background-color: #c8c8c8;
      border-color: #c8c8c8;
}

.btn-outline-primary.disabled::before,
.btn-outline-primary:disabled::before {
      display: none;
}

.empdeta strong {
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 500;
      text-transform: uppercase;
}

.mat-dialog-container {
      border-radius: 15px !important;
      background: #f9fcfe !important;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05) !important;
}

.alert {
      border-radius: 15px;
}

.taskrowmob .input-group .btn {
      height: auto !important;
      width: auto !important;
      line-height: normal;
      display: flex;
      align-items: center;
}

fcsro-app-settings .tableplatformlist h6 {
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.5px;
}

.ngx-slider .ngx-slider-tick-value {
      font-size: 12px;
}

mat-datepicker-toggle .mat-icon-button.mat-button-base {
      width: 2em !important;
      height: 2em !important;
}

fcsro-gallery-media .mat-icon-button {
      line-height: 33px !important;
}

fcsro-gallery-media .arrowslr .mat-icon-button {
      line-height: 21px !important;
}

fcsro-attendance-report .mat-form-field-label {
      padding-left: 5px;
}

fcsro-employee-report .tabletitle.thrblksec {
      min-height: 171px;
}

.sidebar-main .version_div a {
      overflow: hidden;
      width: 23px;
}

.longlinksfr {
      display: flex;
      flex-direction: row;
}

.llfrtxt {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
}

.llfricon {
      margin-right: 10px;
      font-size: 18px;
      cursor: pointer;
      transition: all ease 0.75s
}

.llfricon:hover {
      color: #0560fd;
}

.main-svg {
      background: transparent !important;
}

fcsro-attendance-report .mat-sort-header-container {
      white-space: nowrap;
}

.mat-tab-label .mat-tab-label-content {
      text-transform: uppercase;
      font-family: 'Roboto', Verdana, sans-serif;
      font-weight: 600;
      font-size: 14px;
      color: #333333;
}


/*new chnages*/
.alert-info {
      background: transparent !important;
      border-color: #0560fd;
      margin: 0;
      color: #333 !important;
}

.alert-info .iq-alert-text a {
      color: #0560fd;
      text-decoration: underline;
}

.alert-info .iq-alert-text a:hover {
      text-decoration: none;
}

.alert-info .close {
      color: #333;
}

.alert-info:hover {
      background: #ecf3fc !important;
}

.ngx-slider {
      max-width: 260px;
}

.col-md-6>.tableplatformlist>.mt-4>.ngx-slider {
      max-width: 340px !important;
}

.col-md-12 .tableplatformlist>div>.mt-4>.ngx-slider {
      max-width: 500px !important;
}

/*new chnages*/

.sign-in-from .form-group .btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      background: #0560fd !important;
      border-color: #0560fd !important;
}

.empdetaemail {
      display: block;
      text-overflow: ellipsis;
      max-width: 280px;
      overflow: hidden;
      white-space: nowrap;
}

.sign-in-page .boxshad {
      display: flex;
      height: 100vh;
      padding-top: 100px;
      justify-content: center;
}

.sign-in-from h1 {
      font-size: 25px;
}

.sign-in-from {
      padding: 0 0px;
}

.sign-in-page app-sign-in,
.sign-in-page fcsro-manager-login,
.sign-in-page app-password-reset {
      width: 100%;
}

.dstep {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 185px;
      background: #fff;
      margin: 25px 0px 15px 0px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
      padding: 35px 15px 10px;
      border-radius: 15px;
      text-transform: none;
      color: #212325;
      margin-bottom: 15px;
      border: 2px solid #fff;
      position: relative;
      text-align: center;
}

.dstep .dsnum {
      font-size: 25px;
      color: #0560fd;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translatex(-50%);
      width: 50px;
      height: 50px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #eee;
      background: #fff;
      line-height: 45px;
      font-weight: bold;
}

.dstep mark {
      background-color: #fff;
      margin-top: 5px;
      display: block;
}

.logcodeas {
      width: 100%;
      display: flex;
      text-align: left;
      align-items: center;
      font-size: 12px;
      padding: 5px 0px;
}

.logcodeas:not(:last-child) {
      border-bottom: 1px solid #edf3ff;
}

.logcodeas .dslodeta {
      width: 70px;
      display: inline-block;
}

.dsstepbg h4,
.dsstepbg h5 {
      color: #000 !important;
}

.dsstepbg {
      background: #fff !important;
      padding: 15px;
}


/*Login page new changes*/
.sign-in-page .boxshad {
      display: flex;
      height: auto;
      justify-content: center;
      padding-top: 100px;
}

.sign-in-page .boxshad fcsro-choose-login {
      width: 100%;
}

.sign-in-page .boxshad>fcsro-choose-login>.sign-in-from {
      width: 100%;
      display: flex;
      align-items: initial;
      justify-content: space-between;
      background: #E8F1FF;
      border-radius: 20px;
      box-shadow: 0 10px 20px #b7b7b7;
}

.sign-in-page .boxshad .sign-in-from ._lft {
      width: 50%;
      background: #0560fd;
      padding: 30px;
      border-radius: 20px 0 0 20px;
}

.sign-in-page .boxshad .sign-in-from ._lft .row {
      align-items: center;
      justify-content: center;
}

.sign-in-page .boxshad .sign-in-from ._lft .row ul {
      margin: 0;
      padding: 0;
      flex-direction: column;
      margin-top: 55px;
}

.sign-in-page .tab-content>.tab-pane {
      transition: all ease 0.75s;
}

.sign-in-page .boxshad .sign-in-from ._lft .row ul li {
      list-style: none;
}

.sign-in-page .boxshad .sign-in-from ._lft .row ul li:not(:last-child) {
      margin-bottom: 30px;
}

.sign-in-page .boxshad .sign-in-from ._lft .row ul li a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-size: 17px;
      line-height: 20px;
      padding: 15px 15px;
      border-radius: 10px;
      background: #5392fd;
      text-decoration: none;
      transition: all ease 0.75s;
      border: 1px solid #1a6eff;
      box-shadow: 0 2px 3px 0 rgb(0 70 187);
}

.sign-in-page .boxshad .sign-in-from ._lft .row ul li a.nav-link.active,
.sign-in-page .boxshad .sign-in-from ._lft .row ul li a.nav-link:hover {
      border: 1px solid #1a6eff;
      box-shadow: inset 0 0 7px 0 rgb(0 32 87 / 80%);
      background: #0046bb;
}

.sign-in-page .boxshad .sign-in-from ._rft {
      width: 50%;
      padding: 30px;
      min-height: 583px;
}

.sign-in-page .boxshad .sign-in-from ._rft .sign-info {
      padding: 0;
      margin: 0;
}

.sign-in-from label {
      color: #495058 !important;
}

.c_box input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
}

.c_box {
      padding-left: 0;
}

.c_box label {
      position: relative;
      cursor: pointer;
      margin: 0;
      padding-left: 24px;
}

.c_box label::before {
      content: '';
      background-color: transparent;
      border: 1px solid #aeb2b4;
      padding: 7px;
      display: inline-block;
      position: absolute;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 4px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
}

.c_box input:checked+label::before {
      border-color: #0560fd !important;
      background-color: #0560fd;
}

/*Login page new changes*/

/*Focus Loop*/

.focus_loop {
      width: 100%;
      margin-bottom: 30px;
}

p.l_chat {
      color: #0560fd;
      padding-bottom: 52px;
}

.focus_loop .fl_cnt {
      width: 100%;
      padding: 15px 0;
      background: #fff;
      border-radius: 15px;
}

.focus_loop .fl_cnt .head {
      width: 40%;
      margin-left: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
}

.focus_loop .fl_cnt .head h2 {
      font: 26px/30px Poppins-Bold;
      color: #000;
}

.focus_loop .fl_cnt .head .date_selector {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 341px;
      padding: 0px 10px;
      box-shadow: 0 0 5px 0 #e1e1e1;
      border-radius: 10px;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle__list {
      display: flex;
      flex: 1 1 auto;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin: 0;
      padding: 10px 0;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle__list-item {
      flex: 0 0 auto;
      padding: 5px;
      max-width: 100px;
      text-align: center;
      background: rgba(210, 233, 255, 0.54);
      margin-right: 10px;
      border-radius: 6px;
      list-style: none;
      font-size: 10px;
      line-height: 20px;
      text-transform: uppercase;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle__list-item span {
      display: flex;
      font-size: 20px;
      margin-right: 5px;
}

.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle__list-item.active {
      border: 2px solid #0560fd;
      background: #000 !important;
      color: #fff;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle__list-item.active {
      background: #0560fd;
      color: #fff;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle__list::-webkit-scrollbar {
      display: none;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle button {
      width: 35px;
      height: 35px;
      background-color: #fff;
      padding: 5px;
      border-color: transparent;
      box-shadow: 0 0 5px 0 #e1e1e1;
      border-radius: 5px;
      transition: 0.5s all;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      position: relative;
      z-index: 1;
      overflow: hidden;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle button.scroll-toggle__button.scroll-toggle__button--left {
      background: url(../images/left-chevron.png) no-repeat center #fff;
      background-size: 25px;
      box-shadow: none !important;
      margin-right: 10px;
}

.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle button.scroll-toggle__button.scroll-toggle__button--left {
      background: url(../images/left-chevron-white.png) no-repeat center #363739;
      background-size: 25px;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle button.scroll-toggle__button.scroll-toggle__button--right {
      background: url(../images/right-chevron.png) no-repeat center #fff;
      background-size: 20px;
      box-shadow: none !important;
}

.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle button.scroll-toggle__button.scroll-toggle__button--right {
      background: url(../images/right-chevron-white.png) no-repeat center #363739;
      background-size: 20px;
}

.rept_det .lft {
      background: #fff;
      padding: 20px;
      box-shadow: 0 0 5px 0 #e1e1e1;
      border-radius: 6px;
}

.rept_det .lft ul {
      padding: 0;
      margin: 0;
}

.rept_det {
      width: 100%;
      display: flex;
      align-items: initial;
      justify-content: space-between;
}

.rept_det .col-3 {
      flex: 0 0 20%;
      max-width: 20%;
}

.rept_det .lft ul li {
      list-style: none;
      padding: 10px 15px;
      border: 1px solid #E2E8F0;
      cursor: pointer;
      box-shadow: 0 0 3px 0 #e1e1e1;
      border-radius: 6px;
      transition: 0.5s all ease-in-out;
      text-align: left;
}

.rept_det .lft ul li.current {
      background: #e7f3ff;
      border-color: #0560fd;
}

.rept_det .lft ul li:not(:last-child) {
      margin-bottom: 10px;
}

.tab_frm {
      width: 100%;
      margin-bottom: 25px;
}

.tab_frm input {
      width: 100%;
      border: 1px solid transparent;
      border-radius: 30px;
      padding: 10px 15px;
      box-shadow: 0 0 5px 0 #e1e1e1;
      background: url(../images/filter.png) no-repeat right 15px center #fff;
      background-size: 18px;
}

.rept_det .col-9 {
      flex: 0 0 80%;
      max-width: 80%;
      padding-right: 0;
}

.rept_det .col-9 .tabs_content {
      min-height: 300px;
      height: 100%;
}

.tabs_content {
      padding: 20px;
      background: #fff;
      box-shadow: 0 0 5px 0 #e1e1e1;
      border-radius: 6px;
      position: relative;
}

.tabs_content .tab-content {
      width: 100%;
      height: 100%;
      transition: 0.5s all ease-in-out;
      display: none;
}

.tabs_content .tab-content.current {
      display: block;
}

.emp_content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
}

.emp_content .emp_chart {
      width: 60%;
      position: relative;
}

.emp_chart .scroll-box {
      width: 100%;
      width: 100%;
      max-height: 645px;
      overflow: hidden;
      overflow-y: scroll;
}

.scroll-box::-webkit-scrollbar,
.view_details::-webkit-scrollbar,
.t_activity .table .t_body .flex div:last-child ul::-webkit-scrollbar {
      width: .4em;
      height: .4em;
}

.itl i {
      font-style: normal;
}

.scroll-box::-webkit-scrollbar,
.scroll-box::-webkit-scrollbar-thumb,
.view_details::-webkit-scrollbar,
.view_details::-webkit-scrollbar-thumb,
.t_activity .table .t_body .flex div:last-child ul::-webkit-scrollbar-thumb {
      overflow: visible;
      border-radius: 4px;
}

.scroll-box::-webkit-scrollbar-thumb,
.view_details::-webkit-scrollbar-thumb,
.t_activity .table .t_body .flex div:last-child ul::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .2);
}

.cover-bar {
      position: absolute;
      background: #fff;
      height: 100%;
      top: 0;
      right: 0;
      width: .4em;
      -webkit-transition: all .5s;
      opacity: 1;
}

.emp_detail .cover-bar {
      right: 20px;
}

.iq-sidebar .cover-bar {
      background: linear-gradient(to bottom, #f9fcfe 5.8%, #deedfe, #ecf3fc 1%);
      right: 4px;
      z-index: 1;
}

/* MAGIC HAPPENS HERE */
.emp_chart:hover .cover-bar,
.emp_detail:hover .cover-bar,
.iq-sidebar:hover .cover-bar {
      opacity: 0;
      -webkit-transition: all .5s;
}



.emp_chart .chart {
      width: 100%;
      display: flex;
      align-items: inherit;
      justify-content: center;
}

.chart .chart_lft {
      width: 0%;
      position: relative;
}

.chart .chart_rft {
      width: 75%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 18px 10px;
}

.chart_rft .blk:last-child {
      padding-left: 10px;
      position: relative;
      min-width: 100px;
}

.chart_rft .blk:first-child {
      min-width: 72px;
}

.chart .chart_lft::after {
      content: "";
      width: 3px;
      height: 100%;
      display: block;
      background: #add7ff8a;
      position: absolute;
      right: 10px;
      top: 0;
}

.chart .chart_rft::before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      left: -18px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      background: #0560fd;
      z-index: 1;
}

.chart .chart_rft::after {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      border: 1px solid #0560fd;
      border-radius: 100%;
      position: absolute;
      left: -23px;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0 0 5px 0 #0658e6;
      background: #fff;
      z-index: 0;
}

.emp_content .emp_detail {
      width: 38%;
      padding: 20px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 0 3px 0 #e1e1e1;
      position: relative;
      border: 1px solid #E2E8F0;
}

.view_scrn {
      width: 100%;
      margin-bottom: 35px;
}

.view_scrn button {
      width: 100%;
      color: #fff;
      background: #0052df;
      border: 1px solid #0052df;
      border-radius: 30px;
      padding: 10px 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
}

.view_details>table {
      width: 100%;
}

.view_details>table tbody tr td span.box {
      width: 50px;
      height: 40px;
      border: 1px solid #E2E8F0;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 3px 0 #e1e1e1;
}

.view_details>table tbody tr td .blk p {
      margin: 0;
      line-height: 15px;
}

.view_details>table tbody tr td .blk p.individualReportLink {
      text-decoration: underline;
      color: #000;
}

.view_details>table tbody tr td .blk p.google {
      color: #000;
}

.view_details>table tbody tr td .blk p.fb {
      color: #4267B2;
}

.view_details>table tbody tr td .blk p.msoffice {
      color: #4145ac;
}

.view_details>table tbody tr td .blk p.vs {
      color: #0e7dca;
}

.view_details>table tbody tr td .blk p.tele {
      color: #2fa3d9;
}

.view_details>table tbody tr td .blk span {
      width: auto;
      padding: 1px 10px;
      border-radius: 30px;
      border: 1px solid;
      color: #fff;
      font-size: 10px;
      line-height: 15px;
}

.view_details>table tbody tr td .blk span.google_tag {
      background: #e94235;
      border-color: #e94235;
}

.view_details>table tbody tr td .blk span.fb_tag {
      background: #4267B2;
      border-color: #4267B2;
}

.view_details>table tbody tr td .blk span.msoffice_tag {
      background: #4145ac;
      border-color: #4145ac;
}

.view_details>table tbody tr td .blk span.vs_tag {
      background: #0e7dca;
      border-color: #0e7dca;
}

.view_details>table tbody tr td .blk span.tele_tag {
      background: #2fa3d9;
      border-color: #2fa3d9;
}

.view_details>table thead th {
      padding-bottom: 10px;
      width: 20%;
      position: relative;
      font-size: 14px;
}

.view_details>table thead th button.sort {
      background: transparent;
      border: none;
      max-width: 15px;
      padding: 0;
      margin: -3px 0 0 10px;
}

.view_details>table thead th button.sort img {
      object-fit: contain;
      width: 100%;
      height: 100%;
}

.view_details>table tbody tr td {
      padding-bottom: 27px;
}

.justify {
      justify-content: end;
}

.view_details {
      max-height: 505px;
      overflow: hidden;
      overflow-y: scroll;
}

.view_details>table thead th label.box {
      width: 50px;
      height: 40px;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
}

.view_details>table thead th label {
      font-weight: bold;
      line-height: 30px;
}

.chart .chart_rft .box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      background: #e7f3ff;
      padding: 25px 15px 15px;
      margin-left: 15px;
      border-radius: 5px;
}

.chart .chart_rft .box::before {
      content: "";
      display: block;
      border: 11px solid #e7f3ff;
      position: absolute;
      left: -22px;
      top: 30%;
      transform: translateY(-30%);
      border-left-color: transparent;
      border-top-color: transparent;
      border-bottom-color: transparent;
}

.round_img {
      width: 40px;
      height: 40px;
      position: absolute;
      right: -9px;
      top: 37%;
      transform: translateY(-37%);
      z-index: 2;
      border-radius: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #0052df;
}

.round_img img {
      background: #0052df;
      border-radius: 100%;
}

/*Focus Loop*/

/*new changes*/
.iq-card-body .border .custom-control {
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.iq-card-body .border .custom-control .custom-control-label::before,
.iq-card-body .border .custom-control .custom-control-label::after {
      top: 50% !important;
      transform: translateY(-50%) !important;
}

.iq-card-body .pl-3.mb-3 .custom-control-label::before,
.iq-card-body .pl-3.mb-3 .custom-control-label::after {
      top: 2px;
}

.alert.alert-.text-white.bg-info {
      margin: 5px 10px 5px 0;
      min-height: 42px;
      max-height: 42px;
      padding: 10px;
}

.alert.alert-.text-white.bg-info button {
      min-height: 30px;
      margin-left: 10px;
}

fcsro-app-settings .tableplatformlist h6 span.enable {
      color: green;
      min-width: 65px;
}

fcsro-app-settings .tableplatformlist h6 span.disable {
      color: red;
      min-width: 65px;
}

.mat-sort-header-button {
      text-transform: uppercase;
}

.table thead th {
      text-transform: uppercase;
      font-weight: 600;
      background: #ecf3fc;
      padding-top: 15px !important;
      padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(even) {
      background-color: rgb(235, 245, 255);
}

.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgb(255, 255, 255);
}

.contentsec .tableplatformlist label,
.contentsec .tableplatformlist .etttile1,
.row.ng-star-inserted .tabletitle h6,
.activitybars>span,
.actsetlist p,
.tableplatformlist1 h4,
.tableplatformlist h4 {
      text-transform: uppercase;
}

.contentsec .tableplatformlist label,
.mat-radio-label-content {
      font-weight: bold;
}

.iq-sidebar-menu .iq-menu li.active>a:hover {
      background: rgb(213 212 212 / 54%);
}

.view_scrn h4 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-transform: capitalize;
}

.total_val ul {
      padding: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 200px;
}

.total_val ul li {
      list-style: none;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
}

.total_val ul li span {
      display: block;
      color: #0560fd;
      font-weight: bold;
}

.row.ng-star-inserted .rept_det .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
}

.chart_rft .blk h5 {
      padding: 0;
      font-size: 14px;
      text-transform: inherit;
      font-weight: 600;
}

.chart_rft .blk:last-child h6 {
      font-size: 11px;
}

fcsro-over-time-report.ng-star-inserted .tableplatformlist.table-responsive .mat-form-field {
      width: auto !important;
      max-width: 230px;
      min-width: 230px;
}

/*ACTIVE STREAM*/
.act_stream {
      width: 100%;
      box-shadow: 0 0 5px 0 #e1e1e1;
      padding: 10px 15px;
      border-radius: 6px;
      margin-bottom: 20px;
}

.live_stream ul {
      display: flex;
      overflow-x: scroll;
      flex: 1 1 auto;
      margin: 0px 10px;
      padding: 0;
      flex-wrap: nowrap;
      -ms-overflow-style: none;
      scrollbar-width: none;
}

.live_stream ul::-webkit-scrollbar {
      display: none;
}

.live_stream ul li {
      list-style: none;
      transition: all ease .75s;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      margin-right: 15px;
      width: 15.8%;
      flex-direction: column;
}

.live_stream ul li p i {
      color: #0560fd;
}

.live_stream ul li p {
      margin: 0;
      width: 100%;
      font-weight: 600;
      text-align: right;
}

.live_stream ul li img {
      width: 100%;
      object-fit: cover;
}

.s_head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.s_head a {
      margin-left: 15px;
      font-size: 12px;
      line-height: 24px;
      color: #0560fd;
}

.live_stream ul li .s_img {
      background: #edf3ff;
      width: 100%;
}

/*ACTIVE STREAM*/
.d-inline-block.float-sm-right>mat-paginator.mat-paginator,
.text-right>mat-paginator.mat-paginator,
.contentsec>div>mat-paginator.mat-paginator:first-child,
.text-right>mat-paginator.d-inline-block.mat-paginator {
      display: none !important;
}

.mat-sort-header-button {
      font-weight: 500 !important;
}

.idel_alertbox {
      background: #f9fcfe;
      box-shadow: 0 0px 3px rgb(0 0 0 / 5%);
      padding: 30px 15px;
      border-radius: 15px;
      color: #212325;
      margin-bottom: 15px;
      border: 2px solid #fff;
      position: relative;
}

.alert_full .ngx-slider {
      max-width: 550px;
}

span.enable {
      color: green;
}

span.disable {
      color: red;
}

.idel_alertbox h6 {
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.5px;
}

table.mat-table tbody tr td button:first-child {
      width: auto;
}

body.french table.mat-table tbody tr td button:first-child {
      width: auto;
}

.tableplatformlist.mb-3>h6+.col-md-6>.mat-form-field {
      max-width: 540px;
}

.rept_det button.btn.btn-primary {
      width: 110px;
}

.col-md-12.text-center.viewdownbtns button {
      width: 140px;
}

.row.actsetlist>.col-md-4.col-sm-12>.tableplatformlist {
      min-height: 110.78px;
}

.col-md-12.text-center.viewdownbtns button span+i {
      position: absolute;
      right: 15px;
}

table.mat-table tbody tr td button.eye_btn {
      width: auto;
}

.project_report .row.ng-star-inserted {
      align-items: inherit;
}

.project_report .row.ng-star-inserted .col-md-6 .tableplatformlist {
      margin-bottom: 0;
}

.project_report .row.ng-star-inserted .col-md-6 .actvbox {
      height: 100%;
}

.teams_detail .pagetitbtn button:first-child {
      min-width: 145.48px;
}

.teams_detail .pagetitbtn button:last-child {
      min-width: 110px;
}

.new_team .form-group {
      position: relative;
      padding-bottom: 25px;
      margin: 0;
}

.new_team .form-group .invalid-feedback.ng-star-inserted {
      position: absolute;
      bottom: 5px;
}

.new_team .form-group .validation-feedback.ng-star-inserted {
      position: absolute;
      bottom: -10px;
}

.over_time .tableplatformlist>div .mat-form-field-label-wrapper {
      left: 0px;
}

.employee_page .table-responsive table tbody tr td:nth-child(3),
.employee_page .table-responsive table tbody tr td:nth-child(5),
.employee_page .table-responsive table tbody tr td:nth-child(6),
.employee_page .table-responsive table tbody tr td:nth-child(7),
.employee_page .table-responsive table thead tr th:nth-child(5),
.employee_page .table-responsive table thead tr th:nth-child(6),
.employee_page .table-responsive table thead tr th:nth-child(7) {
      text-align: center !important;
}

.employee_page .table-responsive table thead tr th:nth-child(3) div {
      justify-content: center;
}

.employee_page .table-responsive table thead tr th:last-child {
      padding-left: 100px;
}

.desg_Cellptxt+.actvidlebox {
      width: 161px;
}

.desg_Cellptxt+.actvidlebox .idlebox,
.desg_Cellptxt+.actvidlebox .actvbox {
      align-items: center;
}

.new_team .table-responsive .table thead tr th:nth-child(2) {
      width: 30%;
}

.new_team .table-responsive .table thead tr th:nth-child(2) {
      width: 320px;
      text-align: center !important;
}

.new_team .table-responsive .table thead tr th:last-child {
      text-align: center !important;
}

.new_team .table-responsive .table tbody tr td:nth-child(2) {
      padding: 0;
}

.new_team .table-responsive .table tbody tr td:last-child button {
      margin: 0 !important;
}

.designation_frm form {
      min-width: 524px;
      max-width: 524px;
}

.mat-dialog-content {
      overflow-x: hidden !important;
}

.designation_frm form .form-group,
.add_workshift form .form-group {
      margin-bottom: 28px;
}

.designation_frm form .form-group .invalid-feedback.ng-star-inserted,
.add_workshift form .invalid-feedback.ng-star-inserted {
      position: absolute;
      bottom: 12px;
}

.designation_frm form .form-group .validation-feedback.ng-star-inserted,
.add_workshift form .validation-feedback.ng-star-inserted {
      position: absolute;
      bottom: 1px;
}

.designation_frm form .form-group .validation-feedback.ng-star-inserted+.validation-feedback.ng-star-inserted,
.add_workshift form .validation-feedback.ng-star-inserted+.validation-feedback.ng-star-inserted {
      bottom: -10px;
}

.add_workshift form {
      margin: 0;
}

.login_report {
      min-height: 100vh;
}

.employee_page .table-responsive table thead tr th:last-child {
      width: 17%;
}

.employee_page .table-responsive table thead tr td:nth-child(5),
.employee_page .table-responsive table thead tr td:nth-child(6) {
      text-align: center !important;
}



/*EMPLOYEE REPORTS*/
.empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec .etttile {
      justify-content: center;
}

.empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec .col-3 {
      text-align: center;
}

.empgallery .gegsec fcsro-horizontal-calendar .pickcalendar>div:first-child {
      flex: 0 0 40.666667%;
      max-width: 40.666667%;
}

.empgallery .gegsec fcsro-horizontal-calendar .pickcalendar>div:last-child {
      flex: 0 0 59.333333%;
      max-width: 59.333333%;
}

.timeline_div {
      width: 100% !important;
}

.box-shadow.img_overdiv>img {
      border-radius: 5px;
}

/*EMPLOYEE REPORTS*/


.dash_board .live_stream .screenimgs {
      background: #e7f3ff;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05);
      border: 2px solid #E2E8F0;
      border-radius: 6px;
      padding: 0 5px 5px;
}

.emp_report,
.container-fluid {
      min-height: 100vh;
}

.dormant_report .table-responsive table thead tr th:first-child {
      width: 30%;
}

.dormant_report .table-responsive table thead tr th:last-child {
      width: 23%;
}

/*new changes*/

.mat-dialog-container addingform {
      max-width: 1000px;
      display: block;
}

/*ATTENDANCE REPORT*/
.at_report .tableplatformlist>.row {
      margin: 0;
      justify-content: flex-end;
}

.at_head {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.at_head .card p {
      margin: 0;
      width: 100%;
      text-transform: uppercase;
      height: 100%;
      font-weight: 500;
}

.at_head .card {
      width: auto;
      min-width: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      text-align: center;
      padding: 10px 5px;
}

.at_select {
      width: 66%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.at_select .form-group {
      margin-bottom: 0;
}

.at_select>div {
      width: auto !important;
      flex: unset;
}

span.at_box {
      width: 20px;
      height: 20px;
      display: inline-flex;
      background: #0560fd;
      color: #fff;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      font-size: 10px;
      line-height: 12px;
      border-radius: 3px;
}

.at_details .row.dashpages {
      margin: 0;
      justify-content: space-between;
}

.name_prf .lft .card {
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #0760fa;
      color: #0560fd;
      height: 35px;
      font-size: 24px;
      line-height: 26px;
}

.at_details .fill_sec {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
}

.at_details .fill_sec .mat-form-field-appearance-outline .mat-form-field-wrapper {
      padding: 0;
      margin: 0;
}

.name_prf .rft>div {
      position: relative;
      background: transparent;
}

.at_details .fill_sec button {
      background: transparent;
      border: none;
}

.fill_sec .cards .card p {
      display: flex;
      align-items: inherit;
      justify-content: space-between;
      margin: 0;
      padding: 5px;
      min-width: 120px;
      text-align: center;
}

.fill_sec .cards .card p>span {
      line-height: 16px;
}

.fill_sec .cards .card p>span:first-child {
      width: calc(100% - 55px);
}

.fill_sec .cards .card p>span:last-child {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      width: 48px;
}

.name_prf .rft {
      width: calc(100% - 75px);
      padding-left: 10px;
}

.name_prf .rft>div>button {
      position: absolute;
      top: -25px;
      background: transparent;
      border: none;
      height: 22px;
      line-height: 22px;
      right: 0;
}

.name_prf .rft>.text-center {
      position: relative;
      padding-bottom: 0 !important;
}

.name_prf .rft>.text-center .mat-form-field {
      width: 100% !important;
      line-height: 20px;
}

.name_prf .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
}

.name_prf .rft>div>button:first-child {
      right: 20px;
}

.atdet_lft input.mat-input-element {
      line-height: 20px;
}

.name_prf .rft>.text-center+div {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      position: absolute;
      left: 15px;
      bottom: 8px;
}

.atdet_lft {
      display: flex;
      align-items: center;
      width: calc(100% - 51px);
}

.atdet_lft>.cards .card p {
      margin: 0;
      color: #fff;
      font-size: 12px;
      line-height: 18px;
      width: 100%;
}

.atdet_lft>.cards .card {
      width: 65px;
      height: 95px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      padding: 5px 3px;
}

.atdet_lft>.cards .card p span {
      display: block;
      background: #fff;
      padding: 5px;
      font-size: 15px;
      line-height: 13px;
}

.atdet_lft>.cards .card p b {
      display: block;
      font-size: 30px;
      line-height: 30px;
}

.cards {
      display: flex;
      align-items: center;
      margin-left: 15px;
}

.atdet_lft button.clear_btn {
      position: absolute;
      right: 0;
      top: -21px;
      background: transparent;
      border: none;
}

.dash_board .live_stream .screenimgs:hover {
      box-shadow: none;
}

.rept_det .lft ul button {
      box-shadow: 0 0 5px 0 #e1e1e1;
      border-radius: 5px;
      color: #000;
      background: #ffffff;
      border: 1px solid #E2E8F0;
}

.tab_fr {
      position: relative;
}

.tab_fr span {
      position: absolute;
      font-size: 9px;
      line-height: 12px;
      text-transform: uppercase;
      display: inline-block;
      padding: 5px;
      background: #fff;
      left: 10px;
      top: -12px;
}

.tab_fr span label {
      margin: 0;
}

.s_head button {
      width: auto;
      background: #ecf3fc;
      color: #4d4d4d !important;
      border: 1px solid #cad5e4;
      border-radius: 5px;
      font-weight: 600;
}

.tableplatformlist .col-md-12>h6 {
      text-transform: uppercase;
}

.time_log .table-responsive .table thead th.tble_hdr:first-child {
      min-width: 200px;
}

span.tooltips {
      position: absolute;
      width: auto;
      min-width: 200px;
      max-width: 200px;
      height: auto;
      left: 50%;
      top: -40px;
      transform: translateX(-50%);
      background: #fbfbfb;
      color: #000;
      font-size: 10px;
      line-height: 18px;
      transition: 0.5s all ease-in-out;
      padding: 6px;
      border-radius: 5px;
      visibility: hidden;
      opacity: 0;
      box-shadow: 0 0 10px 0 #e1e1e1;
      text-align: center;
      border: 1px solid #dcdcdc;
      z-index: 10;
}

.app_act_list .strips ul li::before {
      content: "";
      display: block;
      border: 7px solid #fff;
      position: absolute;
      left: 50%;
      bottom: 21px;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      transform: translateX(-50%);
      transition: 0.5s all ease-in-out;
      opacity: 0;
      visibility: hidden;
}

.app_act_list .strips ul li:hover::before {
      opacity: 1;
      visibility: visible;
}

.tool {
      position: relative;
      width: 100%;
}

.app_act_list .strips ul li:hover>.tool>span.tooltips {
      visibility: visible;
      opacity: 1;
}

/*ATTENDANCE REPORT*/

/*DARK THEME*/

.dark_theme .iq-top-navbar,
.dark_theme .iq-sidebar-logo,
.dark_theme .version_div {
      background: #1d232c;
      border: none;
      box-shadow: none;
}

.dark_theme .iq-top-navbar {
      border-color: #2d2d2d;
}

.dark_theme .dialog_width h4 {
      color: #403d3d;
}

.dark_theme .text-muted {
      color: #dfe1e4 !important;
}

.dark_theme .text-muted p small {
      font-size: 12px;
}

.dark_theme .nav-tzcc i {
      color: #0560fd;
}

.dark_theme .nav-tzcc>span {
      color: #999999 !important;
}

.dark_theme .version_div>p {
      color: #fff;
}

.dark_theme .version_div>a:hover {
      color: #fff;
}

.dark_theme .version_div>a {
      color: #9f9f9f;
}

.dark_theme .iq-sidebar-menu .iq-menu li.active>a,
.dark_theme .iq-sidebar-menu .iq-menu li.menu-open>a {
      color: #ffffff;
      background: rgb(5 96 253);
}

.dark_theme .iq-sidebar-menu .iq-menu li a {
      color: #9f9f9f;
}

.dark_theme .iq-sidebar-menu .iq-menu li a:hover,
.dark_theme .iq-sidebar-menu .iq-menu li ul li a:hover {
      background: rgb(30 31 37);
      color: #fff;
}

.dark_theme .iq-sidebar-menu .iq-menu li a:hover .iq-arrow-right {
      color: #fff;
}

.dark_theme .iq-sidebar-menu .iq-menu li.active a .iq-arrow-right {
      color: #fff;
}

.dark_theme .iq-sidebar-menu .iq-menu li.active>a:hover {
      background: rgb(5 96 253);
}

.dark_theme .iq-waves-effect.grand-child~app-list-style1 ul {
      background: #131517;
}

.dark_theme .tableplatformlist {
      background: #1d232c !important;
      border: 2px solid transparent;
      color: #9f9f9f;
      box-shadow: 0 0 5px 0 #000000;
}

.dark_theme .t_activity .table .t_body .flex div:first-child ul li b,
.dark_theme .t_activity .table .t_body .flex div>p,
.dark_theme .t_activity .table .t_body .flex div:last-child ul li .rft span:last-child {
      color: #9f9f9f;
}

.dark_theme .t_activity .table .t_body .flex div h4,
.dark_theme .t_activity .table .t_body .flex div:last-child ul li .tft {
      color: #fff;
}

.dark_theme .dashlists:nth-child(odd) {
      background: #131517;
}

.dark_theme .dashboxtit {
      border-bottom-color: transparent;
}

.dark_theme .dashlists:hover {
      background: #0560fd;
}

.dark_theme .dashlists:hover>p>span {
      color: #fff !important;
}

.dark_theme .dashlists:hover .text-primary {
      color: #fff !important;
}

.dark_theme .activitybars,
.dark_theme .dashboxtit {
      color: #fff;
}

.dark_theme .rept_det .lft,
.dark_theme .tabs_content,
.dark_theme .live_stream ul li .s_img,
.dark_theme .emp_content .emp_detail,
.dark_theme .chart .chart_rft::after {
      background: #1d232c;
}

.dark_theme h1,
.dark_theme h2,
.dark_theme h3,
.dark_theme h4,
.dark_theme h5,
.dark_theme h6 {
      color: #fff;
}

.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle,
.dark_theme .rept_det .lft,
.dark_theme .tabs_content,
.dark_theme .rept_det .lft ul li,
.dark_theme .act_stream,
.dark_theme .emp_content .emp_detail {
      box-shadow: 0 0 5px 0 #000;
}

.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle__list-item,
.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle button,
.dark_theme .rept_det .lft ul button {
      box-shadow: 0 0 5px 0 #131517;
      color: #fff;
      background: #363739;
}

.dark_theme .text-dark,
.dark_theme .chart_rft .blk span,
.dark_theme .total_val ul li,
.dark_theme .view_details>table thead th,
.dark_theme .view_details>table tbody tr td,
.dark_theme .live_stream ul li p {
      color: #9f9f9f !important;
}

.dark_theme .rept_det .lft ul li {
      border-bottom-color: transparent;
      color: #9f9f9f;
      border: 2px solid #36373c;
}

.dark_theme .iq-menu-bt {
      background: #20262d;
}

.dark_theme .line-menu {
      background: #fff;
}

.dark_theme .rept_det .lft ul li.current {
      color: #fff;
}

.dark_theme .tabletitle {
      background: #1d232c;
      border-color: #1d232c;
      box-shadow: 0 0 5px 0 #000000;
}

.dark_theme .gallery_div .tabletitle .mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent;
}

.dark_theme input.mat-input-element::placeholder,
.dark_theme input.mat-input-element {
      color: #9f9f9f !important;
}

.dark_theme .gallery_div .tabletitle p {
      color: #9f9f9f;
}

.dark_theme .gallery_div .tabletitle.thrblksec .etttile+h5 {
      color: rgb(5 96 253) !important;
}

.dark_theme .todaymonth {
      background: #1d232c;
}

.dark_theme .curtimezone {
      background: #0560fd;
}

.dark_theme .curtimezone span,
.dark_theme .timeline_div .hours.days.current-date-marker>div,
.dark_theme .mat-tab-label .mat-tab-label-content {
      color: #fff;
}

.dark_theme .gallery_div .qtext>i,
.dark_theme .timeline_div .hours>div {
      color: #9f9f9f;
}

.dark_theme .timeline_div {
      background: #131517;
}

.dark_theme .mat-select-arrow,
.dark_theme .mat-select-value>span>span,
.dark_theme .mat-paginator-page-size-label,
.dark_theme .mat-paginator-range-label,
.dark_theme fcsro-gallery-media .mat-icon-button svg {
      color: #9f9f9f;
}

.dark_theme .mat-tab-header {
      border-bottom: none;
}

.dark_theme .mat-tab-header-pagination-chevron {
      border-color: rgb(142 142 145);
}

.dark_theme .mat-tab-labels {
      border-bottom: 1px solid #2d2d2d;
}


.dark_theme .tab_fr span {
      background: #1d232c;
      color: #9f9f9f;
}

.dark_theme .tab_fr span label {
      color: #9f9f9f;
}

.dark_theme .screenimgs {
      background: #1d232c;
      border-color: #3f3f3f;
      box-shadow: 0 0 3px 0 #0560fd;
}

.dark_theme .screenimgs .mat-form-field-type-mat-select .mat-form-field-label,
.dark_theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.dark_theme .mat-form-field-empty.mat-form-field-label {
      color: #9f9f9f !important;
}

.dark_theme .mat-radio-label-content,
.dark_theme .mat-checkbox-layout .mat-checkbox-label {
      color: rgb(232, 230, 227);
}

.dark_theme .mat-radio-outer-circle {
      border-color: rgba(140, 130, 115, 0.54);
}

.dark_theme .table thead th.tble_hdr,
.dark_theme .mat-table tbody tr td,
.dark_theme tr.mat-header-row td {
      background: #1d232c;
      color: rgb(232, 230, 227) !important;
      border-color: rgb(43 43 43);
}

.dark_theme .mat-checkbox-frame {
      border-color: rgba(232, 230, 227, 0.12);
}

.dark_theme .mat-button-wrapper svg,
.dark_theme .empdeta,
.dark_theme .table thead th,
.dark_theme .table td {
      color: #9f9f9f;
}

.dark_theme .text-primary.emp_name:hover {
      color: #0560fd !important;
}

.dark_theme .emplabdeta .emplabdetabox:hover {
      background: #131517;
}

.dark_theme .empicon {
      background: #1d232c;
      box-shadow: 2px 0px 5px rgb(40 40 43);
}

.dark_theme .table-striped tbody tr:nth-of-type(odd) {
      background-color: rgb(29 35 44);
}

.dark_theme .table-hover tbody tr:hover {
      background-color: rgb(19 21 23);
}

.dark_theme .dash_board .live_stream .screenimgs {
      box-shadow: none;
      background: #1d232c;
      border-color: #36373c;
}

.dark_theme .dashlists,
.dark_theme .idel_alertbox {
      background: #1d232c;
      border-color: #1d232c;
}

.dark_theme .project_report .col-md-4>label,
.dark_theme mat-panel-title.mat-expansion-panel-header-title {
      color: #fff;
}

.dark_theme .form-group>label,
.dark_theme .tbcon .dots,
.dark_theme .col-md-6>label,
.dark_theme .col-sm-12>.mb-3>.mat-expansion-panel label,
.dark_theme .col-sm-12>.mb-3>.mat-expansion-panel .mat-expansion-panel-header-description {
      color: #9f9f9f;
}

.dark_theme .col-sm-12>.mb-3>.mat-expansion-panel {
      background: #131517;
}

.dark_theme .mat-button-toggle-appearance-standard {
      color: rgb(255 255 255 / 87%);
      background: #1d232c;
}

.dark_theme .app_report {
      background: #1d232c;
      box-shadow: 0 0 5px 0 #131517;
}

.dark_theme .a_body .boxz {
      background: #131517;
      box-shadow: 0 0 5px 0 #131517;
}

.dark_theme .dt_list ul li {
      color: #9f9f9f;
}

.dark_theme .dt_list ul li span {
      box-shadow: 0 0 5px 0 #131517;
}

.dark_theme .sign-in-page {
      background: #131517;
      background-size: cover;
}

.dark_theme .sign-in-page .boxshad>fcsro-choose-login>.sign-in-from {
      background: #1d232c;
      box-shadow: 0 10px 10px #3c3b3b;
}

.dark_theme .sign-in-page .boxshad {
      padding: 100px 0;
      min-height: 100vh;
      align-items: center;
}

.dark_theme .sign-in-from,
.dark_theme .sign-in-from label,
.dark_theme .sign-in-from .custom-checkbox .custom-control-label {
      color: #9f9f9f !important;
}

.iq-sidebar-logo a {
      position: relative;
      min-width: 150px;
}

confirmation-dialog.ng-star-inserted h4 {
      color: #000;
}

.iq-sidebar-logo a img {
      position: relative;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
}

body.sidebar-main .iq-sidebar a {
      min-width: 0;
}

.iq-sidebar-logo a img:nth-child(2),
.dark_theme .iq-sidebar-logo a img:first-child {
      opacity: 0;
      visibility: hidden;
      position: absolute;
}

.dark_theme .iq-sidebar-logo a img:nth-child(2) {
      opacity: 1;
      visibility: visible;
}

app-root.dark_theme,
body.dark_theme {
      background: #000;
}

.dark_theme .act_stream {
      background: #1d232c;
}

.dark_theme .iq-sidebar .cover-bar {
      background: #000;
      top: 57px;
}

.dark_theme .hours ul li {
      border-color: #131517;
      box-shadow: 0 0 5px 0 #1d232c;
      background: #1d232c;
}

.dark_theme span.tooltips {
      background: #131517;
      box-shadow: 0 0 10px 0 #131517;
      border: 1px solid #36373c;
      color: #fff;
}

.dark_theme .app_act_list .strips ul li::before {
      border-top-color: #131517;
}

.dark_theme .cover-bar {
      background: #1d232c;
}

.dark_theme .mat-expansion-panel {
      background: #131517;
      box-shadow: none !important;
}

.dark_theme .mat-expansion-indicator::after,
.dark_theme .bdy .blk span,
.dark_theme .dashboxtxt p {
      color: #fff;
}

.dark_theme .act_stream,
.dark_theme .rept_det .lft,
.dark_theme .tabs_content {
      border: 2px solid transparent;
}

.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle {
      background: #1d232c;
      border: 2px solid #36373c;
}

.dark_theme .view_details>table tbody tr td .blk p.individualReportLink,
.dark_theme .t_body p,
.dark_theme .table td,
.dark_theme .table th {
      color: #9f9f9f;
}

.dark_theme .view_details>table thead th button.sort img {
      border-radius: 0;
      filter: invert(1);
}

.live_stream ul li .s_img>figure {
      margin: 0;
}

.dark_theme .mat-form-field-appearance-legacy .mat-form-field-underline {
      background-color: rgb(127 127 127) !important;
}

.dark_theme table.table.detail-table tr th {
      background: #131517 !important;
}

.dark_theme table.table.detail-table tr td {
      background: #1d232c !important;
}

.dark_theme .table thead th,
.dark_theme .atdet_lft button.clear_btn,
.dark_theme input.form-control {
      color: #fff;
}

.dark_theme .contentsec .tableplatformlist label {
      color: #9f9f9f;
}

.dark_theme .task_time_report .actvbox>div h6 span {
      color: #fff !important;
}

.dark_theme .task_time_report .actvbox>div h6 {
      color: #fff;
      background: #131517;
      border: 2px solid #36373c;
}

.dark_theme .modal-body,
.dark_theme .modal-body .tableplatformlist {
      background: #1d232c;
      border-color: transparent;
}

.dark_theme .modal-body .contentsec .tableplatformlist label {
      color: #fff;
}

.dark_theme .modal-body .contentsec .tableplatformlist input.form-control,
.dark_theme .modal-body .contentsec .tableplatformlist .mat-select-value,
.dark_theme .modal-body .contentsec .tableplatformlist .mat-select-arrow {
      background: transparent;
      color: #e7e7e7;
}

.dark_theme .custom-scrollbar+.cover-bar {
      background: #1d232c;
      right: -2px;
}

.dark_theme .add_emp .tableplatformlist form {
      background: #131517;
      box-shadow: none;
}

.dark_theme .add_emp .tableplatformlist form input,
.dark_theme .add_emp .tableplatformlist form select {
      box-shadow: none !important;
      color: #9f9f9f;
}

.dark_theme .navbar-list .iq-card {
      background: #131517;
}

.dark_theme .navbar-list li .iq-sub-dropdown .iq-sub-card {
      border-bottom-color: #36373c;
}

.dark_theme .media-body p {
      color: #9f9f9f;
}

.dark_theme .iq-card-body a.btn {
      color: #fff;
}

.dark_theme .user_profile img {
      border-radius: 100%;
}

.dark_theme .profile app-iq-card .iq-card {
      background: #1d232c;
}

.dark_theme .profile app-iq-card .iq-card .custom-control-input+.custom-control-label {
      color: #fff;
}

.dark_theme .profile .iq-card .iq-card-header {
      background-color: #1d232c;
      border-color: #36373c;
}

.dark_theme .curtimezone {
      color: #fff;
}

fcsro-horizontal-calendar .timeline_div .hours.days.chosen-date>div {
      color: #fff;
}

.dark_theme .navbar-list li .iq-sub-dropdown .iq-sub-card:hover {
      background: #233e6c !important;
}

.dark_theme .hours.days.selectable-date.current-date-marker.active-date>div {
      color: #000;
}

.dark_theme addingform>div>h4,
.dark_theme addingform fcsro-add-work-shift-form label,
.dark_theme addingform fcsro-add-work-shift-form input.form-control,
.dark_theme fcsro-confirmation-dialog>div>h4,
.dark_theme fcsro-add-designation-form input.form-control,
.dark_theme fcsro-add-tag-group-form input.form-control {
      color: #9f9f9f !important;
}

.dark_theme .ngx-slider .ngx-slider-tick-value {
      color: #a5a2a2;
}

.dark_theme fcsro-week-day-picker .mat-button-toggle-checked {
      background-color: #0660fe;
      color: #fff !important;
}

.dark_theme .dormant_report .tableplatformlist .text-right span.small,
.dark_theme .high_idle .text-right span.small {
      color: #fff;
}

.dark_theme .login_report .dashpages>div>span.small {
      color: #9f9f9f;
}

.dormant_report .tableplatformlist .text-right span.small,
.high_idle .text-right span.small,
.login_report .dashpages>div>span.small {
      font-size: 12px;
}

.dark_theme fcsro-add-tag-group-form .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      z-index: 1;
      background: #1d232c;
      width: auto;
      padding: 0 5px 0 0;
      text-align: center !important;
}

.dark_theme .activity_set .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      background: #1d232c;
}

.dark_theme .activity_set input.form-control {
      color: #fff !important;
}

.mat-table tbody tr td>.mat-table tbody tr td:last-child {
      width: 10%;
      max-width: 100px;
      overflow: hidden;
      overflow-x: scrol l;
      word-break: break-all;
}

.dark_theme span.dashboxtxt img {
      filter: brightness(0) invert(1);
}

.dark_theme .chart_rft .blk:last-child h6 {
      color: #9f9f9f;
}

.dark_theme .dtd>span,
.dark_theme .view_details>table thead th {
      color: #fff !important;
}

.dark_theme .view_details>table thead th button.sort,
.dark_theme span.t_tips {
      box-shadow: none;
}

.dark_theme .t_activity .table .t_body .flex div:last-child ul li,
.dark_theme .t_activity .table .t_body .flex div:last-child ul li .rft,
.dark_theme .brdrign,
.dark_theme .nav-tzcc,
.dark_theme .timeline_div .hours,
.dark_theme .table td,
.dark_theme .table th {
      border-color: #2d2d2d;
}

.dark_theme .hours ul li::after,
.dark_theme .hours ul li:first-child::before,
.dark_theme .dashbox::after {
      background: #2d2d2d;
}

.actvidlebox {
      flex-wrap: wrap;
}

.desg_Cellptxt+.actvidlebox .idlebox {
      margin-bottom: 2px;
}

.dark_theme .todaymonth .col-12 h4 {
      color: #fff !important;
}

.dark_theme .todaymonth .col-12 h5,
.dark_theme .todaymonth .col-12 .dstep span,
.dark_theme .todaymonth .col-12 .dstep small {
      color: #9f9f9f !important;
}

.dark_theme .todaymonth .col-12 .dstep {
      background: #131517;
      border-color: #36373c;
}

.dark_theme .todaymonth .col-12 .dstep .dsnum {
      background: #0862fd;
      color: #fff !important;
}

.dark_theme .todaymonth .col-12 .dstep mark {
      background: transparent;
}

.dark_theme .todaymonth .col-12 .dstep .dsnum .logcodeas:not(:last-child) {
      border-bottom: 1px solid #2d2d2d;
}

.dark_theme .t_body>.flex {
      box-shadow: 0 2px 10px 0 #131517;
      background: #131517;
}

.dark_theme .t_activity .table .t_body .flex div:first-child ul li .itl+span {
      box-shadow: 0 5px 5px 0 #222329;
}

.dark_theme .t_activity .table .t_body .flex div:last-child ul li {
      box-shadow: 5px 5px 5px 0px #000000;
      background: #1d232c;
}

.dark_theme .search_activity .frm label {
      color: #9f9f9f;
}

.dark_theme .search_activity .frm select {
      background: transparent;
      color: #fff;
}

.dark_theme .sort {
      box-shadow: none;
      border: 2px solid #36373c;
      background: #1d232c;
}

.dark_theme .srt span {
      color: #fafafa;
}

.dark_theme .mrt ul li button,
.dark_theme .rrt ul button {
      box-shadow: none;
      background: #131517;
      color: #9f9f9f;
      border-color: #36373c;
}

.dark_theme .t_activity .table .t_body .flex:nth-child(2n) {
      background: #252839;
}

.dark_theme span.year-label,
.dark_theme .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      color: #fff;
}

.dark_theme .right-bar ul li.active button {
      background: rgb(71 71 71 / 54%);
}

.dark_theme .right-bar ul li>div .live_pop .pop_content p {
      color: #9f9f9f;
}

.dark_theme .select_team .frm label,
.dark_theme .lt_body .box .t_head p,
.dark_theme .lt_body .box .t_head .rft span i {
      color: #999999 !important;
}

.dark_theme .lt_body .box .t_head {
      background: #131517;
}

.dark_theme .lt_body .box {
      border-color: #3f3f3f;
      box-shadow: 5px 5px 0px 0px #272727;
}

.dark_theme .mat-autocomplete-panel.mat-autocomplete-visible {
      background: #131517;
}

.dark_theme .t_hd .date>p span {
      box-shadow: -5px 5px 1px 0 #272727;
}

.dark_theme .lt_body .box .t_head .rft span {
      background: #3c3d43;
}

.dark_theme .lt_body .box .t_body,
.dark_theme .lt_body .box .t_body>div {
      background: #1d232c;
}

.dark_theme .mat-select-panel {
      background: #1d232c;
}

.dark_theme .mat-option-text {
      color: #9f9f9f;
}

.dark_theme .mat-option:hover:not(.mat-option-disabled),
.dark_theme .mat-option:focus:not(.mat-option-disabled) {
      background: rgb(255 255 255 / 10%);
}

.dark_theme .right-bar ul li button:hover {
      background: rgb(101 101 101 / 54%);
}

.dark_theme .right-bar ul li button:hover>* {
      color: #fff;
}

.dark_theme .t-activeload fcsro-place-holder {
      background: #1d232c;
}

/*DARK THEME*/

/*APP ACTIVITY REPORT*/
.txt_sz_12 {
      font-size: 12px;
}

.nav-tabs .nav-link.active {
      background-color: transparent;
}

.nav-link {
      padding-bottom: 0;
}

.min_hght_tab {
      min-height: 294px;
}

.pre_wrap {
      white-space: pre-wrap;
}

.cdk-overlay-container {
      z-index: 1500 !important;
}

.cursor-pointer {
      cursor: pointer !important;
}

.app_report {
      width: 100%;
      font-family: 'Segoe', 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana, sans-serif;
}

.app_report .a_head h4 {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
}

.select_emp {
      width: 100%;
      margin-bottom: 50px;
}

.select_emp select {
      width: 100%;
      max-width: 200px;
      padding: 10px 15px;
      border-radius: 5px;
      border: 1px solid #e1e1e1;
      font-size: 14px;
      line-height: 24px;
}

.select_emp select:focus {
      outline: none;
}

.hours ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      margin: 0;
}

.hours ul li {
      list-style: none;
      position: relative;
      border: 1px solid #ffffff;
      padding: 5px 10px;
      box-shadow: 0 0 5px 0 #e1e1e1;
      background: #fff;
      font-size: 10px;
      line-height: 15px;
      height: 50px !important;
      min-width: 60px;
      max-width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
}

.a_body .hours {
      width: 100%;
}

.a_body .boxz {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      padding: 15px 25px;
      box-shadow: 0 0 5px 0 #e1e1e1;
      border-radius: 5px;
}

.a_body .boxz:not(:last-child) {
      margin-bottom: 25px;
}

.boxz .date {
      width: 100px;
      font-size: 14px;
      line-height: 24px;
}

.strips {
      width: 100%;
      height: 45px;
}

.app_act_list {
      width: calc(100% - 100px);
      overflow: hidden;
      overflow-x: auto;
}

.app_act_list .strips ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #f9f9f9;
      max-width: 1100px;
      position: relative;
}

.app_act_list .strips ul li {
      list-style: none;
      height: 45px;
      transition: 0.5s all ease-in-out;
      top: 0;
}

.app_act_list .strips ul li:hover {
      transform: translateY(-10px);
      box-shadow: 0 5px 5px 0 #acacac;
      z-index: 1;

}

.hours ul li::after {
      content: "";
      width: 1px;
      height: 45px;
      background: #dcdcdc;
      position: absolute;
      right: 0%;
      bottom: -45px;
}

.hours ul li:first-child::before {
      content: "";
      width: 1px;
      height: 45px;
      background: #dcdcdc;
      position: absolute;
      left: 0%;
      bottom: -45px;
}

.app_act_list ul li.chrome,
.dt_list ul li span.chrome {
      background: #dd5144;
}

.app_act_list ul li.ms_teams,
.dt_list ul li span.ms_teams {
      background: #424aaf;
}

.app_act_list ul li.vs_code,
.dt_list ul li span.vs_code {
      background: #0184c9;
}

.app_act_list ul li.file,
.dt_list ul li span.file {
      background: #ffbd18;
}

.app_act_list ul li.notepad,
.dt_list ul li span.notepad {
      background: #94c260;
}

.dt_list ul {
      padding: 0;
      margin: 35px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
}

.strips ul li {
      position: relative;
}

.dt_list {
      width: 100%;
}

.dt_list ul li {
      list-style: none;
}

.dt_list ul li:not(:last-child) {
      margin-right: 20px;
}

.dt_list ul li {
      font-size: 12px;
      line-height: 24px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      width: 15%;
      font-weight: 400;
}

.dt_list ul li span {
      width: 20px;
      min-width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 10px;
      box-shadow: 0 0 5px 0 #e1e1e1;
      border-radius: 3px;
}

.strips ul li {
      position: absolute;
}

.tab_fr input.form-control {
      border: none !important;
      border-bottom: 1px solid #0560fd !important;
      border-radius: 0 !important;
}

.tab_fr span {
      display: none;
}

/*APP ACTIVITY REPORT*/
.empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec .etttile {
      position: relative;
}

.empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec .etttile .box-indicator {
      position: absolute;
      right: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
}

#intergramRoot>div {
      left: 90px;
      right: auto !important;
      bottom: 115px !important;
}

span.s_call {
      color: #0560fd;
}

.custom-scrollbar {
      position: relative;
      overflow: hidden;
}

.scr {
      width: 100%;
      position: relative;
}

.scr .cover-bar {
      background: #f9fcfe;
}

.scr:hover .cover-bar {
      opacity: 0;
}

.custom-scrollbar+.cover-bar {
      background: #f9fcfe;
      right: 5px;
      width: 1.4em;
      z-index: 1;
}

.tableplatformlist:hover .custom-scrollbar+.cover-bar {
      opacity: 0;
}

/* Top Activity css*/
.top_activeReport {
      width: 100%;
      max-width: 1562px;
      margin: 100px 0 100px auto;
      background: #fff;
      box-shadow: 0 0 5px 0 #e1e1e1;
      border-radius: 15px;
      padding: 30px;
}

.top_activeReport h2 {
      font-size: 30px;
      line-height: 35px;
      color: #000;
      margin: 0 0 25px;
}

.team_date {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.team_date .blk:not(:last-child) {
      margin-right: 78px;
}

.team_date .blk {
      width: 13%;
}

.team_date .blk span {
      width: 100%;
      display: block;
      border: 1px solid #e1e1e1;
      text-align: center;
      border-radius: 5px;
      font-size: 20px;
      line-height: 24px;
      padding: 10px 15px;
      box-shadow: 0 0 5px 0 #e1e1e1;
}

.team_date .blk button {
      width: 100%;
      text-align: center;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      padding: 10px 10px;
      background: #0560fd;
      border: 1px solid #0560fd;
      color: #fff;
      text-transform: uppercase;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 #222f45;
      transition: 0.5s all ease-in-out;
      cursor: pointer;
}

.team_date .blk button:hover {
      background: transparent;
      color: #0560fd;
}

.s_activity {
      width: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.s_activity .lft span {
      display: block;
      width: auto;
      border: 1px solid #e1e1e1;
      text-align: center;
      border-radius: 5px;
      font-size: 20px;
      line-height: 24px;
      padding: 10px 15px;
      box-shadow: 0 0 5px 0 #e1e1e1;
}

.s_activity .lft {
      width: 235.05px;
}

.s_activity .rft {
      width: calc(100% - 280px);
      background: #fff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.s_activity .rft ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      width: calc(100% - 100px);
}

.s_activity .rft ul li:not(:last-child) {
      margin-right: 15px;
}

.s_activity .rft ul li {
      list-style: none;
      font-size: 18px;
      line-height: 24px;
      padding: 10px 15px;
      background: #efefef;
      width: 126px;
      min-width: 126px;
      text-align: center;
      border-radius: 5px;
}

.s_activity .rft button {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
}

.sort {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      background: #fff;
      box-shadow: 0 0 3px 0 #e1e1e1;
      border-radius: 10px;
}

.srt span {
      font-size: 14px;
      line-height: 24px;
      color: #000;
}

.mrt ul li,
.rrt ul li {
      list-style: none;
}

.mrt ul li:not(:last-child),
.rrt ul li:not(:last-child) {
      margin-right: 10px;
}

.mrt ul,
.rrt ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.mrt ul li button,
.rrt ul button {
      font-size: 12px;
      line-height: 24px;
      padding: 3px 15px;
      font-weight: 500;
      background: #f4f4f4;
      border: 1px solid #e1e1e1;
      border-radius: 3px;
      cursor: pointer;
      box-shadow: 0px 0px 5px 0px #e1e1e1;
}

.t_activity {
      width: 100%;
      padding-top: 25px;
}

.t_activity .table {
      width: 100%;
}

.t_activity .table .t_head {
      width: 100%;
      margin-bottom: 20px;
}

.t_activity .table .t_head h4 {
      text-transform: uppercase;
}

.t_body>.flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 2px 5px 0 #e1e1e1;
      border-radius: 10px;
      background: #ecf3fc;
}

.t_body>.flex>div:first-child {
      width: 18%;
}

.t_body>.flex>div:nth-child(2) {
      width: 13%;
}

.t_body>.flex>div:last-child {
      width: 70%;
}

.t_activity .table .t_body ul li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.t_body>.flex>div ul {
      margin: 0;
      padding: 0;
}

.itl {
      width: 100%;
      max-width: 100px;
      min-width: 100px;
}

.scroll-toggle2 {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1024px;
}

.t_activity .table .t_body div span.qtext {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 5px;
}

.t_activity .table .t_body .flex>div:first-child ul li:not(:last-child) {
      margin-bottom: 0px;
}

.t_activity .table .t_body .flex>div:nth-child(2) {
      padding-left: 10px;
}

.t_activity .table .t_body .flex div:first-child ul li .itl+span {
      display: inline-block;
      padding: 8px 10px;
      color: #fff;
      margin: 0 10px;
      text-transform: capitalize;
      font-size: 12px;
      min-width: 70px;
      max-width: 70px;
      box-shadow: 0 2px 5px 0 #b6c2d6;
      text-align: center;
}

.t_activity .table .t_body .flex div:first-child ul li.idle span {
      background: #acacac;
}

.t_activity .table .t_body .flex div:first-child ul li.meet span {
      background: #0560fd;
}

.t_activity .table .t_body .flex div:first-child ul li.break span {
      background: orange;
}

.t_activity .table .t_body .flex div:first-child ul li {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
}

.t_activity .table .t_body .flex div:first-child ul li b {
      color: #000;
}

.t_activity .table .t_body .flex div:first-child ul li.idle,
.t_activity .table .t_body .flex div:first-child ul li.idle p {
      color: #6e6e6e;
}

.t_activity .table .t_body .flex div:first-child ul li.meet,
.t_activity .table .t_body .flex div:first-child ul li.meet p {
      color: #0560fd;
}

.t_activity .table .t_body .flex div:first-child ul li.break,
.t_activity .table .t_body .flex div:first-child ul li.break p {
      color: orange;
}

.t_activity .table .t_body .flex div:first-child ul li p {
      display: inline-block;
      width: 82px;
      padding-right: 10px;
      margin: 0;
      text-align: right;
}

.t_activity .table .t_body .flex div h4 {
      font-size: 15px;
      line-height: 24px;
      margin: 0;
      color: #000;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
}

.t_activity .table .t_body .flex div span {
      font-size: 12px;
      line-height: 12px;
      color: #7f7f95;
      text-transform: uppercase;
}

.t_activity .table .t_body .flex div>p {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 20px;
      color: #000;
      font-weight: 500;
}

.t_activity .table .t_body .flex div:last-child ul {
      display: flex;
      flex: 1 1 auto;
      overflow-x: scroll;
      padding: 50px 10px 10px;
      border-radius: 5px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;
}

.t_activity .table .t_body .flex div:last-child ul::-webkit-scrollbar {
      display: none;
}

.t_activity .table .t_body .flex div:last-child ul li:not(:last-child) {
      margin-right: 10px;
}

.t_activity .table .t_body .flex div:last-child ul li {
      width: 100%;
      min-width: 100%;
      border: 1px solid #e1e1e1;
      display: flex;
      align-items: initial;
      justify-content: space-between;
      position: relative;
      box-shadow: 5px 5px 5px 0px #a7b8ce;
      background: #fff;
}

span.t_tips {
      position: absolute;
      width: auto;
      min-width: max-content;
      max-width: max-content;
      height: auto;
      left: 0;
      top: -40px;
      background: #fbfbfb;
      color: #000 !important;
      font-size: 10px !important;
      line-height: 18px !important;
      transition: .5s all ease-in-out;
      padding: 3px 5px;
      border-radius: 5px;
      visibility: hidden;
      opacity: 0;
      box-shadow: 0 0 10px 0 #e1e1e1;
      text-align: center;
      border: 1px solid #dcdcdc;
      z-index: 10;
      text-transform: capitalize !important;
}

span.t_tips::after {
      content: "";
      position: absolute;
      border: 10px solid #e1e1e1;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);
}

.t_activity .table .t_body .flex div:last-child ul li .tft:hover+.t_tips {
      visibility: visible;
      opacity: 1;
}

.t_activity .table .t_body .flex div:last-child ul li .rft {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      border-left: 1px solid #e1e1e1;
}

.t_activity .table .t_body .flex div:last-child ul li .tft {
      padding: 10px;
      font-size: 14px;
      line-height: 24px;
      width: calc(100% - 65px);
      max-width: 150px;
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
}

.t_activity .table .t_body .flex div:last-child ul li .rft span:first-child {
      display: flex;
      width: 100%;
      text-align: center;
      background: #0560fd;
      color: #fff;
      height: 50%;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      line-height: 24px;
}

.t_activity .table .t_body .flex div:last-child ul li .rft span:last-child {
      width: 100%;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-size: 12px;
      line-height: 24px;
      text-transform: capitalize;
}

.t_activity .table .t_body .flex>div {
      padding: 35px 10px;
      position: relative;
}

.t_activity .table .t_body .flex:nth-child(2n) {
      background: #ecf3fc;
}

.t_activity .table .t_body .flex div .btns {
      position: absolute;
      right: 40px;
      top: 30px;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
}

.t_activity .table .t_body .flex div .btns button {
      width: 30px;
      height: 30px;
      background: transparent;
      border: 1px solid #e1e1e1;
      border-radius: 3px;
      box-shadow: 0 0 5px 0 #d7d7d7;
      display: flex;
      align-items: center;
      justify-content: center;
}

.t_activity .table .t_body .flex div .btns button img {
      width: 100%;
      object-fit: contain;
      object-position: center;
}

.team_date .mat-form-field-appearance-fill .mat-form-field-flex {
      background: transparent;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
      display: block !important;
}

.t_body .owl-carousel .owl-nav .owl-prev {
      position: absolute;
      top: -65px !important;
      right: 25px !important;
      left: auto;
      max-width: 30px;
}

.t_body .owl-carousel .owl-nav .owl-next {
      position: absolute;
      top: -65px !important;
      right: 0 !important;
      max-width: 30px;
}

.team_date .form-group {
      margin: 0 !important;
}

.team_date .mat-form-field-flex .mat-select {
      padding: 0 !important;
}

/* .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      max-height: 61.5px;
} */

.sidebar-main .ng-scrollbar-wrapper[deactivated=false][dir=ltr]>scrollbar-y.scrollbar-control {
      right: -4px !important;
}

.sidebar-main .iq-sidebar .cover-bar {
      background: #ecf3fc;
      right: 0px;
      z-index: 1;
      top: 55px;
}

.sidebar-main .iq-sidebar-menu .iq-menu li.active>a,
.sidebar-main .iq-sidebar-menu .iq-menu li.menu-open>a {
      width: 100%;
      transition: 0.5s all ease-in-out;
}

.t_activity .table .t_body .flex div:first-child ul li b {
      min-width: 60px;
}

.iq-sidebar .cover-bar {
      top: 55px;
      background: #ecf3fc;
}


.t-activeload {
      position: relative;
      min-height: 250px;
      transition: all ease 0.75s;
}

.t-activeload fcsro-place-holder {
      position: absolute;
      width: 100%;
      left: 0px;
      top: 0px;
      height: 100%;
      z-index: 9;
}

.hide-sort .mat-sort-header-arrow {
      display: none;
}

.t_activityhide {
      position: absolute;
      width: 100%;
      left: 0px;
      top: 0px;
      height: 100%;
      background: #f9fcfe;
      z-index: 8;
}

.mat-tab-body-wrapper .mat-tab-body-content {

      overflow: hidden !important;

}

label#mat-form-field-label-5 span.mat-placeholder-required.mat-form-field-required-marker {
      color: red !important;
}

/*BG CHANGES*/
.has-mask {
      z-index: 1;
      position: relative;
}

.nk-mask {
      position: fixed;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
}

.blur-1:before,
.blur-1:after {
      content: "";
      width: 100%;
      position: absolute;
}

.blur-1:before {
      background-image: url(/assets/images/a.png);
      background-size: cover;
      height: 831px;
      left: 0;
      top: 0;
      width: 350px;
}

.blur-1:after {
      background-image: url(/assets/images/b.png);
      background-size: cover;
      height: 850px;
      right: 0;
      bottom: -80px;
      width: 370px;
}

.focus_loop .fl_cnt .head .date_selector .scroll-toggle {
      background: #fff;
}

.iq-sidebar {
      background: transparent;
}

.focus_loop .fl_cnt,
.iq-submenu {
      background: transparent;
}

.tableplatformlist,
.act_stream,
.rept_det .lft,
.tabs_content,
.version_div {
      background: #fff;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05);
      border: 1px solid #E2E8F0;
}

.right-bar {
      box-shadow: unset;
      border-left: 1px solid #E2E8F0;
}

.iq-top-navbar {
      background: #ffffff;
      box-shadow: 0 0px 3px #e1e1e1;
}

.version_div {
      background: rgb(255 255 255);
}

.act_stream {
      background: #fff;
}

.head_name {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
}

.head_name h4 {
      margin-left: 15px;
      font-size: 15px;
      line-height: 24px;
}

.head_name>a {
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
      font-weight: 600;
}

.rf_btn {
      margin-left: auto;
}

.emp_content .emp_chart>h4 {
      text-align: center;
      margin-top: 10px;
      font-size: 20px;
      font-weight: 600;
}

.over_time .row>.col-md-6>.tableplatformlist {
      height: 96%;
}

.fl_cnt>h3 {
      margin-bottom: 15px;
      font-weight: 600;
}

/*BG CHANGES*/

.dtd>span {
      width: auto;
      font-size: 14px;
      font-weight: 600;
}

.dtd {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.at_report .tableplatformlist>.row {
      align-items: center;
}

body.russian .at_head {
      width: 50%;
}

body.spanish table.mat-table tbody tr td button {
      width: 240px;
}

body.catalan table.mat-table tbody tr td button {
      width: 226px;
}

body.german table.mat-table tbody tr td button {
      width: 215px;
}

body.indonesian table.mat-table tbody tr td button {
      width: 200px;
      max-width: 200px;
}

body.catalan .s_head button {
      width: 130px;
}

body.indonesian .s_head button {
      width: 125px;
}

body.catalan .at_head {
      width: 55%;
}

body.portuguese .at_head {
      width: 60%;
}

.fc-csat {
      display: flex;
      flex-direction: row;
}

.fc-cs {
      display: flex;
      width: 100%;
      flex-direction: column;
}

.fc-cs ul {
      list-style: none;
      padding: 0px;
      margin-bottom: 0;
}

.fc-cs ul li {
      display: flex;
      align-items: center;
      font-size: 14px;
      width: max-content;
      position: relative;
}

.fc-cs ul li:hover>.custom_pop {
      opacity: 1;
      visibility: visible;
}

.custom_pop {
      position: absolute;
      left: 100%;
      top: -10px;
      background: #fff;
      padding: 15px;
      border-radius: 10px;
      z-index: 10;
      width: auto;
      transition: 0.5s all;
      opacity: 0;
      visibility: hidden;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      border: 1px solid #e5eaef;
}

.custom_pop ul {
      overflow: hidden;
      overflow-y: auto;
      max-height: 170px;
      padding-right: 15px;
}

.custom_pop ul::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #bdc2ca;
      background-color: #F5F5F5;
}

.custom_pop ul::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
}

.custom_pop ul::-webkit-scrollbar-thumb {
      background-color: #bdc2ca;
}

.fcsroclassdis p,
.hd h4,
.dashboxtxt {
      font-size: 14px;
}

.fc-cs ul li:not(:last-child) {
      margin-bottom: 10px;
}

.fc-cs ul li span {
      width: 38px;
      height: 30px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-weight: 600;
      font-size: 16px;
      justify-content: flex-end;
}

.fc-cs p {
      margin-bottom: 10px;
}

.dashbox .dashcount .small {
      font-size: 14px;
      text-transform: capitalize;
      font-weight: normal;
}

.hd {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
}

.hd>span {
      margin-left: 25px;
}

.bdy {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      padding: 0 25px;
      text-align: center;
}

.bdy>.blk:first-child span {
      color: #2ab218;
}

.bdy>.blk:nth-child(2) span {
      color: #0560fd;
}

.bdy>.blk:nth-child(3) span {
      color: orange;
}

.bdy>.blk:last-child span {
      color: gray;
}

.bdy .blk>p {
      margin: 0;
      font-weight: normal;
      font-size: 14px;
}

.bdy .blk span {
      color: #333333;
      padding-bottom: 15px;
      font-size: 18px;
      font-weight: 600;
}

.bdy .blk span small {
      font-size: 14px;
      margin-left: -3px;
}

span.dashboxtxt p span.h_img {
      width: 36px;
      height: 36px;
      padding: 5px;
      background: #373737;
      border-radius: 100%;
      display: block;
      margin-right: 10px;
}

.dashboxtxt .dash_icon {
      background: orange !important;
}

.dashbox .dash_icon {
      background: #0862fd;
}

.hd span.dash_icon {
      background: #28b116;
}

.dashbox.fcsroclassdis .activitybars {
      display: none !important;
}

span.dashboxtxt img {
      width: 100%;
      filter: brightness(0) invert(1);
}

.mtg h4 {
      margin-bottom: 15px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
}

.hd h4 {
      display: flex;
      align-items: center;
      margin-top: -6px;
      width: 100%;
      justify-content: center;
}

span.dashbox.mtg {
      margin-top: 3px;
}

.mtg p span {
      color: #0560fd;
      font-size: 20px;
      line-height: 24px;
      padding: 0 2px 0 5px;
      font-weight: 500;
      min-width: 50px;
      width: max-content;
      text-align: right;
      display: inline-block;
}

.dashbox .dashcount span.text-primary {
      width: max-content;
      min-width: 42px;
      display: inline-block;
      text-align: right;
}

.dashbox .dashcount .text-primary {
      margin: 0 5px;
}

.mtg p {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      text-transform: capitalize;
}

.hd>mat-form-field {
      width: 45%;
      position: absolute;
      right: 0;
      top: -65px;
}

.content-page>.container-fluid {
      padding: 0;
}

.mat-slide-toggle-thumb {
      display: flex;
      align-items: center;
      justify-content: center;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb mat-icon.ri-moon-fill.m-1.text-white::before {
      position: absolute;
      left: 55%;
      transform: translateX(-50%);
      top: -2px;
}

span.dashboxtxt p span,
span.avg_day {
      font-size: 12px;
      font-weight: 400;
      margin-left: 5px;
}

/*ADD EMPLOYEE CHANGES*/

/*WELCOME POPUP*/


.welcome-box {
      width: 100%;
}

.welcome-box .welcome-box-body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem;
      border: 2px dashed #ecf0fa;
      margin: 20px;
      text-align: center;
}

.welcome-box .modal-header .close {
      opacity: 1;
}

.welcome-box .modal-header .close span {
      color: #fff;
}

.welcome-box .modal-body fcsro-custom-timezone>div {
      min-height: auto !important;
      text-align: center;
}

.welcome-box .modal-body h3 {
      text-align: center;
      font-size: 20px;
      line-height: 26px;
}

.welcome-box .modal-body fcsro-custom-timezone>div>div {
      padding: 0;
}

.welcome-box .modal-body {
      position: relative;
      overflow: hidden;
}

.welcome-box .modal-body .nk-mask {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
}

.welcome-box .modal-body fcsro-custom-timezone>div>div .tableplatformlist {
      background: transparent;
      border: transparent;
}


.customTimezone .mat-form-field-label {
      left: 0;
}

/*WELCOME POPUP*/



.modal-body table.table.stickythtrcon.mat-table {
      white-space: unset;
      max-width: 100%;
}

.modal-body table.table.stickythtrcon.mat-table thead tr th:first-child {
      width: 62%;
}

.modal-body table.table.stickythtrcon.mat-table thead tr th:nth-child(2) {
      width: 20%;
}

.modal-body table.table.stickythtrcon.mat-table thead tr th:nth-child(2),
.modal-body table.table.stickythtrcon.mat-table thead tr th:last-child,
.modal-body table.table.stickythtrcon.mat-table tbody tr td:nth-child(2),
.modal-body table.table.stickythtrcon.mat-table tbody tr td:last-child {
      text-align: center !important;
}

.modal-body .contentsec {
      padding: 0;
}

.modal-body .contentsec>fcsro-place-holder+.container-fluid {
      min-height: auto;
}

.view_details>table tbody tr td .blk p.individualReportLink:hover {
      color: #0052df;
}

.tableplatformlist h3.text-left>button,
.add_emp .tableplatformlist form button {
      width: 35px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto !important;
      text-align: center;
      height: 35px;
}

.tableplatformlist h3.text-left>button>i,
.add_emp .tableplatformlist form button>i {
      margin: 0 !important;
}

.add_emp .tableplatformlist form button {
      position: relative;
      bottom: -35px;
      right: 0px;
}

.add_emp .tableplatformlist form .row,
.add_emp .tableplatformlist form div#collapsedSection {
      width: 100%;
      padding: 0;
}

.add_emp .tableplatformlist form .row>div:last-child {
      display: flex;
      align-items: center;
}

.add_emp .tableplatformlist form .row>div:last-child a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 15px !important;
}

.add_emp .tableplatformlist form .row>div:last-child a>i {
      font-size: 20px;
      line-height: normal;
      transform: rotate(90deg);
      margin-left: 5px;
}

.add_emp .tableplatformlist form .row>div:last-child a.text-danger>i {
      transform: rotate(270deg);
}

.add_emp .tableplatformlist form button+div+div {
      width: 96% !important;
      padding-left: 0px !important;
}

.add_emp .tableplatformlist form .row>div:first-child a {
      margin: 0 !important;
}

.add_emp .tableplatformlist form {
      margin: 0px !important;
      background: #ecf3fc;
      box-shadow: 0 0 10px 0 #ecf3fc;
      padding: 15px;
      margin-bottom: 15px !important;
      border-radius: 10px;
}

.custom-select option {
      width: 250px !important;
      max-width: 250px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-appearance: inherit !important;
}

.custom-select option:focus {
      width: 250px;
}

.add_emp .tableplatformlist form input,
.add_emp .tableplatformlist form select {
      box-shadow: none !important;
}

.add_emp .tableplatformlist form input:focus,
.add_emp .tableplatformlist form select:focus {
      border-color: #0660fe !important;
      outline: none !important;
}

.add_emp .tableplatformlist form select {
      font-size: 14px;
      line-height: 24px;
}

.add_emp .tableplatformlist form h6 {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
}

.add_head button {
      width: auto !important;
      justify-content: space-between !important;
}

.add_head button>span {
      margin-left: 10px;
}

.emp_frm {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
}

.emp_frm>form {
      width: 100%;
}

.emp_frm>div {
      margin-bottom: 0 !important;
      margin-top: 15px !important;
}

.form_frm {
      width: 85%;
      margin: auto;
}

.custom-data-wrapper[_ngcontent-qmx-c3] {
      min-height: auto !important;
}

.task_time_report .etttile {
      justify-content: flex-start;
}

.task_time_report .etttile small {
      margin-left: 10px;
}

.task_box {
      display: flex;
      max-width: 100%;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: auto;
}

/* .task_box .mat-form-field-label {
      left: 12px;
} */

.task_box>div>.mat-form-field {
      width: 100% !important;
      top: 10px;
}

.date_selector {
      width: 100%;
      text-align: right;
      position: relative;
}

.date_selector small {
      position: absolute;
      width: 100%;
      right: 0;
      bottom: -6px;
      text-transform: uppercase;
}

.date_selector .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
      width: 20px;
}

.task_time_report .actvbox {
      flex-direction: row;
      align-items: initial;
      justify-content: space-between !important;
      padding: 50px 30px !important;
}

.task_time_report .actvbox>div {
      flex: 0 0 33.3%;
      max-width: 33.3%;
      text-align: center;
}

.task_time_report .actvbox.select-project>div {
      flex: 0 0 25%;
      max-width: 25%;
}

.task_time_report .actvbox.select-project.select-task>div {
      flex: 0 0 20%;
      max-width: 20%;
}

.task_time_report .actvbox>div h6 {
      background: #ecf3fc;
      padding: 0 0 25px 0;
      border-radius: 6px;
      height: 100%;
}

.task_time_report .actvbox>div h6 span {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      background: #0560fd;
      margin-bottom: 15px;
      color: #fff;
      border-radius: 6px 6px 0 0;
      padding: 10px;
}

.cdk-overlay-pane {
      transform: unset !important;
}

.hd .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0 !important;
      padding: 0 !important;
}

fcsro-app-report-dialog.ng-star-inserted .container-fluid {
      min-height: auto;
}

fcsro-app-report-dialog.ng-star-inserted .contentsec {
      padding: 0;
}

fcsro-app-report-dialog.ng-star-inserted table.mat-table {
      border-spacing: 0;
      width: 1000px !important;
      max-width: 1000px;
}

fcsro-app-report-dialog.ng-star-inserted table.mat-table tbody tr td:first-child {
      width: 60%;
}

fcsro-app-report-dialog.ng-star-inserted table.mat-table tbody tr td span {
      word-wrap: break-word;
      white-space: break-spaces;
}

body.sidebar-main #intergramRoot>div {
      left: 0px;
      bottom: 115px !important;
}

.sidebar-main .version_div a {
      margin-bottom: 0;
}

.sidebar-main p.l_chat {
      margin: 0;
}

span.dashboxtxt p,
p.dashboxtit,
.mtg h4,
.hd h4 {
      font-weight: 600 !important;
      font-size: 15px;
}

/*NEW CHANGES*/
.tableplatformlist.gallery_data .mat-tab-body-wrapper .fl_cnt .rept_det .col.rft .tabs_content {
      min-height: 330px;
}

.time_log .table thead th.tble_hdr:not(:first-child),
.time_log .table tbody td:not(:first-child) {
      text-align: center !important;
}

.desg_Cellptxt+.actvidlebox {
      margin: 0 auto 5px;
}

.desg_Cellptxt+.actvidlebox>div {
      min-height: 70px;
      display: flex;
      align-items: center !important;
      justify-content: flex-start;
      padding: 4px;
      flex-direction: column;
      margin: 0 5px 5px 0 !important;
      width: 78px !important;
      max-width: 78px !important;
      min-width: 78px;
}

.desg_Cellptxt+.actvidlebox>div:nth-child(2n) {
      margin-right: 0 !important;
}

.desg_Cellptxt+.actvidlebox>div:first-child {
      /* background: #e1e1e1; */
      background: transparent;
      border: 1px solid #808080;
}

.desg_Cellptxt+.actvidlebox>div:first-child>span:first-child {
      color: #808080;
}

.desg_Cellptxt+.actvidlebox>div:nth-child(2) {
      /* background: #ffe5b4; */
      background: transparent;
      border: 1px solid #ffa500;
}

.desg_Cellptxt+.actvidlebox>div:nth-child(2)>span:first-child {
      color: #ffa500;
}

.desg_Cellptxt+.actvidlebox>div:nth-child(3) {
      /* background: #eef2fb; */
      background: transparent;
      border: 1px solid #0560fd;
}

.desg_Cellptxt+.actvidlebox>div:nth-child(3)>span:first-child {
      color: #0560fd;
}

.desg_Cellptxt+.actvidlebox>div:last-child {
      /* background: #e9ffe6; */
      background: transparent;
      border: 1px solid #28b116;
}

.desg_Cellptxt+.actvidlebox>div:last-child>span:first-child {
      color: #28b116;
}


.fc-cs ul li>p {
      min-width: 110px;
      position: relative;
      margin: 0;
      padding-right: 35px;
}

.fc-cs ul li>p::after {
      content: "";
      width: 25px;
      height: 25px;
      background: url(../images/tool_arrow.png) no-repeat center;
      background-size: 22px;
      position: absolute;
      right: 10px;
      transition: 0.5s all;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
}

.fc-cs ul li:hover p::after {
      right: 0px;
}

/*TOP ACTIVITY*/
.top_activity .t_body .owl-carousel .owl-nav .owl-prev {
      color: transparent;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: url(../images/right-chevron.png) no-repeat center #D6D6D6;
      background-size: 13px;
      transform: rotate(180deg);
      right: 35px !important;
      top: -80px !important;
}

.top_activity .t_body .owl-carousel .owl-nav .owl-next {
      color: transparent;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: url(../images/right-chevron.png) no-repeat center #D6D6D6;
      background-size: 13px;
      transform: rotate(0deg);
      top: -80px !important;
}

.t_body>.flex:not(:last-child) {
      margin-bottom: 15px;
}

.t_activity .table .t_body .flex:nth-child(2n) {
      background: #ffffff;
}

.search_activity .frm {
      width: 100%;
      max-width: 300px;
      margin-left: auto;
}

.search_activity .frm label {
      display: block;
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      margin: 0;
}

.search_activity .frm select {
      width: 100%;
      border: none;
      border-bottom: 1px solid #949494;
      padding: 10px 0;
}

.mrt ul li button.active,
.rrt ul button.active {
      background: #0560fd;
      color: #fff;
}

.t_activity .table .t_body .flex div:first-child ul li.work span {
      background: #28b116;
}

.t_activity .table .t_body .flex div:first-child ul li.work,
.t_activity .table .t_body .flex div:first-child ul li.work p {
      color: #28b116;
}

/*TOP ACTIVITY*/


/*LIVE TRACKING*/
.live_tracking .lt_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.live_tracking .lt_head p {
      margin: 0;
}

.lt_body .box {
      width: 100%;
      overflow: hidden;
      background: #fff;
      box-shadow: 5px 5px 0px 0px #dfe3e7;
      border-radius: 10px;
      border: 1px solid #dfe3e7;
      position: relative;
      cursor: pointer;
      max-height: 280px;
}

.lt_body .box.meeting {
      box-shadow: 5px 5px 0px 0px #6da4ff;
      border-color: #0560fd;
}

.lt_body .box.break {
      box-shadow: 5px 5px 0px 0px #ffc761;
      border-color: orange;
}

.lt_body .box.idle {
      box-shadow: 5px 5px 0px 0px #9d9a9a;
      border-color: gray;
}

.lt_body .box .t_head {
      width: 100%;
      padding: 10px;
      border-radius: 0 0;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 54px;
}

.live_head {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 68px;
}

.live_tracking .lt_head,
.activity_pattern .lt_head {
      width: 320px;
}

.lt_body .box .t_head p {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #495058;
      font-size: 13px;
      line-height: 24px;
      transition: 0.5s all ease-in-out;
}

.lt_body .box .t_head p:hover {
      color: #0560fd;
}

.lt_body .box .t_head .lft .time p {
      font-size: 12px;
}

.lt_body .box .t_head .rft span {
      color: #495058;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ededed;
      border-radius: 100%;
}

.lt_body .box .t_head .rft span i {
      font-size: 30px;
      color: #818080;
}

.lt_body .box.meeting .t_head .rft span {
      background: #0560fd;
}

.lt_body .box.break .t_head .rft span {
      background: #ffd400;
}

.lt_body .box.meeting .t_head .rft span i,
.lt_body .box.break .t_head .rft span i {
      color: #fff;
}

.t_hd .date p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
}

.t_hd .date p span {
      margin-left: 15px;
}

.t_hd .date p span .live-clock p.clock {
      font-size: 11px;
}

.t_hd .date>p span:last-child {
      width: auto;
}

.t_hd .date>p span i {
      margin-right: 5px;
      font-size: 16px;
      line-height: 20px;
}

.t_hd .date>p span {
      font-size: 13px;
      color: #242628;
      letter-spacing: 1px;
      background: #ecf3fc;
      padding: 5px 10px;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 6px;
      font-weight: bold;
      border: 1px solid #dfe3e7;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05);
      border: 1px solid #E2E8F0;
}

.t_hd .date>p span i {
      color: #0862fd;
}

.lt_body .box .t_body img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      transition: 0.5s all ease-in-out;
}

.lt_body .box .t_body .img {
      width: 100%;
      position: relative;
}

.lt_body .box .t_body .img .time {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      text-align: right;
      padding: 10px;
      background: rgba(255, 255, 255, 0.5);
}

.lt_body .box .t_body .img .time p {
      margin: 0 !important;
      justify-content: flex-end;
}

.lt_body .box .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: -100%;
      background: rgba(5, 96, 253, 50%);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      transition: 0.5s all;
      flex-direction: column;
}

.lt_body .box:hover .overlay {
      bottom: 0;
}

.lt_body .box .overlay .dtl {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: transparent;
      padding: 10px;
      border-radius: 5px;
}

.lt_body .box .overlay .dtl p {
      color: #fff;
}

.lt_body .box .overlay .dtl .progress_div.report_progress {
      border: 1px solid #7fa7ed;
}

.select_team {
      margin-bottom: 0px;
      width: 30%;
      min-width: 200px;
}

.select_team .frm label {
      display: block;
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      margin: 0;
}

.select_team .frm select {
      width: 100%;
      border: none;
      border-bottom: 1px solid #949494;
      padding: 10px 0;
}

.t_hd {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
}

.lt_body>div {
      margin-bottom: 20px;
}

.lt_body .box .t_head .lft {
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.status {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: auto;
      max-width: 100%;
      text-transform: uppercase;
}

.status>i>img {
      width: 100%;
      object-fit: contain;
      object-position: center;
}

.status>i {
      width: 30px;
      height: 30px;
      margin-right: 10px;
}

.quick_view .paginatormob .col-md-4 .mat-form-field mat-label.ng-star-inserted {
      margin-left: 15px;
}

.select_team .frm mat-select#selectedTeam {
      border-bottom: 1px solid #bfbebe;
}

.lfts_head .px-3 {
      width: calc(100% - 300px);
}

.lfts_head .px-3>div {
      justify-content: space-between;
      align-items: center;
}

.lfts_head .px-3>div .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
}

.lfts_head .px-3>.row .col-md-4:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
}

/*LIVE TRACKING*/

.quick_view table.mat-table thead tr th:not(:first-child) .mat-sort-header-container,
.quick_view table.mat-table tbody tr td:not(:first-child) .mat-sort-header-container {
      justify-content: left;
}

p.l_chat {
      width: 100%;
      text-align: center !important;
}

.version_div p {
      width: 100%;
      text-align: center !important;
}

.version_div>a {
      display: block;
      width: 100%;
      text-align: center !important;
      position: relative;
      margin-bottom: 15px;
}

.version_div {
      display: block;
}

.version_div>a i {
      position: absolute;
      left: 30px;
      top: 42%;
      transform: translateY(-50%);
      margin: 0;
}

.version_div>.l_chat+a i {
      left: 43px;
}

.sidebar-main .version_div>a i {
      position: relative;
      left: unset;
      top: unset;
      transform: unset;
}

.sidebar-main .version_div>.l_chat+a i {
      left: 0;
}

.actvbox span:first-child,
.idlebox span:first-child {
      white-space: break-spaces;
}

body.tamil .t_activity .table .t_body .flex div:first-child ul li p {
      width: 125px;
}

body.tamil .itl {
      min-width: 150px;
      max-width: 150px;
}

body.tamil .t_body>.flex>div:first-child {
      width: 20%;
}

body.tamil .t_body>.flex>div:last-child {
      width: 68%;
}

body.russian .t_activity .table .t_body .flex div:first-child ul li p,
body.spanish .t_activity .table .t_body .flex div:first-child ul li p {
      width: 80px;
}

body.russian .t_body>.flex>div:first-child,
body.spanish .t_body>.flex>div:first-child {
      width: 22%;
}

body.russian .t_body>.flex>div:last-child,
body.spanish .t_body>.flex>div:last-child {
      width: 72%;
}

body.german .t_activity .table .t_body .flex div:first-child ul li p,
body.indonesian .t_activity .table .t_body .flex div:first-child ul li p {
      width: 90px;
}

body.german .itl,
body.indonesian .itl {
      max-width: 90px;
      min-width: 90px;
}

body.german .t_body>.flex>div:first-child,
body.indonesian .t_body>.flex>div:first-child {
      width: 25%;
}

body.german .t_body>.flex>div:last-child,
body.indonesian .t_body>.flex>div:last-child {
      width: 72%;
}

body.french .dashboxtop .row .col-md-3:nth-child(2),
body.russian .dashboxtop .row .col-md-3:nth-child(2),
body.turkey .dashboxtop .row .col-md-3:nth-child(2) {
      max-width: 27%;
      flex: 0 0 27%;
}

body.french .dashboxtop .row .col-md-3:nth-child(3),
body.russian .dashboxtop .row .col-md-3:nth-child(3),
body.turkey .dashboxtop .row .col-md-3:nth-child(3) {
      max-width: 23%;
      flex: 0 0 23%;
}

body.tamil span.dashboxtxt p,
p.dashboxtit,
.mtg h4,
.hd h4 {
      font-size: 13px;
}

body.tamil .fc-cs ul li>p,
body.tamil .dashbox .dashcount .small,
body.tamil .mtg p {
      font-size: 12px;
}

body.tamil .bdy .blk>p {
      font-size: 11px;
}

body.russian span.dashboxtxt p,
body.turkey span.dashboxtxt p {
      font-size: 13px;
}

body.tamil .fc-cs ul li>p {
      min-width: 148.97px;
}

body.russian .fc-cs ul li>p,
body.portuguese .fc-cs ul li>p {
      min-width: 115.91px;
}

body.spanish .fc-cs ul li>p {
      min-width: 133.5px;
}

body.german .fc-cs ul li>p {
      min-width: 116.36px;
}

body.indonesian .fc-cs ul li>p {
      min-width: 141.78px;
}

.csv_btn {
      width: 35px !important;
      height: 35px !important;
      max-height: 35px !important;
      margin: 0 10px 0 0;
      background: #ecf3fc;
      border: 1px solid #cad5e4;
      border-radius: 5px;
      color: #4d4d4d !important;
      font-size: 18px !important;
}

.csv_btn i {
      margin: 0;
}

.dark_theme .csv_btn,
.dark_theme .manage_btn .btn,
.dark_theme .lfts_head .btn,
.dark_theme fcsro-leave-policy-configuration table td .btn,
.dark_theme fcsro-employees table.mat-table tbody tr td button,
.dark_theme .add_head button,
.dark_theme fcsro-add-work-shift-form.add_workshift .btn,
.dark_theme fcsro-add-designation-form .btn,
.dark_theme fcsro-add-tag-group-form .btn-outline-primary,
.dark_theme fcsro-add-tag-group-form .btn-gradiant,
.dark_theme .emp_frm button.btn.btn-gradiant,
.dark_theme fcsro-teams .btn,
.dark_theme fcsro-designations .btn,
.dark_theme fcsro-work-shifts .btn-outline-primary,
.dark_theme fcsro-work-shifts .btn-gradiant,
.dark_theme fcsro-holiday-definition .btn {
      background: #0052df !important;
      border-color: #0560fd !important;
      color: #fff !important;
}

.dark_theme .csv_btn i,
.dark_theme fcsro-leave-policy-configuration table td .btn i {
      color: #fff;
}

.dark_theme .csv_btn:hover,
.dark_theme .manage_btn .btn:hover,
.dark_theme .lfts_head .btn:hover,
.dark_theme fcsro-leave-policy-configuration table td .btn:hover,
.dark_theme fcsro-employees table.mat-table tbody tr td button:hover,
.dark_theme .add_head button:hover,
.dark_theme fcsro-add-work-shift-form.add_workshift .btn:hover,
.dark_theme fcsro-add-designation-form .btn:hover,
.dark_theme fcsro-add-tag-group-form .btn-outline-primary:hover,
.dark_theme fcsro-add-tag-group-form .btn-gradiant:hover,
.emp_frm button.btn.btn-gradiant:hover,
.dark_theme fcsro-teams .btn:hover,
.dark_theme fcsro-designations .btn:hover,
.dark_theme fcsro-work-shifts .btn-outline-primary:hover,
.dark_theme fcsro-work-shifts .btn-gradiant:hover,
.dark_theme fcsro-holiday-definition .btn:hover {
      background: transparent !important;
      color: #0560fd !important;
}

.dark_theme fcsro-leave-policy-configuration table td .btn:hover i {
      color: #0052df;
}

.work_shift .form_frms button.btn {
      width: 35px;
      padding: 0;
}

.work_shift .form_frms button.btn i {
      margin: 0 !important;
}

.dark_theme .t_body .owl-carousel .owl-nav .owl-prev,
.dark_theme .t_body .owl-carousel .owl-nav .owl-next {
      background: #363739 !important;
      color: #fff !important;
}

.navbar-list li .nav-tzcc2 {
      width: auto;
      border: 1px solid #cad5e4;
      border-radius: 30px;
      justify-content: space-between !important;
      margin-left: 10px;
      padding: 0px 5px 0 3px;
      background: #ecf3fc;
}

.navbar-list li .nav-tzcc2 .chooselanguage {
      border-color: transparent !important;
      box-shadow: none !important;
}

.navbar-list li .nav-tzcc2>span {
      background: #fff;
      border-radius: 100%;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
}

.navbar-list li .nav-tzcc2 span i {
      margin: 0;
}

.custom-tooltip {
      background-color: #333;
      color: #fff;
      font-size: 14px;
      padding: 8px;
      border-radius: 4px;
}

.navbar-list li:nth-child(2) {
      padding-left: 10px;
}

.navbar-list li .nav-tzcc2 select {
      background: transparent;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      width: 70px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
}

.navbar-list li .nav-tzcc2 select option {
      color: #000;
}

body.german .thrblksec .row>div:first-child h6.etttile {
      justify-content: flex-start !important;
}

body.french fcsro-dashboard .csv_btn,
body.portuguese fcsro-dashboard .csv_btn,
body.catalan fcsro-dashboard .csv_btn {
      top: 70px;
}

body.portuguese .total_val,
body.catalan .total_val,
body.french .total_val {
      padding-top: 20px;
}

.backhome {
      display: flex;
      max-width: fit-content;
      margin: 15px auto;
}

.add_head h3>.lft,
.lfts {
      display: flex;
      width: auto;
      align-items: center;
      justify-content: center;
}

.add_head h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.lfts {
      width: 50%;
      justify-content: flex-end;
}

.add_head h3>.lft span,
.lfts_head .lfts span {
      cursor: pointer;
      font-size: 12px;
      text-transform: capitalize;
}

.lfts_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;
}

.lfts_head h4 {
      width: 50%;
}

.quick_view .lfts_head h4,
fcsro-leave-permission-approval .lfts_head h4 {
      width: 300px;
}

.time_log_date {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0 0;
}

.time_log_date p {
      margin-bottom: 0 !important;
}

._402_page p {
      font-size: 18px;
      line-height: 24px;
}

.emp_details .tableplatformlist>form>.row>.col-md-4._full_width,
.emp_details .tableplatformlist>form>.row>.col-md-4._full_width {
      max-width: 100%;
      flex: 0 0 100%;
      text-align: center;
}

.emp_details .tableplatformlist>form>.row>.col-md-4._full_width>div,
.emp_details .tableplatformlist>form>.row>.col-md-4._full_width>div {
      margin: 0;
}

.at_details .row.dashpages {
      align-items: center;
}

/* 
.add_record .row>div:first-child .form-group>div .mat-form-field mat-label.ng-star-inserted {
      padding-left: 15px;
} */

.add_record .row>div:first-child .form-group>div {
      margin: 0 !important;
      width: 100%;
}

.add_record .row>div:first-child .form-group>div .mat-form-field {
      width: 100%;
      margin-top: 25px;
}

.add_record .row>div:first-child>span {
      display: none;
}

.add_record .row {
      justify-content: center;
}

.add_record alert.ng-star-inserted .alert {
      max-width: fit-content;
      margin: auto;
}

.add_record .row>div:first-child .form-group>div .mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent;
}

.orgCodeMobileView {
      display: none;
}

.quick_view table.mat-table thead tr th:not(:first-child),
.quick_view table.mat-table tbody tr td:not(:first-child) {
      text-align: left !important;
}

.live_animation {
      transition: 0.5s all ease-in-out;
      -webkit-animation: fade_out 1s ease-in-out;
      animation: fade_out 1s ease-in-out;
}

.live_animation2 {
      transition: 0.5s all ease-in-out;
      -webkit-animation: fade_in 1s ease-in-out;
      animation: fade_in 1s ease-in-out;
}

.screen_change {
      transition: 0.5s all ease-in-out;
      -webkit-animation: fade_out 1s ease-in-out;
      animation: trans_shadow 1s ease-in-out;
}


@keyframes fade_out {
      0% {
            transform: scale(1);
            opacity: 1;
      }

      50% {
            transform: scale(0.5);
            opacity: 0.5;
      }

      100% {
            transform: scale(0);
            opacity: 0;
      }
}

@keyframes fade_in {
      0% {
            transform: scale(0);
            opacity: 0;
      }

      50% {
            transform: scale(0.5);
            opacity: 0.5;
      }

      100% {
            transform: scale(1);
            opacity: 1;
      }
}

@keyframes trans_shadow {
      0% {
            box-shadow: 0px -5px 5px 0 #F4E869;
      }

      25% {
            box-shadow: 5px 0 5px 0 #F4E869;
      }

      50% {
            box-shadow: 0px 5px 5px 0 #F4E869;
      }

      75% {
            box-shadow: -5px 0px 5px 0px #F4E869;
      }

      100% {
            box-shadow: 0px 0px 5px 5px #F4E869;
      }
}



.lfts_head .px-3>div .col-md-5 .mat-form-field-label {
      left: 12px;
}

.quick_view .table-responsive .table thead tr th {
      width: 9.6%;
}

.quick_view .table-responsive .table thead tr th.emps_name {
      width: 16%;
}

.quick_view .table-responsive .table thead tr th:first-child,
.quick_view .table-responsive .table tbody tr td:first-child {
      padding-left: 20px !important;
      min-width: 135px;
}

.thrblksec .etttile>.box-indicator {
      justify-content: center;
      align-items: center;
      display: flex;

}

.gallery_div .tabletitle fcsro-focus-spread>.row>div {
      text-align: left !important;
}

.t_body {
      position: relative;
}

.task_enhancement .tableplatformlist>.row {
      align-items: flex-start;
      justify-content: flex-start;
}

.task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(4)>.blk {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
}

.task_enhancement .tableplatformlist>.row>.float-right>.mat-form-field:first-child {
      margin-right: 10px;
}

.task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(4)>.blk .mat-form-field:not(:last-child) {
      margin-right: 15px;
}

.task_enhancement .table-responsive .table thead tr th {
      width: 10%;
}

.task_enhancement .table-responsive .table thead tr th:last-child,
.task_enhancement .table-responsive .table thead tr th:nth-child(7) {
      width: 10%;
      text-align: center !important;
}

td.desg_Cell.mat-cell:nth-child(7) {
      text-align: center;
}

td.desg_Cell.edit_sec textarea {
      background: #fff;
      box-shadow: 0 0 5px 0 #e1e1e1;
}

.navbar-list li {
      display: flex;
      align-items: center;
      justify-content: center;
}

.navbar-list li .nav-tzcc2 {
      height: 34px;
}

.employee_page .lfts_head .lfts .btn {
      width: auto;
}

.employee_page .lfts_head .lfts .btn>span>i {
      margin-right: 10px;
}

.iq-sidebar-menu .iq-menu li a {
      overflow: unset;
}

body.russian .iq-sidebar-menu .iq-menu li a .badge {
      top: -3px;
      right: 0px;
}

body.russian .iq-sidebar-menu .iq-menu li ul.iq-submenu li a .badge,
body.portuguese .iq-sidebar-menu .iq-menu li ul.iq-submenu li a .badge {
      top: 5px;
      right: 0;
}

body.portuguese .iq-sidebar-menu .iq-menu>li>a>.badge {
      right: 0;
      top: -3px;
}

body.indonesian .iq-sidebar-menu .iq-menu>li>a>.badge {
      right: 0;
}

body.turkey .iq-sidebar-menu .iq-menu li ul.iq-submenu li a .badge {
      top: -2px;
}

body.tamil .time_log_date>div:first-child,
body.russian .time_log_date>div:first-child {
      width: 70%;
}

.q_name_sec {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px 0;
}

.q_name_sec>span {
      margin-top: 8px;
}

.em_name>p {
      line-height: 24px;
      margin: -10px 0 0 -3px;
}

/*Employee report icons*/
.mat-tab-label .mat-tab-label-content {
      padding-left: 30px;
}

.mat-tab-label:first-child .mat-tab-label-content {
      background: url(../images/emp_report/screen_icon.png) no-repeat left center;
      background-size: 22px;
}

.mat-tab-label:nth-child(2) .mat-tab-label-content {
      background: url(../images/emp_report/focus_t_icon.png) no-repeat left center;
      background-size: 25px;
}

.mat-tab-label:nth-child(3) .mat-tab-label-content {
      background: url(../images/emp_report/task_icon.png) no-repeat left center;
      background-size: 22px;
}

.mat-tab-label:nth-child(4) .mat-tab-label-content {
      background: url(../images/emp_report/ap_icon.png) no-repeat left center;
      background-size: 22px;
}

.mat-tab-label:nth-child(5) .mat-tab-label-content {
      background: url(../images/emp_report/tp_icon.png) no-repeat left center;
      background-size: 22px;
}

.mat-tab-label:nth-child(6) .mat-tab-label-content {
      background: url(../images/emp_report/ot_icon.png) no-repeat left center;
      background-size: 22px;
}

.mat-tab-label:nth-child(7) .mat-tab-label-content {
      background: url(../images/emp_report/ma_icon.png) no-repeat left center;
      background-size: 22px;
}

.mat-tab-label:nth-child(8) .mat-tab-label-content {
      background: url(../images/emp_report/b_m_icon.png) no-repeat left center;
      background-size: 25px;
}

.mat-tab-label:nth-child(9) .mat-tab-label-content {
      background: url(../images/emp_report/idle.png) no-repeat left center;
      background-size: 15px;
      padding-left: 20px;
}

.mat-tab-label:nth-child(10) .mat-tab-label-content {
      background: url(../images/emp_report/offline.png) no-repeat left center;
      background-size: 22px;
      padding-left: 25px;
}

.mat-tab-label:nth-child(11) .mat-tab-label-content {
      background: url(../images/emp_report/Visit_screen.png) no-repeat left center;
      background-size: 20px;
      padding-left: 27px;
}

.manager-login .mat-tab-label:nth-child(6) .mat-tab-label-content {
      background: url(../images/emp_report/ma_icon.png) no-repeat left center;
      background-size: 22px;
}

.manager-login .mat-tab-label:nth-child(7) .mat-tab-label-content {
      background: url(../images/emp_report/b_m_icon.png) no-repeat left center;
      background-size: 25px;
}

.manager-login .mat-tab-label:nth-child(8) .mat-tab-label-content {
      background: url(../images/emp_report/idle.png) no-repeat left center;
      background-size: 15px;
      padding-left: 20px;
}

.manager-login .mat-tab-label:nth-child(9) .mat-tab-label-content {
      background: url(../images/emp_report/offline.png) no-repeat left center;
      background-size: 22px;
      padding-left: 25px;
}

.dark_theme .manager-login .mat-tab-label:nth-child(6) .mat-tab-label-content {
      background: url(../images/emp_report/ma_icon_dark.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme .manager-login .mat-tab-label:nth-child(7) .mat-tab-label-content {
      background: url(../images/emp_report/b_m_icon_dark.png) no-repeat left center;
      background-size: 25px;
}

.dark_theme .manager-login .mat-tab-label:nth-child(8) .mat-tab-label-content {
      background: url(../images/emp_report/idle_dark.png) no-repeat left center;
      background-size: 15px;
      padding-left: 20px;
}

.dark_theme .manager-login .mat-tab-label:nth-child(9) .mat-tab-label-content {
      background: url(../images/emp_report/offline_dark.png) no-repeat left center;
      background-size: 22px;
      padding-left: 25px;
}

.dark_theme .mat-tab-label .mat-tab-label-content {
      padding-left: 30px;
}

.dark_theme .mat-tab-label:first-child .mat-tab-label-content {
      background: url(../images/emp_report/screen_icon_dark.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme .mat-tab-label:nth-child(2) .mat-tab-label-content {
      background: url(../images/emp_report/focus_t_icon_dark.png) no-repeat left center;
      background-size: 25px;
}

.dark_theme .mat-tab-label:nth-child(3) .mat-tab-label-content {
      background: url(../images/emp_report/task_icon_dark.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme .mat-tab-label:nth-child(4) .mat-tab-label-content {
      background: url(../images/emp_report/ap_icon_dark.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme .mat-tab-label:nth-child(5) .mat-tab-label-content {
      background: url(../images/emp_report/tp_icon_dark.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme .mat-tab-label:nth-child(6) .mat-tab-label-content {
      background: url(../images/emp_report/ot_icon_dark.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme .mat-tab-label:nth-child(7) .mat-tab-label-content {
      background: url(../images/emp_report/ma_icon_dark.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme .mat-tab-label:nth-child(8) .mat-tab-label-content {
      background: url(../images/emp_report/b_m_icon_dark.png) no-repeat left center;
      background-size: 25px;
}

.dark_theme .mat-tab-label:nth-child(9) .mat-tab-label-content {
      background: url(../images/emp_report/idle_dark.png) no-repeat left center;
      background-size: 20px;
}

.dark_theme .mat-tab-label:nth-child(10) .mat-tab-label-content {
      background: url(../images/emp_report/offline_dark.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme .mat-tab-label:nth-child(11) .mat-tab-label-content {
      background: url(../images/emp_report/Visit_screen_dark.png) no-repeat left center;
      background-size: 20px;
      padding-left: 27px;
}

/*Employee report icons*/

.lt_body .box .t_head .rft .time p {
      padding-right: 0;
}

.lt_body .box .t_head .lft p.big-letter>span {
      width: 25px;
      height: 25px;
      display: inline-flex;
      background: #0560fd;
      color: #fff;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      font-size: 10px;
      line-height: 12px;
      border-radius: 3px;
}

.lt_body .box .t_head p>bdi>p {
      max-width: 180px;
      color: #0560fd;
      text-overflow: ellipsis;
      white-space: nowrap;
}

.lt_body .box .t_head p>bdi>span {
      color: #92989d;
      font-size: 11px;
      line-height: 12px;
      margin-top: -2px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
}

/*NEW CHANGES*/

/*live popup*/
.live_pop {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
}

.pop_content {
      position: relative;
      background: #ecf3fc;
      text-align: center;
      padding: 25px;
      border-radius: 10px;
      width: auto;
      max-width: 85%;
}

.dark_theme .right-bar ul>.live_pop>.pop_content {
      background: #212121;
      border: 2px solid #36373c;
}

.pop_content>button {
      position: absolute;
      width: 35px;
      height: 35px;
      background: #0862fd;
      border: 1px solid #0862fd;
      border-radius: 5px;
      right: 0;
      top: 0;
      box-shadow: 0 0 5px 0 #2e2d2d;
}

.pop_content>button>img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      filter: invert(1);
}

.pop_content img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      max-height: initial;
      max-width: 1120px;
}

/*live popup*/

.dashboxtop .row>div {
      position: relative;
}

.dashboxtop .row>div p.dashboxtit.cmp_avg {
      position: absolute;
      top: -57px;
      right: -48%;
}

span.dashboxtxt p.d_break {
      font-size: 13px;
}

.dashboxtxt .dash_icon {
      margin-right: 10px;
}

/*emp report new changes*/
.gallery_div .tabletitle.thrblksec.act_row>.row {
      margin: 0;
      justify-content: space-between;
}

.gallery_div .tabletitle.thrblksec.act_row>.row .blk {
      width: 20%;
      padding: 0 15px;
}

.gallery_div .tabletitle.thrblksec.act_row>.row .blk h6 {
      min-height: 35px;
}

.gallery_div.empgallery>.gegsec>.row>.col-md-2 {
      flex: 0 0 23.5%;
      max-width: 23.5%;
}

.gallery_div.empgallery>.gegsec>.row>.col-md-10 {
      flex: 0 0 76.5%;
      max-width: 76.5%;
}

fcsro-gallery-media .arrowslr .mat-icon-button {
      position: relative;
}

.arrowslr .mat-icon-button span.tool_tips {
      position: absolute;
      left: 20%;
      bottom: -85px;
      background: #fbfbfb;
      padding: 10px;
      border-radius: 15px;
      font-size: 13px;
      color: #000;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transform: translateX(-50%);
      box-shadow: 0 0 10px 0 #e1e1e1;
      text-align: center;
      border: 1px solid #dcdcdc;
      transition: 0.5s all ease-in-out;

}

fcsro-gallery-media .arrowslr .mat-icon-button span.tool_tips {
      left: auto;
      right: 0;
      transform: unset;

}

.arrowslr .mat-icon-button:hover span.tool_tips {
      visibility: visible;
      opacity: 1;
}

.gallery_div .tabletitle.thrblksec:first-child {
      width: 60%;
      margin-right: 2%;
}

.gallery_div .tabletitle.thrblksec:last-child {
      width: 38%;
}

.gallery_div .tabletitle.thrblksec {
      padding: 10px !important;
}

.gallery_div .tabletitle.thrblksec:last-child .row .blk {
      width: 33.3%;
}

.gallery_div.empgallery>.gegsec>.row>.col-md-10 {
      display: flex;
}

.gallery_div .tabletitle.thrblksec.act_row>.row .blk h6>div {
      margin-bottom: 0;
}

.gallery_div .tabletitle.thrblksec.act_row>.row {
      min-height: 120.19px;
}

.gallery_div .tabletitle.thrblksec.act_row>.row .blk h6 {
      font-size: 12px;
}

.gallery_div .tabletitle.thrblksec:last-child .row .blk {
      padding: 0 10px;
}


.dlt_box {
      width: 100%;
      display: flex;
      align-items: initial;
      justify-content: space-between;
}

.dlt_box .box {
      background: #f9fcfe;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      text-transform: none;
      color: #212325;
      margin-bottom: 15px;
      border: 2px solid #fff;
      min-height: 149.78px;
      padding: 0px;
      text-align: center;
      width: 34%;
      order: 3;
}

.dlt_box .box:last-child {
      width: 16%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      order: 1;
      flex-direction: column;
}

.dlt_box .box:first-child {
      width: 45.5%;
      order: 2;
}

.dlt_box .box .blk>.etttile {
      justify-content: center;
      align-items: flex-start;
}

.sub_blk {
      width: 100%;
      padding: 0 15px;
}

.sub_blk .row {
      justify-content: center;
}

.sub_blk .row>.blk {
      width: 25%;
      padding: 0 10px;
      text-align: left;
}

.sub_blk .row>.blk .etttile {
      justify-content: space-between;
      padding-left: 0;
      padding-right: 0;
}

.sub_blk .row>.blk .etttile .box-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 3px;
      min-width: 20px;
      width: max-content;
      font-size: 11px;
}

.sub_blk .row>.blk>h5 {
      color: #212325;
}

.dlt_box .box:nth-child(2) .sub_blk .row>.blk {
      width: 33.3%;
}

.gallery_div .tabletitle {
      min-height: 161.31px;
      height: 93%;
}

.dlt_box .box:last-child .sub_blk .row>.blk {
      width: 100%;
}

.sub_blk .row>.blk p {
      margin-bottom: 0;
}

.box-indicator.offline-box {
      background: #555555;
}

.gallery_div.empgallery>.gegsec>.row>.col-md-7 {
      flex: 0 0 59.333333%;
      max-width: 59.333333%;
}

.gallery_div.empgallery>.gegsec>.row>.col-md-5 {
      flex: 0 0 40.666667%;
      max-width: 40.666667%;
}

.empgallery .gegsec fcsro-horizontal-calendar .pickcalendar>div:first-child {
      flex: 0 0 39.666667%;
      max-width: 39.666667%;
}

.empgallery .gegsec fcsro-horizontal-calendar .pickcalendar>div:last-child {
      flex: 0 0 60.333333%;
      max-width: 60.333333%;
}

.add_emp .tableplatformlist form button+div+div>.row {
      padding-left: 15px;
      width: 101%;
}


/*emp report new changes*/

/*How it's works bar*/
.content-page {
      padding: 80px 15px 0 15px;
}

.content-page.OrgUserRole {
      padding-right: 65px;
}

.right-bar {
      position: fixed;
      right: 0;
      top: 57px;
      height: calc(100% - 55px);
      background: #fff;
      box-shadow: 0 0px 10px #f2f2f2;
      width: 50px;
      overflow: hidden;
      padding: 15px 5px;
      transition: 0.5s all ease-in-out;
      z-index: 98;
}

.right-bar:hover {
      width: 450px;
}

.right-bar h4 {
      text-align: center;
      color: #333333;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-family: 'Roboto', Verdana, sans-serif;
      transition: 0.5s all ease-in-out;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
}

.right-bar:hover h4 {
      width: 100%;
      opacity: 1;
      visibility: visible;
}

.right-bar ul {
      padding: 0;
      margin: 0;
}

.right-bar ul li {
      list-style: none;
      margin-bottom: 1px;
}

.right-bar ul li>div>button {
      padding: 5px 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: transparent;
      border: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-radius: 8px;
}

.right-bar ul li button:hover {
      background: rgba(210, 233, 255, .54);
}

.right-bar ul li.active button {
      background: rgb(213 212 212 / 54%);
}

.right-bar ul li.active span,
.right-bar ul li.active i {
      color: #0560fd;
}

.right-bar ul li i {
      font-size: 20px;
      margin-right: 5px;
      width: 35px;
      height: 35px;
      border-radius: 5px;
}

.right-bar ul li span {
      white-space: nowrap;
      padding: 0;
      width: calc(100% - 40px);
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 600;
      font-size: 12px;
      color: #000;
      text-transform: uppercase;
      transition: 0.5s all ease-in-out;
      text-align: left;
}

@keyframes blink {

      0%,
      100% {
            background: rgb(213 212 212 / 54%);
            color: #0560fd;
      }

      50% {
            background: #0560fd;
            color: #fff;
      }
}

.right-bar ul li i.ri-question-line {
      animation: blink 1s linear infinite;
}

.right-bar ul li>div {
      position: relative;
      overflow: hidden;
}

.right-bar ul li>div .live_pop {
      width: 100%;
      max-width: 95%;
      max-height: 100%;
      position: relative;
      top: unset;
      right: unset;
      margin: 10px auto;
      padding: 0;
      border-radius: 0;
      background: transparent;
}

.right-bar ul li>div .live_pop .pop_content {
      position: relative;
      background: transparent;
      text-align: center;
      padding: 0;
      border-radius: 10px;
      width: 100%;
}

.right-bar ul li>div .live_pop .pop_content .blk {
      width: 100%;
      position: relative;
}

.right-bar ul li>div .live_pop .pop_content>video {
      width: 100%;
      max-width: 100%;
}

.right-bar ul li>div .live_pop .pop_content .blk>button {
      position: absolute;
      width: 30px;
      height: 30px;
      border: 1px solid #0862fd;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 #2e2d2d;
      background: #0862fd;
      right: -5px;
      top: -10px;
}

.right-bar ul li>div .live_pop .pop_content .blk>button img {
      filter: invert(1);
}

.right-bar ul li>div .live_pop .pop_content .blk video {
      width: 100%;
}

/*How it's works bar*/
.employee_page .paginatormob div form mat-select#selectedFilter {
      padding: 10px 35px 10px 15px !important;
      border-radius: 15px;
      transition: 0.5s all;
}

.employee_page .paginatormob div form mat-select#selectedFilter:focus {
      border-color: transparent !important;
      box-shadow: none !important;
}

form.selecctForm {
      position: relative;
}

form.selecctForm button {
      position: absolute;
      z-index: 2;
      right: 0;
      top: 35px;
      font-size: 18px;
}

.employee_page table.mat-table tbody tr td:nth-child(4) {
      text-align: center;
}

.employee_page table.mat-table thead tr th:nth-child(4)>div,
.employee_page table.mat-table thead tr th:nth-child(5)>div {
      justify-content: center;
}

.employee_page .paginatormob>.row {
      justify-content: flex-end;
      align-items: flex-end;
}

form.was-validated>.row:nth-child(3) .mat-form-field .mat-form-field-flex {
      border: 1px solid #0560fd;
      border-radius: 15px;
      height: 46px;
      padding: 0px 10px;
}

form.was-validated>.row:nth-child(3) .mat-form-field .mat-form-field-flex+div {
      height: 0;
}

form.was-validated>.row:nth-child(3) .mat-form-field .mat-form-field-flex+div>span {
      background-color: transparent !important;
}

form.was-validated>.row:nth-child(3) .mat-form-field .mat-form-field-flex>div>div {
      border: none;
}

.min_btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
}

button.minimize-button {
      border: 2px solid #0560fd;
      background: #0560fd;
      color: #fff;
      line-height: 24px;
      width: 35px;
      height: 35px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 20px;
      position: relative;
}

button.minimize-button.ex::before,
button.minimize-button.ex::after {
      content: "";
      width: 10px;
      height: 2px;
      background: #fff;
      position: absolute;
      transform: rotate(45deg);
      left: 7.5px;
      top: 50%;
      transition: 0.5s all ease-in-out;
}

button.minimize-button.ex::after {
      transform: rotate(130deg);
      right: 7.5px;
      left: auto;
}

button.min.minimize-button::before,
button.min.minimize-button::after {
      content: "";
      width: 10px;
      height: 2px;
      background: #fff;
      position: absolute;
      transform: rotate(-45deg);
      left: 7.5px;
      top: 50%;
      transition: 0.5s all ease-in-out;
}

button.min.minimize-button::after {
      transform: rotate(230deg);
      right: 7.5px;
      left: auto;
}

.dark_theme .right-bar {
      background: #1d232c;
      box-shadow: 0 0px 10px #36373c;
      top: 58px;
      border-left: 1px solid #36373c;
}

.dark_theme .right-bar ul li span,
.dark_theme .right-bar ul li i {
      color: #9f9f9f;
}

.task_enhancement table.mat-table tbody tr td:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: 100%;
      padding-right: 12px !important;
      flex-wrap: wrap;
}

.task_enhancement table.mat-table tbody tr td:last-child p,
.task_enhancement table.mat-table tbody tr td:last-child>div {
      margin-bottom: 0;
}

.task_enhancement table.mat-table tbody tr td {
      padding: 15px 10px;
}

.task_enhancement table.mat-table tbody tr td:last-child p,
.task_enhancement .table-responsive .table tbody tr td:nth-child(8) p,
.task_enhancement .table-responsive .table tbody tr td:last-child p {
      width: calc(100% - 45px);
      max-height: 100px;
      overflow: hidden;
      overflow-y: auto;
      display: block;
      word-break: auto-phrase;
}

.task_enhancement .table-responsive .table tbody tr td:nth-child(8) p,
.task_enhancement .table-responsive .table tbody tr td:last-child p {
      width: 100%;
}

.task_enhancement table.mat-table tbody tr td.past-due {
      background-color: #ff4f4f;
      color: #fff !important;
}

.dlt_box .box>.blk {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
}

.dlt_box .box .blk>.etttile {
      width: auto;
}

.sub_blk .blk .etttile {
      width: 100% !important;
}

.task_enhancement .tableplatformlist:nth-child(2) .row>div.float-right>.col-md-3,
.task_enhancement .tableplatformlist:nth-child(2) .row>div.float-right>.col-md-3>.mat-form-field {
      max-width: 100%;
      flex: 0 0 100%;
      width: 100%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row>div.float-right.ng-star-inserted {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 30%;
      max-width: 30%;
      flex: 0 0 30%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row>div.float-right.ng-star-inserted>.mat-form-field {
      width: 49%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(4)>.blk>div {
      width: 48%;
}

button.refresh_btn {
      width: 30px;
      height: 30px;
      margin: 0 10px 0 0;
      background: #ecf3fc;
      border: 1px solid #cad5e4;
      border-radius: 5px;
      color: #4d4d4d;
      font-size: 14px;
}

.fc-cs ul li span.text-warning {
      color: orange !important;
}

.fc-cs ul li span.text-danger {
      color: gray !important;
}

.fc-cs ul li span.text-secondary {
      color: #646464 !important;
}

table.mat-table tbody tr.past-due>td {
      border: 1px solid #f3c0c0;
      border-right-color: transparent;
      border-left-color: transparent;
}

.project_report.task_enhancement table.mat-table tbody tr.past-due {
      border: 2px double #f3c0c0;
}

.project_report.task_enhancement table.mat-table tbody tr.past-due>td {
      border: none !important;
}

table.mat-table tbody tr.past-due>td:first-child {
      border-left-color: #f3c0c0;
}

table.mat-table tbody tr.past-due>td:last-child {
      border-right-color: #f3c0c0;
}

.dark_theme .dlt_box .box {
      background: #1d232c;
      border-color: #1d232c;
}

.dark_theme .dlt_box .box .sub_blk .row>.blk>h5,
.dark_theme .dlt_box .box .sub_blk .row>.blk p {
      color: #9e9e9e !important;
}

body.french .dlt_box .box:last-child,
body.tamil .dlt_box .box:last-child,
body.russian .dlt_box .box:last-child,
body.hindi .dlt_box .box:last-child,
body.spanish .dlt_box .box:last-child,
body.catalan .dlt_box .box:last-child,
body.german .dlt_box .box:last-child,
body.portuguese .dlt_box .box:last-child,
body.indonesian .dlt_box .box:last-child {
      align-items: center;
}

body.french .dlt_box .box:last-child .blk,
body.tamil .dlt_box .box:last-child .blk,
body.russian .dlt_box .box:last-child .blk,
body.hindi .dlt_box .box:last-child .blk,
body.spanish .dlt_box .box:last-child .blk,
body.catalan .dlt_box .box:last-child .blk,
body.german .dlt_box .box:last-child .blk,
body.portuguese .dlt_box .box:last-child .blk,
body.indonesian .dlt_box .box:last-child .blk {
      flex-direction: column;
}

body.french .dlt_box .box .blk>.etttile {
      width: auto;
      word-break: auto-phrase;
}

body.french .activitybars>div {
      line-height: 16px;
}

body.tamil .activitybars div {
      font-size: 10px;
      line-height: 16px;
}

body.tamil .actvbox span:first-child,
body.tamil .idlebox span:first-child {
      font-size: 9px;
      word-break: auto-phrase;
}

body.portuguese .actvbox span:first-child,
body.portuguese .idlebox span:first-child {
      font-size: 10px;
}

body.spanish .sub_blk .blk .etttile,
body.russian .sub_blk .blk .etttile,
body.turkey .sub_blk .blk .etttile {
      font-size: 13px;
}

body.indonesian .sub_blk .blk .etttile {
      font-size: 12px;
}

.pop_content>h4 {
      color: #000;
}

.dark_theme .sminp {
      color: #9f9f9f;
}

.dash_board .tableplatformlist.dashboxtop>div {
      min-height: 232px;
}

.quick_view .table-responsive .table thead tr th {
      min-height: 46.09px;
      line-height: 22px;
}

/*503 error page*/
fcsro-error503 {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      background: #ecf3fc;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 10px 15px;
}

fcsro-error503 h3 {
      margin-bottom: 20px;
}

fcsro-error503 img {
      width: 100%;
      max-width: 750px;
      margin-bottom: 30px;
      object-fit: contain;
      object-position: center;
}

/*503 error page*/

.task_enhancement .table-responsive .table thead tr th {
      padding: 10px;
}

.task_enhancement .table-responsive .table thead tr th:nth-child(7) {
      width: 10%;
}

.task_enhancement .table-responsive .table thead tr th:first-child {
      width: 20%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(4)>div {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(4)>div>.mat-form-field {
      width: 100%;
}

.task_enhancement .table-responsive .table thead tr th:nth-child(6),
.task_enhancement .table-responsive .table thead tr th:nth-child(7) {
      width: 8%;
}

.task_enhancement table.mat-table tbody tr td:last-child {
      position: relative;
}

.right-bar ul li>div .live_pop .pop_content .blk>button.expand_btn {
      right: 30px;
      color: #fff;
      display: flex;
      align-items: center;
}

.right-bar ul li>div .live_pop .pop_content .blk>button.expand_btn img {
      filter: unset;
}

/*Leave page*/
.leave_box>.tabletitle {
      padding: 15px;
}

.leave_box .leave_h h4 {
      color: #000;
      text-transform: uppercase;
}

.leave_box .leave_bdy {
      width: 100%;
      padding: 30px;
}

.leave_box .leave_bdy .l_frm:not(:last-child) {
      margin-bottom: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
}

.leave_box .leave_bdy .l_frm ._frm:first-child {
      width: 150px;
      text-align: right;
}

.leave_box .leave_bdy .l_frm ._frm:nth-child(2) {
      width: calc(100% - 150px);
      padding-left: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.leave_box .leave_bdy .l_frm ._frm label {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      margin: 15px 0 0;
}

.leave_box .leave_bdy .l_frm ._frm select#leave,
.leave_box .leave_bdy .l_frm ._frm:nth-child(2)>select {
      width: 100%;
      max-width: 250px;
      padding: 10px 15px;
      border: none;
      border-bottom: 1px solid #949494;
      border-radius: 0px;
      font-size: 13px;
      line-height: 24px;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
      background: url(../images/tool_arrow.png) no-repeat right 5px center transparent;
      background-size: 20px;
}

.leave_box .leave_bdy .l_frm ._frm:nth-child(2)>.mat-form-field {
      width: 100%;
      max-width: 250px;
}

.leave_box .leave_bdy .l_frm ._frm:nth-child(2)>.mat-form-field .mat-form-field-flex {
      background: transparent;
      border-radius: 15px;
}

.leave_box .leave_bdy .l_frm ._frm:nth-child(2)>.mat-form-field .mat-form-field-wrapper {
      padding: 0;
}

.leave_box .leave_bdy .l_frm ._frm select#leave option {
      padding: 10px 15px;
      font-size: 14px;
      line-height: 30px;
}

.leave_box .leave_bdy .l_frm ._frm:last-child label {
      margin: 0 15px;
      min-width: 25px;
      text-align: center;
}
.indonesian .leave_box .leave_bdy .l_frm ._frm:last-child label {
      min-width: 50px;
}

body.tamil .leave_box .leave_bdy .l_frm ._frm:last-child label {
      min-width: 60px;
}

body.tamil .leave_box .leave_bdy .l_frm ._frm textarea#reason {
      max-width: 580px;
}

body.hindi .leave_box .leave_bdy .l_frm ._frm:last-child label {
      min-width: 45px;
}

body.spanish .leave_box .leave_bdy .l_frm ._frm:last-child label {
      min-width: 40px;
}

.leave_box .leave_bdy .l_frm ._frm textarea#reason {
      width: 100%;
      max-width: 545px;
      padding: 10px 15px;
      border: none;
      border-bottom: 1px solid #949494;
      border-radius: 0px;
      font-size: 13px;
      line-height: 24px;
}

.leave_box .leave_bdy .l_frm:last-child {
      justify-content: center;
      align-items: center;
      display: flex;
}

.apply_btn {
      background: #0560fd;
      color: #fff;
      border: 1px solid #0560fd;
      border-radius: 30px;
      padding: 10px 15px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      transition: 0.5s all ease-in-out;
}

.apply_btn:hover {
      background: #fff;
      color: #0560fd;
}

.dark_theme .leave_box {
      box-shadow: 0 0 5px 0 #101010;
      border-color: #36373c;
}

.dark_theme .leave_box .leave_bdy .l_frm ._frm label {
      color: #fff;
}

.leave_box .leave_bdy form ._frm>span.warning-message {
      width: 100%;
      display: block;
      text-align: right;
      margin-bottom: 15px;
      color: red;
}

/*Leave page*/

.dark_theme .mat-datepicker-content {
      background: #1d232c;
      border: 2px solid #36373c;
}

.dark_theme .mat-calendar-body-cell-content,
.dark_theme .mat-calendar-body-label,
.dark_theme .mat-calendar-table-header th,
.dark_theme .mat-button-wrapper,
.dark_theme .mat-calendar-previous-button::after,
.dark_theme .mat-calendar-next-button::after {
      color: #fff;
}

.dark_theme .mat-calendar-arrow {
      border-top-color: #fff;
}

body.russian .quick_view table.mat-table {
      width: 2000px;
}

body.chinese .employee_page .table-responsive table thead tr th:nth-child(6) {
      width: 15%;
}

.col-md-6._idle_tracking+div>.mat-expansion-panel {
      box-shadow: none;
      border-radius: 15px;
      transition: 0.5s all ease-in-out;
}

.col-md-6._idle_tracking+div mat-expansion-panel.mat-expansion-panel.mat-expanded {
      height: 91.5%;
}

fcsro-app-settings .tableplatformlist h6 {
      font-size: 12px;
}

.col-md-6._idle_tracking+div>.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
      background: transparent;
}

.col-md-6._idle_tracking+div>.mat-expansion-panel .mat-expansion-panel-header {
      padding: 0;
}

.col-md-6._idle_tracking+div>.mat-expansion-panel .mat-expansion-panel-body {
      padding: 0;
}

.col-md-6._idle_tracking+div>.mat-expansion-panel .mat-expansion-panel-body .tableplatformlist {
      background: transparent;
      box-shadow: none;
}

.col-md-6._idle_tracking+div>.mat-expansion-panel .mat-expansion-panel-body .tableplatformlist h6 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.col-md-6._idle_tracking+div>.mat-expansion-panel .mat-expansion-panel-body .tableplatformlist h6>span>br {
      display: none;
}

._designation table.mat-table tbody tr td:last-child button:first-child {
      margin: auto !important;
}

.dark_theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
      background: #28282b;
}

.dark_theme .mat-dialog-container {
      background: #1d232c !important;
      box-shadow: 0 0px 3px rgb(37 37 41) !important;
      border: 2px solid #36373c !important;
}

.dark_theme mat-clock .circle {
      background: #313233;
}

.iq-sidebar-menu .iq-menu li:nth-child(3)>a .badge {
      top: -5px;
}

.dark_theme .leave_box .leave_h h4 {
      color: #fff;
}

.dark_theme .leave_box>.tabletitle {
      border: 2px solid #36373c;
}

.dark_theme .leave_box .leave_bdy .l_frm ._frm select#leave,
.dark_theme .leave_box .leave_bdy .l_frm ._frm:nth-child(2)>select,
.dark_theme .leave_box .leave_bdy .l_frm ._frm textarea#reason,
.dark_theme .leave_box .leave_bdy .l_frm ._frm:nth-child(2)>.mat-form-field .mat-form-field-flex {
      background: transparent;
      color: #9f9f9f;
      border-color: #36373c;
}

.dark_theme .leave_box .leave_bdy .l_frm ._frm select#leave,
.dark_theme .leave_box .leave_bdy .l_frm ._frm:nth-child(2)>select {
      background: url(../images/tool_arrow2.png) no-repeat right 5px center #1d232c;
      background-size: 20px;
}

.dark_theme .leave_box .leave_bdy .l_frm ._frm:nth-child(2)>select>option {
      background: #1d232c;
}

.leave_box .leave_bdy .l_frm ._frm:nth-child(2)>input {
      border: none !important;
      border-bottom: 1px solid #949494 !important;
      max-width: 250px;
      background-color: transparent;
}

body.catalan .leave_box .leave_bdy .l_frm ._frm:first-child {
      width: 160px;
}

fcsro-leave-permission-details table.mat-table thead tr th {
      width: 11.11%;
}

fcsro-leave-permission-details table.mat-table tbody tr td {
      max-width: 100%;
      word-break: break-all;
      white-space: initial;
      display: table-cell !important;
      padding: 15px 0;
}

fcsro-leave-permission-approval table.mat-table tbody tr td span,
fcsro-leave-permission-details table.mat-table tbody tr td span {
      width: 100%;
      max-height: 99px;
      overflow: hidden;
      overflow-y: auto;
      display: block;
      word-break: break-word;
      max-width: 185px;
}

.dark_theme .mat-pseudo-checkbox {
      color: rgb(159 159 159);
}

.dark_theme .mat-table tbody tr:nth-child(2n) td {
      background: #131517;
}

.dark_theme .form-control {
      border-color: #0560fd !important;
      background: #1d232c;
      border-width: 1px;
      color: #9f9f9f;
}

.dark_theme .custom-select.is-valid,
.dark_theme .was-validated .custom-select:valid {
      background-color: #1d232c;
      color: #9f9f9f;
}

body.french .leave_box .leave_bdy .l_frm ._frm:first-child {
      width: 165px;
}

.dark_theme confirmation-dialog.ng-star-inserted h4 {
      color: #fff;
}

.tamil .dlt_box .box .blk>.etttile {
      font-size: 11px;
      word-break: auto-phrase;
}

.project_report.task_enhancement table.mat-table tbody tr td:last-child {
      text-align: center;
}

.task_time_report .etttile small {
      min-width: 50px;
}

body.catalan .employee_page table.mat-table {
      width: 2000px;
}

.pop_content>video {
      max-width: 100%;
}

fcsro-leave-permission-approval table.mat-table tbody tr td {
      padding-top: 15px;
      padding-bottom: 15px;
}

fcsro-leave-permission-approval table.mat-table tbody tr td:nth-child(9) span {
      width: 150px;
      height: 100px;
      overflow: hidden;
      overflow-y: auto;
      display: block;
      word-break: auto-phrase;
      white-space: break-spaces;
      line-height: 24px;
}

fcsro-leave-permission-approval table.mat-table {
      white-space: normal;
}

fcsro-leave-permission-approval table.mat-table tbody tr td:nth-child(2) {
      min-width: auto;
}

fcsro-leave-permission-approval table.mat-table thead tr th {
      width: 9%;
}

fcsro-leave-permission-approval table.mat-table thead tr th:first-child {
      width: 15%;
}

fcsro-leave-permission-approval table.mat-table tbody tr td:last-child>button {
      margin: 0;
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
}

fcsro-leave-permission-approval table.mat-table tbody tr td:last-child>button:not(:last-child) {
      margin-bottom: 10px;
}

div#collapsedSection .form-group {
      margin-bottom: 2rem;
}

.navbar-list li .nav-tzcc2 select>option {
      text-align: left;
      font-size: 18px;
      line-height: 24px;
}

fcsro-leave-permission-approval table.mat-table tbody tr td:last-child>button i {
      font-size: 14px;
}

.navbar-list li .nav-tzcc2 .mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius: 0;
      padding: 0;
      background: transparent;
      border: none;
}

.navbar-list li .nav-tzcc2 .mat-form-field-appearance-fill .mat-form-field-infix {
      padding: 0;
      border: none;
      width: 85px;
      background: transparent;
}

.navbar-list li .nav-tzcc2 .mat-form-field-wrapper {
      padding-bottom: 0;
}

.navbar-list li .nav-tzcc2 .chooselanguage {
      background: transparent;
      border: none;
}

.navbar-list li .nav-tzcc2 .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
      transform: unset;
}

.navbar-list li .nav-tzcc2 .mat-select-arrow {
      color: #242628 !important;
}

.navbar-list li .nav-tzcc2 .mat-select-value-text {
      color: #242628;
      font-size: 12px;
      font-weight: bold;
}

.navbar-list li .nav-tzcc2 .mat-form-field-underline {
      display: none;
}

div#chooselanguage-panel {
      top: 35px !important;
      max-width: 140px !important;
      min-width: 140px !important;
      position: absolute;
      left: -40px;
      border-radius: 5px !important;
}

.mat-select-panel-wrap.ng-tns-c109-2 {
      left: -10px;
}

div#chooselanguage-panel .mat-select-panel-wrap {
      width: 100%;
}

div#chooselanguage-panel .mat-option.mat-selected:not(.mat-option-multiple) {
      background: rgba(0, 0, 0, .12) !important;
}

div#chooselanguage-panel .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
      color: #0052df;
}

div#chooselanguage-panel .mat-option-text span {
      font-size: 12px;
}

div#chooselanguage-panel .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
      background: rgba(0, 0, 0, .04);
}

div#chooselanguage-panel .mat-option {
      line-height: normal;
      height: 42px;
}

div#chooselanguage-panel .mat-select-panel {
      border-radius: 5px !important;
}

div#chooselanguage-panel::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 1);
      background-color: #fff;
}

div#chooselanguage-panel::-webkit-scrollbar {
      width: 4px;
      background-color: #c5c5c5;
}

div#chooselanguage-panel::-webkit-scrollbar-thumb {
      background-color: #0560fd;
      border-radius: 5px;
}

body.french fcsro-leave-permission-approval table.mat-table tbody tr td:last-child>button:first-child {
      width: 100%;
}

.mob_view {
      display: none;
}

.mob_view div#accordion h4.accordion-title {
      padding: 5px 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: transparent;
      border: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-radius: 8px;
      cursor: pointer;
      font-weight: normal;
      margin: 0;
}

.mob_view div#accordion h4.accordion-title i {
      font-size: 20px;
      margin-right: 5px;
      width: 35px;
      height: 35px;
      border-radius: 5px;
}

.mob_view div#accordion h4.accordion-title span {
      white-space: nowrap;
      padding: 0;
      width: calc(100% - 40px);
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 600;
      font-size: 12px;
      color: #000;
      text-transform: uppercase;
      transition: 0.5s all ease-in-out;
      text-align: left;
}

.mob_view div#accordion h4.accordion-title.open {
      background: rgb(213 212 212 / 54%);
}

.mob_view div#accordion h4.accordion-title.open i,
.mob_view div#accordion h4.accordion-title.open span {
      color: #0560fd;
}

.mob_view div#accordion .accordion-content {
      padding: 10px 15px;
}

.mob_view div#accordion .accordion-content p {
      text-align: center;
}

.mob_view div#accordion .accordion-content .blk {
      width: 100%;
      position: relative;
}

.mob_view div#accordion .accordion-content .blk video,
.mob_view div#accordion .accordion-content .blk>img {
      width: 100%;
}

.mob_view div#accordion .accordion-content .blk>button {
      position: absolute;
      width: 30px;
      height: 30px;
      border: 1px solid #0862fd;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 #2e2d2d;
      background: #0862fd;
      right: -5px;
      top: -10px;
}

.mob_view div#accordion .accordion-content .blk>button.expand_btn {
      right: 30px;
      color: #fff;
      display: flex;
      align-items: center;
}

.mob_view div#accordion .accordion-content .blk>button img {
      filter: invert(1);
      width: 100%;
      object-fit: contain;
      object-position: center;
}

.mob_view div#accordion .accordion-content .blk>button.expand_btn img {
      filter: unset;
}

.dark_theme .mob_view div#accordion h4.accordion-title span,
.dark_theme .mob_view div#accordion h4.accordion-title i,
.mob_view div#accordion .accordion-content p {
      color: #9f9f9f;
}

.dark_theme .mob_view div#accordion h4.accordion-title.open {
      background: rgb(6 96 254);
}

.dark_theme .mob_view div#accordion h4.accordion-title.open span,
.dark_theme .mob_view div#accordion h4.accordion-title.open i {
      color: #fff;
}

.dark_theme .nav-tzcc.nav-tzcc2 .mat-select-value>span>span,
.dark_theme .modal-body .contentsec p {
      color: #fff;
}

fcsro-leave-permission-details table.mat-table tbody tr td:last-child>button:last-child {
      width: 38px;
      height: 35px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 !important;
}

.em_name {
      padding-right: 10px;
      width: calc(100% - 30px);
}

.em_name p {
      line-height: 18px;
      margin-top: 0px;
}

._designation table.mat-table thead tr th:last-child>div {
      justify-content: flex-start;
}

.lt_body .box .t_head .rft {
      width: 106.11px;
}

.lt_body .box .t_head .lft {
      width: calc(100% - 106.11px);
      overflow: hidden;
}

fcsro-leave-permission-details table.mat-table tbody tr td:last-child {
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 131px;
}

fcsro-leave-permission-details table.mat-table tbody tr td:last-child>button {
      width: 100% !important;
}

fcsro-leave-permission-details table.mat-table tbody tr td:last-child>button>i {
      margin: auto !important;
}

fcsro-leave-permission-details table.mat-table tbody tr td:last-child button>span {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 26px;
}

fcsro-leave-permission-details table.mat-table tbody tr td:last-child>button>span>i {
      margin: 0 3px 0 0 !important;
}

.nav-tzcc .alert .iq-alert-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
}

.nav-tzcc .alert .iq-alert-text>button {
      width: 95px;
}

.nav-tzcc .alert .iq-alert-text>span {
      width: calc(100% - 95px);
      font-size: 12px;
}

.emp_alert,
.leave_cancel_alret {
      width: max-content;
      margin-left: auto;
      margin-right: 10px;
      border-radius: 3px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 250px;
}

.emp_alert>button,
.leave_cancel_alret>button {
      padding: 6px !important;
}

.leave_cancel_alret {
      min-width: 280px;
}


fcsro-gallery-media .mat-tab-label {
      padding: 0 15px !important;
      min-width: auto !important;
      margin-right: 20px;
      filter: grayscale(1);
}

/* fcsro-pie-chart .plot-container.plotly {
      display: flex;
      align-items: center;
      justify-content: center;
} */

fcsro-leave-permission-approval table.mat-table tbody tr td:last-child>button span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
}

fcsro-leave-permission-approval form.selecctForm {
      padding-bottom: 15px;
}

fcsro-leave-permission-approval .lfts {
      align-items: center;
      width: calc(100% - 300px);
}

fcsro-leave-permission-approval .lfts .col-md-6 {
      justify-content: space-between;
      display: flex;
}

fcsro-leave-permission-approval .lfts .col-md-6>.mat-form-field {
      width: 48%;
      max-width: 100% !important;
}

.dark_theme fcsro-leave-permission-details table.mat-table tbody tr {
      background: #1d232c;
      border-bottom: 1px solid rgb(43 43 43);
}

.dark_theme fcsro-leave-permission-details table.mat-table tbody tr:nth-child(2n) {
      background: #131517;
}

.dark_theme fcsro-leave-permission-details table.mat-table thead tr {
      border-bottom: 1px solid rgb(43 43 43);
}

.dark_theme fcsro-leave-permission-details table.mat-table tbody tr td:last-child,
fcsro-leave-permission-details table.mat-table tbody tr td:last-child {
      border: none;
}

fcsro-leave-permission-details table.mat-table thead tr,
fcsro-leave-permission-details table.mat-table tbody tr {
      border-bottom: 1px solid #dee2e6;
}

fcsro-leave-permission-approval table.mat-table tbody tr td:nth-child(3) {
      min-width: auto;
}

.mat-input-element {
      max-width: -webkit-fill-available !important;
      max-width: -moz-available !important;
}

fcsro-leave-permission-approval table.mat-table tbody tr:first-child td:last-child>button:last-child {
      margin: 0 !important;
}

fcsro-leave-permission-details .tableplatformlist>.row {
      margin: 0;
}

.row.actsetlist>.col-md-4.col-sm-12>.tableplatformlist:last-child {
      height: calc(100% - 110.77px);
}

.quick_view table.mat-table tbody tr td:nth-child(4)>div {
      margin-right: 10px;
}

.quick_view table.mat-table tbody tr td {
      border: none;
      padding: 10px 0;
}

button#button-addon2 i {
      margin: 0;
}

._designation form .row+div>button:not(:last-child) {
      margin-right: 15px;
}

.row.actsetlist+div {
      margin-top: 30px;
}

/*Input design*/
.form-item {
      position: relative;
}

.form-item input {
      border-radius: 0;
      border: none !important;
      transition: 0.5s all ease-in-out;
      padding-left: 0;
      background-color: transparent;
}

.form-item input+label {
      position: absolute;
      cursor: text;
      z-index: 2;
      margin: 0;
      left: 0;
      top: 13px;
      transition: 0.5s all ease-in-out;
}

.form-item input:focus {
      border-color: #0560fd !important;
      background-color: transparent;
      box-shadow: none !important;
}

.form-item input:focus+label,
.form-item input:valid+label {
      top: -6px;
      font-size: 9px;
}

.form-item .mat-form-field {
      width: 100%;
}

.form-item .mat-form-field-appearance-legacy .mat-form-field-infix {
      height: 45px;
}

.add_emp .tableplatformlist form {
      padding: 40px 10px 20px;
      box-shadow: none;
      border: 1px solid #ededed;
      position: relative;
      background: #fff;
}

.form-item .mat-form-field-label {
      font-size: 12px !important;
      color: #92989d;
}

.form-item .mat-form-field-appearance-legacy .mat-form-field-underline {
      background-color: rgb(225 225 225);
}

.add_emp .tableplatformlist form button {
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: unset;
}

.add_emp .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent,
.add_emp .form-item .mat-form-field-appearance-legacy .mat-form-field-underline {
      background-color: #e1e1e1 !important;
}

.tableplatformlist,
.btn,
.navbar-list li .nav-tzcc2,
.navbar-list li .user_img img,
.employee_page .paginatormob div form mat-select#selectedFilter,
.iq-sidebar-menu .iq-menu li a {
      border-radius: 6px;
}

.emp_frm button.btn.btn-gradiant {
      width: 140px;
}

.emp_frm .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: transparent !important;
}

.emp_frm .mat-form-field-appearance-fill .mat-form-field-underline::before {
      background-color: #e1e1e1;
}

.emp_frm .mat-form-field-appearance-fill .mat-form-field-ripple {
      height: 0;
}

/*Input design*/

/*Table design*/
.employee_page .table thead th.tble_hdr {
      font-weight: 600;
      background: #ecf3fc;
      padding-top: 15px !important;
      padding-bottom: 15px !important;
}

.employee_page .mat-table tbody tr td,
tr.mat-header-row td {
      padding: 15px 10px;
      color: #000;
      border: none;
}

.employee_page .mat-table tbody tr td p {
      font-size: 11px;
      line-height: 15px;
}

.mat-table tr:nth-child(even) {
      background: #f8fbff;
}

.employee_page .paginatormob div form mat-select#selectedFilter {
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-radius: 0;
}

.emp_frm .mat-form-field-appearance-fill .mat-form-field-flex {
      padding-left: 0 !important;
}

.dark_theme .employee_page .table thead th.tble_hdr {
      background: #131517;
}

.dark_theme p {
      color: #9f9f9f;
}

.highlight-row {
      background-color: rgb(213 234 255) !important;
}

.cancel-btn,
.add-btn {
      width: auto !important;
      min-width: 100px !important;
}

.cancel-btn span {
      display: flex;
      align-items: center;
      justify-content: center;
}

/*Table design*/

.tamil .atdet_lft>.cards .card p {
      font-size: 7px;
}

.russian .atdet_lft>.cards .card p,
.german .atdet_lft>.cards .card p,
.portuguese .atdet_lft>.cards .card p {
      font-size: 10px;
}

.german .atdet_lft>.cards .card {
      width: 82px;
}

button.how_btn {
      display: none;
}

.add_emp .form-control {
      line-height: normal;
      height: 30px;
}

.dark_theme .navbar-list li>a,
.dark_theme .emp_content .emp_detail {
      border-color: #36373c;
}

.dark_theme .rept_det .lft ul li.current {
      background: #0560fd;
}

.manage_lg.ng-star-inserted {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.manage_lg>div {
      width: 65%;
}

.current-date-marker {
      border-radius: 0 6px 6px 0 !important;
}

.mat-tab-labels {
      border-radius: 6px;
      padding: 5px;
}

.mat-tab-label {
      height: 43px !important;
}

.sign-in-from input.form-control {
      border-radius: 6px;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
      box-shadow: 0 0 5px 0 #e1e1e1;
}

.modal .tableplatformlist {
      border: none;
}

.form-control,
.clientSelectedEmployees.mat-form-field-appearance-legacy.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex,
.selectedTeams.mat-form-field-appearance-legacy.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex,
form.was-validated>.row:nth-child(3) .mat-form-field .mat-form-field-flex,
.custom-select {
      border-radius: 0px !important;
}

.taskmbcont .mat-form-field {
      width: 100%;
}

fcsro-employee-report .row>.col-md-6>.tableplatformlist {
      height: 96%;
}

fcsro-employee-report .empreportht {
      height: 100% !important;
}

.at_head .card p {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
}

.dark_theme .table thead th {
      background: transparent;
}

.dark_theme .table td {
      background: #1d232c;
}

.dark_theme .table tbody tr:nth-child(2n) td {
      background: #131517;
}

.dark_theme .highlight-row {
      background-color: rgb(0 1 1) !important;
}

.dark_theme .view_details>table tbody tr td .blk p.google {
      color: #9f9f9f !important;
}

.dark_theme .table-striped tbody tr:nth-of-type(even) {
      background-color: rgb(19 21 23);
}

.dark_theme .movie-list,
.dark_theme .border_btm {
      border-color: #36373c !important;
}

form.map_form>.row>.frm:not(:last-child) {
      margin-right: 20px;
}

form.map_form>.row {
      margin: 0;
      align-items: center;
      justify-content: space-between;
}

form.map_form>.row .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: transparent !important;
}

form.map_form>.row>div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

form.map_form>.row>div:first-child>.frm {
      margin-right: 20px;
}

div#map {
      z-index: 0;
}

body.french .csv_btn {
      top: 0;
}

body.french fcsro-task-enhancement .csv_btn {
      top: 0px;
}

.a_body {
      padding: 25px 10px;
}

.time_log .contentsec {
      padding: 0;
}

.gallery_div .tabletitle .text-primary>span {
      color: #000;
}

.dark_theme .gallery_div .tabletitle .text-primary>span {
      color: #fff;
}

fcsro-gallery-media .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
      color: #0560fd;
}

fcsro-gallery-media .mat-tab-label.mat-tab-label-active {
      filter: grayscale(0);
}

.russian .itl {
      max-width: 125px;
}

body.russian .t_activity .table .t_body .flex div:first-child ul li p {
      width: 100px;
}

.mat-tab-body.mat-tab-body-active {
      margin-top: 20px;
}

fcsro-gallery-media .mat-tab-body.mat-tab-body-active .mat-tab-body-content>.row>.col-md-6:nth-child(2)>div {
      height: 96%;
}

.select_option .frm {
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.select_option .frm>div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

.select_option .frm>div:first-child>.frm {
      margin-right: 15px;
}

.select_option .frm>div:first-child>.frm .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: transparent;
}

fcsro-live-location .mat-tab-label:first-child .mat-tab-label-content {
      background: url(../images/emp_report/location_dark.png) no-repeat left center;
      background-size: 22px;
}

fcsro-live-location .mat-tab-label:nth-child(2) .mat-tab-label-content {
      background: url(../images/emp_report/feild_location_dark.png) no-repeat left center;
      background-size: 24px;
}

fcsro-live-location .mat-tab-label:nth-child(3) .mat-tab-label-content {
      background: url(../images/emp_report/tracking_locations_dark.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme fcsro-live-location .mat-tab-label:first-child .mat-tab-label-content {
      background: url(../images/emp_report/location.png) no-repeat left center;
      background-size: 22px;
}

.dark_theme fcsro-live-location .mat-tab-label:nth-child(2) .mat-tab-label-content {
      background: url(../images/emp_report/feild_location.png) no-repeat left center;
      background-size: 24px;
}

.dark_theme fcsro-live-location .mat-tab-label:nth-child(3) .mat-tab-label-content {
      background: url(../images/emp_report/tracking_locations.png) no-repeat left center;
      background-size: 22px;
}

.frm .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: transparent;
}

.modal-body>img {
      width: 100% !important;
      max-width: 765px;
      max-height: 570px;
      min-height: 570px;
      object-fit: cover;
      margin-bottom: 10px;
}

.frm>.frm:not(:last-child) {
      margin-right: 25px;
}

.frm {
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

._frm._date_frm {
      display: flex;
      align-items: center;
      justify-content: flex-start;
}

._frm._date_frm input {
      background: transparent;
      border: none !important;
      border-radius: 0 !important;
      height: 35px;
}

._frm._date_frm .mat-form-field-appearance-legacy .mat-form-field-infix {
      padding: 0 !important;
}

._frm._date_frm>div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 20px;
}

._frm._date_frm>div>label {
      display: block;
      width: 50px;
      margin: 0;
}

.sec_filter {
      width: 225px;
      padding: 0 15px;
      margin-left: auto;
}

.sec_filter label {
      margin: 0;
}

.sec_filter .mat-select {
      border-bottom: 1px solid #e0e0e0;
}

.contentsec .taskrowmob h5 {
      padding: 15px 0;
}

.taskrowmob .tableplatformlist>hr {
      display: none;
}

.modal-footer {
      position: absolute;
      left: 0;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      justify-content: space-between;
      border: none;
}

.modal-footer>button {
      width: 35px;
}

.modal-footer>button>i {
      margin: 0;
      font-size: 30px;
}

.drop {
      padding: 0 !important;
      background-color: #0862fd;
      box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.44);
      margin: 0 auto;
      width: 55px !important;
      height: 55px !important;
      border-radius: 0% 100% 100% 100%;
      transform: rotate(225deg) !important;
      position: relative !important;
}

.profile .tableplatformlist1.mt-3 .col-md-6>.tableplatformlist.p-1 {
      height: 93%;
}

.card-header.iq-header-title.iq-card-header {
      background: #e5f2ff;
      height: 60px;
      display: flex;
      align-items: center;
}

.card-header.iq-header-title.iq-card-header h4 {
      margin-bottom: 0;
}

.dark_theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
      background-color: rgb(125 125 125);
}

.dark_theme .leaflet-popup-content-wrapper {
      background: #535353;
      color: #fff;
}

.dark_theme .modal-body strong {
      color: #9f9f9f;
}

.dark_theme .card-header.iq-header-title.iq-card-header {
      background: #1d232c;
      border-color: rgb(54 55 60);
}

body.portuguese fcsro-dashboard .csv_btn {
      top: 45px;
}

.row.taskrowmob>.col-md-4>div>.row {
      min-height: 116.19px;
}

.General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row>.col-sm-6 .tableplatformlist {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: calc(100% - 15px);
}

.General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row>.col-sm-6 .tableplatformlist>h6+.mt-4 {
      margin: 0 !important;
}

.General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row {
      align-items: initial;
}

.General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row>.col-sm-6 .tableplatformlist p {
      margin: 0;
}

.General_settings .contentsec .row>div mat-expansion-panel .mat-expansion-panel-body>.row .col-md-6>.tableplatformlist,
.mat-expansion-panel-body .row .tableplatformlist {
      height: calc(100% - 15px);
}

.General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row .col-sm-6:nth-child(2) .tableplatformlist {
      align-items: flex-start;
}

.General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row:nth-child(5)>.col-md-6:first-child>.tableplatformlist>.col-md-6:first-child {
      display: flex;
      align-items: center;
}

.mat-expansion-panel-body .row {
      align-items: initial;
}

.mat-expansion-panel-header {
      height: 45px;
}

.mat-expansion-panel-header.mat-expanded {
      height: 45px;
}

.General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row>.col-sm-6>.tableplatformlist {
      transition: 0.5s all ease-in-out;
}

.General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row>.col-sm-6:hover>.tableplatformlist,
.General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row>.col-sm-6:hover+.col-sm-6>.tableplatformlist {
      box-shadow: 0 4px 2px 2px #d3e1f9;
}

body.catalan fcsro-dashboard .csv_btn {
      top: 50px;
}

.General_settings .mat-tab-group {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
}

.General_settings .mat-tab-group mat-tab-header.mat-tab-header {
      width: 320px;
      background: #fff;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05);
      border: 1px solid #E2E8F0;
      padding: 15px;
      border-radius: 6px;
}

.General_settings .mat-tab-group .mat-tab-body-wrapper {
      width: calc(100% - 340px);
      background: #fff;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05);
      border: 1px solid #E2E8F0;
      padding: 15px;
      border-radius: 6px;
}

.General_settings .mat-tab-group .mat-tab-labels {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0;
      border-radius: 0;
}

.General_settings .mat-tab-group .mat-tab-label-container {
      display: block;
}

.General_settings .mat-tab-group .mat-tab-label:not(:last-child) {
      margin-bottom: 10px;
}

.General_settings .mat-tab-group .mat-tab-label {
      width: 100%;
      background: #fff;
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05);
      border: 1px solid #E2E8F0;
      padding: 15px 25px;
      height: auto !important;
      border-radius: 6px;
}

.General_settings .mat-tab-group.mat-primary .mat-ink-bar {
      background-color: transparent;
}

.General_settings .mat-tab-body.mat-tab-body-active {
      margin-top: 0;
}

.General_settings .mat-tab-label .mat-tab-label-content {
      padding-left: 50px;
      padding-top: 0;
      width: 100%;
      text-align: left;
      font-size: 12px;
      justify-content: flex-start;
      height: 35px;
}

.General_settings .mat-tab-label:first-child .mat-tab-label-content {
      background: url(../images/emp_report/screen_icon.png) no-repeat left center;
      background-size: 40px;
}

.General_settings .mat-tab-label:nth-child(2) .mat-tab-label-content {
      background: url(../images/emp_report/idle.png) no-repeat left center;
      background-size: 30px;
}

.General_settings .mat-tab-label:nth-child(3) .mat-tab-label-content {
      background: url(../images/emp_report/emp_page.png) no-repeat left center;
      background-size: 35px;
}

.General_settings .mat-tab-label:nth-child(5) .mat-tab-label-content {
      background: url(../images/emp_report/Visit_screen.png) no-repeat left center;
      background-size: 35px;
}

.General_settings .mat-tab-label:nth-child(4) .mat-tab-label-content {
      background: url(../images/emp_report/manager_page.png) no-repeat left center;
      background-size: 35px;
}

.General_settings .mat-tab-label:nth-child(6) .mat-tab-label-content {
      background: url(../images/emp_report/ma_icon.png) no-repeat left center;
      background-size: 35px;
}

.General_settings .mat-tab-label:nth-child(7) .mat-tab-label-content {
      background: url(../images/emp_report/Hourly_rate.png) no-repeat left center;
      background-size: 35px;
}

.General_settings .mat-tab-group .mat-tab-label.mat-tab-label-active {
      border-color: #0862fd;
}

.General_settings .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .row:not(:last-child) {
      margin-bottom: 15px;
}

.General_settings .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .row>div>.tableplatformlist {
      height: 100%;
      margin: 0;
}

.General_settings .mat-tab-body-wrapper .mat-tab-body-content {
      overflow: unset !important;
      height: auto;
}

.General_settings .mat-tab-body.mat-tab-body-active {
      position: relative;
      overflow-x: unset;
      overflow-y: unset;
      z-index: 1;
      flex-grow: unset;
}

.dark_theme .General_settings .mat-tab-group mat-tab-header.mat-tab-header,
.dark_theme .General_settings .mat-tab-group .mat-tab-body-wrapper,
.dark_theme .General_settings .mat-tab-group .mat-tab-label {
      background: #1d232c;
      border-color: #36373c;
}

.dark_theme .General_settings .mat-tab-label:first-child .mat-tab-label-content {
      background: url(../images/emp_report/screen_icon_dark.png) no-repeat left center;
      background-size: 40px;
}

.dark_theme .General_settings .mat-tab-label:nth-child(2) .mat-tab-label-content {
      background: url(../images/emp_report/idle_dark.png) no-repeat left center;
      background-size: 30px;
}

.dark_theme .General_settings .mat-tab-label:nth-child(5) .mat-tab-label-content {
      background: url(../images/emp_report/Visit_screen_dark.png) no-repeat left center;
      background-size: 35px;
}

.dark_theme .General_settings .mat-tab-label:nth-child(3) .mat-tab-label-content {
      background: url(../images/emp_report/emp_page_dark.png) no-repeat left center;
      background-size: 35px;
}

.dark_theme .General_settings .mat-tab-label:nth-child(4) .mat-tab-label-content {
      background: url(../images/emp_report/manager_page_dark.png) no-repeat left center;
      background-size: 35px;
}

.dark_theme .General_settings .mat-tab-label:nth-child(6) .mat-tab-label-content {
      background: url(../images/emp_report/ma_icon_dark.png) no-repeat left center;
      background-size: 35px;
}

.dark_theme .General_settings .mat-tab-label:nth-child(7) .mat-tab-label-content {
      background: url(../images/emp_report/Hourly_rate_dark.png) no-repeat left center;
      background-size: 35px;
}

.dark_theme .General_settings .mat-tab-labels {
      border-bottom: none;
}

.dark_theme .General_settings .mat-tab-group .mat-tab-label.mat-tab-label-active {
      border-color: #0862fd;
}

.General_settings .mat-tab-group .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
      display: none;
}

.quick_view table.mat-table tbody tr td>.d-flex>span {
      margin-left: 8px;
}

.quick_view table.mat-table tbody tr td>.d-flex {
      align-items: center;
      justify-content: flex-start;
}

/* .quick_view .table-responsive::-webkit-scrollbar {
      display: none;
} */
.add_break_btn {
      background: transparent;
      border: none;
      font-weight: 500;
}

.work_shift .form_frms {
      box-shadow: none;
      position: relative;
      background: #fff;
      border-radius: 6px;
}

.dark_theme .work_shift .form_frms {
      background: #1d232c;
      border-color: #36373c;
}

.task_enhancement .table-responsive {
      margin-top: 20px;
}

mat-select#statusFilter .mat-select-trigger {
      height: 100%;
}

mat-select#statusFilter .mat-select-value-text {
      display: block;
      padding-top: 8px;
}

fcsro-leave-permission-approval form.selecctForm .mat-select {
      max-height: 21.5px;
      margin-top: 0 !important;
}

.fill_section>div>*,
.fill_section>div>form>.mat-form-field {
      width: 100%;
}

.fill_section>.col-md-4 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 5px;
}

.fill_section>.col-md-4>.mat-form-field {
      width: 48%;
      max-width: 100%;
}

.taskmbcont .mat-form-field p.text-danger {
      position: absolute;
      left: -7px;
      top: 45px;
}

fcsro-leave-permission-approval table.mat-table thead tr th:last-child {
      text-align: center !important;
}

.dark_theme fcsro-timelog .table thead th {
      background: #1d232c;
      border-color: rgb(43 43 43);
      border-width: 1px;
      color: rgb(232, 230, 227) !important;
}

fcsro-timelog .table thead th {
      color: #000;
      font-size: 11px;
      text-align: center;
}

fcsro-employeee-task-enhancement-report .task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(3) {
      display: flex;
      flex-direction: column;
}

.dark_theme .s_head button {
      background: #0560fd;
      color: #ffffff !important;
      box-shadow: none !important;
      border-color: #0560fd;
}

.dark_theme .s_head button:hover {
      background: transparent !important;
}

.dark_theme .right-bar ul li.active span,
.dark_theme .right-bar ul li.active i {
      color: #fff;
}

.dark_theme .rept_det .lft ul button {
      border-color: transparent;
}

fcsro-email-digest .mat-expansion-panel,
fcsro-holiday-definition .mat-expansion-panel {
      padding: 15px;
}

.dark_theme td.desg_Cell.edit_sec textarea {
      background: #1d232c;
      box-shadow: 0 0 0px 0 #e1e1e1;
}

fcsro-custom-timezone .mat-form-field-appearance-outline .mat-form-field-prefix,
fcsro-custom-timezone .mat-form-field-appearance-outline .mat-form-field-suffix {
      top: -3px;
}

.dark_theme .mat-slide-toggle-bar {
      background-color: #000;
}

.dark_theme .mat-slide-toggle-thumb {
      background-color: #425364;
}

fcsro-gallery-media .mat-expansion-panel-header {
      padding: 0 15px !important;
}

fcsro-holiday-definition .form-group>div {
      width: 100%;
      margin-left: 0 !important;
}

fcsro-holiday-definition .form-group>div>.mat-form-field {
      width: 100%;
}

.dark_theme .custom_pop {
      background: #363739;
      box-shadow: none !important;
      border-color: #36373c;
}

.dark_theme .custom_pop ul li {
      color: #fff;
}

.gallery_div.empgallery>.gegsec>.row>.col-md-2 .mat-form-field .mat-form-field-suffix,
fcsro-top-activity-report .mat-form-field .mat-form-field-suffix,
fcsro-over-time-report .table-responsive>.mat-form-field .mat-form-field-suffix,
fcsro-client-report .mat-form-field .mat-form-field-suffix,
fcsro-project-report .mat-form-field .mat-form-field-suffix,
fcsro-employee-report .mat-form-field .mat-form-field-suffix,
fcsro-task-time-report .mat-form-field .mat-form-field-suffix,
fcsro-add-tag-group-form .mat-form-field .mat-form-field-suffix,
fcsro-task-enhancement .col-md-2 .mat-form-field .mat-form-field-suffix {
      top: -3px;
}

.dark_theme .toolTip {
      background-color: #1d232c !important;
      color: #fff !important;
      border-color: #36373c !important;
}

.dark_theme .mat-dialog-container addingform .mat-dialog-title>h4 {
      color: #fff !important;
}

.felabel {
      display: flex !important;
      justify-content: flex-end;
      margin-right: 15px !important;
}


.dark_theme button.refresh_btn,
.dark_theme .navbar-list li .nav-tzcc2,
.dark_theme .t_hd .date>p span {
      background: #0f1317;
      border-color: transparent;
      color: #fff
}

.dark_theme .navbar-list li .nav-tzcc2 .mat-select-arrow {
      color: #999 !important;
}

fcsro-email-digest .mat-form-field-appearance-legacy .mat-form-field-underline {
      bottom: 0;
}

fcsro-email-digest .mat-chip.mat-standard-chip {
      line-height: 15px;
}

fcsro-email-digest .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      max-height: max-content;
      padding-bottom: 3px;
}

fcsro-email-digest input.mat-chip-input {
      width: 100%;
      flex: 1 0 100%;
}

.dark_theme fcsro-email-digest .mat-chip.mat-standard-chip {
      background-color: #0862fd;
      color: #fff;
}

.dark_theme fcsro-email-digest .mat-chip.mat-standard-chip .mat-chip-remove {
      color: #fff;
}

fcsro-email-digest .mat-expansion-panel-header.mat-expanded,
fcsro-email-digest .mat-expansion-panel-header {
      height: auto;
}

fcsro-email-digest .custom-radio .custom-control-label::before,
fcsro-email-digest .custom-radio .custom-control-input:checked~.custom-control-label::after {
      top: 1px;
}

.snd_cpy .mat-expansion-panel-header>.mat-content {
      flex-direction: column;
}

.snd_cpy .mat-expansion-panel-header>.mat-content label {
      font-size: 12px;
}

.snd_cpy .mat-expansion-panel-header>.mat-content mat-panel-title {
      width: 100%;
      margin: 0;
}

.snd_cpy .mat-expansion-panel-header-description {
      margin: 0;
      justify-content: flex-start;
}

.snd_cpy .mat-expansion-panel-header:hover {
      background-color: transparent !important;
}

.snd_cpy .mat-expansion-panel-header {
      background: url(../images/tool_arrow.png) no-repeat right center !important;
}

.snd_cpy .mat-expansion-panel-header.mat-expanded {
      background: url(../images/tool_arrow_up.png) no-repeat right center !important;
}

.dark_theme .snd_cpy .mat-expansion-panel-header {
      background: url(../images/tool_arrow_w.png) no-repeat right center !important;
}

.dark_theme .snd_cpy .mat-expansion-panel-header.mat-expanded {
      background: url(../images/tool_arrow_up_w.png) no-repeat right center !important;
}

li.nav-item.sel_timezone .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0 15px !important;
      padding: 0 !important;
}

li.nav-item.sel_timezone .mat-form-field-label {
      padding: 0 10px;
      background: #fff;
      width: auto !important;
}

li.nav-item.sel_timezone {
      cursor: pointer;
}

.mat-option {
      line-height: 26px !important;
      height: 36px !important;
}

.mat-option-text {
      font-size: 13px !important;
      line-height: 22px !important;
      display: block !important;
      flex-grow: unset !important;
      /* overflow: unset !important;
      text-overflow: unset !important; */
      width: 100%;
      /* white-space: break-spaces; */
}

.task_enhancement .tableplatformlist.enhance_tab>.row>.col-md-8.text-right {
      display: none;
}

.fullloader .tableplatformlist {
      min-height: calc(100vh - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      box-shadow: none;
      border: none;
}

.comtithead {
      padding: 0px 15px;
      min-height: 68px;
      display: flex;
      align-items: center;
}

.comtithead h4 {
      display: flex !important;
      align-items: center;
      font-size: 20px;
}

.comtithead h4 i {
      top: 0 !important;
      margin-left: 5px;
}

.comfromlft {
      display: flex;
      width: 100% !important;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      padding: 0px !important;
      position: relative;
      top: 10px;
}

.comfromlft fcsro-search-data-table {
      margin-right: 15px;
}

.stlmywrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: normal;
      text-align: left;
}

.comtithead h4 i {
      color: #6c757d;
}

.quick_view .comfromlft .mat-form-field-appearance-outline .mat-form-field-outline-thick,
fcsro-report .mat-form-field-appearance-outline .mat-form-field-outline-thick,
fcsro-gallery-media .mat-form-field-appearance-outline .mat-form-field-outline-thick,
.activity_pattern .t_hd>.row .col-md-5 .mat-form-field-appearance-outline .mat-form-field-outline-thick,
fcsro-custom-timezone .mat-form-field-appearance-outline .mat-form-field-outline-thick,
fcsro-task-enhancement .mat-form-field-appearance-outline .mat-form-field-outline-thick,
fcsro-login-report .mat-form-field-appearance-outline .mat-form-field-outline-thick,
fcsro-over-time-report .mat-form-field-appearance-outline .mat-form-field-outline-thick,
fcsro-attendance-report .at_select .mat-form-field-appearance-outline .mat-form-field-outline-thick,
fcsro-add-tag-group-form .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
.sel_year .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
      color: transparent;
}

.quick_view .comfromlft .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-report .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-gallery-media .mat-form-field-appearance-outline .mat-form-field-outline,
.activity_pattern .t_hd>.row .col-md-5 .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-custom-timezone .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-task-enhancement .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-login-report .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-over-time-report .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-client-report .comtithead>div>.col-md-4 .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-project-report .comtithead>div .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-employee-report .comtithead>div .col-md-4 .mat-form-field-appearance-outline .mat-form-field-outline,
.task_box .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-attendance-report .at_select .mat-form-field-appearance-outline .mat-form-field-outline,
.at_report .tableplatformlist>.row .sel_mnth .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-add-tag-group-form .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-add-work-shift-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline,
.sel_year .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-holiday-definition .mat-form-field-appearance-outline .mat-form-field-outline,
.leave_box .leave_bdy .l_frm .mat-form-field-appearance-outline .mat-form-field-outline,
.at_details .row.dashpages .mat-form-field-appearance-outline .mat-form-field-outline,
fcsro-attendance-report-details .mat-form-field-appearance-outline .mat-form-field-outline,
.emptask_fill .mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent;
      border-bottom: 1px solid #949494;
}

.comfromlft fcsro-search-data-table .mat-form-field-infix {
      width: 222px;
      max-width: 222px;
      padding-top: 13px;
}

fcsro-report .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
fcsro-gallery-media .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
fcsro-custom-timezone .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
fcsro-task-enhancement .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
fcsro-over-time-report .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
      color: transparent;
}

fcsro-custom-timezone .mat-form-field-appearance-outline .mat-form-field-flex {
      padding: 0;
}

.activity_pattern .t_hd>.row {
      position: relative;
      top: 10px;
      width: 100%;
}

.activity_pattern .t_hd>.row div button {
      background: transparent;
      border: none;
}

.activity_pattern .t_hd>.row>div>button i {
      font-size: 22px;
}

.activity_pattern .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 9px 0;
}

.activity_pattern .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 12px 0 0;
}

.activity_pattern .comtithead {
      max-height: 68px;
}

.ad_report>.col-md-9>.col-md-3>.mat-form-field {
      width: 100%;
      max-width: 100%;
}

.ad_report>.col-md-9>.col-md-5>div>.mat-form-field {
      width: 48%;
}

.ad_report>.col-md-9>.col-md-5 .mat-form-field-appearance-legacy .mat-form-field-infix {
      padding: 7.5px 0;
}

.time_log_date>.col-md-3 {
      margin-top: 5px;
}

.quick_view .table-responsive .table thead tr th .mat-sort-header-content span i,
.mat-tab-label .mat-tab-label-content i,
.gallery_div .qtext i,
.sub_blk .row>.blk p i,
.gallery_div .tabletitle p i {
      color: #6c757d;
}

.hd .mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent;
      border-bottom: 1.5px solid rgba(0, 0, 0, .12);
}

form.selecctForm .mat-select {
      border-bottom: 1px solid #949494;
      padding: 6px 0 !important;
}

fcsro-dormant-employee-report .pagetitle.comtithead,
fcsro-high-idle-report .comtithead,
fcsro-client-report .comtithead {
      justify-content: space-between;
      width: 100%;
}

fcsro-dormant-employee-report .pagetitle.comtithead>.row,
fcsro-high-idle-report .comtithead>.row,
fcsro-over-time-report .comtithead>.row,
fcsro-project-mappings .comtithead>.row {
      width: 100%;
      margin: 0;
}

fcsro-dormant-employee-report .pagetitle.comtithead>.row>div,
fcsro-high-idle-report .comtithead>.row>div,
fcsro-over-time-report .comtithead>.row>div,
fcsro-project-mappings .comtithead>.row>div {
      padding: 0;
}

fcsro-login-report .mat-form-field-appearance-outline .mat-form-field-suffix {
      top: -3px;
}

fcsro-over-time-report .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0;
      padding-bottom: 0;
}

.fillter_ses .col-md-3 .mat-form-field {
      width: 100%;
}

.fillter_ses .col-md-4 {
      padding: 0;
}

.fillter_ses .col-md-4 .mat-form-field {
      width: calc(100% - 80px) !important;
}

.tbbtns .btn {
      transition: 0.5s all ease-in-out;
      opacity: 0;
      visibility: hidden;
}

.tasklist:hover .tbbtns .btn {
      opacity: 1;
      visibility: visible;
}

.row.taskrowmob>.col-md-4>div>.row>.col-4>.btn,
.row.taskrowmob>.col-md-4>div>.row>.col-6 .btn {
      margin-top: 13px;
}

.row.taskrowmob>.col-md-4>div>.row .input-group input.form-control,
.taskrowmob .input-group .btn {
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #949494 !important;
      margin: 0 !important;
}

.taskrowmob .btn {
      border-radius: 6px !important;
}

.modal-body.taskmbcont .form-group input.form-control,
.clientSelectedEmployees.mat-form-field-appearance-legacy.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex,
.selectedTeams.mat-form-field-appearance-legacy.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex,
.modal-body.taskmbcont .form-group .custom-select,
form.was-validated>.row:nth-child(3) .mat-form-field .mat-form-field-flex,
.row.taskrowmob>.col-md-4>div>.row .col-6>input.form-control {
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #949494 !important;
}

fcsro-client-report .comtithead>div>.col-md-4,
fcsro-client-report .comtithead>div,
fcsro-project-report .comtithead>div,
fcsro-employee-report .comtithead>div,
fcsro-task-time-report .comtithead>div,
fcsro-task-organisation-report .comtithead>div {
      padding: 0;
}

fcsro-client-report .comtithead>div>.col-md-4 .mat-form-field-wrapper,
fcsro-project-report .comtithead>div .mat-form-field-wrapper,
fcsro-employee-report .comtithead>div .mat-form-field-wrapper {
      padding: 0 !important;
      margin: 0 !important;
}

fcsro-client-report .comtithead>div>.col-md-4 {
      flex: 0 0 35.333333%;
      max-width: 35.333333%;
}

fcsro-employee-report .comtithead {
      justify-content: space-between;
}

.tableplatformlist h4>i {
      color: #6c757d;
}

fcsro-task-organisation-report fcsro-search-data-table .mat-form-field {
      top: 10px;
}

fcsro-client-report .comtithead>div {
      align-items: center;
}

fcsro-client-report .comtithead>div>.col-md-3 .mat-form-field {
      top: 10px;
}

fcsro-client-report .comtithead>div>.col-md-3 {
      flex: 0 0 35.333333%;
      max-width: 35.333333%;
      padding-right: 0;
}

.task_enhancement .tableplatformlist:nth-child(2) .row>div {
      flex: 0 0 25%;
      max-width: 25%;
}

.sel_period>label {
      display: block;
}

.sel_period>.mat-form-field {
      width: 100%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row.extra>div {
      flex: 0 0 17%;
      max-width: 17%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4 {
      flex: 0 0 32%;
      max-width: 32%;
}

fcsro-task-time-report .comtithead>div>span>label {
      margin: 0;
      line-height: normal;
}

fcsro-task-time-report .comtithead>div>span>input {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
}

fcsro-leave-permission-approval form.selecctForm .mat-select {
      border-bottom: none;
}

fcsro-leave-permission-approval .lfts .col-md-3 .mat-form-field {
      top: 10px;
}

fcsro-attendance-report .comtithead {
      max-height: 68px;
}

.at_report .tableplatformlist>.row {
      width: 100%;
}

.at_select .mat-form-field {
      width: 100% !important;
}

fcsro-attendance-report .at_select .mat-form-field-label {
      padding-left: 0;
}

.sel_serch .mat-form-field {
      top: 5px;
}

.at_select .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0;
}

.task_box>div {
      width: 49%;
}

.task_box>div .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 0;
}

.task_box.extra-project>div,
.task_box.custom-view>div {
      width: 32%;
}

.task_box.extra-project.extra-task>div,
.task_box.extra-project.custom-view>div {
      width: 24%;
}

.date_selector>.mat-form-field {
      width: 48%;
      top: 10px;
}

.date_selector .mat-form-field-appearance-legacy .mat-form-field-infix {
      padding: 11.26px 0;
}

.task_box.extra-project.extra-task.custom-view>div {
      width: 19%;
}

.task_time_report .comtithead>.col-md-2 {
      width: 240px !important;
      flex: unset;
      max-width: 100%;
}

.task_time_report .comtithead>.col-md-10 {
      width: calc(100% - 240px) !important;
      flex: unset;
      max-width: 100%;
}

.fill_section>div {
      flex: 0 0 20%;
      max-width: 20%;
}

.fill_section.customdate>div {
      flex: 0 0 33.3%;
      max-width: 33.3%;
}

.at_report .tableplatformlist.comtithead>.row>div,
fcsro-teams .comtithead>div,
fcsro-designations .comtithead>div,
fcsro-work-shifts .comtithead>div {
      padding: 0;
}

.sel_mnth .mat-form-field {
      width: 100%;
}

.paginatormob form.selecctForm button {
      top: 5px;
}

fcsro-add-work-shift-form.add_workshift input.form-control,
fcsro-add-designation-form.designation_frm input.form-control,
fcsro-add-tag-group-form input.form-control,
fcsro-teams input.form-control,
.General_settings .mat-tab-body.mat-tab-body-active input.form-control,
fcsro-designations input.form-control,
fcsro-work-shifts input.form-control,
fcsro-email-digest input.form-control,
fcsro-holiday-definition input.form-control,
fcsro-employee-details input.form-control,
fcsro-employee-details .custom-select,
fcsro-attendance-report-details input.form-control {
      border: none !important;
      border-bottom: 1px solid #949494 !important;
      background: transparent;
}

fcsro-add-work-shift-form.add_workshift input.form-control:focus,
fcsro-add-designation-form.designation_frm input.form-control:focus,
fcsro-add-work-shift-form.add_workshift.form-control.is-invalid:focus,
fcsro-add-work-shift-form.add_workshift .was-validated .form-control:invalid:focus,
fcsro-add-designation-form.designation_frm.form-control.is-invalid:focus,
fcsro-add-designation-form.designation_frm .was-validated .form-control:invalid:focus,
fcsro-teams .form-control.is-invalid:focus,
fcsro-teams .was-validated .form-control:invalid:focus,
fcsro-designations .was-validated .form-control:invalid:focus,
fcsro-work-shifts .was-validated .form-control:invalid:focus,
fcsro-employee-details .was-validated .custom-select:valid:focus,
fcsro-employee-details .was-validated .form-control:valid:focus,
fcsro-attendance-report-details .was-validated .form-control:invalid:focus {
      box-shadow: none;
      background: transparent;
}

fcsro-add-work-shift-form.add_workshift .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
      color: transparent;
      border-bottom: 1px solid #949494 !important;
}

fcsro-data-table .table-responsive table thead tr th:nth-child(3),
fcsro-data-table .table-responsive table thead tr th:nth-child(4) {
      width: 200px;
}

fcsro-holiday-definition .mat-form-field-appearance-outline .mat-form-field-wrapper {
      margin: 6px 0 0;
}

.row.actsetlist>div:not(:last-child) {
      margin-bottom: 30px;
}

.employee_page .mat-table tbody tr td:first-child {
      cursor: pointer;
}

fcsro-employee-details .form-control[readonly] {
      background-color: transparent;
}

fcsro-attendance-report-details .comtithead {
      max-height: 68px;
}

.at_details .row.dashpages {
      width: 100%;
}

.dark_theme .tableplatformlist.tasklist.active {
      background: #0052df !important;
}

.dark_theme .tableplatformlist.tasklist.disabled .tbcon .dots {
      color: #000;
}

.dark_theme .tableplatformlist.tasklist.active .tbcon .dots {
      color: #fff;
}

.dark_theme .tableplatformlist.tasklist.disabled {
      background-color: rgba(216, 216, 216, 0.5) !important;
}

.dark_theme .tableplatformlist.tasklist.completed {
      background-color: rgb(208, 255, 218) !important;
}

.dark_theme .tableplatformlist.tasklist.completed .tbcon .dots {
      color: #0a6a1f;
}

.activity_pattern .t_hd>.row .mat-form-field-appearance-legacy .mat-form-field-infix,
.time_log_date .mat-form-field-appearance-legacy .mat-form-field-infix {
      max-height: 41.75px;
}

.emptask_fill {
      flex-wrap: wrap;
}

.task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div {
      flex: 0 0 25%;
      max-width: 25%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div.custom_sec {
      flex: 0 0 52%;
      max-width: 52%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div.custom_sec .row>.col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div.custom_sec .row {
      width: 100%;
}

.task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div.custom_sec .row>.col-md-6 label {
      display: block;
}

.task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div.custom_sec .row>.col-md-6 .mat-form-field {
      width: 100%;
}

.dark_theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      border-color: rgb(255 255 255 / 38%);
}

.emp_date>.mat-form-field {
      width: 49%;
}

fcsro-monthly-time-log .org_toper_no {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
      box-shadow: none !important;
}

.form-control:focus {
      background-color: transparent;
}

.profile app-iq-card label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.policy_btn {
      width: auto;
      background: #ecf3fc;
      color: #4d4d4d !important;
      border: 1px solid #cad5e4;
      border-radius: 5px;
      font-weight: 600;
      line-height: 15px;
}

.invoice_popup h5,
.invoice_popup p {
      padding: 0;
      margin-bottom: 10px;
}

.invoice_popup .box:not(:last-child) {
      margin-bottom: 15px;
}

.invoice_popup .box {
      padding: 15px;
      background: #fff;
      box-shadow: 0 0 5px 0 #dae8ff;
      border-radius: 6px;
      border: 1px solid #d6e5ff;
}

.alert_w_auto {
      float: right;
      margin-right: 10px;
}

.tamil .select_team {
      width: 45%;
}

.tamil .quick_view .lfts_head h4 {
      width: 320px;
}

.tamil .gallery_div .tabletitle p {
      font-size: 10px;
}

.tamil .at_head .card p {
      font-size: 11px;
}

.russian .focus_loop .emp_content .emp_detail {
      width: 50%;
}

.russian .live_tracking .lt_head,
.russian .quick_view .lfts_head h4 {
      width: 330px;
}

.russian .live_tracking .t_hd {
      width: calc(100% - 330px);
}

.russian .fill_sec .cards .card p>span:last-child {
      width: 60px;
}

.russian .fill_sec .cards .card p>span:first-child {
      width: calc(100% - 40px);
}

.row.card_payment .mat-form-field-appearance-fill .mat-form-field-flex,
.row.card_payment .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.row.card_payment .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent,
.row.card_payment .mat-form-field.mat-focused .mat-form-field-ripple,
.row.card_payment .mat-form-field-ripple {
      background-color: transparent !important;
}

.row.card_payment .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex,
.row.card_payment .mat-form-field-appearance-fill .mat-form-field-underline::before {
      background-color: transparent !important;
      border-bottom: 1px solid #e1e1e1 !important;
}

.sub_btn {
      text-align: center;
      margin-top: 25px;
}

.employee_page .paginatormob div form mat-select#selectedFilter {
      padding-left: 0 !important;
}

.spanish .fill_sec .cards .card p {
      font-size: 11px;
}

.german .fill_sec .cards .card:first-child {
      width: 155px;
}

.portuguese .at_details .fill_sec .cards .card {
      width: 130px;
}

.alert.w-auto {
      width: auto;
      max-width: max-content;
}

/*New Task page*/
.task_mid_sec .add_btn {
      background: #EEF2F5;
      border: 1px solid #EEF2F5;
      border-radius: 6px;
      width: 40px;
      height: 40px;
}

.task_mid_sec .add_btn>i {
      font-size: 20px;
      color: #000;
}

.task_mid_sec .form-group {
      margin: 0;
      position: relative;
}

.task_mid_sec .form-group select {
      width: 100%;
      border: none;
      border-bottom: 1px solid #0560FD;
      border-radius: 0px;
      height: 40px;
      padding: 5px 35px 5px 15px;
      background: url(../images/tool_arrow.png) no-repeat right 5px center #fff;
      background-size: 25px;
      top: 0;
}

.task_mid_sec .form-group>button {
      position: absolute;
      right: 36px;
      top: 0px;
      width: 40px;
      height: 40px;
      background: transparent;
      border: transparent;
      color: #FF5F61;
}

.task_mid_sec .form-group>button>i,
.task-lists button i {
      font-size: 20px;
}

.task_mid_sec .form-group>button.edit {
      right: 76px;
}

.task_mid_sec .form-group>button.edit i,
.task-lists button.edit i,
.task-lists button.dot_btn i {
      color: #545454;
}

.task_mid_sec h4,
.task-lists h4 {
      text-transform: capitalize;
}

.task-lists button {
      background: transparent;
      border: none;
}

.task-lists .tableplatformlist {
      background: #EEF2F5;
      height: 100%;
      margin: 0;
}

.task_top {
      width: 100%;
      margin-bottom: 25px;
}

.task_body>.box:not(:last-child) {
      margin-bottom: 20px;
}

.task_body>.box {
      width: 100%;
      background: #fff;
      border: 1px solid #C6D3DC;
      border-radius: 6px;
      padding: 20px;
}

.task_body>.box .ttp .dot_btn {
      width: 20px;
      height: 20px;
}

.task_body>.box .ttp .dot_btn i,
.task-lists button.dot_btn i {
      font-weight: bold;
}

.task_body>.box .ttp span.label_new {
      width: 30px;
      height: 25px;
      background: #EBF2FF;
      border: 1px solid #BBD4FF;
      border-radius: 6px;
      color: #0560FD;
      font-size: 10px;
      font-weight: 600;
}

.task_body .cnt p {
      margin: 10px 0 25px;
      font-size: 18px;
      line-height: 24px;
}

.task_body .user_date .rft span {
      font-size: 14px;
      line-height: 24px;
      color: #7A7A7A;
}

.task_body>.box.disable {
      background: #EAEAEA;
}

.task_body>.box .ttp span.label_prohibited {
      width: 25px;
      height: 25px;
      background: #CECECE;
      border: 1px solid #C7C7C7;
      border-radius: 6px;
}

.task_body>.box .ttp span.label_prohibited i {
      color: #595959;
      font-size: 20px;
}

.task_body>.box .user_date .lft ul {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
}

.task_body>.box .user_date .lft ul li {
      list-style: none;
}

.task_body>.box .user_date .lft ul li:not(:first-child) {
      position: absolute;
      top: 0;
      width: 100%;
}

.task_body>.box .user_date .lft ul li:nth-child(2) {
      left: 20px;
}

.task_body>.box .user_date .lft ul li:last-child {
      left: 40px;
}

.task_body>.box .ttp span.label_progress {
      width: 30px;
      height: 25px;
      background: #FFF4E3;
      border: 1px solid #FFDAA1;
      border-radius: 6px;
      color: #0560FD;
      font-size: 10px;
      font-weight: 600;
}

.task_body>.box .ttp span.label_check {
      width: 25px;
      height: 25px;
      background: #EBFFE3;
      border: 1px solid #BEECAB;
      border-radius: 6px;
      color: #0560FD;
      font-size: 10px;
      font-weight: 600;
}

.task_body>.box .ttp span.label_check i {
      color: #15C01B;
}

.task_body>.box .ttp .label_review {
      width: 25px;
      height: 25px;
      background: #FCFFB4;
      border: 1px solid #F4FE00;
      border-radius: 6px;
      color: #0560FD;
      font-size: 10px;
      font-weight: 600;
}

.task_body>.box .ttp {
      position: relative;
}

.task_drop {
      position: absolute;
      display: none;
      right: 0;
      top: 120%;
      animation: fade-in-bottom .6s cubic-bezier(.39, .575, .565, 1) both;
      transform: translateY(100%);
      background-color: #fff;
      transition: all .3s;
      padding: 10px;
      border-radius: 6px;
      box-shadow: 0 0 3px rgba(0, 0, 0, .05);
      transition: all .3s ease-out 0s;
      border: 1px solid #ECECEC;
      width: 125px;
}

.task_drop.open {
      display: inline-block;
}

.task_drop ul {
      margin: 0;
      padding: 0;
}

.task_drop ul li {
      list-style: none;
}

.task_drop ul li:not(:last-child) {
      margin-bottom: 5px;
}

.task_drop ul li a {
      font-size: 14px;
      color: #2C2C2C;
}

.task_drop ul li a i {
      font-size: 18px;
      line-height: 20px;
}

.task_drop ul li:first-child a i {
      color: #0560FD;
}

.task_drop ul li:last-child a i {
      color: #FF5F61;
}

.task_drop ul li a:hover {
      text-decoration: none;
}

.list-boxs {
      display: flex;
      align-items: initial;
      justify-content: space-between;
}

.list-boxs .list-box {
      width: 24.3%;
}

.dark_theme .task_mid_sec .form-group select {
      background: url(../images/tool_arrow_w.png) no-repeat right 5px center transparent;
}

.dark_theme .task_mid_sec .form-group>button.edit i,
.dark_theme .task-lists button.edit i,
.dark_theme .task-lists button.dot_btn i {
      color: #9f9f9f;
}

.dark_theme .task-lists button i,
.dark_theme .task_body .cnt p {
      color: #fff;
}

.dark_theme .task_body>.box {
      background: #1d232c;
      border-color: #3d4348;
}

.dark_theme .task_body>.box.disable {
      background: #3f3f3f;
}

.task_body {
      max-height: 890px;
      overflow: hidden;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
}

.task_body .example::-webkit-scrollbar {
      display: none;
}

.label-m0 label {
      margin-bottom: 0;
}

/*New Task page*/

.dark_theme fcsro-gallery-media .mat-tab-body.mat-tab-body-active .mat-tab-body-content>.row>.col-md-6 .tableplatformlist {
      border-color: #2d2d2d;
}

.hd .mat-form-field-appearance-outline .mat-form-field-outline {
      border-bottom-color: rgb(136 138 139);
}

.dark_theme fcsro-employees .alert.alert-success.text-white p,
.dark_theme .designation_frm form .form-group>span {
      color: #fff;
}

.General_settings .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .row>div>.tableplatformlist .mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent;
      border-bottom: 1px solid #949494;
}

.General_settings .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .row>div>.tableplatformlist .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      color: transparent;
}

.leave_policy .table thead th {
      background: transparent;
}

/* .leave_policy .comtithead h4 {
      width: calc(100% - 176px) !important;
} */

button#button-addon2 {
      margin-left: auto;
}

.leave_policy_frm .mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent;
      border-bottom: 1px solid #949494;
}

.leave_policy_frm .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
      color: transparent;
}

.leave_policy_frm .leave_h h4 {
      font-size: 22px;
}

.leave_policy_frm .leave_h {
      width: 100%;
      margin-bottom: 20px;
      position: relative;
}

.leave_h button {
      position: absolute;
      right: 0;
      top: 0;
      background: transparent;
      border: none;
      font-size: 20px;
}

.leave_policy_frm .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
      background-color: rgba(5, 96, 255, .54);
}

.leave_policy_frm .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
.leave_policy_frm .mat-slide-toggle.mat-checked .mat-ripple-element {
      background-color: #0560fd;
}

.sv_cnl_btns .btn {
      width: auto;
      min-width: 80px;
      line-height: 20px;
}

.dark_theme app-leave-policy-add .mat-slide-toggle-label span,
.dark_theme app-leave-policy-add label,
.dark_theme .clear_btn i {
      color: #9f9f9f;
}

fcsro-work-shifts .table thead th {
      width: 20%;
}

fcsro-work-shifts .table tbody td {
      white-space: break-spaces;
}

input#hourlyRate {
      -moz-appearance: textfield;
}

input#hourlyRate::-webkit-outer-spin-button,
input#hourlyRate::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
}

button.clear_btn {
      background: transparent;
      border: none;
}

fcsro-leave-history .t_hd {
      width: calc(100% - 320px);
}

fcsro-leave-history .t_hd .mat-form-field {
      top: -5px;
      width: calc(100% - 55px);
}

.General_settings .mat-tab-group .mat-tab-label.mat-ripple:not(:empty) {
      transform: unset;
}

fcsro-leave-history .t_hd .mat-form-field-appearance-outline .mat-form-field-outline {
      color: transparent;
      border-bottom: 1px solid #e1e1e1;
}

.activitybars .color_div.shift_break_time {
      background: #ffa500;
}

fcsro-top-activity-report .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
fcsro-timelog-report .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
fcsro-time-log-report .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
fcsro-project-cost-report .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.28125em) scale(0.75) translateZ(0.001px);
}

fcsro-leave-policy-configuration .table tbody tr td:nth-child(6) {
      display: flex;
      flex-wrap: wrap;
      max-width: 360px;
}

fcsro-project-cost-report .task_box.custom-view .col-md-4 {
      flex: 0 0 20%;
      max-width: 20%;
}

fcsro-project-cost-report .task_box.custom-view>div {
      width: 40%;
}

.cost_report>div:last-child {
      width: 350px;
}

.cost_report>div:first-child {
      width: 630px;
}

.cost_report>div:first-child div h6 {
      background: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      box-shadow: -3px 3px 3px 2px #c5c5c5;
      justify-content: space-between;
      border-right: 5px solid #0560fd;
      border-left: 5px solid #0560fd;
      font-size: 14px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      color: #0560fd;
}

.cost_report>div:first-child div h6 span {
      font-size: 12px;
      color: #000;
}

.dark_theme fcsro-project-cost-report .table thead th {
      background: #1d232c;
}

.dark_theme .cost_report>div:first-child div h6 {
      background: #1d232c;
      box-shadow: -3px 3px 3px 2px #0e0e0e;
      border-top-color: #2b2b2b;
      border-bottom-color: #2b2b2b;
}

.dark_theme .cost_report>div:first-child div h6 span,
.dark_theme .mat-sort-header-content {
      color: #fff;
}

.mat-sort-header-content {
      color: #000;
}

fcsro-login-report .dashpages>div {
      padding: 0;
}

fcsro-login-report .dashpages>div span {
      display: block;
}

fcsro-project-mappings .activitybars {
      padding: 0 0 0 15px;
}

fcsro-leave-history .table tbody tr td span {
      padding: 3px 8px;
      border: 1px solid transparent;
      border-radius: 3px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: max-content;
      line-height: normal;
      font-weight: 500;
      font-size: 12px;
}

fcsro-leave-history .table tbody tr td span.paid {
      color: #28b116;
      border-color: #28b116;
}

fcsro-leave-history .table tbody tr td span.loss-of-pay {
      color: #ffa500;
      border-color: #ffa500;
}

div#stateModal {
      background: rgba(0, 0, 0, .32);
}

div#stateModal .modal-dialog {
      width: 100%;
      max-width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
}

div#stateModal .modal-dialog .modal-content {
      max-width: 500px;
      padding: 24px;
      border-radius: 6px;
}

div#stateModal .modal-dialog .modal-content .modal-header {
      background: transparent;
      box-shadow: none;
      border: none;
      padding: 0;
}

div#stateModal .modal-dialog .modal-content .modal-body {
      padding: 0;
}

div#stateModal .modal-dialog .modal-content .modal-header h4 {
      color: #000;
      font-size: 24px;
}

div#stateModal .modal-dialog .modal-content .modal-body .payment-form-container {
      padding: 20px 0 0 !important;
}

div#stateModal .row.card_payment {
      flex-direction: column;
}

div#stateModal .row.card_payment .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
}

div#stateModal .payment-button {
      color: #4d4d4d !important;
      background: #ecf3fc !important;
      border-color: #cad5e4 !important;
      box-shadow: none !important;
}

div#stateModal .payment-button:hover {
      color: #fff !important;
      background: #0560fd !important;
      border-color: #0560fd !important;
}

.emp_frm button.btn.btn-gradiant span {
      line-height: normal;
}

div#stateModal .modal-dialog .modal-content .modal-body .payment-form-container .form-group {
      margin-bottom: 0;
}

div#stateModal .row.card_payment .col-md-6 .mat-form-field {
      width: 100%;
}

div#stateModal mat-select#mat-select-8:focus {
      box-shadow: none;
}

div#stateModal mat-select#mat-select-8 {
      border: transparent;
      padding: 0;
}

.screenimgs figure figcaption {
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      top: 0;
      left: 0;
}

.screenimgs figure figcaption span:first-child {
      width: 100%;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 2px;
      text-align: center;
}

.screenimgs figure figcaption span:last-child {
      position: absolute;
      left: 5px;
      bottom: 5px;
      font-weight: 400;
      text-align: left;
      width: 95%;
      font-size: 11px;
      line-height: 13px;
}

.live_stream ul li .s_img>figure {
      position: relative;
}

.dark_theme fcsro-timelog-report .viewdownbtns button {
      color: #fff !important;
      background: #0052df !important;
      border-color: #0052df !important;
}

.dark_theme fcsro-timelog-report .viewdownbtns button:hover,
.dark_theme .row.taskrowmob>.col-md-4>div>.row>.col-4>.btn:hover,
.dark_theme .row.taskrowmob>.col-md-4>div>.row>.col-6 .btn:hover {
      background: transparent !important;
}

.dark_theme .modal-body.taskmbcont .tableplatformlist {
      box-shadow: none;
}

.dark_theme .row.taskrowmob>.col-md-4>div>.row>.col-4>.btn,
.dark_theme .row.taskrowmob>.col-md-4>div>.row>.col-6 .btn {
      background: #0052df;
}

.dark_theme .row.taskrowmob>.col-md-4>div>.row>.col-4>.btn i,
.dark_theme .row.taskrowmob>.col-md-4>div>.row>.col-6 .btn i {
      color: #fff;
}

.dark_theme .navbar-list li .nav-tzcc2>span {
      background: transparent;
}

.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle__list-item span,
.dark_theme .stlmywrap div,
.dark_theme .quick_view table.mat-table tbody tr td {
      color: #9f9f9f !important;
}

.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle__list-item.active span,
.dark_theme .focus_loop .fl_cnt .head .date_selector .scroll-toggle__list-item.active .stlmywrap div {
      color: #fff;
}

.dark_theme .inner-circle {
      background: #1d232c !important;
}

.dark_theme .percentage {
      color: #fff !important;
}

.dark_theme .view_details>table tbody tr td span.box {
      box-shadow: none;
      border-color: #444447;
}

.dark_theme .emp_content .emp_detail {
      border-color: transparent;
}

.dark_theme .modal-body.taskmbcont .col-5.text-right button.btn.btn-outline-primary {
      color: #fff !important;
      background: #0560fd;
}

.dark_theme .modal-body.taskmbcont .col-5.text-right button.btn.btn-outline-primary:hover {
      background: transparent;
}

.dark_theme .fill_sec .cards .card p>span:last-child {
      color: #fff !important;
      background: #1d232c;
}

.dark_theme fcsro-profile .user_profile .custom-select {
      background: #0560fd !important;
      color: #fff !important;
}

.dark_theme fcsro-profile .user_profile .custom-select span,
.dark_theme fcsro-profile .user_profile .custom-select .mat-select-arrow {
      color: #fff;
}

@keyframes live-fadeOut {
      0% {
            opacity: 1;
            transform: scale(1);
      }

      100% {
            opacity: 0;
            transform: scale(0);
      }
}

@keyframes live-fadeIn {
      0% {
          box-shadow: 0px -5px 5px 0 #F4E869;
          opacity: 0;
          transform: scale(0);
      }

      25% {
          box-shadow: 5px 0 5px 0 #F4E869;
         
      }

      50% {
          box-shadow: 0px 5px 5px 0 #F4E869;
         
      }

      75% {
          box-shadow: -5px 0px 5px 0px #F4E869;
      }

      100% {
          box-shadow: 0px 0px 5px 5px #F4E869;
          opacity: 1;
          transform: scale(1);
      }
  }
.add-live {
      animation-name: live-fadeIn;
      animation-duration: 3s;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
}
.french .cost_report>div:first-child {
      width: 680px;
}
.tamil .cost_report>div:first-child {
      width: 730px;
}
.russian .cost_report>div:first-child {
      width: 825px;
}
.romanian .cost_report>div:first-child {
      width: 665px;
}
.spanish .cost_report>div:first-child {
      width: 660px;
}
.catalan .cost_report>div:first-child {
      width: 690px;
}
.General_settings .mat-tab-label .mat-tab-label-content {
      white-space: break-spaces;
}
.french fcsro-employees table thead tr th:first-child {
      width: 15%;
}
fcsro-project-report .mat-table tbody tr td {
      white-space: pre-line;
}
fcsro-project-report .mat-table tbody tr td:nth-child(4) {
      width: 20%;
}
.dark_theme .ngx-slider .ngx-slider-bubble {
      color: #9f9f9f !important;
}
.romanian .leave_box .leave_bdy .l_frm ._frm:first-child {
      width: 175px;
}
.romanian .leave_box .leave_bdy .l_frm ._frm:last-child label {
      min-width: 55px;
}
fcsro-employees .mat-slide-toggle {
      margin-left: 0 !important;
}
fcsro-client-employee-work-hours .comtithead h4 {
      width: calc(100% - 233px);
}
fcsro-client-employee-work-hours .comtithead h4 + div {
      margin-bottom: -15px;
      display: flex;
      align-items: center;
}
fcsro-client-employee-work-hours .comtithead h4 + div .mat-form-field-appearance-legacy .mat-form-field-infix {
      min-height: 46px;
}
.cus-date .mat-form-field {
      width: 49%;
}

/*Responsive*/
@media screen and (max-width: 1299px) {
      .sidebar-main .version_div a {
            width: auto;
      }

      .iq-sidebar-logo a img {
            transform: unset;
      }

      body.dark_theme.sidebar-main .iq-sidebar,
      body.dark_theme .iq-sidebar .orgCodeMobileView {
            background: #131517;
      }

      body.dark_theme.sidebar-main .iq-sidebar .cover-bar {
            background: #131517;
      }

      #intergramRoot>div,
      body.sidebar-main #intergramRoot>div {
            left: 5px;
            bottom: 60px !important;
      }

      p.l_chat {
            padding: 0;
      }

      .iq-sidebar-logo a img {
            top: 0;
      }
}

@media screen and (max-width: 1299px) and (min-width:1254px) {
      .screen_count_div {
            float: right;
      }
}

@media(max-width: 1920px) {

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 210.09px;
      }

      .lt_body .box .t_body img {
            width: 100%;
            height: 210px;
            object-fit: cover;
            object-position: center;
      }

      .lt_body .box {
            max-height: 273px;
      }

      body.german .dlt_box .box .blk>.etttile {
            font-size: 13px;
      }

      body.french .activitybars>div {
            font-size: 12px;
      }

      body.russian .at_head {
            width: 55%;
      }
}

@media(max-width: 1896px) {

      body.french .dashboxtop .row .col-md-3:nth-child(2),
      body.tamil .dashboxtop .row .col-md-3:nth-child(2),
      body.russian .dashboxtop .row .col-md-3:nth-child(2),
      body.spanish .dashboxtop .row .col-md-3:nth-child(2),
      body.german .dashboxtop .row .col-md-3:nth-child(2),
      body.portuguese .dashboxtop .row .col-md-3:nth-child(2) {
            max-width: 29%;
            flex: 0 0 29%;
      }

      body.french .dashboxtop .row .col-md-3:nth-child(3),
      body.tamil .dashboxtop .row .col-md-3:nth-child(3),
      body.russian .dashboxtop .row .col-md-3:nth-child(3),
      body.spanish .dashboxtop .row .col-md-3:nth-child(3),
      body.german .dashboxtop .row .col-md-3:nth-child(3),
      body.portuguese .dashboxtop .row .col-md-3:nth-child(3) {
            max-width: 21%;
            flex: 0 0 21%;
      }

      body.french .at_head,
      body.german .at_head {
            width: 55%;
      }

      body.spanish .at_head {
            width: 50%;
      }
}


@media(min-width: 1800px) {
      .screen_count_div {
            float: right;
      }

      .t_body>.flex>div:first-child {
            width: 18%;
      }

      .t_body>.flex>div:nth-child(2) {
            width: 10%;
      }

      .t_body>.flex>div:last-child {
            width: 70%;
      }

      .bdy {
            padding: 0;
      }

      span.dashboxtxt p {
            display: flex;
            align-items: center;
      }

      span.dashboxtxt p i {
            margin: 0 0 0 5px;
      }

      .bdy .blk span {
            font-size: 18px;
      }

      .lt_body .box .t_body img {
            height: 180px;
            object-fit: cover;
      }

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 180.09px;
      }

      .lt_body .box {
            max-height: 243px;
      }

      .dlt_box .box:last-child>.blk {
            flex-direction: column;
            justify-content: center;
            width: 100%;
      }

      .dlt_box .box .blk>.etttile {
            font-size: 13px;
      }
}

@media (max-width: 1800px) {
      .pro_check>.col-md-3 {
            flex: 0 0 30%;
            max-width: 30%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(4)>.blk>div .mat-form-field {
            width: 100%;
      }

      form.selecctForm {
            width: 100%;
      }

      .galy_scrns>.col-md-3 {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      fcsro-task-enhancement .table {
            width: 1800px;
      }

      .tamil .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      .tamil .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4 {
            flex: 0 0 66.6%;
            max-width: 66.6%;
      }

      body.russian .at_head {
            width: 60%;
      }

      .task-lists .list-boxs .list-box {
            min-width: 365px;
            max-width: 365px;
      }

      .task-lists .list-boxs .list-box:not(:last-child) {
            margin-right: 15px;
      }

      .task-lists .list-boxs {
            flex-wrap: nowrap;
            overflow-x: auto;
            flex-direction: row;
            width: 100%;
            margin-right: 0;
      }
}

@media screen and (max-width: 1695px) {
      .dashboxtop>div>.col-md-4 {
            flex: 0 0 29.333333%;
            max-width: 29.333333%;
      }

      .dashboxtop>div>.col-md-2 {
            flex: 0 0 20.666667%;
            max-width: 20.666667%;
      }
}

@media(max-width:1680px) {
      .login_div h6 {
            font-size: 18px;
      }

      .darkblue .sign-in-detail {
            padding: 50px;
      }

      .sign-in-content h3 {
            font-size: 30px !important;
      }

      .sign-in-content p {
            font-size: 15px !important;
            line-height: 25px !important;
      }

      .screen_count_div .monitor_div {
            margin-left: 0px !important;
      }

      .monitor_div {
            min-width: 200px;
            margin-bottom: 30px !important;
      }

      .gallery_div .classificationVisualisation svg {
            position: relative;
            left: -7px;
      }

      .live_stream ul li {
            width: 20%;
      }

      .dashboxtop .col-md-3:nth-child(3),
      .dashboxtop .col-md-3:nth-child(2) {
            flex: 0 0 23%;
            max-width: 23%;
      }

      .dashboxtop .col-md-3:nth-child(1),
      .dashboxtop .col-md-3:last-child {
            flex: 0 0 27%;
            max-width: 27%;
      }

      .bdy {
            padding: 0;
      }

      .at_head {
            width: 55%;
      }

      body.french .dashboxtop .row .col-md-3:nth-child(1),
      body.french .dashboxtop .row .col-md-3:last-child,
      body.russian .dashboxtop .row .col-md-3:nth-child(1),
      body.russian .dashboxtop .row .col-md-3:last-child,
      body.turkey .dashboxtop .row .col-md-3:nth-child(1),
      body.turkey .dashboxtop .row .col-md-3:last-child {
            max-width: 25%;
            flex: 0 0 25%;
      }

      body.french .dashbox .dash_icon {
            display: block;
      }

      body.french .dashboxtxt .dash_icon {
            margin-right: 10px;
            min-width: 30px;
      }

      body.spanish .dashbox .dashcount,
      body.german .dashbox .dashcount,
      body.portuguese .dashbox .dashcount,
      body.indonesian .dashbox .dashcount,
      body.turkey .dashbox .dashcount {
            padding-left: 0;
      }

      .employee_page .table-responsive table {
            width: 1680px;
      }

      .task_enhancement .tableplatformlist>.row>div:last-child {
            display: flex;
      }

      .task_enhancement .tableplatformlist>.row>div:last-child .mat-form-field {
            width: 48%;
      }

      .dlt_box .box:last-child {
            width: 13%;
      }

      .dlt_box .box:first-child {
            width: 48.5%;
      }

      .sub_blk .row>.blk .etttile {
            font-size: 13px;
      }

      body.tamil .sub_blk .row>.blk .etttile {
            font-size: 11px;
      }

      .tamil .at_head .card p {
            font-size: 11px;
      }

      .gallery_div .tabletitle p {
            font-size: 10px;
      }

      .timelogcheck>.col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .manage_btn>button {
            margin-left: 0 !important;
      }

      .emp_date {
            flex: 0 0 35%;
            max-width: 35%;
      }

      .french fcsro-over-time-report .comtithead>.row>div.col-md-4 {
            flex: 0 0 40%;
            max-width: 40%;
      }

      .french fcsro-over-time-report .comtithead>.row>div.col-md-8 {
            flex: 0 0 60%;
            max-width: 60%;
      }

      .french fcsro-over-time-report .comtithead h4 {
            font-size: 18px;
      }

      body.russian .at_head {
            width: 65%;
      }
}

@media(max-width:1600px) {
      .gallery_div .classificationVisualisation svg {
            margin-left: -8px;
      }

      .etttile {
            font-size: 11px;
      }

      .gallery_div .tabletitle p,
      .thrblksec h5 p.small {
            font-size: 10px;
      }

      .exheg {
            min-height: 137px !important;
      }

      .gallery_div .todaymonth {
            min-height: 95px;
      }

      .live_stream ul li {
            width: 20%;
      }

      .hd>span {
            margin-left: 15px;
      }

      .add_emp .tableplatformlist form button {
            right: 10px;
      }

      .task_time_report .actvbox {
            padding: 50px 10px !important;
      }

      .task_time_report .actvbox>div h6 span,
      span.dashboxtxt p,
      p.dashboxtit,
      .mtg h4,
      .hd h4 {
            font-size: 14px;
      }

      .fc-cs ul li span {
            font-size: 18px;
      }

      .fc-cs ul li>p {
            font-size: 12px;
      }

      .bdy .blk span {
            font-size: 17px;
      }

      .dashboxtop .col-md-3:nth-child(3) {
            flex: 0 0 20%;
            max-width: 20%;
      }

      .dashboxtop .col-md-3:nth-child(1) {
            flex: 0 0 30%;
            max-width: 30%;
      }

      .dashboxtxt .dash_icon {
            margin-right: 15px;
      }

      .at_head {
            width: 71%;
      }

      .sub_blk .row>.blk .etttile {
            font-size: 11px;
      }

      fcsro-leave-permission-approval table.mat-table {
            width: 1800px;
      }

      .quick_view table.mat-table {
            min-width: 1500px;
      }

      .pro_check>.col-md-3 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      fcsro-leave-permission-approval .lfts {
            align-items: flex-end;
            width: 100%;
            flex-wrap: wrap;
      }

      fcsro-leave-permission-approval .lfts .col-md-1 {
            flex: 0 0 13%;
            max-width: 13%;
      }

      .french .select_team {
            width: 35%;
      }

      .fill_section>div {
            flex: 0 0 25%;
            max-width: 25%;
      }

      .tamil .comtithead h4 {
            font-size: 18px;
      }

      .tamil .select_team {
            width: 50%;
      }

      .russian fcsro-project-mappings .comtithead>.row>.col-md-6:first-child {
            flex: 0 0 30%;
            max-width: 30%;
      }

      .russian fcsro-project-mappings .comtithead>.row>.col-md-6:last-child {
            flex: 0 0 70%;
            max-width: 70%;
      }

      body.spanish .at_head {
            width: 70%;
      }

      body.catalan .at_head,
      body.portuguese .at_head {
            width: 70%;
      }

      body.catalan .at_head .card p {
            line-height: 16px;
      }

      body.german .at_head {
            width: 65%;
      }
}

@media(max-width:1536px) {
      .sign-in-content {
            padding-top: 50px;
      }

      .darkblue .sign-in-detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 15px;
            width: 100%;
      }

      .sign-in-content h3 {
            font-size: 40px !important;
      }

      fcsro-horizontal-calendar .timeline_div .hours.days {
            width: 33px;
            font-size: 10px;
      }

      .gallery_div .classificationVisualisation svg {
            left: -18px;
            margin-left: 0px;
      }

      .gallery_div .thrblksec .col-3 {
            padding: 0px 7px;
      }

      .live_stream ul li {
            width: 20%;
      }

      .hd>span {
            padding: 3px 13px;
      }

      body.french .dashboxtop .row .col-md-3:nth-child(1),
      body.tamil .dashboxtop .row .col-md-3:nth-child(1),
      body.russian .dashboxtop .row .col-md-3:nth-child(1),
      body.spanish .dashboxtop .row .col-md-3:nth-child(1),
      body.german .dashboxtop .row .col-md-3:nth-child(1),
      body.portuguese .dashboxtop .row .col-md-3:nth-child(1),
      body.indonesian .dashboxtop .row .col-md-3:nth-child(1),
      body.turkey .dashboxtop .row .col-md-3:nth-child(1) {
            max-width: 100%;
            flex: 0 0 100%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 60px;
            border-bottom: 1px solid #e1e1e1;
            padding-bottom: 20px;
      }

      body.french .dashboxtop .row .col-md-3:nth-child(2),
      body.french .dashboxtop .row .col-md-3:nth-child(3),
      body.french .dashboxtop .row .col-md-3:last-child,
      body.tamil .dashboxtop .row .col-md-3:nth-child(2),
      body.tamil .dashboxtop .row .col-md-3:nth-child(3),
      body.tamil .dashboxtop .row .col-md-3:last-child,
      body.russian .dashboxtop .row .col-md-3:nth-child(2),
      body.russian .dashboxtop .row .col-md-3:nth-child(3),
      body.russian .dashboxtop .row .col-md-3:last-child,
      body.spanish .dashboxtop .row .col-md-3:nth-child(2),
      body.spanish .dashboxtop .row .col-md-3:nth-child(3),
      body.spanish .dashboxtop .row .col-md-3:last-child,
      body.german .dashboxtop .row .col-md-3:nth-child(2),
      body.german .dashboxtop .row .col-md-3:nth-child(3),
      body.german .dashboxtop .row .col-md-3:last-child,
      body.portuguese .dashboxtop .row .col-md-3:nth-child(2),
      body.portuguese .dashboxtop .row .col-md-3:nth-child(3),
      body.portuguese .dashboxtop .row .col-md-3:last-child,
      body.indonesian .dashboxtop .row .col-md-3:nth-child(2),
      body.indonesian .dashboxtop .row .col-md-3:nth-child(3),
      body.indonesian .dashboxtop .row .col-md-3:last-child,
      body.turkey .dashboxtop .row .col-md-3:nth-child(2),
      body.turkey .dashboxtop .row .col-md-3:nth-child(3),
      body.turkey .dashboxtop .row .col-md-3:last-child {
            max-width: 33%;
            flex: 0 0 33%;
            padding-top: 25px;
      }

      .time_log_date>.text-muted {
            width: 100%;
            margin-top: 20px;
      }

      .row.lt_body .col-md-3 {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      .lt_body .box .t_body img {
            height: 210px;
      }

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 210.09px;
      }

      .lt_body .box {
            max-height: 273px;
      }

      .gallery_div .tabletitle.thrblksec.act_row>.row .blk {
            padding: 0 10px;
      }

      .thrblksec h5 {
            font-size: 17px;
      }

      .view_scrn h4 {
            text-align: left;
      }

      .dashbox .dashcount {
            padding-left: 40px;
      }

      .col-md-6._idle_tracking+div>.mat-expansion-panel .mat-expansion-panel-body>div>.row {
            flex-direction: column;
      }

      .col-md-6._idle_tracking+div>.mat-expansion-panel .mat-expansion-panel-body>div>.row .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      body.french .dashboxtop .row .col-md-3:nth-child(1) .dashbox::after {
            display: none;
      }

      body.french .dashboxtop .row>div p.dashboxtit.cmp_avg,
      body.portuguese .dashboxtop .row>div p.dashboxtit.cmp_avg,
      body.indonesian .dashboxtop .row>div p.dashboxtit.cmp_avg,
      body.turkey .dashboxtop .row>div p.dashboxtit.cmp_avg {
            top: -40px;
      }

      body.french .emp_content .emp_chart>h4,
      body.french .view_scrn h4 {
            font-size: 18px;
      }

      body.french .activitybars>div {
            font-size: 11px;
            line-height: 12px;
      }

      body.french .mat-tab-label .mat-tab-label-content {
            font-size: 13px;
      }

      .tamil .contentsec h4,
      .tamil .contentsec h3 {
            font-size: 17px;
      }

      body.catalan .dashbox .dashcount {
            padding-left: 0;
      }

      .dashboxtxt .dash_icon,
      .dashbox .dash_icon,
      .hd span.dash_icon {
            min-width: 30px;
            max-width: 30px;
            max-height: 30px;
      }

      .employee_page .paginatormob>.row>.col-md-3,
      .employee_page .paginatormob>.row>.col-md-2 {
            flex: 0 0 30%;
            max-width: 30%;
      }

      .french .actvbox h6 {
            min-height: 36px;
      }

      .tamil fcsro-project-mappings .comtithead .row .col-md-6:first-child {
            flex: 0 0 40%;
            max-width: 40%;
      }

      .tamil fcsro-project-mappings .comtithead .row .col-md-6:last-child {
            flex: 0 0 60%;
            max-width: 60%;
      }

      .russian .lfts_head .px-3 {
            width: calc(100% - 330px) !important;
      }

      .russian fcsro-over-time-report .comtithead h4,
      .russian fcsro-attendance-report .comtithead h4 {
            font-size: 18px;
      }

      body.russian .at_head {
            width: 71%;
      }

      .russian .at_details .fill_sec {
            width: 90%;
      }

      .catalan fcsro-over-time-report .comtithead h4 {
            font-size: 18px;
      }

      .catalan .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      .catalan .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4 {
            flex: 0 0 66.6%;
            max-width: 66.6%;
      }
}

@media(max-width:1440px) {
      .dashbox p.text_transform_none {
            font-size: 11px;
      }

      .tamil .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .russian .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .gallery_div .todaymonth h2 {
            font-size: 18px;
      }

      .gallery_div .todaymonth h1 {
            font-size: 22px;
      }

      .gallery_div .tabletitle.thrblksec {
            padding: 10px 10px !important;
      }

      .gallery_div .classificationVisualisation svg {
            margin-left: -26px;
            transform: scale(0.9);
            left: 0px;
      }

      .demo-stp .col-md-3 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .emp_content .emp_detail {
            width: 50%;
      }

      .live_stream ul li {
            width: 20%;
      }

      .t_body>.flex>div:first-child {
            width: 27%;
      }

      .t_body>.flex>div:nth-child(2) {
            width: 17%;
      }

      .t_body>.flex>div:last-child {
            width: 56%;
      }

      .bdy {
            padding: 0;
      }

      .hd h4,
      .mtg h4 {
            font-size: 14px;
      }

      .fc-cs ul li,
      .bdy .blk>p {
            font-size: 12px;
      }

      .dashbox .dashcount,
      .mtg p span {
            font-size: 14px;
      }

      .dashbox .dash_icon {
            width: 30px;
            height: 30px;
            margin-right: 9px;
      }

      .dashboxtxt .dash_icon {
            margin-right: 9px;
      }

      .fcsroclassdis {
            width: 50%;
      }

      .task_time_report .actvbox>div h6 span,
      span.dashboxtxt p,
      p.dashboxtit,
      .mtg h4,
      .hd h4 {
            font-size: 13px;
      }

      body.tamil .dashboxtop .row .col-md-3:nth-child(3) {
            max-width: 30%;
            flex: 0 0 30%;
      }

      body.tamil .dashboxtop .row .col-md-3:last-child {
            max-width: 36%;
            flex: 0 0 36%;
      }

      .lfts_head .px-3>div>.col-md-5 {
            flex: 0 0 50.666667%;
            max-width: 50.666667%;
      }

      .dashboxtop .row>div p.dashboxtit.cmp_avg {
            top: -65px;
      }

      .gallery_div.empgallery>.gegsec>.row>.col-md-2 {
            flex: 0 0 24%;
            max-width: 24%;
      }

      .gallery_div.empgallery>.gegsec>.row>.col-md-10 {
            flex: 0 0 76%;
            max-width: 76%;
      }

      .gallery_div .tabletitle .col-5 {
            flex: 0 0 100%;
            max-width: max-content;
      }

      .gallery_div .tabletitle.thrblksec.act_row>.row .blk h6>div {
            width: 15px;
            height: 15px;
      }

      .sub_blk .row>.blk {
            padding: 0 10px;
      }

      .gallery_div.empgallery>.gegsec>.row>.col-md-2,
      .gallery_div.empgallery>.gegsec>.row>.col-md-10 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .tamil .contentsec h4,
      .tamil .contentsec h3 {
            font-size: 13px;
      }

      .tamil .btn {
            word-break: auto-phrase;
      }

      body.russian .dashboxtop .row>div p.dashboxtit.cmp_avg,
      body.spanish .dashboxtop .row>div p.dashboxtit.cmp_avg,
      .german .dashboxtop .row>div p.dashboxtit.cmp_avg {
            top: -40px;
      }

      .dashboxtxt .dash_icon {
            min-width: 30px;
      }

      body.russian .at_head .card p {
            font-size: 11px;
      }

      body.spanish .at_head .card p {
            font-size: 10px;
      }

      .lt_body .box .t_head p>bdi>span {
            max-width: 100px;
      }

      .row.p-3.pro_check>div {
            max-width: 50%;
            flex: 0 0 50%;
      }

      .General_settings .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .row {
            flex-direction: column;
      }

      .General_settings .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .row>div {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
      }

      .General_settings .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content .row>div:not(:last-child) {
            margin-bottom: 15px;
      }

      .General_settings .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content ._id_track .row {
            flex-direction: row;
      }

      .General_settings .mat-tab-group .mat-tab-body-wrapper .mat-tab-body-content ._id_track .row>div {
            width: 33.3%;
            max-width: 33.3%;
            flex: 0 0 33.3%;
      }

      .fill_section .col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .french .select_team {
            width: 40%;
      }

      .fillter_ses>.col-md-3 {
            flex: 0 0 40%;
            max-width: 40%;
      }

      .fillter_ses>.col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .ser_sec .col-md-3 {
            flex: 0 0 35%;
            max-width: 35%;
      }

      .french .dt_list ul li {
            width: 17%;
      }

      .french .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      .french .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4 {
            flex: 0 0 66.7%;
            max-width: 66.7%;
      }

      .french .task_enhancement table {
            width: 1680px;
      }

      .tamil .select_team {
            width: 58%;
      }

      .tamil .t_hd .date>p span {
            font-size: 12px;
      }

      .dt_list ul li {
            width: 22%;
      }

      body.russian fcsro-attendance-report .table {
            width: 1255px;
      }

      .client_chart .row>.col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .client_chart .row>.col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .spanish .task_enhancement .tableplatformlist>.row.extra>div {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      .spanish .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4 {
            flex: 0 0 66.6%;
            max-width: 66.6%;
      }

      fcsro-leave-history .t_hd .row .col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }
}

@media(max-width:1366px) {
      .darkblue {
            padding: 75px 0px 75px 75px;
      }

      .hindi .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .gallery_div fcsro-horizontal-calendar .mat-icon-button {
            width: 20px !important;
      }

      .french .dashbox p.text_transform_none,
      .russian .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .spanish .dashbox p.text_transform_none {
            font-size: 10px;
      }

      .german .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .monitor_div {
            min-width: 165px;
      }

      .date_only {
            max-width: 250px;
      }

      .gallery_div .todaymonth h2 {
            font-size: 15px;
      }

      .gallery_div .todaymonth h1 {
            font-size: 20px;
      }

      .curtimezone {
            font-size: 11px;
      }

      .exheg {
            min-height: 132px;
      }

      .gallery_div .classificationVisualisation svg {
            margin-left: -32px;
            transform: scale(0.8);
      }

      .gallery_div .activitybars {
            font-size: 11px;
      }

      table .mat-column-resetPassword .btn {
            font-size: 10px;
      }

      table .mat-column-resetPassword .btn {
            display: block;
            margin: 5px auto !important;
      }

      .employee_page table.mat-table tbody tr td button:last-child {
            width: 155.52px;
      }

      .russian table .mat-column-resetPassword .btn {
            min-width: 171px;
      }

      .focus_loop .fl_cnt .head .date_selector {
            width: 75%;
      }

      .live_stream ul li {
            width: 23.5%;
            min-width: 255px;
            max-width: 255px;
      }

      .brdrign {
            padding: 0 10px;
      }

      .dashboxtop .col-md-3:last-child,
      .dashboxtop .col-md-3:nth-child(2) {
            flex: 0 0 25%;
            max-width: 25%;
      }

      .at_head>div button {
            margin-left: 0 !important;
      }

      table.mat-table {
            min-width: 1600px;
      }

      .employee_page .table-responsive table thead tr th:last-child {
            padding-left: 112px;
      }

      body.chinese table.mat-table tbody tr td button,
      body.arabic table.mat-table tbody tr td button,
      body.portuguese table.mat-table tbody tr td button {
            width: 155.52px;
      }

      body.german table.mat-table tbody tr td button {
            max-width: 215px;
      }

      .form_frm {
            width: 100%;
      }

      .nav-tzcc .alert .iq-alert-text>button {
            width: 80px;
            font-size: 10px;
      }

      .nav-tzcc .alert .iq-alert-text>span {
            font-size: 10px;
      }

      .nav-tzcc alert>div {
            margin: 0 !important;
      }

      .iq-top-navbar {
            padding-right: 0;
      }

      .live_pop {
            width: 100%;
      }

      .nav-tzcc {
            min-width: 78px;
      }

      .employee_page table.mat-table tbody tr td button:first-child {
            width: 155.52px;
      }

      fcsro-leave-permission-approval .lfts .col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      fcsro-leave-permission-approval .lfts .col-md-2 {
            flex: 0 0 28%;
            max-width: 28%;
      }

      .timelogcheck>.col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .email_box {
            flex: 0 0 75%;
            max-width: 75%;
      }

      .t_hd {
            width: calc(100% - 320px);
      }

      .t_hd .select_team {
            width: 40%;
      }

      .task_box.extra-project.extra-task.custom-view>div,
      .task_box.extra-project.extra-task.custom-view>div:last-child>.date_selector>.mat-form-field {
            width: 49%;
      }

      .task_box.extra-project.extra-task.custom-view>div:last-child {
            width: 100%;
      }

      .task_box.extra-project.extra-task.custom-view>div .mat-form-field-appearance-outline .mat-form-field-flex {
            padding: 0;
      }

      .task_time_report .actvbox.select-project.select-task>div {
            flex: 0 0 30%;
            max-width: 30%;
            margin-bottom: 20px;
      }

      .task_time_report .actvbox.select-project.select-task {
            padding-bottom: 30px !important;
      }

      .french .live_tracking .lt_head {
            width: 200px;
      }

      .french .t_hd {
            width: calc(100% - 200px);
      }

      .french .t_hd .select_team {
            width: 50%;
      }

      .activity_pattern .t_hd>.row .col-md-5>.mat-form-field {
            width: 100%;
      }

      .activity_pattern .t_hd>.row .col-md-7>.mat-form-field {
            width: 48%;
      }

      .tamil .select_team {
            width: 43%;
      }

      .tamil .sel_period>label {
            font-size: 11px;
      }

      .russian fcsro-attendance-report .comtithead h4 {
            font-size: 16px;
      }

      fcsro-teams table.mat-table {
            width: 100%;
            min-width: 100%;
      }

      .spanish fcsro-project-mappings .comtithead>.row>div:first-child,
      .portuguese fcsro-project-mappings .comtithead>.row>div:first-child {
            flex: 0 0 40%;
            max-width: 40%;
      }

      .spanish fcsro-project-mappings .comtithead>.row>div:last-child,
      .portuguese fcsro-project-mappings .comtithead>.row>div:last-child {
            flex: 0 0 60%;
            max-width: 60%;
      }

      .portuguese .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      .portuguese .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4 {
            flex: 0 0 66.6%;
            max-width: 66.6%;
      }

      fcsro-leave-history .t_hd .col-md-5 {
            flex: 0 0 100%;
            max-width: 100%;
      }
}

@media(max-width: 1299px) {
      .live_pop {
            width: 100%;
      }

      fcsro-leave-permission-approval .lfts {
            flex-wrap: wrap;
      }

      fcsro-leave-permission-approval .lfts .col-md-4 {
            flex: 0 0 29%;
            max-width: 29%;
      }

      fcsro-leave-permission-approval .lfts .col-md-6 {
            flex: 0 0 58%;
            max-width: 58%;
      }

      fcsro-leave-permission-approval .lfts .col-md-3 {
            flex: 0 0 35%;
            max-width: 35%;
      }
}

@media(max-width:1280px) {

      #content-page .container-fluid .container-fluid,
      #content-page .container-fluid .container {
            padding: 0px;
      }

      .sidebar-main .version_div {
            width: 280px;
      }

      .tamil .dashbox p.text_transform_none {
            font-size: 14px;
      }

      .german .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .gallery_div .classificationVisualisation svg {
            transform: none;
            margin-left: -8px;
      }

      .arrowslr,
      .gallery_div .tabletitle.thrblksec p {
            width: auto;
      }

      .gallery_div .thrblksec .col-3 {
            padding: 0px 15px;
      }

      .demo-stp .col-md-3 {
            flex: 0 0 25%;
            max-width: 25%;
      }

      .iq-top-navbar {
            padding: 0px;
      }

      .emp_content .emp_detail {
            width: 45%;
      }

      .content-page>.container-fluid {
            padding: 0 15px;
      }
}

@media(max-width:1199px) {
      .sign-in-content {
            padding-top: 0 !important;
      }

      .container,
      .container-lg,
      .container-md,
      .container-sm {
            max-width: 100%;
      }

      .french .tableplatformlist .col-11 p {
            font-size: 12px;
      }

      .monitor_div {
            padding-top: 15px;
            margin-bottom: 15px !important;
            min-height: auto;
      }

      .tamil .dashbox p.text_transform_none {
            font-size: 11px;
      }

      .gallery_div .classificationVisualisation svg {
            margin-left: -13px;
            transform: scale(0.85);
      }

      .demo-stp .col-md-3 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      /*new changes*/
      .sign-in-page .boxshad {
            padding-right: 15px;
            padding-left: 15px;
      }

      .focus_loop .fl_cnt {
            padding: 20px 0 0;
      }

      .sidebar-main .ng-scrollbar-wrapper[deactivated=false][dir=ltr]>scrollbar-y.scrollbar-control {
            right: -10px !important;
      }

      .mtg p {
            font-size: 13px;
      }

      .gallery_div .thrblksec h5 {
            font-size: 16px;
      }

      /*new changes*/
      .pop_content {
            width: 85%;
            margin: auto;
      }

      .gallery_div .tabletitle.thrblksec {
            padding: 10px 0 !important;
      }

      .sub_blk .row>.blk {
            padding: 0 10px;
      }

      .tamil .at_report .tableplatformlist>.row .at_head {
            width: 65%;
      }

      .quick_view .table-responsive .table thead tr th {
            min-width: 125px;
      }

      body.german .employee_page .paginatormob>.row .col-md-2 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      body.tamil .activitybars div {
            font-size: 8px;
            line-height: 12px;
      }

      body.tamil .sub_blk .row>.blk .etttile {
            font-size: 10px;
      }

      fcsro-leave-permission-approval .lfts .col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .timelogcheck>.col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .emp_hrs_act h4 {
            font-size: 14px;
      }

      .catalan .activitybars {
            padding: 0;
      }

      .catalan fcsro-leave-permission-approval .comtithead h4 {
            font-size: 18px;
      }
}

@media(max-width:1024px) {
      .dashbox::after {
            right: 5px;
      }

      fcsro-horizontal-calendar .d-md-inline-block.mb-2 {
            display: block !important;
      }

      .timeline_div .hours_div .hours {
            padding: 3px 0px !important;
      }

      .french .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .chinese .dashbox p.text_transform_none {
            font-size: 10px;
      }

      .sign-in-content h3 {
            font-size: 25px !important;
      }

      .sign-in-content .owl-carousel .owl-item img {
            max-width: 150px !important;
            margin-bottom: 15px !important;
      }

      .sign-in-content p {
            font-size: 12px !important;
            line-height: 20px !important;
      }

      .login_div h6 {
            font-size: 14px;
      }

      .sign-in-from h3 {
            font-size: 18px;
      }

      .loginbox img {
            max-width: 65px;
      }

      .darkblue {
            padding: 50px 0px 50px 50px;
      }

      .gallery_div .classificationVisualisation svg {
            margin-left: -30px;
      }

      .gallery_div .classificationVisualisation svg {
            margin-left: 0px;
            transform: none;
      }

      .gallery_div .tabletitle p,
      .thrblksec h5 p.small {
            width: auto;
      }

      .gallery_div .thrblksec .row .col-3:nth-child(2) {
            flex: 0 0 25%;
            max-width: 25%;
      }

      .gallery_div .thrblksec .col-3:first-child {
            flex: 0 0 25%;
            max-width: 25%
      }

      .activitybars {
            padding: 0px 15px !important;
      }

      .tbcon .dots {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: block;
      }

      .rept_det {
            flex-direction: column;
      }

      .rept_det .col-3 {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 20px;
      }

      .rept_det .col-9 {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
      }

      .emp_content .emp_detail {
            width: 40%;
      }

      .live_stream ul li {
            flex: 0 0 auto;
      }

      .dt_list ul li {
            width: 17%;
      }

      .dash_board .dashboxtop>.row>div {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .dash_board .dashboxtop>.row>div:not(:nth-child(3), :last-child) {
            margin-bottom: 30px;
      }

      .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec .etttile .box-indicator {
            right: -12px;
      }

      /*NEW CHNAGES*/
      .dash_board .dashboxtop>.row>div:first-child {
            flex: 0 0 100%;
            max-width: 100%;
            border-bottom: 1px solid #e1e1e1;
            padding-bottom: 25px;
      }

      .dash_board .dashboxtop>.row>div {
            flex: 0 0 33%;
            max-width: 33%;
      }

      .fc-csat {
            max-width: 380px;
            margin: auto;
      }

      .hd>mat-form-field {
            top: -320px;
      }

      .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec .etttile {
            justify-content: flex-start;
      }

      .at_report .tableplatformlist>.row .col-md-3 {
            flex: 0 0 30%;
            max-width: 30%;
      }

      .at_report .tableplatformlist>.row .col-md-3 .mat-form-field {
            width: 100%;
      }

      .at_report .tableplatformlist>.row .at_head {
            width: 80%;
      }

      .at_report .tableplatformlist>.row .at_head+div>button {
            margin-left: 0 !important;
      }

      .add_emp .tableplatformlist form button+div+div {
            width: 96% !important;
            padding-left: 0px !important;
      }

      ._idle_tracking .align-items-center {
            width: 100%;
            justify-content: space-between;
      }

      ._idle_tracking .align-items-center>h6 {
            max-width: 65%;
            flex: 0 0 65%;
      }

      body.french .brdrign {
            padding: 0 8px;
      }

      body.tamil .total_val {
            padding-top: 20px;
      }

      .side_menu {
            padding-top: 25px;
      }

      body.french .at_report .tableplatformlist>.row .at_head {
            width: 60%;
      }

      body.tamil .bdy .blk>p {
            font-size: 10px;
      }

      body.tamil .t_body>.flex>div:first-child {
            width: 31%;
      }

      body.tamil .t_body>.flex>div:last-child {
            width: 57%;
      }

      /*EMP DETAIL*/

      body.tamil .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec:first-child .col-3,
      body.russian .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec:first-child .col-3 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      body.tamil .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec:first-child .col-3:first-child,
      body.tamil .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec:first-child .col-3:nth-child(2),
      body.russian .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec:first-child .col-3:first-child,
      body.russian .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec:first-child .col-3:nth-child(2) {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #e1e1e1;
      }

      /*EMP DETAIL*/

      body.tamil .rept_det .col-9 .tabs_content .dtd>span,
      body.tamil .rept_det .col-9 .tabs_content .view_details>table thead th {
            font-size: 12px;
      }

      body.tamil .rept_det .col-9 .tabs_content .view_details>table thead th button.sort {
            margin: -3px 0 0 7px;
      }

      body.tamil .at_head .card p {
            font-size: 11px;
      }

      body.tamil .tasks .tableplatformlist.pagetitle .row>div:first-child {
            max-width: 35%;
            flex: 0 0 35%;
      }

      body.tamil .tasks .tableplatformlist.pagetitle .row>div:last-child {
            max-width: 65%;
            flex: 0 0 65%;
      }

      body.tamil .row.taskrowmob h5 {
            font-size: 13px;
      }

      body.tamil table .mat-column-resetPassword .btn {
            max-width: 200px;
      }

      body.russian .t_body>.flex>div:first-child {
            width: 30%;
      }

      body.russian .t_body>.flex>div:last-child {
            width: 50%;
      }

      .org_report>.row:first-child {
            margin-bottom: 30px;
      }

      .org_report>.row:last-child .tableplatformlist>.row {
            flex-direction: column;
      }

      /*NEW CHNAGES*/
      .lt_body .box .t_body img {
            height: 180px;
      }

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 180.09px;
      }

      .lt_body .box {
            max-height: 243px;
      }

      .dash_board .dashboxtop>.row>div:not(:first-child) {
            padding-top: 30px;
      }

      .dashboxtop .row>div p.dashboxtit.cmp_avg {
            top: -20px;
            right: -100%;
      }

      .gallery_div.empgallery>.gegsec>.row>.col-md-2 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .gallery_div.empgallery>.gegsec>.row>.col-md-10 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row {
            flex-wrap: wrap;
      }

      .leave_box {
            max-width: 100%;
            flex: 0 0 100%;
      }

      .timelog_rpt .contentsec h5 {
            font-size: 18px;
      }

      body.spanish .at_report .tableplatformlist>.row .at_head {
            width: 56%;
      }

      body.german .employee_page .paginatormob>.row .col-md-2 {
            flex: 0 0 25.666667%;
            max-width: 25.666667%;
      }

      .form_frm {
            width: 100%;
      }

      body.indonesian #addMoreLink[_ngcontent-sci-c310],
      body.indonesian .cursor-pointer[_ngcontent-sci-c310],
      body.indonesian .form-group label,
      body.indonesian .custom-control-input+.custom-control-label {
            font-size: 11px;
      }

      body.indonesian .add_head h3 {
            font-size: 20px;
      }

      body.turkey .employee_page .paginatormob>.row .col-md-2 {
            flex: 0 0 50%;
            max-width: 30%;
      }

      .tamil .dlt_box {
            flex-wrap: wrap;
      }

      .tamil .dlt_box .box:first-child {
            width: 100%;
            order: 1;
      }

      .tamil .dlt_box .box:nth-child(2) {
            width: 83.5%;
      }

      .General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row,
      .mat-expansion-panel-body .row {
            flex-direction: column;
      }

      .General_settings .contentsec .row mat-expansion-panel .mat-expansion-panel-body>.row>div,
      .mat-expansion-panel-body .row>div {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .gallery_div .tabletitle p {
            line-height: 13px;
      }

      fcsro-leave-permission-approval .lfts .col-md-1 {
            flex: 0 0 20%;
            max-width: 20%;
      }

      fcsro-employee-report .comtithead>div>.row>div {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .at_report .tableplatformlist.comtithead>.row {
            flex-direction: column;
      }

      fcsro-attendance-report .comtithead {
            max-height: max-content;
            padding-top: 10px;
            padding-bottom: 10px;
      }

      .at_report .tableplatformlist.comtithead>.row>div {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
      }

      .at_report .tableplatformlist.comtithead>.row>div h4 {
            margin-bottom: 10px;
      }

      .dt_list ul li {
            width: 22%;
      }

      .emp_date {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .dt_list ul {
            margin: 0;
      }

      .french .dt_list ul li {
            width: 22%;
      }

      .russian fcsro-attendance-report-details .comtithead {
            max-height: max-content;
            padding-top: 10px;
            padding-bottom: 10px;
      }

      .russian .atdet_lft {
            flex-direction: column;
      }

      .russian .at_details .fill_sec,
      .russian .at_details .name_prf {
            width: 100%;
            justify-content: flex-start;
      }

      .russian .at_details .name_prf .lft {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 15px;
      }

      .russian .at_details .name_prf .lft .card {
            margin-right: 10px;
      }
      
}

@media (max-width: 992px) {
      .orgCodeMobileView {
            display: block;
      }

      div#navbarSupportedContent {
            display: none !important;
      }

      .content-page,
      body.sidebar-main .content-page {
            margin-left: 0;
      }

      .side_menu {
            padding-top: 15px;
      }
}

@media(max-width:991px) {
      .container {
            max-width: 100%;
      }

      .avaicons .container {
            max-width: 95%;
      }

      .iconlist {
            padding: 0 10px;
      }

      .regbtnsec a {
            width: 100%;
      }

      .created_time {
            font-size: 12px;
      }

      .date_only {
            max-width: 270px;
      }

      .orgCodeMobileView {
            width: 50%;
            margin-bottom: 15px;
            display: inline-block;
            background: #f9fcfe;
      }

      .orgCodeMobileView .nav-tzcc {
            padding: 15px;
      }

      .sidebar-main .iq-sidebar-logo {
            padding-left: 20px;
      }

      .dashboxtop .row {
            display: flex !important;
            width: auto !important;
      }

      .dashboxtop .row .col-md-4 {
            max-width: 33.3%;
            flex: 0 0 33.3%;
            justify-content: center;
            align-items: center;
            display: inline-flex;
      }

      .dashbox.fcsroclassdis {
            width: 100%;
      }

      .tamil .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .pagetitle .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center !important;
      }

      fcsro-image-groups .col-md-12.text-right {
            text-align: center !important;
      }

      fcsro-employee-report .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      fcsro-project-mappings .col-md-6 .d-flex {
            justify-content: flex-start;
      }

      .task_mid_sec>.row {
            flex-direction: column;
      }

      .task_mid_sec>.row>div {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .taskrowmob {
            flex-wrap: nowrap;
            overflow-x: auto;
      }

      .taskrowmob .col-md-4.col-12 {
            flex: 0 0 80%;
            max-width: 80%;
      }

      .paginatormob .col-md-4,
      .paginatormob .col-md-8 {
            flex: 0 0 100%;
            max-width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
      }

      .paginatormob .col-md-4 fcsro-search-data-table {
            width: 100%;
      }

      .paginatormob .mat-paginator-container {
            flex-direction: column;
      }

      .paginatormob .mat-paginator-container .mat-paginator-page-size {
            margin-right: 0px;
      }

      .brdrign {
            padding: 0 5px;
      }

      table.table.stickythtrcon.mat-table {
            max-width: initial;
            width: 1000px;
      }

      .dashboxtop .row .col-md-4 {
            flex-direction: column;
      }

      .row.lt_body .col-md-3 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .lfts_head .px-3>div>.col-md-4,
      .lfts_head .px-3>div>.col-md-5 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .lfts_head .px-3 {
            width: 70%;
      }

      .lt_body .box .t_body img {
            height: 210px;
      }

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 210.09px;
      }

      .lt_body .box {
            max-height: 273px;
      }

      .gallery_div.empgallery>.gegsec>.row>.col-md-2,
      .gallery_div.empgallery>.gegsec>.row>.col-md-10,
      .gallery_div.empgallery>.gegsec>.row>.col-md-7,
      .gallery_div.empgallery>.gegsec>.row>.col-md-5 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .right-bar.hide {
            width: 0;
            visibility: hidden;
            opacity: 0;
      }

      button.how_btn {
            position: fixed;
            bottom: 50px;
            right: 5px;
            z-index: 100;
            background: #0560fd;
            color: #fff;
            border: 1px solid #0560fd;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            width: 45px;
            height: 45px;
            box-shadow: 0 0 5px 0 #e1e1e1;
            z-index: 1000;
      }

      .right-bar.show {
            width: 240px;
      }

      right-side-bar .live_pop {
            max-width: 100% !important;
            right: 0 !important;
            z-index: 100;
      }

      .content-page.OrgUserRole {
            padding-right: 0;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row .col-md-5 {
            flex: 0 0 49.666667%;
            max-width: 49.666667%;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row .col-md-7 {
            flex: 0 0 50.333333%;
            max-width: 50.333333%;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row .col-md-7>mat-form-field {
            width: auto !important;
      }

      body.french .hd span.dash_icon {
            width: 30px;
            height: 30px;
      }

      body.french .dashbox .dashcount {
            padding-left: 0;
      }

      .dashbox .dash_icon,
      .hd h4 span {
            height: 30px;
      }

      .right-bar ul li {
            display: none;
      }

      .mob_view {
            display: block;
      }

      .modal-body>img {
            max-height: 400px;
            min-height: 400px;
      }

      .t_hd .date>p span {
            letter-spacing: 0;
            font-size: 11px;
      }

      .activity_pattern .lt_head {
            width: 240px;
      }

      .activity_pattern .t_hd {
            width: calc(100% - 240px);
      }

      .activity_pattern .t_hd>.row {
            width: 100%;
      }

      fcsro-client-report .comtithead>div>.col-md-4,
      fcsro-client-report .comtithead>div>.col-md-3 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .search_ss>.col-md-3 {
            flex: 0 0 35.5%;
            max-width: 35.5%;
      }

      fcsro-project-report .comtithead>.col-md-8 .col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4 {
            flex: 0 0 66.7%;
            max-width: 66.7%;
      }

      .task_time_report .actvbox.select-project>div {
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 20px;
      }

      .task_time_report .actvbox.select-project {
            padding-bottom: 30px !important;
      }

      fcsro-attendance-report-details .comtithead {
            max-height: max-content;
            padding-top: 10px;
            padding-bottom: 10px;
      }

      .atdet_lft {
            flex-direction: column;
            width: 100%;
      }

      .atdet_lft .name_prf {
            width: 100%;
            margin-bottom: 15px;
      }

      .at_details .fill_sec {
            width: 100%;
            justify-content: flex-start;
      }

      .at_details .row.dashpages {
            position: relative;
      }

      .atdet_lft+div {
            position: absolute;
            top: 0;
            right: 0;
      }

      .fill_sec .cards .card p {
            min-width: 108px;
      }

      .fill_section>div {
            flex: 0 0 32%;
            max-width: 32%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row>div {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      .french .actvbox h6 {
            justify-content: center;
      }

      .french .actvbox h5 {
            text-align: center;
      }

      fcsro-employee-report .empreportht {
            height: 286px !important;
      }

      .tamil fcsro-project-mappings .col-md-6 .d-flex {
            justify-content: flex-start;
            margin-bottom: 0;
      }

      body.tamil .tasks .tableplatformlist.pagetitle .row>div:last-child {
            max-width: 80%;
            flex: 0 0 80%;
      }

      body.tamil .tasks .tableplatformlist.pagetitle .row>div:first-child {
            max-width: 20%;
            flex: 0 0 20%;
      }

      .tamil fcsro-leave-permission-approval .comtithead h4 {
            font-size: 15px;
      }

      .russian .activity_pattern .lt_head h4 {
            font-size: 18px;
      }

      .russian fcsro-project-mappings .col-md-6 .d-flex {
            justify-content: flex-start;
            margin: 0;
      }

      .russian .activitybars {
            padding: 0px !important;
            width: 100%;
            justify-content: flex-end;
      }

      .spanish fcsro-project-mappings .col-md-6 .d-flex {
            justify-content: flex-start;
            margin-bottom: 0;
      }

      .spanish fcsro-project-mappings .comtithead>.row>div:last-child .activitybars {
            padding: 0px !important;
            justify-content: flex-end;
            width: 100%;
      }

      .spanish fcsro-project-mappings .comtithead>.row>div:last-child .activitybars>span:last-child {
            margin-right: 0 !important;
      }

      .spanish fcsro-project-mappings .comtithead>.row>div:first-child,
      .portuguese fcsro-project-mappings .comtithead>.row>div:first-child {
            flex: 0 0 30%;
            max-width: 30%;
      }

      .spanish fcsro-project-mappings .comtithead>.row>div:last-child,
      .portuguese fcsro-project-mappings .comtithead>.row>div:last-child {
            flex: 0 0 70%;
            max-width: 70%;
      }

      .spanish fcsro-email-digest .comtithead h4 {
            font-size: 18px;
      }

      .catalan .at_details .fill_sec .cards .card {
            min-width: 125px;
      }

      .portuguese fcsro-project-mappings .col-md-6 .d-flex {
            justify-content: flex-start;
            margin-bottom: 0;
      }

      fcsro-leave-history .t_hd .row .col-md-4 {
            flex: 0 0 75%;
            max-width: 75%;
      }

      fcsro-project-cost-report .task_box.custom-view .col-md-4 {
            flex: 0 0 33.33%;
            max-width: 33.33%;
      }

      fcsro-project-cost-report .task_box.custom-view>div {
            width: 67%;
      }

      fcsro-project-cost-report .task_box.custom-view>div .date_selector>.mat-form-field {
            width: 50%;
            padding: 0 15px;
      }

      .content-page {
            padding: 80px 0 0;
      }
}

@media(max-width:830px) {
      .fcsrocaltime .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      fcsro-horizontal-calendar {
            max-width: 100%;
      }

      fcsro-horizontal-calendar .tableplatformlist {
            margin: 15px auto 15px;
            max-width: 100%;
            width: 100% !important;
      }

      fcsro-horizontal-calendar .tableplatformlist .tableplatformlist {
            max-width: 300px;
            margin: 0px;
      }

      fcsro-horizontal-calendar .d-md-inline-block.mb-2 {
            display: inline-block !important;
      }

      .monitor_div {
            margin-bottom: 0px !important;
      }

      .russian fcsro-timelog-report .timelogcheck .col-md-3 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .mw-150px {
            max-width: 145px;
      }

      .dashbox {
            padding: 15px 0;
            width: 100%;
            text-align: center;
      }

      .russian .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .german .dashbox p.text_transform_none {
            font-size: 11px;
      }

      .monitor_div {
            min-width: 140px;
      }

      .activehrssec {
            flex-direction: column;
            display: flex;
      }

      fcsro-add-tag-group-form .col-md-8.mt-2,
      fcsro-add-tag-group-form .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .emp_content .emp_detail {
            width: 50%;
      }

      .focus_loop .fl_cnt .head {
            flex-direction: column;
            margin-bottom: 25px;
      }

      .focus_loop .fl_cnt .head .date_selector {
            width: 100%;
            margin-top: 20px;
      }

      .focus_loop .fl_cnt .head h3 {
            width: 100%;
      }

      /*NEW CHNAGES*/
      .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
            right: 65px;
            top: 5px;
      }

      .hd>mat-form-field {
            top: -345px;
            width: 55%;
      }

      .dashbox .dashcount {
            padding-left: 0;
      }

      nav.iq-sidebar-menu.side_menu>li.orgCodeMobileView:first-child {
            width: 53%;
      }

      nav.iq-sidebar-menu.side_menu>li.orgCodeMobileView:nth-child(2) {
            width: 47%;
      }

      .team_date {
            justify-content: space-between;
      }

      .team_date .col-md-5 {
            flex: 0 0 44.666667%;
            max-width: 44.666667%;
      }

      .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec .col-3 {
            text-align: center;
            flex: 0 0 50%;
            max-width: 50%;
      }

      .gallery_div .tabletitle.thrblksec:nth-child(2) .row .col-3 {
            flex: 0 0 25% !important;
            max-width: 25% !important;
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;
            border-bottom: none !important;
            padding: 0 10px;
      }

      .gallery_div .thrblksec h5 {
            font-size: 14px;
      }

      .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec .etttile {
            font-size: 9px;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-5 {
            flex: 0 0 43.666667%;
            max-width: 43.666667%;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-7 {
            flex: 0 0 56.333333%;
            max-width: 56.333333%;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-7 .mat-form-field {
            width: 50% !important;
      }

      .org_report>.row:last-child .col-sm-12 .tableplatformlist>.row {
            flex-direction: column;
      }

      .org_report>.row:last-child {
            margin-top: 25px;
      }

      .task_time_report .etttile {
            font-size: 10px;
      }

      .task_time_report .actvbox>div {
            flex: 0 0 33.3%;
            max-width: 33.3%;
            margin-bottom: 25px;
      }

      .task_time_report .actvbox {
            padding: 35px 10px 10px !important;
      }

      .form_frm {
            width: 95%;
      }

      ._idle_tracking .align-items-center {
            margin: 0;
      }

      ._idle_tracking .align-items-center>* {
            padding-left: 0;
      }

      /*NEW CHNAGES*/
      .gallery_div .tabletitle.thrblksec:first-child,
      .gallery_div .tabletitle.thrblksec:last-child {
            width: 100%;
            margin-right: 0;
      }

      .gallery_div.empgallery>.gegsec>.row>.col-md-10 {
            flex-direction: column;
      }

      .ad_report {
            flex-direction: column;
      }

      .ad_report>div {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .dt_list ul li {
            width: 30%;
      }

      .russian .at_details .fill_sec {
            flex-wrap: wrap;
      }

      .russian .at_details .fill_sec .cards {
            width: 100%;
            margin: 15px 0 0;
            justify-content: center;
      }

      .russian .at_details .fill_sec .rft.mr-3,
      .russian .at_details .fill_sec .dates {
            width: 48.5%;
      }

      .russian .at_details .fill_sec .dates .mat-form-field {
            width: 100%;
      }

      .russian .at_details .fill_sec .rft.mr-3 .mat-form-field {
            width: calc(100% - 40px);
      }
}

@media(max-width:767px) {
      .slidersection .carousel-item .container h1 {
            font-size: 25px;
            line-height: 30px;
      }

      .customprice {
            margin-top: 0px !important;
      }

      .contentsec {
            padding: 25px 0px;
      }

      .lftrgtcontent img {
            display: block;
            margin: 0px auto 15px auto;
      }

      .lrctxt {
            padding: 0 50px;
            text-align: justify;
      }

      .regbtnsec {
            margin-top: 15px;
            width: 280px;
      }

      .slidersection .carousel-item .container {
            padding-top: 125px;
      }

      .createaccountsec br {
            display: none;
      }

      .mat-expansion-panel-header {
            padding: 0px 15px !important;
      }

      .contentsec h4,
      .contentsec h3,
      .contentsec h4+span,
      .contentsec h3+span {
            display: block !important;
      }

      .contentsec .btn.btn-gradiant.float-right {
            float: none !important;
            margin: 15px auto 0px !important;
            display: block;
            max-width: 65%;
      }

      .tableplatformlist h4+.pull-right {
            float: none;
            display: flex;
            margin: 10px 0px 15px 0px;
            justify-content: center;
      }

      .contentsec h4+.mat-form-field,
      .contentsec h3+.mat-form-field {
            justify-content: center;
            align-items: center;
            display: flex;
            margin-top: 15px;
      }

      .contentsec h4+.tableplatformlist .btn.btn-gradiant,
      .contentsec h3+.tableplatformlist .btn.btn-gradiant,
      .contentsec h4+.tableplatformlist .btn-outline-danger {
            float: none !important;
            margin: 0px auto 15px !important;
            display: block;
      }

      .sign-in-content h3 {
            font-size: 30px !important;
      }

      .sign-in-content .owl-carousel .owl-item img {
            margin: 0px auto 30px !important;
      }

      .sign-in-from .loginbox {
            margin-bottom: 30px;
      }

      .sign-in-page .row .col-md-6:first-child {
            order: 2;
      }

      .sign-in-content {
            padding-top: 0px !important;
            text-align: center;
            margin-top: 0px;
      }

      .darkblue {
            padding: 0px 50px 50px;
            height: auto;
      }

      .darkblue .sign-in-detail {
            border-top-left-radius: 0px;
            border-bottom-right-radius: 20px;
            padding: 30px;
      }

      .pagetittxt,
      .pagetitbtn,
      fcsro-team-details .col-md-6.text-right {
            width: 100%;
            text-align: center !important;
      }

      .pagetittxt h4 {
            display: inline-block !important;
      }

      .pagetittxt h4+i.fa-question-circle-o {
            margin-left: 5px;
      }

      #addMoreLink {
            margin: 0px auto;
            display: block;
      }

      .contentsec .tableplatformlist .d-inline-block {
            display: block !important;
            text-align: center;
      }

      fcsro-image-groups .contentsec .tableplatformlist mat-paginator.d-inline-block {
            display: inline-block !important;
      }

      .contentsec .tableplatformlist ngx-slider.d-inline-block,
      .contentsec .tableplatformlist h3.d-inline-block {
            display: inline-block !important;
            width: 400px;
      }

      .table td,
      .table th {
            white-space: nowrap;
            padding-right: 15px !important;
            text-align: left;
      }

      fcsro-app-settings .row {
            margin-bottom: 40px;
      }

      fcsro-app-settings .col-md-4 {
            margin-bottom: 15px;
      }

      .stickythtrcon th,
      .stickythtrcon td {
            min-width: 150px !important;
            white-space: normal;
            font-size: 12px;
      }

      .stickythtrcon td.advanced-td {
            min-width: 30px !important;
      }

      .stickythtrcon .stickythtr {
            max-width: 135px;
            white-space: normal !important;
            min-width: 135px !important;
            font-size: 12px;
            padding-left: 0px !important;
            position: relative !important;
      }

      .tableplatformlist+.table.mat-table td,
      .tableplatformlist+.table-responsive .table.mat-table td {
            max-width: inherit;
      }

      .tamil .dashbox p.text_transform_none {
            font-size: 11px;
      }

      .empdetailsec .table td,
      .empdetailsec .table th {
            text-align: left;
      }

      .russian .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .german .dashbox p.text_transform_none {
            font-size: 10px;
      }

      .monitor_div {
            min-width: 125px;
      }

      .screen_count_div .monitor_div p:nth-child(2) {
            font-size: 25px !important;
      }

      .gallery_div .activitybars,
      .gallery_div .col-md-1 h6 {
            justify-content: center;
      }

      .empdatabtns {
            text-align: center;
            margin-top: 30px;
      }

      .empdatabtns .btn {
            margin-bottom: 5px;
      }

      .dashpages .col-md-6.text-right {
            text-align: center !important;
            padding-top: 15px;
      }

      .dashpages .col-md-6.text-right .button-cust {
            display: inline-block !important;
      }

      fcsro-team-details .pagetitbtn {
            margin-top: 15px;
      }

      fcsro-email-digest .mat-expansion-panel .mat-expansion-panel-header {
            height: auto !important;
      }

      fcsro-email-digest .mat-expansion-panel .mat-expansion-panel-header .mat-content {
            flex-direction: column;
            align-items: flex-start;
      }

      fcsro-email-digest .mat-expansion-panel-header-description {
            padding-bottom: 5px;
      }

      fcsro-email-digest .custom-control-label::after,
      .custom-control-label::before {
            top: 1.5px;
      }

      .custom-control-label::after {
            top: 0.5rem;
      }

      .pagetittxt .col-md-6.text-right {
            text-align: center !important;
      }

      .actsetlist {
            flex-wrap: nowrap;
            overflow-x: auto;
            flex-direction: row;
      }

      .contentsec h4 .fa-question-circle-o,
      .contentsec h3 .fa-question-circle-o {
            font-size: 14px;
      }

      .dashboxtxt {
            flex-direction: column;
      }

      .dashboxtxt .dash_icon {
            margin: 0px 0px 15px 0px;
      }

      .dashbox .dashcount {
            padding-left: 0px;
      }

      fcsro-add-tag-group-form .col-md-8.mt-2 {
            text-align: center;
      }

      fcsro-add-tag-group-form .col-md-8.mt-2 .btn.pull-right {
            float: none !important;
            display: block;
            margin: 15px auto !important;
      }

      fcsro-employee-report .col-md-4.brdrign {
            border-top: 1px solid #d4dee8;
            border-left: none;
            padding: 15px;
      }

      app-treemap h3.text-left {
            width: 100%;
            text-align: center !important;

      }

      app-treemap mat-form-field {
            float: none !important;
            margin: 15px auto 0px;
            width: 200px;
            display: block !important;
      }

      .taskmbcont .tableplatformlist form .row:last-child {
            flex-direction: column-reverse;
      }

      .taskmbcont .tableplatformlist form .row:last-child .col-5 {
            margin-bottom: 15px;
      }

      .taskmbcont .tableplatformlist form .row:last-child .col-7,
      .taskmbcont .tableplatformlist form .row:last-child .col-5 {
            text-align: center !important;
            flex: 0 0 100%;
            max-width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
      }

      .taskmbcont .tableplatformlist form .row:last-child .col-7 .btn {
            margin-bottom: 15px;
      }

      .taskmbcont .table td {
            white-space: nowrap !important;
      }

      fcsro-employee-invitation .tableplatformlist h3 {
            text-align: center !important;
      }

      /*new changes*/
      .sign-in-page .boxshad>fcsro-choose-login>.sign-in-from {
            flex-direction: column;
      }

      .sign-in-page .boxshad .sign-in-from ._lft,
      .sign-in-page .boxshad .sign-in-from ._rft {
            width: 100%;
            border-radius: 30px 30px 0px 0px;
      }

      .sign-in-page .boxshad {
            padding: 15px;
      }

      .sign-in-page .boxshad .sign-in-from ._lft .row ul {
            margin-top: 0px;
      }

      .gegsec .col-md-5 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .team_date {
            flex-direction: column;
      }

      .t_body>.flex {
            flex-direction: column;
      }

      .t_body>.flex>div:first-child {
            width: 100%;
      }

      .t_body>.flex>div:nth-child(2) {
            width: 100%;
      }

      .t_body>.flex>div:last-child {
            width: 100%;
      }

      .t_activity .table .t_body .flex>div {
            padding: 15px 10px;
      }

      .t_activity .table .t_body .flex>div:nth-child(2) {
            padding-left: 0;
            padding-bottom: 0;
      }

      .s_head {
            flex-direction: column;
      }

      .rf_btn {
            margin-left: unset;
      }

      .focus_loop .fl_cnt .head {
            width: auto;
            margin-left: 0;
      }

      .col-md-9.mbt {
            width: 75%;
      }

      .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
            right: 65px;
            top: 8px;
            left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
      }

      .navbar-list li>a {
            margin-right: 10px;
      }

      fcsro-employee-invitation .tableplatformlist form>.row>div {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .task_time_report .actvbox {
            padding: 30px 10px !important;
            justify-content: center !important;
      }

      .task_time_report .actvbox>div {
            flex: 0 0 33%;
            max-width: 33%;
            margin-bottom: 20px;
      }

      body.french .dashboxtop .row,
      body.tamil .dashboxtop .row {
            flex-direction: column;
      }

      body.french .dashboxtop .row .col-md-3:nth-child(1),
      body.tamil .dashboxtop .row .col-md-3:nth-child(1) {
            margin-bottom: 0;
      }

      body.french .dashbox::after,
      body.tamil .dashbox::after {
            display: none;
      }

      body.french .dashboxtop .row .col-md-3:nth-child(2),
      body.french .dashboxtop .row .col-md-3:nth-child(3),
      body.french .dashboxtop .row .col-md-3:last-child,
      body.tamil .dashboxtop .row .col-md-3:nth-child(2),
      body.tamil .dashboxtop .row .col-md-3:nth-child(3),
      body.tamil .dashboxtop .row .col-md-3:last-child {
            max-width: 100%;
            flex: 0 0 100%;
            padding: 0;
      }

      body.french .dashboxtop .row .col-md-3:nth-child(2),
      body.french .dashboxtop .row .col-md-3:nth-child(3),
      body.tamil .dashboxtop .row .col-md-3:nth-child(2),
      body.tamil .dashboxtop .row .col-md-3:nth-child(3) {
            border-bottom: 1px solid #e1e1e1;
      }

      body.french .dashboxtop .row .col-md-3:last-child,
      body.tamil .dashboxtop .row .col-md-3:last-child {
            padding: 100px 15px 0;
      }

      .live_head {
            flex-direction: column;
      }

      .live_tracking .lt_head {
            width: 100%;
            margin-bottom: 20px;
      }

      /*new changes*/
      .lt_body .box .t_body img {
            height: 180px;
      }

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 180.09px;
      }

      .lt_body .box {
            max-height: 243px;
      }

      .task_enhancement .contentsec>.tableplatformlist.mb-0>.row {
            flex-direction: column;
      }

      .task_enhancement .tableplatformlist>.row>div {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
      }

      .task_enhancement .tableplatformlist>.row>div:last-child {
            justify-content: center;
      }

      .task_enhancement .tableplatformlist>.row>div:last-child .mat-form-field {
            width: 47%;
      }

      .gallery_div.empgallery>.gegsec>.row>.col-md-2,
      .gallery_div.empgallery>.gegsec>.row>.col-md-10 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .dlt_box {
            flex-wrap: wrap;
      }

      .dlt_box .box:last-child {
            width: 100%;
            min-height: 70px;
      }

      .dlt_box .box {
            width: 48%;
      }

      .dlt_box .box:first-child,
      .dlt_box .box {
            width: 100%;
      }

      .dlt_box .box:first-child>.blk,
      .dlt_box .box:nth-child(2)>.blk {
            padding-bottom: 15px;
      }

      .sub_blk .row>.blk {
            padding: 0px 10px 10px;
      }

      .content-page,
      body.sidebar-main .content-page {
            padding-right: 0;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row>div:first-child,
      .task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(2),
      .task_enhancement .tableplatformlist:nth-child(2) .row>div.float-right.ng-star-inserted,
      .task_enhancement .tableplatformlist:nth-child(2) .row>div.float-right,
      .task_enhancement .tableplatformlist:nth-child(2) .row>div:last-child {
            max-width: 100%;
            width: 100%;
            flex: 0 0 100%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row>div.float-right.ng-star-inserted {
            padding: 0 15px;
      }

      .right-bar.show {
            width: 80%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(4) {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
      }

      .dlt_box .box:last-child>.blk {
            flex-direction: row;
      }

      .leave_box .leave_bdy .l_frm:not(:last-child) {
            flex-direction: column;
      }

      .leave_box .leave_bdy .l_frm ._frm:first-child {
            width: 100%;
            text-align: left;
      }

      .leave_box .leave_bdy .l_frm ._frm label {
            margin: 0 0 10px;
      }

      .leave_box .leave_bdy .l_frm ._frm:last-child {
            width: 100%;
            padding-left: 0;
      }

      .leave_box .leave_bdy .l_frm ._frm select#leave,
      .leave_box .leave_bdy .l_frm ._frm textarea#reason {
            max-width: 100%;
      }

      .leave_box .leave_bdy .l_frm ._frm:nth-child(2)>.mat-form-field {
            max-width: 100%;
      }

      .leave_box .leave_bdy form ._frm>span.warning-message {
            text-align: center;
      }

      .leave_box .leave_bdy .l_frm ._frm select#leave,
      .leave_box .leave_bdy .l_frm ._frm:nth-child(2)>select {
            max-width: 100%;
      }

      .lfts_head .px-3>div {
            flex-direction: column;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row {
            flex-direction: column;
            justify-content: center;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row>div {
            flex: 0 0 100% !important;
            max-width: 100% !important;
            width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row .col-md-7>mat-form-field {
            width: 100% !important;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row .col-md-5>.mat-form-field {
            width: 50%;
            max-width: 100%;
      }

      body.french .hd>mat-form-field,
      .hd>mat-form-field {
            top: -70px;
      }

      body.tamil .dashboxtxt .dash_icon {
            margin: 0 10px 0 0;
      }

      body.tamil .dashboxtxt {
            flex-direction: row;
            align-items: center;
            justify-content: center;
      }

      .quick_view .table-responsive .table thead tr th:first-child,
      .quick_view .table-responsive .table tbody tr td:first-child {
            min-width: 135px;
      }

      .tamil .activity_pattern .contentsec h4,
      .tamil .activity_pattern .contentsec h3 {
            text-align: left !important;
      }

      fcsro-leave-permission-details .lfts_head h4 {
            width: 100%;
            text-align: center !important;
      }

      body.spanish .at_report .tableplatformlist>.row .at_head {
            width: 100%;
            margin-bottom: 10px;
      }

      .at_report .tableplatformlist>.row .at_head {
            width: 100%;
            margin-bottom: 15px;
            justify-content: space-between !important;
      }

      body.german .employee_page .paginatormob>.row .col-md-2 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      body.turkey .employee_page .paginatormob>.row .col-md-2 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .at_details .row.dashpages {
            flex-direction: column;
      }

      .atdet_lft {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
      }

      .name_prf .rft {
            position: relative;
      }

      .name_prf .rft>.text-center+div {
            left: 20px;
            bottom: -25px;
      }

      .name_prf {
            width: 40%;
      }

      .name_prf+.dates {
            width: 30%;
      }

      .name_prf+.dates>.mat-form-field {
            width: 100%;
      }

      .dt_list ul {
            justify-content: space-between;
      }

      .dt_list ul li {
            width: 46%;
      }

      .dt_list ul li:not(:last-child) {
            margin-right: 0;
      }

      .add_record .form-group>label {
            width: 100%;
            display: block;
      }

      .work_shift .tableplatformlist .form-group label {
            width: 100%;
            display: block;
      }

      .add_emp .tableplatformlist form .row>div:last-child {
            padding-top: 15px;
      }

      .add_des {
            position: relative;
      }

      .manage_lg.ng-star-inserted {
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
      }

      .manage_lg>div {
            width: 100%;
      }

      .add_emp .tableplatformlist form button+div+div {
            width: 100% !important;
      }

      fcsro-over-time-report.ng-star-inserted .tableplatformlist.table-responsive .mat-form-field {
            width: 100% !important;
            max-width: 100%;
            min-width: 230px;
      }

      .contentsec .tableplatformlist .d-inline-block {
            margin-left: 0 !important;
      }

      span.arrowslr {
            display: flex;
            align-items: center;
            justify-content: center;
      }

      fcsro-over-time-report.ng-star-inserted .tableplatformlist.table-responsive .mat-form-field>div {
            padding: 0 !important;
            margin: 0 !important;
      }

      fcsro-over-time-report.ng-star-inserted .tableplatformlist.table-responsive>.mat-form-field {
            max-width: 625px;
            margin: 0 auto;
            display: block;
      }

      fcsro-over-time-report.ng-star-inserted .tableplatformlist.table-responsive>.row {
            margin-top: 25px;
      }

      form.map_form>.row>div:first-child {
            width: 100%;
            flex-direction: column;
      }

      form.map_form>.row .frm>.mat-form-field {
            width: 100%;
      }

      form.map_form>.row>div:first-child>.frm {
            margin-right: 0;
            width: 100%;
            margin-bottom: 10px;
      }

      form.map_form>.row>div {
            width: 100%;
      }

      .gallery_div .tabletitle {
            height: 91%;
      }

      .select_option .frm>div:first-child,
      .select_option .frm,
      .frm_blk {
            flex-direction: column;
      }

      .select_option .frm>div {
            width: 100%;
      }

      .select_option .frm>div:first-child>.frm {
            margin-right: 0;
            width: 100%;
      }

      .select_option .frm>div:first-child>.frm .mat-form-field,
      .select_option .frm .mat-form-field,
      .frm_blk>.frm .mat-form-field {
            width: 100% !important;
            display: block !important;
      }

      .frm {
            flex-direction: column;
      }

      .frm>.frm:not(:last-child) {
            margin-right: 0;
      }

      .frm>.frm {
            width: 100%;
      }

      ._frm._date_frm {
            width: 100%;
            flex-direction: column;
      }

      ._frm._date_frm>div {
            margin: 0 0 15px;
      }

      ._frm._date_frm>div {
            margin: 0 0 15px;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
      }

      ._frm._date_frm>div>label {
            padding-left: 5px;
      }

      .profile>div>.col-md-6 {
            padding: 0;
      }

      .General_settings .mat-tab-group {
            flex-direction: column;
      }

      .General_settings .mat-tab-group mat-tab-header.mat-tab-header {
            width: 100%;
            margin-bottom: 15px;
      }

      .General_settings .mat-tab-group .mat-tab-body-wrapper {
            width: 100%;
      }

      .General_settings .mat-tab-group .mat-tab-label:not(:last-child) {
            margin: 0 15px 5px 0;
      }

      .General_settings .mat-tab-group .mat-tab-labels {
            flex-wrap: nowrap;
            overflow-x: auto;
            flex-direction: row;
      }

      .General_settings .mat-tab-group .mat-tab-label {
            min-width: 255px;
            max-height: 67px;
      }

      .General_settings .mat-tab-group .mat-tab-list {
            transform: unset !important;
      }

      .lfts_head h4 {
            width: 100%;
      }

      fcsro-gallery-media .mat-tab-body-wrapper .mat-tab-body:nth-child(8) .row>div:nth-child(2) {
            order: 3;
      }

      fcsro-gallery-media .mat-tab-body-wrapper .mat-tab-body:nth-child(8) .row>div:last-child {
            order: 2;
      }

      .work_shift .form_frms {
            flex-direction: column !important;
            align-items: center !important;
      }

      .work_shift .form_frms>.btn {
            margin-left: auto !important;
            margin-right: 15px !important;
      }

      fcsro-email-digest .contentsec h5+p {
            text-align: center;
      }

      fcsro-email-digest .mat-expansion-panel-header-title .custom-control {
            height: auto;
      }

      .key-container {
            flex-direction: column;
      }

      span.access-key {
            width: 100%;
            margin: 0 0 15px 0 !important;
      }

      .email_box {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row>div {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .live_head,
      .lfts_head {
            padding-top: 10px;
            padding-bottom: 10px;
      }

      .t_hd {
            width: 100%;
      }

      .t_hd .select_team,
      .t_hd .date,
      .lfts_head .px-3>* {
            width: 50%;
      }

      .t_hd .date>p span {
            letter-spacing: 1px;
      }

      .activity_pattern .comtithead {
            max-height: initial;
            flex-direction: column;
            padding-top: 10px;
      }

      .activity_pattern .lt_head,
      .activity_pattern .t_hd {
            width: 100%;
      }

      .activity_pattern .lt_head h4 {
            margin-bottom: 20px;
      }

      .activity_pattern .t_hd>.row {
            margin: 0;
            top: 0;
      }

      .activity_pattern .t_hd>.row>.col-md-5 {
            width: 35%;
      }

      .activity_pattern .t_hd>.row>.col-md-7 {
            width: 65%;
      }

      .activity_pattern .t_hd>.row>.col-md-5 .mat-form-field {
            width: 100%;
      }

      .activity_pattern .t_hd>.row>.col-md-7 .mat-form-field {
            width: 50%;
      }

      .ad_report>.col-md-9 {
            flex-direction: column;
      }

      .time_log_date>.col-md-3,
      .time_log_date .float-right {
            width: 50%;
      }

      .time_log_date .float-right>.mat-form-field {
            width: 47%;
            max-width: 100%;
      }

      fcsro-dormant-employee-report .pagetitle.comtithead>.row>div,
      fcsro-high-idle-report .comtithead>.row>div {
            text-align: left !important;
      }

      .m-d-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
      }

      .m-d-flex>.mat-form-field {
            width: calc(100% - 80px) !important;
            max-width: 100%;
      }

      fcsro-over-time-report .comtithead {
            padding-top: 10px;
            padding-bottom: 10px;
      }

      fcsro-over-time-report .comtithead h4 {
            text-align: left !important;
      }

      .fillter_ses>.col-md-3,
      .fillter_ses>.col-md-4,
      .fill_section .col-md-2 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .search_ss>.col-md-3,
      .fill_section .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      fcsro-project-report .comtithead,
      fcsro-employee-report .comtithead {
            flex-direction: column;
            padding-top: 10px;
            padding-bottom: 10px;
      }

      fcsro-project-report .comtithead>div:first-child,
      fcsro-employee-report .comtithead>div:first-child {
            margin-bottom: 15px;
      }

      fcsro-client-report .comtithead>div {
            width: 50%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div,
      .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4,
      .fill_section.customdate>div:last-child {
            flex: 0 0 100%;
            max-width: 100%;
      }

      fcsro-leave-permission-approval .lfts_head {
            flex-direction: row;
            padding-top: 0;
            padding-bottom: 0;
      }

      fcsro-leave-permission-approval .lfts {
            align-items: center;
      }

      fcsro-leave-permission-approval .lfts .col-md-3,
      .fill_section.customdate>div,
      .at_report .tableplatformlist>.tb_cnt .col-md-3 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .task_time_report .actvbox+.row>.col-md-3 {
            width: calc(100% - 55px);
      }

      .task_time_report .actvbox.select-project.select-task>div {
            flex: 0 0 33.3%;
            max-width: 33.3%;
      }

      .at_report .tableplatformlist.comtithead>.row>div {
            flex-wrap: wrap;
      }

      .at_head .card {
            width: 25%;
      }

      .at_report .tableplatformlist.comtithead>.row>.col-md-9 .col-md-3 {
            flex: unset;
            max-width: 100%;
            width: calc(100% - 35px);
            padding-left: 0;
      }

      .at_head>div button {
            width: 115px;
      }

      .employee_page .paginatormob>.row>.col-md-3,
      .employee_page .paginatormob>.row>.col-md-2 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .ser_sec .col-md-3 {
            flex: 0 0 45%;
            max-width: 45%;
      }

      .at_details .fill_sec>div {
            width: 100%;
            margin: 0 !important;
      }

      .at_details .fill_sec>div:not(:last-child) {
            margin-bottom: 15px !important;
      }

      .at_details .fill_sec>div .mat-form-field,
      .french .live_tracking .lt_head,
      .french .t_hd,
      .french .t_hd .date {
            width: 100%;
      }

      .at_details .fill_sec>div,
      .french .t_hd .date p {
            justify-content: center;
      }

      .fill_sec .cards .card p {
            min-width: 120px;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div,
      .task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div.custom_sec,
      .task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div.custom_sec .row>.col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div.custom_sec .row>.col-md-6 {
            padding: 0;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row.emptask_fill.custom>div.custom_sec .row {
            margin: 0;
      }

      .emp_date,
      .french fcsro-over-time-report .comtithead>.row>div.col-md-4,
      .french fcsro-over-time-report .comtithead>.row>div.col-md-8,
      .french .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div,
      .french .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4,
      .tamil .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div,
      .tamil .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .time_log_date .float-right>.mat-form-field {
            width: 48%;
      }

      .french .t_hd .select_team .frm {
            flex-direction: row;
      }

      .french .select_team .frm label {
            justify-content: flex-start !important;
            width: 225px;
      }

      .french .t_hd,
      .french .lfts_head,
      .russian .quick_view .lfts_head {
            flex-direction: column;
      }

      .french .t_hd .select_team,
      .french .quick_view .lfts_head h4 {
            width: 100%;
            margin-bottom: 10px;
      }

      .galy_scrns>.col-md-3 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .activity_pattern .t_hd>.row>.col-md-5 {
            padding: 0;
      }

      .french .dt_list ul li {
            width: 33%;
      }

      .french .emp_content {
            flex-wrap: wrap;
      }

      .french .emp_content .emp_chart,
      .french .emp_content .emp_detail,
      .french fcsro-client-report .comtithead>div,
      .tamil .quick_view .lfts_head h4 {
            width: 100%;
      }

      .french .emp_content .emp_detail {
            margin-top: 25px;
      }

      body.french .view_scrn h4 {
            margin: 0;
            padding: 0;
      }

      .french .emp_content .emp_chart h3 {
            padding-top: 0;
      }

      .french fcsro-over-time-report .comtithead h4 {
            margin-bottom: 15px;
      }

      .french fcsro-task-organisation-report .comtithead,
      .french fcsro-client-report .comtithead,
      .tamil .lfts_head,
      .russian fcsro-project-mappings .comtithead>.row,
      .spanish fcsro-task-organisation-report .comtithead {
            flex-direction: column;
            padding-top: 10px;
            padding-bottom: 10px;
      }

      .french fcsro-client-report .comtithead h4 {
            margin-bottom: 15px;
      }

      .french .task_enhancement .tableplatformlist>.row>div:last-child {
            justify-content: flex-end;
      }

      .tamil .dlt_box .box:nth-child(2) {
            width: 100%;
      }

      .tamil .time_log_date>.col-md-3,
      .time_log_date .float-right {
            flex: 0 0 50%;
      }

      .tamil .activitybars {
            padding: 0 !important;
            font-size: 12px;
      }

      .tamil .activitybars>span:last-child {
            margin-right: 0 !important;
      }

      .quick_view .lfts_head h4,
      fcsro-leave-permission-approval .lfts_head h4 {
            width: 100%;
      }

      .tamil .at_head>div button {
            width: 220px;
      }

      .tamil .at_report .tableplatformlist>.row .at_head,
      .russian .live_tracking .lt_head,
      .russian .live_tracking .t_hd,
      .russian .quick_view .lfts_head h4,
      .russian .lfts_head .px-3 {
            width: 100% !important;
      }

      .russian .select_team .frm label {
            justify-content: flex-start !important;
            margin: 0 0 0 10px !important;
      }

      .russian fcsro-project-mappings .comtithead>.row>.col-md-6:first-child,
      .russian fcsro-project-mappings .comtithead>.row>.col-md-6:last-child,
      .spanish fcsro-project-mappings .comtithead>.row>div:last-child,
      .spanish fcsro-project-mappings .comtithead>.row>div:first-child,
      .client_chart .row>.col-md-6,
      .client_chart .row>.col-md-4,
      .spanish .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4,
      .catalan .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div,
      .catalan .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4,
      .portuguese fcsro-project-mappings .comtithead>.row>div:last-child,
      .portuguese .task_enhancement .tableplatformlist:nth-child(2) .row.extra>div,
      .portuguese .task_enhancement .tableplatformlist:nth-child(2) .row.extra>.col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .russian .activitybars {
            justify-content: flex-start;
      }

      .russian .at_head>div button {
            width: 165px;
      }

      fcsro-employees .lfts_head h4 {
            width: 50%;
      }

      fcsro-holiday-definition .tableplatformlist h4+.pull-right .mat-form-field {
            width: 100%;
      }

      .select_team .frm label {
            justify-content: flex-start;
      }

      fcsro-quick-view-report .lfts_head,
      .german fcsro-task-organisation-report .comtithead,
      .cost_report,
      fcsro-login-report .dashpages {
            flex-direction: column;
      }

      .chinese .at_details .fill_sec {
            flex-wrap: wrap;
      }

      .at_details .fill_sec {
            flex-wrap: wrap;
      }

      .spanish fcsro-project-mappings .comtithead>.row>div:last-child .activitybars,
      .spanish fcsro-project-mappings .col-md-6 .d-flex {
            justify-content: center;
      }

      .General_settings .mat-tab-group .mat-tab-label {
            padding: 15px;
      }

      .General_settings .mat-tab-group .mat-tab-labels {
            align-items: initial;
      }

      .js-plotly-plot .plotly {
            overflow: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
      }

      .portuguese fcsro-project-mappings .comtithead>.row,
      .portuguese fcsro-task-organisation-report .comtithead {
            flex-direction: column;
      }

      .portuguese fcsro-project-mappings .col-md-6 .d-flex {
            justify-content: center;
            margin-bottom: 5px;
      }

      .felabel {
            margin-right: 0 !important;
      }

      .task_mid_sec .tableplatformlist .row>div:first-child {
            margin-bottom: 10px;
            justify-content: space-between !important;
      }

      .task_mid_sec .form-group select {
            margin-bottom: 0;
      }

      fcsro-leave-history .t_hd .row .col-md-4,
      fcsro-project-cost-report .task_box.custom-view .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
      }

      .cost_report>div:first-child,
      .cost_report>div:last-child {
            width: 100%;
            padding: 0 !important;
      }

      .cost_report>div:first-child>div {
            width: 50%;
      }

      fcsro-project-cost-report .task_box>div,
      fcsro-project-cost-report .task_box.custom-view>div {
            width: 100%;
            padding: 0;
      }

      fcsro-project-cost-report .mat-form-field-appearance-outline .mat-form-field-flex {
            padding: 0;
      }

      fcsro-project-cost-report .task_box.custom-view>div .date_selector>.mat-form-field {
            width: 48%;
            padding: 0;
      }
}


@media(max-width:730px) {
      .iconlist {
            padding: 0 5px;
            font-size: 12px;
      }

      .about_box {
            width: 100%;
      }

      .russian .dashbox p.text_transform_none {
            font-size: 11px;
      }

      .designation_frm form {
            width: 100%;
            min-width: 100%;
      }

      .designation_frm form .form-group .validation-feedback.ng-star-inserted,
      .add_workshift form .validation-feedback.ng-star-inserted {
            position: relative;
            line-height: 12px;
      }

      .designation_frm form .form-group .validation-feedback.ng-star-inserted+.validation-feedback.ng-star-inserted,
      .add_workshift form .validation-feedback.ng-star-inserted+.validation-feedback.ng-star-inserted {
            bottom: unset;
            line-height: 12px;
      }

      .designation_frm form .form-group .invalid-feedback.ng-star-inserted,
      .add_workshift form .invalid-feedback.ng-star-inserted {
            position: relative;
            bottom: unset;
      }

      .designation_frm form .form-group,
      .add_workshift form .form-group {
            margin-bottom: 15px;
      }
}

@media(max-width:640px) {
      .iconlist {
            padding: 10px 20px;
      }

      .soon_txt {
            bottom: -7px;
      }

      .monitor_div {
            min-width: 110px;
      }

      .russian .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .sscontents .d-inline-block {
            float: none !important;
      }

      .sscontents {
            text-align: center !important;
      }

      .mat-paginator-container {
            justify-content: center !important;
      }

      .screen_count_div .monitor_div p:nth-child(2) {
            font-size: 20px !important;
      }

      .gallery_div .classificationVisualisation svg {
            margin-left: -8px;
            transform: scale(0.9);
      }

      .empdatabtns {
            width: 400px;
            margin: 15px auto 0px;
      }

      fcsro-project-mappings .activitybars {
            flex-direction: column;
            align-items: flex-start;
      }

      .dmlsl .iq-card-body .custom-control {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            display: flex;
            height: auto;
      }

      .dmlsl .badge-primary {
            margin: 5px 0px !important;
      }

      fcsro-email-digest .custom-control-label::after,
      .custom-control-label::before {
            top: 3px;
      }

      .custom-control-label::after {
            top: 0.2rem;
      }

      .row.lt_body .col-md-3 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .lt_body .box .t_body img {
            height: 319px;
      }

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 319.09px;
      }

      .lt_body .box {
            max-height: 382px;
      }

      .time_log_date {
            flex-direction: column;
      }

      .time_log_date>.col-md-3,
      .time_log_date .float-right {
            width: 100%;
      }
}

@media(max-width:575px) {
      .imgpritxt ul {
            padding-left: 150px;
      }

      .slidersection .carousel-item .container {
            padding-top: 100px;
      }

      .custom-control-label {
            font-size: 14px;
      }

      .card-title {
            padding: 0 !important;
      }

      .date_only {
            max-width: 210px;
      }

      .sign-in-from h1 {
            font-size: 20px;
      }

      .darkblue {
            padding: 0px 15px 15px;
      }

      .darkblue .sign-in-detail {
            padding: 30px;
      }

      .sign-in-content h3 {
            font-size: 25px !important;
            line-height: 30px !important;
      }

      .sign-in-content p {
            font-size: 12px !important;
      }

      .sign-in-content .owl-carousel .owl-item img {
            max-width: 150px !important;
      }

      fcsro-horizontal-calendar .d-md-inline-block.mb-2 {
            display: block !important;
      }

      .russian .dashbox p.text_transform_none {
            font-size: 11px;
      }

      fcsro-app-settings ngx-slider {
            display: block !important;
            margin: 30px auto 20px !important;
      }

      fcsro-app-settings .col-md-4 {
            font-weight: bold;
            font-size: 12px;
      }

      .dashboxtop .row .col-md-4 {
            max-width: 100%;
            flex: 0 0 100%;
      }

      .dashbox {
            padding: 30px 15px;
      }

      .dashbox::after {
            right: 0;
            width: 100%;
            height: 1px;
            top: auto;
            bottom: 0px;
      }

      .dashbox.fcsroclassdis {
            padding: 15px;
      }

      .dashbox p.text-primary {
            line-height: normal;
            margin-top: 15px;
      }

      .russian .dashbox p.text_transform_none {
            font-size: 12px;
      }

      .russian fcsro-timelog-report .timelogcheck .col-md-3 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .monitor_div {
            min-width: 90px;
            padding: 15px 10px;
      }

      .sminp {
            top: 0;
      }

      .screen_count_div .monitor_div p:nth-child(2) {
            font-size: 18px !important;
      }

      .gegsec .col-md-3,
      .gegsec .col-md-2,
      .gegsec .col-md-5,
      .gegsec .col-md-2:nth-child(3) {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .gallery_div .classificationVisualisation svg {
            margin-left: 0;
            transform: none;
      }

      .thrblksec .col-3,
      fcsro-focus-spread .col-4 {
            flex: 0 0 50% !important;
            max-width: 50% !important;
            border-bottom: 1px solid #e2eeff;
      }

      .thrblksec .row .col-3:nth-child(3),
      .thrblksec .row .col-3:nth-child(4),
      fcsro-focus-spread .row .col-4:nth-child(3) {
            border-bottom: 0px;
      }

      .gallery_div .thrblksec .col-3,
      fcsro-focus-spread .col-4 {
            padding: 15px 15px;
      }

      fcsro-image-groups .mat-paginator-page-size {
            flex-direction: column;
            justify-content: center;
            align-items: center;
      }

      fcsro-email-digest .custom-control-label::after,
      .custom-control-label::before {
            top: 3px;
      }

      .custom-control-label::after {
            top: .25rem;
      }

      .gegsec .col-md-6 .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      fcsro-add-tag-group-form .col-md-8.mt-2 {
            text-align: center;
      }

      fcsro-add-tag-group-form .btn.btn-gradiant.ml-2.pull-right {
            float: none !important;
      }

      fcsro-employee-report .thrblksec .col-md-4.brdrign,
      fcsro-employee-report .thrblksec .col-sm-8.brdrign {
            padding: 15px;
            border-top: 1px solid #d4dee8;
            border-left: none;
            margin: 0px;
      }

      fcsro-employee-report .table td,
      fcsro-employee-report .table th {
            white-space: normal;
      }

      .demo-stp .col-md-3 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .contentsec .tableplatformlist ngx-slider.d-inline-block,
      .contentsec .tableplatformlist h3.d-inline-block {
            width: 70%;
      }

      .taskrowmob .col-md-4.col-12 {
            flex: 0 0 90%;
            max-width: 90%;
      }

      /*new changes */
      .orgCodeMobileView {
            width: 100%;
      }

      .tableplatformlist .row>div+div {
            width: 75%;
      }

      .align-items-center>.col-md-1 {
            width: 25%;
            margin-top: 15px;
      }

      .col-md-12 .tableplatformlist>div>.mt-4>.ngx-slider {
            width: 80%;
      }

      .emp_content {
            flex-direction: column-reverse;
      }

      .emp_content .emp_detail {
            width: 100%;
            margin-bottom: 25px;
      }

      .emp_content .emp_chart {
            width: 100%;
      }

      .chart .chart_rft {
            width: 80%;
      }

      .dash_board .dashboxtop>.row>div {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .fc-csat {
            width: 100%;
      }

      .dash_board .dashboxtop>.row>div:not(:nth-child(3), :last-child) {
            margin-bottom: 0;
      }

      .dash_board .dashboxtop>.row>div:last-child {
            padding: 30px 35px 10px;
      }

      .lfts_head .px-3 {
            width: 100%;
      }

      .t_hd {
            flex-direction: column;
      }

      .select_team {
            width: 100%;
            margin-bottom: 20px;
      }

      /*new changes */
      .lt_body .box .t_body img {
            height: 230px;
      }

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 230.09px;
      }

      .lt_body .box {
            max-height: 293px;
      }

      .gallery_div .tabletitle.thrblksec:nth-child(2) .row .col-md-9.mbt {
            width: 100%;
      }

      .gallery_div .tabletitle.thrblksec:nth-child(2) .row {
            position: relative;
      }

      .gallery_div .tabletitle.thrblksec:nth-child(2) .row .col-3 {
            position: absolute;
            left: 50%;
            top: 50%;
            flex: 0 0 50% !important;
            max-width: 50% !important;
            border-left: 1px solid #e2eeff;
            height: 50%;
            padding-top: 15px;
      }

      .gallery_div .tabletitle.thrblksec.act_row>.row .blk {
            width: 50%;
            margin-bottom: 20px;
      }

      .gallery_div .tabletitle.thrblksec.act_row>.row .blk:nth-child(odd) {
            border: none;
      }

      .gallery_div .tabletitle.thrblksec.act_row>.row .blk {
            padding: 0 20px;
      }

      .dlt_box .box {
            width: 100%;
      }

      fcsro-error503 h3 {
            text-align: center;
            font-size: 20px;
      }

      .hd>mat-form-field {
            top: -450px;
            width: 45%;
      }

      .work_shift .tableplatformlist .row>div+div {
            width: 100%;
      }

      body.french .at_report .tableplatformlist>.row .at_head {
            width: 100%;
            margin: 10px 0 15px;
      }

      body.french .at_report .tableplatformlist>.row .col-md-3 {
            flex: 0 0 30%;
            max-width: 30%;
      }

      body.french .project_report .tableplatformlist .row>div+div {
            width: 100%;
      }

      .tamil .hd>mat-form-field {
            top: -70px;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-5 {
            flex: 0 0 100%;
            max-width: 100%;
            flex-direction: column;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-5 h4 {
            width: 100%;
      }

      .tamil .col-md-12.text-center.viewdownbtns button {
            margin-top: 15px;
      }

      .project_report .tableplatformlist .row>div+div {
            width: 100%;
      }

      body.russian .dashboxtop .row .col-md-3:nth-child(2),
      body.russian .dashboxtop .row .col-md-3:nth-child(3),
      body.russian .dashboxtop .row .col-md-3:last-child,
      body.spanish .dashboxtop .row .col-md-3:nth-child(2),
      body.spanish .dashboxtop .row .col-md-3:nth-child(3),
      body.spanish .dashboxtop .row .col-md-3:last-child,
      body.german .dashboxtop .row .col-md-3:nth-child(2),
      body.german .dashboxtop .row .col-md-3:nth-child(3),
      body.german .dashboxtop .row .col-md-3:last-child,
      body.portuguese .dashboxtop .row .col-md-3:nth-child(2),
      body.portuguese .dashboxtop .row .col-md-3:nth-child(3),
      body.portuguese .dashboxtop .row .col-md-3:last-child,
      body.indonesian .dashboxtop .row .col-md-3:nth-child(2),
      body.indonesian .dashboxtop .row .col-md-3:nth-child(3),
      body.indonesian .dashboxtop .row .col-md-3:last-child,
      body.turkey .dashboxtop .row .col-md-3:nth-child(2),
      body.turkey .dashboxtop .row .col-md-3:nth-child(3),
      body.turkey .dashboxtop .row .col-md-3:last-child {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .dashbox .dash_icon,
      .hd h4 span {
            max-width: 30px;
      }

      body.russian .at_report .tableplatformlist>.row .at_head {
            width: 100%;
            margin-bottom: 10px;
      }

      .form_frm .row>.col-md-4 {
            width: 100% !important;
      }

      body.portuguese fcsro-employee-invitation .tableplatformlist h3 {
            text-align: left !important;
            font-size: 17px;
      }

      body.turkey .employee_page .paginatormob>.row .col-md-2 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .atdet_lft {
            flex-wrap: wrap;
      }

      .cards {
            width: 100%;
            margin: 30px auto 15px;
            justify-content: center;
      }

      .name_prf {
            width: 70%;
      }

      .tableplatformlist .row>div+div {
            width: 100%;
      }

      .dt_list ul li {
            width: 100%;
      }

      .sec_filter {
            padding-left: 15px;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row>div {
            flex-direction: column;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row .col-md-5>.mat-form-field {
            width: 100%;
      }

      .quick_view .table-responsive .table thead tr th:first-child,
      .quick_view .table-responsive .table tbody tr td:first-child {
            min-width: 185px;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-7 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-7 .mat-form-field {
            width: 100% !important;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-5>mat-form-field {
            width: 100%;
            max-width: 100%;
      }

      .emp_hrs_act h4 {
            font-size: 12px;
      }

      .emp_hrs_act>.col-6 {
            padding: 0 0 0 10px;
      }

      .gallery_div .tabletitle p {
            font-size: 8px;
      }

      .snd_cpy .mat-expansion-panel-header,
      .dark_theme .snd_cpy .mat-expansion-panel-header {
            background-position: right -8px top -5px !important;
      }

      .fill_section .col-md-2,
      .fill_section .col-md-4,
      fcsro-leave-permission-approval .lfts .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .fill_section>.col-md-4,
      fcsro-leave-permission-approval .lfts,
      .lfts_head .px-3 {
            flex-direction: column;
      }

      .fill_section>.col-md-4>.mat-form-field,
      fcsro-leave-permission-approval .lfts_head h4 {
            width: 100%;
            text-align: left !important;
      }

      .t_hd .select_team,
      .t_hd .date,
      .lfts_head .px-3>* {
            width: 100%;
      }

      .t_hd .date p {
            justify-content: flex-end;
      }

      .comfromlft fcsro-search-data-table {
            margin-right: 0;
      }

      .activity_pattern .t_hd>.row {
            flex-direction: column;
      }

      .activity_pattern .t_hd>.row>.col-md-5,
      .activity_pattern .t_hd>.row>.col-md-7 {
            width: 100%;
            padding: 0;
      }

      fcsro-over-time-report .comtithead>.row>.col-md-8>.row,
      fcsro-client-report .comtithead {
            flex-direction: column;
            padding: 10px;
      }

      .fillter_ses>.col-md-3,
      .fillter_ses>.col-md-4,
      fcsro-project-report .comtithead>.col-md-8 .col-md-4,
      fcsro-employee-report .comtithead>div>.row>div {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .fillter_ses>.col-md-3 {
            margin-bottom: 35px;
      }

      fcsro-client-report .comtithead>div>.col-md-4 {
            margin-top: 15px;
      }

      fcsro-project-report .comtithead>.col-md-8 .col-md-4:first-child,
      fcsro-employee-report .comtithead>div>.row>div:first-child {
            margin-bottom: 20px;
      }

      fcsro-client-report .comtithead>div {
            width: 100%;
      }

      fcsro-client-report .comtithead>div>.col-md-3 .mat-form-field {
            top: 19px;
      }

      fcsro-leave-permission-approval form.selecctForm {
            padding-bottom: 0;
      }

      fcsro-leave-permission-approval .lfts_head {
            flex-direction: column;
      }

      fcsro-leave-permission-approval .lfts {
            flex-direction: row;
      }

      fcsro-leave-permission-approval .lfts .col-md-3 {
            flex: 0 0 100%;
            max-width: calc(100% - 35px);
            padding-left: 0;
      }

      fcsro-leave-permission-approval .lfts .col-md-3 .mat-form-field {
            top: 5px;
      }

      .task_time_report .actvbox+.row>.col-md-3+div {
            width: 55px;
      }

      .task_time_report .actvbox>div,
      .fill_section.customdate>div,
      .ser_sec .col-md-3 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .task_time_report .comtithead {
            flex-direction: column;
            justify-content: space-around;
      }

      .task_time_report .comtithead>.col-md-2,
      .task_time_report .comtithead>.col-md-10 {
            width: 100% !important;
      }

      .task_box.extra-project>div,
      .task_box.custom-view>div,
      .task_box>div,
      .at_head>div button {
            width: 100%;
      }

      .task_box.extra-project.extra-task>div,
      .task_box.extra-project.custom-view>div {
            width: 48%;
      }

      .task_time_report .actvbox.select-project.select-task>div {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .at_report .tableplatformlist>.row .at_head {
            flex-wrap: wrap;
      }

      .manage_btn {
            width: 100%;
            margin-top: 10px;
      }

      .at_head .card {
            width: 22%;
      }

      fcsro-teams .comtithead>div {
            width: 50%;
      }

      .atdet_lft+div {
            width: auto !important;
      }

      .french .quick_view .lfts_head h4 {
            margin: 0;
      }

      .galy_scrns>.col-md-3,
      .actvbox .row .col-4,
      .french fcsro-client-report .comtithead>div>div {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
      }

      .french .dt_list ul li {
            width: 50%;
      }

      .french .actvbox h6 {
            min-height: auto;
      }

      .french fcsro-client-report .comtithead h4 {
            margin-bottom: 0;
      }

      .french fcsro-client-report .comtithead>div,
      .russian fcsro-task-organisation-report .comtithead,
      .catalan fcsro-task-organisation-report .comtithead {
            flex-direction: column;
      }

      .french fcsro-client-report .comtithead>div>.col-md-3 .mat-form-field {
            top: 15px;
      }

      .french fcsro-employee-report .table {
            width: 575px;
            max-width: max-content;
      }

      body.tamil .time_log_date>div:first-child,
      body.russian .time_log_date>div:first-child,
      .cost_report>div:first-child>div {
            width: 100%;
      }

      .russian fcsro-task-organisation-report fcsro-search-data-table .mat-form-field {
            top: 8px;
      }

      fcsro-employee-report .spnd_task {
            max-width: unset;
            width: 650px;
      }

      .russian .task_time_report .comtithead h4 {
            margin-bottom: 10px;
      }

      .catalan fcsro-task-organisation-report fcsro-search-data-table .mat-form-field {
            top: 5px;
      }

      .catalan fcsro-task-organisation-report .comtithead {
            padding-top: 10px;
      }

      .cost_report>div:first-child>div:first-child {
            margin-bottom: 15px;
      }

      div#stateModal .modal-dialog .modal-content {
            margin: 0 20px;
      }

      .screenimgs figure figcaption {
            width: 94%;
      }
      fcsro-client-employee-work-hours .comtithead {
            flex-direction: column;
      }
      fcsro-client-employee-work-hours .comtithead h4 {
            width: 100%;
            margin-bottom: 10px;
      }
      fcsro-client-employee-work-hours .comtithead h4 + div {
            margin-bottom: 0;
            width: 100%;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-end;
      }
      fcsro-client-employee-work-hours .comtithead h4 + div > .mat-form-field {
            width: 100%;
      }
      .cus-date {
            width: 100%;
            margin: 0 !important;
      }
      fcsro-client-employee-work-hours .comtithead h4 + div .csv_btn {margin: 0;}
}

@media(max-width:530px) {

      .monitor_div {
            min-width: 150px;
      }

      .table .btn {
            font-size: 12px;
            white-space: nowrap;
      }

      .table th,
      .table td {
            text-align: left;
      }

      .empgallery fcsro-focus-spread {
            float: none;
            display: block;
            margin: 3px;
      }

      .french .gallery_div .tabletitle.thrblksec p {
            width: 95px;
      }

      .tamil .thrblksec .etttile,
      .russian .thrblksec .etttile,
      .german .thrblksec .etttile {
            min-height: 35px;
      }

}

@media(max-width: 480px) {
      .iq-top-navbar .iq-navbar-custom .iq-menu-bt {
            top: 10px;
      }

      .orgCodeMobileView .nav-tzcc {
            width: 100%;
      }

      .side_menu>li:first-child {
            margin: 0;
      }

      .side_menu>li:first-child>div {
            padding-bottom: 0;
      }

      .content-page,
      body.sidebar-main .content-page {
            padding-top: 80px;
      }

      .focus_loop .fl_cnt .head h3 {
            font-size: 20px;
      }

      .tableplatformlist .row>div+div {
            width: 100%;
      }

      .contentsec .tableplatformlist ngx-slider.d-inline-block,
      .contentsec .tableplatformlist h4.d-inline-block,
      .contentsec .tableplatformlist h3.d-inline-block {
            width: 100% !important;
      }

      .tableplatformlist .mat-form-field {
            display: block;
            width: 100% !important;
            max-width: 100%;
      }

      .plot-container.plotly>div {
            position: relative !important;
            /* width: 100% !important; */
      }

      .js-plotly-plot .plotly .main-svg {
            width: 100%;
            object-fit: contain;
      }

      .table-responsive>.px-5 {
            padding: 0 !important;
      }

      .movie-list {
            min-height: auto;
      }

      .actsetlist {
            flex-wrap: wrap;
      }

      .contentsec .tableplatformlist .d-inline-block {
            margin-left: 0 !important;
      }

      .viewdownbtns mat-radio-group.mat-radio-group .mat-radio-button:last-child,
      .tableplatformlist button.btn.btn-outline-primary.ml-3.my-1 {
            display: none;
      }

      .mat-paginator-range-label {
            margin: 0 15px !important;
      }

      .focus_loop .fl_cnt .head .date_selector .scroll-toggle {
            width: 341px;
            padding: 0 5px;
      }

      .mat-paginator-page-size .mat-paginator-page-size-label {
            width: calc(100% - 60px);
      }

      .tableplatformlist .mat-paginator-page-size .mat-form-field {
            width: 60px !important;
      }

      .dashpages .col-md-6.text-right {
            display: flex;
            align-items: center;
      }

      .sidebar-main .version_div a {
            width: auto !important;
      }

      .contentsec .tableplatformlist a.btn.btn-outline-primary.ml-3.mt-sm-2.float-right.ng-star-inserted {
            margin: 10px 0 0 0 !important;
            width: 100%;
      }

      select#chooselanguage {
            display: none;
      }

      .taskrowmob {
            flex-wrap: wrap;
      }

      .taskrowmob .col-md-4.col-12 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      fcsro-image-groups .mat-paginator-page-size {
            flex-direction: row;
      }

      .col-md-6>.tableplatformlist>.mt-4>.ngx-slider {
            max-width: 260px !important;
      }

      .iq-sidebar {
            transition: 0.5s all ease-in-out;
      }

      .live_stream ul li {
            width: 100%;
      }

      .s_head {
            flex-direction: column;
      }

      .s_head h4 {
            font-size: 16px;
            margin-bottom: 10px;
      }

      .s_head a {
            margin: auto;
      }

      .live_stream ul li {
            margin-top: 5px !important;
      }

      .designation_frm form .form-group {
            margin-bottom: 10px;
      }

      .designation_frm form .form-group .invalid-feedback.ng-star-inserted {
            bottom: unset;
            line-height: 12px;
      }

      .designation_frm form .form-group .validation-feedback.ng-star-inserted {
            bottom: unset;
            line-height: 12px;
      }

      .sscontents .d-inline-block {
            width: 100%;
      }

      .empgallery .gegsec fcsro-horizontal-calendar .pickcalendar>div:first-child,
      .empgallery .gegsec fcsro-horizontal-calendar .pickcalendar>div:last-child {
            flex: 0 0 100%;
            max-width: 100%;
      }

      div#collapsedSection+div {
            margin-top: 15px !important;
      }

      .at_head {
            width: 100%;
            margin-top: 15px;
            margin-bottom: 0 !important;
            justify-content: center;
      }

      .at_select>div {
            width: auto !important;
            flex: unset;
            padding-left: 0;
      }

      .at_select {
            justify-content: center;
      }

      .atdet_lft {
            width: 100%;
            flex-direction: column;
      }

      .dates {
            margin: 15px 0;
            width: 100%;
      }

      .name_prf {
            width: 100%;
      }

      .name_prf .rft>.text-center+div {
            position: relative;
            left: auto;
            bottom: 0;
      }

      .at_details .tableplatformlist.clearfix {
            padding-top: 20px !important;
      }

      .s_head h4 {
            width: 100%;
      }

      .app_report {
            padding: 15px;
      }

      .a_body .boxz {
            padding: 10px;
      }

      .boxz .date {
            font-size: 12px;
      }

      .boxz .date {
            width: 75px;
      }

      .app_act_list {
            width: calc(100% - 75px);
      }

      .app_act_list {
            position: relative;
            overflow: hidden;
            overflow-x: auto;
      }

      .app_act_list .strips ul li:hover>span.tooltips {
            visibility: hidden;
            opacity: 0;
      }

      .dt_list ul li {
            width: 100%;
      }

      .tableplatformlist .row>div+div>mat-form-field {
            margin: 0 !important;
      }

      span.tooltips {
            top: -25px;
            left: 0;
            transform: translateX(-105%);
            min-width: 115px;
            max-width: 115px;
      }

      .app_act_list .strips ul li::before {
            transform: rotate(270deg);
            top: 10px;
            left: -10px;
      }

      .tool {
            position: unset;
      }

      #intergramRoot div img {
            width: 52px !important;
            object-fit: contain;
      }

      #intergramRoot div {
            width: auto !important;
      }

      span.tooltips {
            position: fixed;
      }

      .tableplatformlist .team_date .mat-form-field {
            margin: 0 !important;
      }

      .t_body>.flex {
            flex-direction: column;
      }

      .t_activity .table .t_body .flex>div {
            padding: 20px 10px;
      }

      .t_activity .table .t_body .flex>div:nth-child(2) {
            padding-left: 10px;
      }

      .t_activity .table .t_body .flex div .btns {
            right: 0;
            top: -20px;
      }

      .t_body>.flex>div {
            width: 100% !important;
      }

      .focus_loop .fl_cnt .head .date_selector .scroll-toggle button.scroll-toggle__button.scroll-toggle__button--left {
            margin-right: 10px;
      }

      .focus_loop .fl_cnt .head .date_selector .scroll-toggle button.scroll-toggle__button.scroll-toggle__button--right {
            margin-left: 10px;
      }

      .head_name {
            flex-direction: column;
            justify-content: center;
            text-align: center;
      }

      .emp_detail .cover-bar {
            right: 15px;
      }

      .col-md-9.mbt fcsro-focus-spread>.row>div:nth-child(2) {
            border-right: 1px solid #e2eeff;
      }

      .over_time .tableplatformlist.table-responsive {
            overflow: unset;
      }

      .over_time .tableplatformlist.table-responsive>.mat-form-field {
            margin: 0 auto 30px;
      }

      .contentsec .tableplatformlist .d-inline-block {
            padding-bottom: 10px;
      }

      span.arrowslr {
            display: flex;
            align-items: center;
            justify-content: space-between;
      }

      span.arrowslr .mat-form-field-appearance-outline .mat-form-field-wrapper {
            margin: 0 !important;
            padding: 0 !important;
      }

      .fc-csat {
            flex-direction: column;
      }

      .fc-cs ul {
            margin: auto;
      }

      .fc-cs {
            border-bottom: 1px solid #e1e1e1;
            padding-bottom: 15px;
      }

      .dashboxtop .row .col-md-4 .dashbox {
            padding-top: 20px;
      }

      .col-md-9.mbt {
            width: 100%;
      }

      .at_head .card:last-child {
            margin-right: 0px !important;
      }

      .sign-in-page .boxshad .sign-in-from ._rft {
            min-height: auto;
      }

      fcsro-time-bar-v3 .timeline_div {
            max-width: 92%;
      }

      .task_box>div,
      .task_box>.mat-form-field {
            width: 100% !important;
      }

      .task_box>div {
            margin-right: 0;
      }

      .task_box>.mat-form-field {
            margin-top: 15px;
      }

      .task_time_report .actvbox>div {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
      }

      .hd {
            flex-direction: column;
      }

      .bdy {
            margin-top: 10px;
      }

      .mtg h4 {
            justify-content: center;
      }

      fcsro-app-report-dialog.ng-star-inserted .mat-dialog-title {
            font: 500 12px/24px Roboto, "Helvetica Neue", sans-serif;
      }

      .hd>mat-form-field {
            width: 45%;
            position: relative;
            right: unset;
            top: unset;
            margin-top: 15px;
      }

      .dashboxtit {
            justify-content: center;
      }

      .iq-sidebar-logo a img {
            transform: unset;
      }

      fcsro-employee-invitation .tableplatformlist h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
      }

      .tableplatformlist h3.text-left>button {
            margin-top: 10px !important;
      }

      #intergramRoot>div {
            left: 10px;
            bottom: 60px !important;
      }

      p.l_chat {
            padding-bottom: 0;
      }

      .desg_Cellptxt+.actvidlebox>div {
            min-width: 75px;
      }

      ._idle_tracking ._id_track h6 {
            margin-bottom: 10px;
      }

      ._idle_tracking ._id_track h6+div {
            width: 80%;
      }

      .add_emp .tableplatformlist form button+div+div {
            width: 100% !important;
            padding-left: 0px !important;
      }

      .sort {
            flex-direction: column;
      }

      .mrt {
            width: 100%;
            margin: 15px 0;
      }

      .mrt ul,
      fcsro-app-report-dialog .mat-paginator-range-actions {
            flex-wrap: wrap;
            justify-content: center;
      }

      .mrt ul li {
            margin-bottom: 12px;
      }

      fcsro-app-report-dialog .mat-paginator-range-actions .mat-paginator-range-label {
            width: 100%;
      }

      .dashboxtop .row .col-md-3:last-child {
            padding: 40px 15px 0 !important;
      }

      body.french .dashboxtop .row .col-md-3:nth-child(1),
      .dashbox.fcsroclassdis {
            padding-bottom: 0;
      }

      .fc-cs {
            border: none;
      }

      body.tamil .bdy .blk>p {
            font-size: 9px;
      }

      .add_head h3>.lft {
            flex-direction: column;
            margin-top: 15px;
      }

      .lfts_head {
            flex-direction: column;
            width: 100%;
      }

      .lfts {
            width: 100%;
            justify-content: center;
            flex-direction: column;
            margin-top: 0px;
      }

      .lfts>a.btn {
            width: auto !important;
      }

      .dashboxtxt {
            flex-direction: row;
            justify-content: center;
            align-items: center;
      }

      .dashboxtxt .dash_icon {
            margin: 0px 10px 0px 0px;
      }

      .view_scrn h4 {
            padding-top: 30px;
      }

      nav.iq-sidebar-menu.side_menu>li.orgCodeMobileView:first-child,
      nav.iq-sidebar-menu.side_menu>li.orgCodeMobileView:nth-child(2) {
            width: 100%;
      }

      .team_date .col-md-5 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .gallery_div .tabletitle.thrblksec:nth-child(2) .row .col-3 {
            flex: 0 0 50% !important;
            max-width: 50% !important;
            padding: 15px !important;
            min-height: 95.3px;
      }

      .empgallery .gegsec .col-md-5.ng-star-inserted .thrblksec .etttile {
            justify-content: center;
      }

      .gallery_div .thrblksec .col-3:first-child,
      .gallery_div .thrblksec .col-3:nth-child(2) {
            margin-bottom: 0;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-7,
      fcsro-top-activity-report .tableplatformlist>.row>.col-md-5 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      fcsro-top-activity-report .tableplatformlist>.row>.col-md-7 .mat-form-field {
            width: 100% !important;
      }

      .at_report .tableplatformlist>.row {
            flex-direction: column;
      }

      .at_report .tableplatformlist>.row .col-md-3 {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 15px;
      }

      .time_log_date .float-right .mat-form-field {
            margin-left: 0 !important;
      }

      .project_report .row.ng-star-inserted .col-md-6 .actvbox h6 {
            min-height: 36px;
      }

      .taskmbcont .tableplatformlist form .row:last-child .col-7 .btn {
            min-width: 126.94px;
            margin-right: 0 !important;
      }

      .taskmbcont .tableplatformlist form .row:last-child .col-5 button {
            min-width: 126.94px;
      }

      .side_menu {
            padding-top: 5px;
      }

      .emp_details .tableplatformlist>form>.row>.col-md-4._full_width>div,
      .emp_details .tableplatformlist>form>.row>.col-md-4._full_width>div {
            display: flex;
            justify-content: center;
      }

      .t_hd {
            flex-direction: column;
      }

      .select_team {
            width: 100%;
      }

      .t_hd .date {
            width: 100%;
            text-align: center;
      }

      .t_hd .date p {
            justify-content: center;
      }

      .lfts_head .px-3>div {
            flex-direction: column;
      }

      .lfts_head .px-3>div>.col-md-4,
      .lfts_head .px-3>div>.col-md-5 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .lfts_head .px-3>div>.col-md-5 {
            margin-top: 15px;
      }

      .lt_body .box .t_body img {
            height: 198px;
      }

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 198.09px;
      }

      .lt_body .box {
            max-height: 261px;
      }

      .task_enhancement .tableplatformlist>.row>div:last-child {
            flex-direction: column;
            padding: 0 20px;
      }

      .dashboxtop .row>div p.dashboxtit.cmp_avg {
            top: 10px;
            right: 0;
      }

      .right-bar.show {
            width: 100%;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row>div.float-right.ng-star-inserted>.mat-form-field {
            width: 49% !important;
      }

      .leave_box .leave_bdy .l_frm ._frm:last-child {
            flex-direction: column;
      }

      .leave_box .leave_bdy .l_frm ._frm:last-child label {
            margin: 10px auto;
      }

      .activity_pattern .contentsec .tableplatformlist:last-child>.row .col-md-5 {
            flex-direction: column;
      }

      .project_report .row.ng-star-inserted .col-md-6 .actvbox>.row>.col-4 {
            max-width: 100%;
      }

      body.french .hd>mat-form-field,
      .tamil .hd>mat-form-field {
            top: 0;
      }

      body.french .at_report .tableplatformlist>.row .col-md-3 {
            flex: 0 0 70%;
            max-width: 70%;
      }

      body.french .at_head .card:last-child {
            margin-right: 0 !important;
      }

      .tamil .live_head h4 {
            font-size: 15px;
      }

      .tamil fcsro-top-activity-report .tableplatformlist>.row>.col-md-5 h4 {
            font-size: 15px;
      }

      fcsro-leave-permission-details .lfts_head .lfts {
            margin-top: 0;
      }

      fcsro-leave-permission-details>.contentsec {
            overflow: hidden;
      }

      body.russian .dashboxtop .row .col-md-3:nth-child(2),
      body.russian .dashboxtop .row .col-md-3:nth-child(3),
      body.russian .dashboxtop .row .col-md-3:last-child,
      body.spanish .dashboxtop .row .col-md-3:nth-child(2),
      body.spanish .dashboxtop .row .col-md-3:nth-child(3),
      body.spanish .dashboxtop .row .col-md-3:last-child,
      body.german .dashboxtop .row .col-md-3:nth-child(2),
      body.german .dashboxtop .row .col-md-3:nth-child(3),
      body.german .dashboxtop .row .col-md-3:last-child,
      body.portuguese .dashboxtop .row .col-md-3:nth-child(2),
      body.portuguese .dashboxtop .row .col-md-3:nth-child(3),
      body.portuguese .dashboxtop .row .col-md-3:last-child,
      body.indonesian .dashboxtop .row .col-md-3:nth-child(2),
      body.indonesian .dashboxtop .row .col-md-3:nth-child(3),
      body.indonesian .dashboxtop .row .col-md-3:last-child,
      body.turkey .dashboxtop .row .col-md-3:nth-child(2),
      body.turkey .dashboxtop .row .col-md-3:nth-child(3),
      body.turkey .dashboxtop .row .col-md-3:last-child {
            padding-top: 0;
      }

      .dash_board .dashboxtop>.row>div:not(:first-child) {
            padding-top: 0;
      }

      .contentsec .lfts_head h4 {
            margin-bottom: 10px;
      }

      .name_prf+.dates {
            width: 100%;
      }

      .cards {
            margin: 0;
      }

      .add_emp .tableplatformlist form .row,
      .add_emp .tableplatformlist form div#collapsedSection {
            width: 100%;
            margin: 0 auto !important;
      }

      .add_emp .mat-form-field-appearance-fill .mat-form-field-flex {
            padding-left: 0;
      }

      .add_emp .tableplatformlist form button+div+div>.row {
            padding: 0;
            width: 100%;
      }

      body.french .csv_btn {
            top: 70px;
      }

      .row.p-3.pro_check>div {
            max-width: 100%;
            flex: 0 0 100%;
      }

      body.portuguese fcsro-dashboard .csv_btn {
            top: 75px;
      }

      body.catalan fcsro-dashboard .csv_btn {
            top: 72px;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(4)>.blk {
            flex-direction: column;
      }

      .task_enhancement .tableplatformlist:nth-child(2) .row>div:nth-child(4)>.blk>div {
            width: 100%;
      }

      .custom_pop {
            left: 10%;
            top: 35px;
      }

      body.sidebar-main .iq-sidebar::after {
            content: "";
            width: 100%;
            height: 100%;
            position: fixed;
            left: 240px;
            top: 0;
            z-index: 250;
      }

      .mat-dialog-container addingform .form_frms {
            flex-direction: column !important;
      }

      .mat-dialog-container addingform .form_frms>.btn {
            margin-left: auto !important;
            display: flex !important;
            align-items: center;
            justify-content: center;
      }

      .mat-dialog-container addingform .form_frms>.btn i {
            margin: 0 !important;
      }

      .emp_content .emp_detail {
            width: 100%;
            box-shadow: none;
            padding: 0 0 20px;
            border: none;
            border-bottom: 1px solid #e1e1e1;
            border-radius: 0;
            margin-bottom: 20px;
      }

      .gallery_div .tabletitle {
            height: auto;
      }

      .activity_pattern .t_hd>.row>.col-md-7 {
            flex-direction: column;
            margin: 15px 0 0;
      }

      .activity_pattern .t_hd>.row .col-md-7>.mat-form-field {
            margin-bottom: 15px !important;
      }

      .activity_pattern .mat-form-field-appearance-outline .mat-form-field-flex {
            padding: 0;
      }

      .ad_report>.col-md-9>.col-md-5>div {
            flex-direction: column;
      }

      .ad_report>.col-md-9>*,
      .time_log_date>.col-md-3 {
            padding: 0;
      }

      fcsro-client-report .comtithead>div,
      .date_selector,
      .emp_date,
      .french .t_hd .select_team .frm {
            flex-direction: column;
      }

      fcsro-client-report .comtithead>div>.col-md-4,
      fcsro-client-report .comtithead>div>.col-md-3 {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
      }

      fcsro-client-report .comtithead>div>.col-md-3 .mat-form-field {
            top: 15px;
      }

      .task_time_report .actvbox.select-project>div,
      .task_time_report .actvbox.select-project.select-task>div,
      .employee_page .paginatormob>.row>.col-md-3,
      .employee_page .paginatormob>.row>.col-md-2 {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .at_report .tableplatformlist.comtithead>.row>div h4 {
            margin-bottom: 0;
      }

      .french .select_team .frm label {
            width: 100%;
            margin: 0;
            padding-left: 10px;
      }

      .french .dt_list ul li {
            width: 100%;
      }

      .french .project_report .actvbox>.row {
            flex-direction: row;
      }

      .tamil .org_report .comtithead {
            flex-direction: column;
            padding-top: 10px;
            padding-bottom: 10px;
      }

      .russian .emp_content .emp_detail {
            width: 100%;
      }

      .russian .at_details .fill_sec .rft.mr-3,
      .russian .at_details .fill_sec .dates {
            width: 100%;
      }

      fcsro-employee-invitation .comtithead h4 {
            font-size: 15px;
      }

      .catalan fcsro-time-log-report .mat-tab-label {
            padding: 0 10px;
      }

      .catalan fcsro-time-log-report .mat-tab-label .mat-tab-label-content {
            font-size: 13px;
      }

      fcsro-leave-policy-configuration .lfts_head {
            flex-direction: row;
      }

      fcsro-leave-policy-configuration .contentsec .lfts_head h4 {
            margin: 0;
      }

      .activity_pattern .t_hd .mat-form-field-wrapper {
            padding-bottom: 0;
      }

      fcsro-leave-history .t_hd .mat-form-field {
            width: calc(100% - 55px) !important;
      }

      .activity_pattern .t_hd>.row>div>button {
            padding-bottom: 0;
      }

      fcsro-top-activity-report .mat-form-field-underline {
            position: unset;
      }

      .activity_pattern .lt_head h4 {
            margin-bottom: 10px;
      }
      .project_report .tableplatformlist .row>div+div {
            margin-top: 15px;
      }
      .cus-date > div {
            flex-direction: column;
      }
}

@media(max-width:475px) {
      .imgpritxt ul {
            padding-left: 100px;
      }

      .tableplatformlist,
      .tabletitle {
            font-size: 12px;
      }

      .tableplatformlist {
            padding: 10px !important;
      }

      .gallery_div fcsro-time-bar-v2 .tableplatformlist {
            padding: 0px !important;
            margin: 0px !important;
      }

      .gallery_div fcsro-time-bar-v2 {
            width: 100%;
      }

      fcsro-horizontal-calendar .tableplatformlist {
            margin: 0px;
            padding: 0px !important;
      }

      .viewdownbtns .mat-radio-group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
      }

      .viewdownbtns .mat-radio-group .mat-radio-button {
            margin: 0px 0px 15px 0px !important;
      }

      .gallery_div .tabletitle,
      .gallery_div .tableplatformlist {
            margin-right: 0px !important;
            margin-left: 0px !important;
            padding: 0px;
      }

      .gallery_div .monitor_div {
            min-width: 130px;
            min-height: auto;
      }

      .gallery_div .tabletitle {
            min-height: auto;
            margin: 0px auto 15px;
            display: block !important;
            padding-bottom: 10px;
      }

      .dashbox.fcsroclassdis {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding-top: 0px;
            margin: 0px;
      }

      .dashbox p.text-primary {
            font-size: 25px;
      }

      .dashbox p.text_transform_none {
            font-size: 11px;
      }

      .dashboxtop .row .col-md-4 .dashbox {
            margin-bottom: 0px;
            padding-left: 10px;
            padding-right: 10px;
      }

      .dashboxtop .row .col-md-4:last-child .dashbox {
            padding-bottom: 20px;
      }

      .empdetailsec .table td,
      .empdetailsec .table th {
            display: block;
            padding: 5px !important;
      }

      .empdetailsec .table td {
            border: none;
      }

      .gallery_div .activitybars,
      .gallery_div .col-md-1 h6 {
            justify-content: flex-start;
      }

      .gallery_div .activitybars {
            padding: 15px 30px !important;
      }

      .empdatabtns {
            width: 100%;
      }

      .focus_loop .fl_cnt {
            padding: 10px 0;
      }

      .emp_content .emp_detail {
            padding: 15px;
      }

      .add_emp .tableplatformlist form {
            position: relative;
      }

      .add_emp .tableplatformlist form button {
            right: 8px;
            position: absolute;
            top: 8px;
            bottom: unset;
      }

      .add_emp .tableplatformlist form .row {
            width: 100%;
            margin: 0;
      }

      .add_emp .tableplatformlist form div#collapsedSection {
            width: 100%;
      }

      fcsro-employee-invitation .tableplatformlist form>.row>div {
            padding: 0;
      }

      .add_emp .tableplatformlist {
            padding: 15px;
      }

      fcsro-employee-invitation .tableplatformlist h3 {
            font-size: 18px;
      }

      .sub_blk .row>.blk {
            padding: 10px 15px;
            width: 50%;
      }

      .sub_blk .row>.blk:nth-child(3) {
            border-left: none;
      }

      .dlt_box .box:nth-child(2) .sub_blk .row>.blk {
            width: 50%;
      }

      .sub_blk .row {
            justify-content: flex-start;
      }
}

@media(max-width:450px) {
      .gallery_div .monitor_div {
            margin-left: 0px !important;
            margin-top: 5px;
            min-width: 125px;
      }

      .gallery_div .tabletitle,
      .gallery_div .tableplatformlist,
      .gallery_div .tabletitle:nth-child(2) {
            width: 100%;
            margin-left: 0px !important;
      }

      fcsro-horizontal-calendar .tableplatformlist .tableplatformlist {
            max-width: 60%;
      }

      fcsro-app-settings .tableplatformlist h6 {
            font-size: 1.1em;
      }

      .contentsec .tableplatformlist ngx-slider.d-inline-block,
      .contentsec .tableplatformlist h4.d-inline-block,
      .contentsec .tableplatformlist h3.d-inline-block {
            width: 85%;
      }

      .col-md-12 .tableplatformlist>div>.mt-4>.ngx-slider {
            width: 85%;
      }

}

@media(max-width:414px) {
      .imgpritxt ul {
            padding-left: 75px;
      }

      .slidersection .carousel-item .container h1 {
            font-size: 18px;
      }

      .lrctxt {
            padding: 0 15px;
      }

      .gallery_div .monitor_div {
            min-width: 115px;
      }

      .gallery_div .tabletitle h1 {
            font-size: 25px;
            text-transform: none;
      }

      .dashbox {
            width: 100%;
      }

      .tamil .dashbox p.text_transform_none {
            font-size: 10px;
      }

      .gallery_div .tabletitle.thrblksec .col-4 {
            flex: 0 0 50%;
            max-width: 50%;
      }

      .gallery_div .tabletitle.thrblksec .col-4:last-child {
            margin: 0px;
            border-left: none;
            flex: 0 0 100%;
            max-width: 100%;
            padding-top: 10px;
      }

      .etttile {
            font-size: 12px;
      }

      .gallery_div .activitybars {
            flex-wrap: wrap;
            justify-content: center;
      }

      .gallery_div .activitybars div.d-inline-block span {
            margin: 0px 5px 0px 0px !important;
      }

      .tamil .thrblksec .etttile,
      .russian .thrblksec .etttile,
      .german .thrblksec .etttile {
            min-height: auto;
      }

      .contentsec h4,
      .contentsec h3 {
            font-size: 14px !important;
            font-weight: bold;
      }

}

@media(max-width: 390px) {

      .month_chose,
      .date_only {
            width: 125px;
      }

      .lt_body .box .t_body img {
            height: 167px;
      }

      .lt_body .box .t_body,
      .lt_body .box .t_body>div {
            min-height: 167.09px;
      }

      .lt_body .box {
            max-height: 224px;
      }

      body.catalan .at_head .card p {
            font-size: 10px;
      }

      .at_head .card:nth-child(2n) {
            margin-right: 0 !important;
      }

      .at_head .card {
            width: 48%;
            margin-bottom: 10px;
      }

}

@media(max-width:380px) {
      .imgpritxt ul {
            padding-left: 50px;
      }

      .imgpritxt h5 {
            font-size: 35px;
      }

      .bottomtxt {
            font-size: 12px;
      }

      .subscripclk .imgpritxt h5 {
            font-size: 25px;
      }

      .toptxt {
            top: -10px;
            font-size: 14px;
      }

      .subscripclk .imgpritxt img {
            height: 40px;
            margin-right: 15px;
      }

      .subscripclk .imgpritxt ul {
            padding-left: 60px;
      }

      .tableplatformlist .listcontent .btn {
            font-size: 10px;
            padding: 3px 5px;
      }

      .gallery_div .monitor_div {
            min-width: 90px;
            padding: 5px;
            text-align: left !important;
            font-size: 10px;
      }

      .gallery_div .monitor_div p {
            line-height: normal;
      }

      .gallery_div .monitor_div p:nth-child(2) {
            font-size: 18px !important;
      }

      .gallery_div .tabletitle p,
      .thrblksec h5 p.small {
            font-size: 11px;
      }

      .contentsec .tableplatformlist ngx-slider.d-inline-block,
      .contentsec .tableplatformlist h4.d-inline-block,
      .contentsec .tableplatformlist h3.d-inline-block {
            width: 62%;
      }

      .tabs_content {
            padding: 0;
      }

      .mat-paginator-range-label {
            margin: 0 10px 0 10px !important;
      }

      .focus_loop .fl_cnt .head .date_selector .scroll-toggle {
            width: 315px;
      }

}

@media(max-width:360px) {

      .tableplatformlist,
      .tabletitle {
            font-size: 12px;
      }

      .slidersection .carousel-item .container h1 {
            font-size: 12px;
            font-weight: bold;
      }

      .contentsec .tableplatformlist ngx-slider.d-inline-block,
      .contentsec .tableplatformlist h4.d-inline-block,
      .contentsec .tableplatformlist h3.d-inline-block {
            width: 57%;
      }

}