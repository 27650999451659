/* You can add global styles to this file, and also import other style files */

/** CDK style for gallery **/

@import '~@angular/cdk/overlay-prebuilt.css';
/** ANGULAR Material styles for data table **/

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
.owl-carousel .owl-dot {
  display: inline-block;
}

.iq-email-list .iq-email-ui li a {
  padding: .5rem 1rem !important;
}

/* Validation error feedback in forms */

.validation-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

/* borders for bootstrap cards*/

.user-card {
  width: 200px !important;
  border-color: #343a40!important;
  cursor: pointer;
}

/* container for data-loader indicator and data*/

.data-wrapper {
  min-height: 200px;
}
.mat-tab-label-active .mat-tab-label-content{color: #0560fd}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{background-color: #0560fd;}
/* #classificationVisualisation svg{width: 200px; height: 200px;} */
/* #classificationVisualisation svg g{transform:translate(100, 100)} */
#classificationVisualisation{position: relative; z-index: 1;}
.tooltip_report{
  background: transparent;
/* box-shadow: 0 0 5px #999999; */
color: #000;
display: none;
font-size: 10px;
left: 23px;
padding: 10px;
position: absolute;
text-align: center;
top: 58px;
min-width: 100px;
z-index: -1;
width: 165px;
text-transform: capitalize;
  }
  .mat-toolbar.mat-primary, .mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary{background: #0560fd;}
  .mat-button.mat-primary{color: #0560fd}
  .focusro-text{font-size: 11px;}


.cdk-drop-list{
   min-height:840px;
}
.opacity-0{opacity: 0;}
.focusro-text tspan{width: 100%; display: block;}
.ngx-slider .ngx-slider-pointer{background-color: transparent !important; width: 16px !important; height: 16px !important;}
.ngx-slider .ngx-slider-pointer::after{width: 20px !important; height: 20px !important; top: 6px !important;
  left: 10px !important;
  border-radius: 10px !important;
  box-shadow: inset 0 0 0px 7px #0560fd;}
.mat-tooltip{font-size: 12px; background: #e5f2ff !important; color: #0560fd !important}
.owl-carousel .owl-item img{width: 600px !important; max-width: 100% !important; margin: 0 auto;}
.filter_invert{filter: brightness(0) invert(1);}
.box_shadow_none{box-shadow: none !important;}
/* .org_topper .svg-container, .org_topper .svg-container svg{width: 100% !important;} */
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background{background-color: #0560fd;}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{background-color: #0560fd;}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{border-color: #0560fd;}
.cdk-drag-placeholder{border: blue; color: #0560fd;}
.legendtext, .ytick text{font-size: 10px !important; text-transform: none;}
.mat-tab-body-content{overflow-x: hidden !important;}
.garbage_table .tble_hdr{min-width: 175px;}
.garbage_table .tble_hdr.mat-column-status{min-width: 90px;}

/** app spinner**/
.app-spinner {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  background: url(./assets/images/logo-gif.gif) no-repeat center;
  background-size: contain;
}
/* .app-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #0560fd;
  border-radius: 50%;
  animation: app-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0560fd transparent transparent transparent;
} */
.app-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.app-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.app-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes app-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width : 1920px){
  .orgCodeMobileView{
  display: none;
  }
}
@media screen and (max-width : 906px){
 .orgCodeMobileView{
  display: block;
  }
}

#toast-container > div {
  width: 400px;
}

.clientSelectedEmployees.mat-form-field-appearance-legacy.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex,
.selectedTeams.mat-form-field-appearance-legacy.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex
 {border: 1px solid #0560fd; border-radius: 15px; height: 46px; padding: 0px 10px;}

 .clientSelectedEmployees.mat-form-field-appearance-legacy .mat-form-field-underline,
 .selectedTeams.mat-form-field-appearance-legacy .mat-form-field-underline {display: none;}

 .tableplatformlist + fcsro-data-loader > .loader {
      top: 120px;
      bottom: unset;
 }
 .tableplatformlist + fcsro-data-loader > .loader .app-spinner, .tableplatformlist > div + hr + fcsro-new-data-loader > .loader  .app-spinner {
      width: 50px;
      height: 50px;
 }

 .tableplatformlist > div + hr + fcsro-new-data-loader > .loader {
      top: 120px;
      bottom: unset;
 }

 #intergramRoot {
  display: none;
}
  /* //rajkumar */


  .passvis {
    position: relative;
  }
  
  .passvis input {
    padding-right: 70px;
  }
  
  .passvis .eyevis {
    width: 70px;
    height: 50px;
    display: flex;
    position: absolute;
    right: 10px;
    top: 0px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
  }
  
  .passvis .eyevis img {
    max-width: 24px;
    max-height: 24px;
    transition: all ease 0.75s;
  }
  
  .passvis .eyevis .show {
    display: block;
  }
  
  .passvis .eyevis .hide {
    display: none;
  }

  
span.eyevis {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-5%);
}
span.eyevis > img {
  height: 20px; 
}

span.eyevis .show {
  display: block;
}

span.eyevis .hide {
  display: none;
}


.hideeye {
  filter: grayscale(1);
  opacity: 0.5;
  transition: all ease 0.75s;
}

.hideeye:hover {
  opacity: 1;
}
.edit_sec .col-md-3 > button:first-child {
  width: 35px;
  min-width: 35px;
}
.edit_sec .col-md-3 > button {
  width: 35px;
}
.edit_sec .col-md-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 15px;
}
.edit_sec .col-md-3 > button:last-child {
  margin-left: 10px;
}
.edit_sec textarea {
  border: 1px solid #e0e0e0;
  padding: 10px !important;
  border-radius: 5px;
  margin: 10px auto !important;
}
td.desg_Cell.mat-cell {
 /* max-width: 100px; */
 word-break: break-all;
 white-space: initial;
}
.task_enhancement .table-responsive .table thead tr th {
  width: 3.28%;
}
.task_enhancement .table-responsive .table tbody tr td {
  display: table-cell !important;
}