@font-face {
    font-family: 'Stem-Hairline';
    src: url('Stem-Hairline.eot');
    src: url('Stem-Hairline.eot?#iefix') format('embedded-opentype'),
        url('Stem-Hairline.woff2') format('woff2'),
        url('Stem-Hairline.woff') format('woff'),
        url('Stem-Hairline.svg#Stem-Hairline') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('Stem-Bold.eot');
    src: url('Stem-Bold.eot?#iefix') format('embedded-opentype'),
        url('Stem-Bold.woff2') format('woff2'),
        url('Stem-Bold.woff') format('woff'),
        url('Stem-Bold.svg#Stem-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('Stem-ExtraLight.eot');
    src: url('Stem-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Stem-ExtraLight.woff2') format('woff2'),
        url('Stem-ExtraLight.woff') format('woff'),
        url('Stem-ExtraLight.svg#Stem-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('Stem-Light.eot');
    src: url('Stem-Light.eot?#iefix') format('embedded-opentype'),
        url('Stem-Light.woff2') format('woff2'),
        url('Stem-Light.woff') format('woff'),
        url('Stem-Light.svg#Stem-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('Stem-Regular.eot');
    src: url('Stem-Regular.eot?#iefix') format('embedded-opentype'),
        url('Stem-Regular.woff2') format('woff2'),
        url('Stem-Regular.woff') format('woff'),
        url('Stem-Regular.svg#Stem-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('Stem-Medium.eot');
    src: url('Stem-Medium.eot?#iefix') format('embedded-opentype'),
        url('Stem-Medium.woff2') format('woff2'),
        url('Stem-Medium.woff') format('woff'),
        url('Stem-Medium.svg#Stem-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('Stem-Thin.eot');
    src: url('Stem-Thin.eot?#iefix') format('embedded-opentype'),
        url('Stem-Thin.woff2') format('woff2'),
        url('Stem-Thin.woff') format('woff'),
        url('Stem-Thin.svg#Stem-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

