/*
Template: Sofbox - Responsive Bootstrap 4 Admin Dashboard Template
Author: iqonicthemes.in
Design and Developed by: iqonicthemes.in
NOTE: This file contains the styling for responsive Template.
*/

/*================================================
[  Table of contents  ]
================================================

:: General
:: Badge
:: Media Support
:: Best Sellers
:: Countdown
:: Progressbar
:: Dropdown Menu
:: Badge Img Tag
:: List Style 1
:: Icon Box
:: Tasks Card
:: Sign In
:: Dash Hover
:: Waves Effect
:: Sidebar
:: Sidebar Menu
:: Top Menu
:: Content Page
:: Error Page
:: OWL Carousel
:: Timeline
:: Team
:: User Add Block
:: Email list
:: Booking list
:: Chart
:: Material Card Style
:: Circle Progressbar
:: FAQ
:: Table
:: Animation Card
:: Footer
:: Map Profile
:: Mail Inbox

======================================
[ End table content ]
======================================*/

/*--------------------------------------------------------------------
General
---------------------------------------------------------------------*/
*::-moz-selection {
  background: #0560fd;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #0560fd;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #0560fd;
  color: #fff;
  text-shadow: none;
}

/* Definition Lists */
dl dd {
  margin-bottom: 15px;
}

dl dd:last-child {
  margin-bottom: 0px;
}

/* Table */

th {
  font-weight: 600;
}

/* scroller */
.scroller {
  overflow-y: auto;
  scrollbar-color: #0560fd #e5f2ff;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: #0560fd;
}

.scroller::-webkit-scrollbar-track {
  background-color: #e5f2ff;
}

/* Form */
#sidebar-scrollbar {
  height: 100%;
}

/*---------------------------------------------------------------------
      Badge
-----------------------------------------------------------------------*/
.badge {
  padding: .3em .6em;
  font-weight: 600;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
}

.breadcrumb.bg-primary .breadcrumb-item+.breadcrumb-item::before {
  color: #ffffff;
}

/*---------------------------------------------------------------------
    Media Support
-----------------------------------------------------------------------*/
.media-support {
  display: inline-block;
  width: 100%;
}

.media-support-user-img img {
  height: 60px;
}

.media-support-header {
  display: flex;
  align-items: flex-start;
}

.media-support-info {
  flex: 1;
}

.projects-media img {
  height: 60px;
}

.iq-media-group .iq-media {
  margin-left: -20px;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-media-group .iq-media:hover {
  z-index: 9;
}

.iq-media-group .iq-media img,
.iq-media-group .iq-media.border {
  border: 2px solid #ddd;
}

.iq-media-group .iq-media:first-child {
  margin-left: 0;
}

.iq-media {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.iq-media:hover {
  text-decoration: none;
}

.media-pl {
  text-align: center;
}

/*---------------------------------------------------------------------
      Best Sellers
-----------------------------------------------------------------------*/
.media-sellers {
  display: flex;
  align-items: flex-start;
}

.media-sellers-media-info {
  flex: 1;
}

.media-sellers-img img {
  height: 80px;
}

.iq-card .list-star li {
  margin-right: 0;
}

/*---------------------------------------------------------------------
      Best Sellers
-----------------------------------------------------------------------*/
.iq-profile-avatar {
  position: relative;
}

.iq-profile-avatar:before {
  content: '';
  position: absolute;
  border-radius: 100%;
  z-index: 1;
  border: 2px solid #fff;
  background-color: #333333;
  height: 16px;
  width: 16px;
  right: 0;
  bottom: 3px;
}

.iq-profile-avatar.status-online:before {
  background-color: #0abb78;
}

.iq-profile-avatar.status-away:before {
  background-color: #ffb820;
}

/*---------------------------------------------------------------------
      Countdown
-----------------------------------------------------------------------*/
.iq-comingsoon {
  margin-top: 80px;
}

.countdown {
  margin: 60px 0 0;
  padding: 0;
}

.countdown li {
  margin: 0 30px 0 0;
  padding: 45px 0;
  list-style: none;
  width: 22.8%;
  display: inline-block;
  text-align: center;
  line-height: normal;
  background: #ffffff;
  float: left;
  color: #333333;
}

.countdown li span {
  font-size: 60px;
  display: inline-block;
  width: 100%;
  color: #0560fd;
}

.countdown li:last-child {
  margin-right: 0;
}

.iq-comingsoon-form {
  position: relative;
}

.iq-comingsoon-form button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 9px 30px;
}

/*---------------------------------------------------------------------
      Badge
-----------------------------------------------------------------------*/
.iq-progress-bar {
  background: #eef1f7;
  box-shadow: 0 0 0;
  height: 6px;
  margin: 0;
  position: relative;
  width: 100%;
  border-radius: 4px;
}

.iq-progress-bar>span {
  background: #0560fd none repeat scroll 0 0;
  display: block;
  height: 100%;
  width: 0;
  border-radius: 4px;
}

/*---------------------------------------------------------------------
  Dropdown Menu
-----------------------------------------------------------------------*/
.iq-card-header .dropdown-bg {
  background: #f9fcfe;
  padding: 8px 15px;
  color: #0560fd;
  border-radius: 4px;
}

.iq-card-header .dropdown-menu {
  margin: 0;
  padding: 0;
  font-size: 12px;
  top: 20px !important;
  border-radius: 4px;
  border-color: #eef0f4;
  -webkit-box-shadow: 0px 15px 30px 0px rgba(233, 237, 244, 1);
  -moz-box-shadow: 0px 15px 30px 0px rgba(233, 237, 244, 1);
  box-shadow: 0px 15px 30px 0px rgba(233, 237, 244, 1);
}

.iq-card-header .dropdown-menu .dropdown-item {
  padding: 8px 15px;
  color: #666;
}

.iq-card-header .dropdown-menu .dropdown-item i {
  font-size: 14px;
  vertical-align: middle;
}

.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover {
  background: #f9fcfe;
  color: #0560fd;
}

/*--------------*/
.iq-card-header select.form-control {
  background: #eef0f4;
  border: none;
  font-size: 14px;
  margin-right: 15px;
}

.iq-card-header select.form-control.width-100 {
  width: 180px;
}

/*---------------------------------------------------------------------
  Badge Img Tag
-----------------------------------------------------------------------*/
.badge-img-tag {
  position: absolute;
  top: 15px;
  left: 15px;
}

/*---------------------------------------------------------------------
  list Style 1
-----------------------------------------------------------------------*/
.iq-list-style-1 {
  margin: 0;
  padding: 0;
}

.iq-list-style-1 li {
  display: inline-block;
  float: left;
  list-style-type: none;
  margin-right: 15px;
}

.iq-list-style-1 li:last-child {
  margin-right: 0;
}

.iq-list-style-1 li a {
  color: #666;
}

.iq-list-style-1 li a i {
  color: #9597af;
  margin-right: 5px;
}

.iq-list-style-1 li a:hover,
.iq-list-style-1 li a:focus {
  text-decoration: none;
  color: #0560fd;
}

.iq-list-style-1.text-white li a,
.iq-list-style-1.text-white li a i {
  color: #ffffff;
}

.list-inline.text-white li a {
  color: #ffffff;
}

.list-inline.text-white li a:hover {
  opacity: 0.6;
  text-decoration: none;
}

/*---------------------------------------------------------------------
  Icon Box
-----------------------------------------------------------------------*/
.iq-icon-box {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  margin-right: 10px;
  font-size: 22px;
  text-decoration: none;
}

.iq-icon-box:hover {
  text-decoration: none;
}

/*---------------------------------------------------------------------
  Tasks Card
-----------------------------------------------------------------------*/
.tasks-card {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #eef0f4;
  margin: 0 0 15px 0;
}

.tasks-card .close {
  font-size: 16px;
}

.tasks-card:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

/*---------------------------------------------------------------------
    Sign In
-----------------------------------------------------------------------*/
.sign-in-page {
  height: 100vh;
  background: #edf6fc;
}

.sign-in-detail {
  padding: 100px 75px;
  height: 100vh;
}

.sign-in-content {
  padding-top: 0px;
}

.sign-in-content h3 {
  font-size: 45px;
}

.sign-in-content p {
  font-size: 16px;
  line-height: 25px;
}

.sign-in-logo {
  display: inline-block;
  width: 100%;
}

.sign-in-logo img {
  height: 50px;
}

.sign-in-from {
  padding: 0 90px;
}

.sign-info {
  border-top: 1px solid transparent;
  margin-top: 30px;
  padding-top: 20px;
}

.iq-social-media {
  margin: 0;
  padding: 0;
  float: right;
}

.iq-social-media li {
  list-style: none;
  float: left;
  margin-right: 10px;
}

.iq-social-media li:last-child {
  margin-right: 0;
}

.iq-social-media li a {
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  background: #f9fcfe;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.iq-social-media li a:hover {
  text-decoration: none;
}

.sign-in-content .owl-carousel .owl-item img {
  max-width: 200px !important;
  margin: 0px !important;
  width: auto !important;
}

.sign-in-from label {
  color: #495058;
}

.sign-in-from input.form-control {
  border-color: #fff !important;
}

.sign-in-from .custom-checkbox .custom-control-label {
  color: #495058 !important;
}

.sign-in-from .custom-checkbox .custom-control-label::before {
  border-color: #dbdbdb !important
}


/*---------------------------------------------------------------------
    Dash Hover
-----------------------------------------------------------------------*/
.dash-hover-blank {
  background-color: #eee;
  border: dashed 2px #444;
  height: 90%;
  justify-content: center;
}

.dash-hover-blank:hover {
  cursor: pointer;
  background-color: #ddd;
}

/*---------------------------------------------------------------------
    Waves Effect
-----------------------------------------------------------------------*/
.iq-waves-effect {
  position: relative;
  overflow: hidden;
}

/*  Ripple */
.ripple {
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(0, 123, 255, 0.1);
  transform: scale(0);
  position: absolute;
  opacity: 1;
}

.rippleEffect {
  animation: rippleDrop .6s linear;
}

@keyframes rippleDrop {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/*---------------------------------------------------------------------
    Sidebar
-----------------------------------------------------------------------*/
.iq-sidebar {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 240px;
  overflow: hidden;
  height: 100%;
  background: #ecf3fc;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-sidebar .iq-sidebar-logo {
  float: none;
  width: 100%;
  height: 56px;
}

.iq-sidebar #sidebar-scrollbar {
  margin-top: 15px;
}

/*---------------------------------------------------------------------
  Sidebar Menu
-----------------------------------------------------------------------*/

/* Form */
#sidebar-scrollbar {
  height: 100vh;
}

.scroll-content div.p-3 {
  height: 100px;
}

.iq-sidebar-menu .iq-menu .iq-menu-title {
  display: block;
  color: #333333;
  font-size: 15px;
  position: relative;
  padding: 15px 0px;
  font-weight: 500;
  line-height: 18px;
  border-top: 1px solid #f2edff;
  border-bottom: 1px solid #f2edff;
  margin: 10px 0;
  text-align: center;
}

.iq-sidebar-menu .iq-menu .iq-menu-title i {
  display: none;
}

.iq-sidebar-menu {
  position: relative;
}

.iq-sidebar-menu .iq-menu {
  margin: 0px 0px 200px 0px;
  padding: 0;
  list-style-type: none;
}

.iq-sidebar-menu .iq-menu li {
  list-style-type: none;
  padding: 0px 15px 0 5px;
  margin-bottom: 1px;
}

.iq-sidebar-menu .iq-menu li a {
  font-size: 12px !important;
  white-space: nowrap;
  display: block;
  color: black;
  position: relative;
  padding: 10px 10px;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  font-weight: 500;
}

.iq-sidebar-menu .iq-menu li>a::before {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  content: "";
  width: 5px;
  height: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
}

.iq-sidebar-menu .iq-menu li.active>a::before {
  opacity: 1;
  height: 100%;
}

.iq-sidebar-menu .iq-menu li a i {
  font-size: 20px;
  margin-right: 15px;
  vertical-align: middle;
  width: 20px;
  display: inline-block;
  float: left;
}

.iq-sidebar-menu .iq-menu li a span {
  white-space: nowrap;
  padding: 0;
  display: inline-block;
  float: left;
  -webkit-transition: all 0.3=s ease-out 0s;
  -moz-transition: all 0.3=s ease-out 0s;
  -ms-transition: all 0.3=s ease-out 0s;
  -o-transition: all 0.3=s ease-out 0s;
  transition: all 0.3=s ease-out 0s;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
}

.iq-sidebar-menu .iq-menu li a .iq-arrow-right {
  margin-right: 0;
  margin-left: auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  color: #676d7c;
}

.iq-sidebar-menu .iq-menu li a .badge {
  width: auto;
  overflow: hidden;
  position: absolute;
  right: 15px;
  transition: all 0.9s ease-out 0s;
  font-weight: 500 !important;
  font-size: 8px !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  top: -3px;
}

.iq-sidebar-menu .iq-menu li a .badge:hover {
  width: inherit;
}

.iq-sidebar-menu .iq-menu li.active {
  border-bottom: none;
}

.iq-sidebar-menu .iq-menu li.active>a,
.iq-sidebar-menu .iq-menu li.menu-open>a {
  color: #0560fd;
  background: rgb(213 212 212 / 54%);
  text-decoration: none;
  font-weight: 500;
}

.iq-sidebar-menu .iq-menu li a:hover,
.iq-sidebar-menu .iq-menu li ul li a:hover {
  background: rgba(210, 233, 255, .54);
  text-decoration: none;
}

.iq-sidebar-menu .iq-menu li ul li.active>a {
  background: rgb(213 212 212 / 54%);
}

.iq-sidebar-menu .iq-menu li.active a .iq-arrow-right {
  transform: rotate(90deg);
  color: #0560fd;
}

.iq-sidebar-menu .iq-menu li li a.grand-child .iq-arrow-right {
  color: #000;
}

.iq-sidebar-menu .iq-menu li ul li li a.grand-child:hover .iq-arrow-right {
  color: #fff !important;
}

.iq-sidebar-menu .grand-child>i:nth-child(3) {
  transform: rotate(0deg) !important;
}

.iq-sidebar-menu li li.active .grand-child>i:nth-child(3),
.iq-sidebar-menu .iq-menu li.active li a.grand-child .iq-arrow-right {
  transform: rotate(90deg) !important;
  color: #0560fd;
}

.iq-sidebar-menu .iq-menu li a:hover .iq-arrow-right {
  color: #0560fd;
}

/*   */
.iq-sidebar-menu .iq-menu li .iq-submenu li>a::before {
  display: none;
}

/*------------------------*/
.iq-sidebar-menu .iq-menu li ul.iq-submenu {
  display: none;
  padding-left: 0px;
}

.iq-sidebar-menu .iq-menu li ul li {
  padding: 0px;
}

.iq-sidebar-menu .iq-menu li ul li a {
  padding: 10px 15px;
  padding-left: 10px !important;
}

/*---------Sidebar Dark---------------*/
.iq-sidebar.sidebar-dark,
.iq-sidebar.sidebar-dark .iq-sidebar-logo {
  background: #151b21;
  color: #ffffff;
}

.iq-sidebar.sidebar-dark .iq-sidebar-logo a span {
  color: rgba(255, 255, 255, 0.8);
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu .iq-menu-title {
  color: rgba(255, 255, 255, 0.4);
}

.iq-sidebar.sidebar-dark .iq-menu li>a::before {
  display: none;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li>a:hover,
.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li.active>a,
.iq-sidebar.sidebar-dark .iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li a {
  color: #ffffff;
  background: #0f1317;
}

.iq-sidebar.sidebar-dark .iq-menu li a:hover,
.iq-sidebar.sidebar-dark .iq-menu li.active>a,
.iq-sidebar.sidebar-dark .iq-menu li.menu-open>a {
  color: #ffffff;
  background: #0f1317;
}


.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li a {
  color: rgba(255, 255, 255, 0.6);
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li ul li a {
  background: transparent;
  padding-left: 60px;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li ul {
  padding-left: 0;
}

.iq-sidebar.sidebar-dark .iq-sidebar-menu .iq-menu li.active .iq-submenu li>a {
  background: transparent;
}

.iq-sidebar.sidebar-dark .ripple {
  background: rgba(255, 255, 255, 0.1);
}

.iq-sidebar.sidebar-dark .iq-menu-bt {
  background: #0f1317;
}

.iq-sidebar.sidebar-dark .line-menu {
  background: #ffffff;
}

.menu-open {
  display: block !important;
}

.menu-open>a {
  text-decoration: none;
}

@media only screen and (max-width: 960px) {
  .sidebar-main .iq-sidebar .iq-submenu {
    display: none
  }

  /* Remove !important here. It blocks mobile dropdown */
}

@media only screen and (min-width: 1025px) {
  .sidebar-main .iq-sidebar .iq-submenu {
    padding-left: 25px;
  }
}

.sidebar-main .iq-sidebar:hover .menu-open {
  display: block !important
}



/*---------------------------------------------------------------------
  Top Menu
-----------------------------------------------------------------------*/
.iq-top-navbar .iq-navbar-custom .iq-menu-bt {
  display: none;
}

.iq-top-navbar {
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  min-height: 54px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  z-index: 100;
  padding-right: 15px;
  background: #f9fcfe;
  border-bottom: 2px solid #fff;
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
}

.iq-top-navbar .iq-navbar-custom .navbar-breadcrumb {
  display: inline-block;
  margin: 15px 0 0 30px;
  float: left;
}

.iq-top-navbar .breadcrumb .breadcrumb-item.active {
  color: #666;
}

.iq-top-navbar .breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
}

.iq-top-navbar .breadcrumb li {
  list-style-type: none;
  font-size: 12px;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "\ea5f";
  font-family: 'remixicon';
  font-size: 14px;
  vertical-align: sub;
  color: #666;
}

.iq-top-navbar .breadcrumb li a,
.iq-top-navbar .breadcrumb li a:hover {
  text-decoration: none;
}

.navbar-list {
  margin: 0;
  padding: 0;
  float: right;
}

.navbar-list li {
  float: left;
  list-style-type: none;
  position: relative;
}

.navbar-list li .dropdown-toggle::after {
  display: none;
}

.navbar-list li>a {
  font-size: 18px;
  margin: 2px 0 2px 15px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 1px solid #dadadb;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05);
  padding: 7px;
}

.menu-tag {
  position: absolute;
  background: #374948;
  color: #ffffff;
  display: inline-block;
  top: 18px;
  left: 18px;
  font-size: 12px;
  border-radius: 90px;
  line-height: normal;
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
}

.navbar-list li a:hover {
  text-decoration: none;
}

.navbar-list li img {
  width: 100%;
  height: auto;
}

.navbar-list li.iq-nav-user {
  display: flex;
  align-items: flex-start;
}

.navbar-list li.iq-nav-user span {
  flex: 1;
  font-size: 14px;
}

.iq-top-navbar .iq-sidebar-logo .top-logo {
  display: none;
}

.iq-breadcrumb {
  display: none;
}

/*---------- Sidebar Logo --------------*/
.iq-sidebar-logo {
  display: inline-block;
  width: 260px;
  padding-top: 0;
  padding-left: 10px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0. 3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding-bottom: 0px;
  float: left;
}

.sidebar-main .iq-sidebar-logo {
  padding-left: 5px;
}

.iq-sidebar-logo a {
  display: flex;
  align-items: flex-start;
}

.iq-sidebar-logo a span {
  margin-left: 10px;
  font-size: 28px;
  color: #333333;
  flex: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-sidebar-logo a:hover,
.iq-sidebar-logo a:focus {
  text-decoration: none;
}

.iq-sidebar-logo img {
  height: 38px;
}

.iq-menu-bt {
  background: #f9fcfe;
  width: 43px;
  height: 38px;
  margin-right: 15px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border: 2px solid transparent;
  cursor: pointer;
  min-width: 43px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.iq-menu-bt:hover {
  background: #0560fd;
}

.iq-menu-bt:hover .line-menu {
  background: #f9fcfe;
}

.wrapper-menu {
  width: 20px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 330ms ease-out;
}

.wramenucus.open .wrapper-menu {
  transform: rotate(-45deg);
}

.line-menu {
  background-color: #0560fd;
  border-radius: 90px;
  width: 100%;
  height: 2px;
}

.line-menu.half {
  width: 50%;
}

.line-menu.start {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.37, 0.37);
  transform-origin: right;
}

.open .line-menu.start {
  transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.37, 0.37);
  transform-origin: left;
}

.open .line-menu.end {
  transform: rotate(-90deg) translateX(-3px);
}

/*----------------------*/
.iq-top-navbar .iq-sub-dropdown {
  width: 300px;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  background-color: #ffffff;
  transition: all 0.3s;
  padding: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border: 1px solid #E2E8F0;
}

/* ----------------------------------------------
* Generated by Animista on 2019-12-22 15:30:2
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation fade-in-bottom
* ----------------------------------------
*/
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.iq-show .iq-sub-dropdown {
  display: inline-block;
}

.navbar-list li .iq-sub-dropdown .iq-sub-card {
  font-size: inherit;
  padding: 15px;
  line-height: normal;
  color: inherit;
  border-bottom: 1px solid #f3f7fd;
  display: inline-block;
  width: 100%;
}

.iq-top-navbar .search-box {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  background-color: #ffffff;
  transition: all 0.3s;
  padding: 15px;
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.iq-show .search-box {
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.search-box .search-input {
  width: 300px;
  height: 50px;
  padding: 0 1em;
  border: 1px solid #d4e3f9;
  background-color: #f3f7fd;
  opacity: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.iq-sub-dropdown .iq-card-icon {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 22px;
}

/*---------------------------------------------------------------------
  Content Page
-----------------------------------------------------------------------*/
.content-page {
  margin-left: 240px;
  overflow: hidden;
  padding: 80px 0 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
}

.content-page::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.iq-user-dropdown {
  width: 350px;
}

body.sidebar-main .content-page,
body.sidebar-main .iq-footer {
  margin-left: 60px;
}

body.sidebar-main .iq-sidebar-logo {
  width: 51px;
}

body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li a span,
body.sidebar-main .iq-sidebar-logo a span,
body.sidebar-main .iq-sidebar-menu .iq-menu li a .badge {
  opacity: 0;
  display: none;
}

body.sidebar-main .iq-sidebar-menu .iq-menu .iq-menu-title span {
  display: none;
}

body.sidebar-main .iq-sidebar-menu .iq-menu .iq-menu-title i {
  font-size: 20px;
  display: block;
}

body.sidebar-main .iq-menu-bt {
  opacity: 0;
  display: none;
}

body.sidebar-main .iq-sidebar {
  width: 50px;
}

body.sidebar-main .iq-sidebar-menu .iq-menu li a .iq-arrow-right {
  margin-right: 0;
  display: none;
}

body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu li a .iq-arrow-right {
  margin-right: 0;
  display: inline-block;
}

body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu li a span,
body.sidebar-main .iq-sidebar:hover .iq-sidebar-logo a span,
body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu li a .badge,
body.sidebar-main .iq-sidebar:hover .iq-menu-bt {
  opacity: 1;
  display: inline-flex;
}

body.sidebar-main .iq-sidebar .iq-sidebar-menu .iq-menu li ul {
  display: none;
}

body.sidebar-main .iq-sidebar.sidebar-hover .iq-sidebar-menu .iq-menu li ul {
  display: none;
}

body.sidebar-main .iq-sidebar:hover .version_div a {
  width: 23px;
}

/*--------------------*/
.iq-card-header-list {
  margin: 0;
  padding: 0;
  float: right;
}

.iq-card-header-list li {
  list-style-type: none;
  float: left;
  display: inline-block;
  font-size: 20px;
  margin: 0 0 0 8px;
}

.iq-card-header-list li a,
.iq-card-header-list li a:hover {
  text-decoration: none;
}

/*-------------- Media Header ---------------*/
.iq-card .iq-card-header.iq-header-media {
  padding: 20px;
}

/*-------------- Content Page ---------------*/
.big-card {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #f2edff;
  padding: 20px;
}

/*---------------------------------------------------------------------
  Error Page
-----------------------------------------------------------------------*/
.iq-error {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: inline-block;
}

.iq-error h1 {
  font-weight: 900;
  font-size: 16rem;
  line-height: 14rem;
  margin-bottom: 0;
  padding-top: 45px;
  letter-spacing: 15px;
}

.iq-error-img {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
}

/*---------------------------------------------------------------------
    OWL Carousel
-----------------------------------------------------------------------*/
.owl-carousel .owl-nav {
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  left: 0;
  width: 100%;
  cursor: pointer;
  z-index: 999;
}

.owl-carousel .owl-nav .owl-prev {
  display: block;
  position: absolute;
  text-align: center;
  text-indent: inherit;
  left: -8%;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, left 0.3s ease 0s;
}

.owl-carousel .owl-nav .owl-next {
  display: block;
  position: absolute;
  text-align: center;
  text-indent: inherit;
  right: -8%;
  width: auto;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, right 0.3s ease 0s;
}

.owl-carousel .owl-nav i {
  font-size: 24px;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  line-height: 42px;
  padding-left: 0px;
  display: inline-block;
  color: #fff;
  background: #e7e7e7;
  font-weight: normal;
  text-align: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.owl-carousel .owl-nav i:hover {
  background: #4ac4f3;
  color: #fff;
}

/* Dots */
.owl-carousel .owl-controls .owl-dot {
  margin-top: 20px;
  display: inline-block;
}

.owl-carousel .owl-dots {
  position: relative;
  width: 100%;
  display: inline-block;
  text-indent: inherit;
  text-align: center;
  cursor: pointer;
}

.owl-carousel .owl-dots .owl-dot span {
  background: transparent;
  border: 1px solid #92dcff;
  display: inline-block;
  border-radius: 3px;
  margin: 0px 5px;
  height: 8px;
  width: 8px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.owl-carousel .owl-dots .owl-dot:hover,
.owl-carousel .owl-dots .owl-dot.active {
  outline: none;
  border: none;
}

.owl-carousel .owl-dots .owl-dot:hover span {
  background: #ffffff;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #ffffff;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
}

.iq-example-row .row>.col,
.iq-example-row .row>[class^=col-] {
  padding-top: .75rem;
  color: #333333;
  padding-bottom: .75rem;
  background-color: rgba(86, 61, 124, .15);
  border: 1px solid rgba(86, 61, 124, .2);
}

.iq-example-row-flex-cols .row {
  margin-bottom: 15px;
  min-height: 10rem;
  background-color: rgba(255, 0, 0, .1);
}

.iq-icons-list {
  line-height: 50px;
  margin-bottom: 15px;
  display: inline-block;
}

.iq-icons-list i {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  float: left;
  width: 40px;
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
  display: inline-block;
}

.iq-icons-list .icon {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  width: 40px;
  height: 40px;
  line-height: 50px;
  margin-right: 10px;
  display: inline-block;
}

.iq-icons-list span {
  display: inline-block;
  vertical-align: middle;
}

/*---------------------------------------------------------------------
    OWL Carousel
-----------------------------------------------------------------------*/
.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 15px 0 0;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #666;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: #333333;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/*---------------------------------------------------------------------
    OWL Carousel
-----------------------------------------------------------------------*/
.iq-card-icon {
  height: 60px;
  width: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
  font-size: 22px;
}

/*---------------------------------------------------------------------
    Timeline
-----------------------------------------------------------------------*/
.iq-timeline {
  margin: 0 0 0 5px;
  padding: 0;
  width: 100%;
  border-left: 3px solid #f2edff;
}

.iq-timeline li {
  margin-left: 15px;
  position: relative;
  padding: 15px 15px 0 5px;
  list-style-type: none;
}

.iq-timeline li .timeline-dots {
  position: absolute;
  top: 20px;
  left: -24px;
  border: 3px solid #0560fd;
  border-radius: 90px;
  padding: 5px;
  background: #fff;
}

/*---------------------------------------------------------------------
    Team
-----------------------------------------------------------------------*/
.iq-team {
  background: #ffffff;
  padding: 15px;
  position: relative;
}

.team-authors-bg {
  position: relative;
  margin-bottom: 50px;
}

.team-authors-bg .avatar-80 {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/*------------------------------*/
.iq-team-1 {
  position: relative;
  background: #ffffff;
}

.iq-team-1 .iq-team-1-info {
  position: absolute;
  padding: 30px 15px;
  bottom: 0;
  left: 0;
  width: 100%;
}

/*---------------------------------------------------------------------
User Add Block
-----------------------------------------------------------------------*/
.user-add-block {
  background: #ffffff;
}

.user-add-plus {
  position: absolute;
  right: 30px;
  font-size: 24px;
  bottom: -25px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  background: #374948;
  color: #ffffff;
  border-radius: 900px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(57, 60, 82, 0.1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(57, 60, 82, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(57, 60, 82, 0.1);
}

.user-add-plus:hover {
  background: #0560fd;
  color: #ffffff;
  text-decoration: none;
}

.user-add-authors {
  position: relative;
}

.user-add-authors:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 132, 255, 0.8);
}

.user-add-authors .user-add-media {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  display: inline-block;
}

.user-add-list {
  padding: 30px;
}


/*---------------------------------------------------------------------
  Email list
-----------------------------------------------------------------------*/
.iq-email-list .iq-email-ui {
  margin: 0;
  padding: 0;
}

.iq-email-list .iq-email-ui li {
  list-style-type: none;
  font-size: 16px;
  color: #666;
  margin-bottom: 3px;
}

.iq-email-list .iq-email-ui li:last-child {
  margin-bottom: 0;
}

.iq-email-list .iq-email-ui li a {
  color: #666;
  padding: 0;
  display: inline-block;
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-list .iq-email-ui li.active a,
.iq-email-list .iq-email-ui li a:hover {
  text-decoration: none;
  color: #0560fd;
}

.iq-email-list .iq-email-ui li {
  list-style-type: none;
}

.iq-email-list .iq-email-ui li i {
  margin-right: 10px;
}

.iq-email-list .iq-email-ui li .badge {
  float: right;
  font-weight: normal;
  margin-top: 3px;
}

.iq-email-list .iq-email-ui.iq-email-label li {
  margin-bottom: 10px;
}

.iq-email-list .iq-email-ui.iq-email-label li:last-child {
  margin-bottom: 0;
}

/*----------------------------------*/
.iq-email-to-list ul {
  margin: 0;
  padding: 0;
}

.iq-email-to-list ul li {
  list-style-type: none;
  font-size: 20px;
  color: #666;
  margin: 0 5px 0 0;
  float: left;
}

.iq-email-to-list ul li:first-child a {
  background: none;
  width: auto;
}

.iq-email-to-list ul li>a {
  background: #f9fcfe;
  color: #0560fd;
  text-align: center;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-to-list ul li a:hover,
.iq-email-to-list ul li.q-list a:hover {
  color: #0560fd;
  text-decoration: none;
}

.iq-email-to-list ul li .custom-control-label::before {
  top: 13px;
  left: -20px;
}

/*----------------------------------*/
.iq-email-search {
  position: relative;
}

.iq-email-search .form-control {
  height: 40px;
  border: 1px solid #eef0f4;
  width: 300px;
}

.iq-email-search .search-link {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 18px;
  line-height: 38px;
  color: #0560fd;
}

.iq-email-search .search-link:hover {
  text-decoration: none;
}

/*----------------------------------*/
.iq-email-listbox .iq-email-sender-list {
  margin: 15px 0 0;
  padding: 0;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.iq-email-listbox .iq-email-sender-list>li {
  list-style-type: none;
  position: relative;
  padding: 15px 20px;
  border-top: 1px solid #eef0f4;
  float: left;
  display: inline-block;
  width: 100%;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-listbox .iq-email-sender-list>li:hover {
  background: #fff;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 132, 255, 0.2);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 132, 255, 0.2);
  box-shadow: 0px 0px 30px 0px rgba(0, 132, 255, 0.2);
}

.iq-email-listbox .iq-email-sender-list li a:hover {
  text-decoration: none;
}

.iq-email-listbox .iq-email-sender-list li.iq-unread a {
  color: #0560fd;
  font-weight: 500;
}

.iq-email-listbox .iq-email-sender-list li.iq-unread li a {
  color: #0560fd;
}

.iq-email-sender-info .iq-checkbox-mail,
.iq-email-sender-info .iq-star-toggle {
  display: block;
  float: left;
}

.iq-email-sender-info .iq-checkbox-mail {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 4px;
}

.iq-email-sender-info .iq-star-toggle {
  color: #666;
  margin-left: 10px;
  font-size: 18px;
}

.iq-email-sender-info .iq-email-title {
  color: #666;
  position: absolute;
  top: 0;
  left: 85px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 62px;
}

.iq-email-content {
  position: absolute;
  top: 0;
  left: 300px;
  right: 0;
  bottom: 0;
  line-height: 62px;
}

.iq-email-content .iq-email-date,
.iq-email-content .iq-email-subject {
  position: absolute;
  top: 0;
}

.iq-email-content .iq-email-subject {
  color: #666;
  left: 0;
  right: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.iq-email-content .iq-email-date {
  right: 20px;
  width: 100px;
  text-align: right;
  padding-left: 10px;
  color: #333333;
}

.iq-email-sender-list .iq-social-media {
  position: absolute;
  line-height: 62px;
  right: -200px;
  top: 0;
  background: #ffffff;
  font-size: 18px;
  padding: 0 30px;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.iq-email-listbox .iq-email-sender-list li:hover .iq-social-media {
  right: 0;
}

/*----------------------------------*/
.iq-inbox-details tr td {
  padding: 5px 10px;
}

.iq-inbox-details tr td:first-child {
  width: 75px;
  color: #333333;
}

.iq-subject-info {
  display: flex;
  align-items: flex-start;
}

.iq-subject-status {
  margin-left: 15px;
  flex: 1;
}

/*----------------------------------------------------------------------
Chart
----------------------------------------------------------------------*/
#sda1-chart,
#sda1-chart1,
#sda1-chart2 {
  min-height: 200px !important;
}


/*-----------------------------------------------------------------
Material Card Style1
----------------------------------------------------------------- */
.card-style1 {
  display: flex;
}

.card-style1 img {
  object-fit: cover;
  border-radius: 100px;
  height: 75px;
  margin-right: 10px;
}

.card-style1 .title {
  text-transform: capitalize;
  font-weight: bold;
}

.card-style1 .percentage {
  font-weight: bolder;
  font-size: 1.5rem;
  margin: auto;
}

.card-style1 .percentage span {
  font-weight: normal;
}

/*-----------------------------------------------------------------
Material Card Style2
----------------------------------------------------------------- */
.card-style1.card-style2 .iq-details {
  width: 100%;
}

.card-style1.card-style2 .percentage {
  font-weight: bolder;
  font-size: 1.2rem;
  margin-top: -5px;
  margin-left: -50px;
}

/*-----------------------------------------------------------------
Circle Progressbar
------------------------------------------------------------------- */
.iq-circle-progressbar .percent {
  font-size: 1.3rem !important;
}

.iq-circle-progressbar svg {
  width: 100px !important;
  height: 100px !important;
}

.iq-circle-progressbar {
  margin: 0 auto;
  width: 100px !important;
  height: 100px !important;
}

/*-----------------------------------------------------------------
Circle Progressbar
------------------------------------------------------------------- */
.iq-booking-ab {
  display: inline-block;
}

.iq-booking-ab li {
  line-height: 3rem;
  height: 45px;
  margin-bottom: 1rem;
  position: relative;
}

.iq-booking-ab li.friest-booking-blank {
  margin-bottom: 0;
  height: 40px;
}

.iq-booking-ab li.secondary-booking-blank {
  height: 70px;
}

.iq-booking-ab li.three-booking-blank {
  height: 70px;
}

.iq-booking-no li {
  width: 4.9%;
  text-align: center;
  margin-right: 0 !important;
}

.iq-booking-no li .iq-seat {
  background: #f3f7fd;
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
}

.iq-booking-no li .iq-seat img {
  width: 100%;
}

.iq-booking-no li .iq-seat img.active-seat {
  display: none;
}

.iq-booking-no li .iq-seat.bg-secondary img.default,
.iq-booking-no li .iq-seat.active img.default {
  display: none;
}

.iq-booking-no li .iq-seat.bg-secondary img.active-seat,
.iq-booking-no li .iq-seat.active img.active-seat {
  display: inline-block;
}

.iq-booking-no li .iq-seat.active {
  background: #00ca00;
}

.iq-booking-status {
  line-height: 30px;
  display: inline-block;
  margin-right: 30px;
}

.iq-booking-status span {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: inline-block;
  background: #f3f7fd;
  margin-right: 10px;
  margin-top: 6px;
  float: left;
}

/*-----------------------------------------------------------------
Sidebar Menu
------------------------------------------------------------------- */
#right-sidebar-scrollbar {
  height: 100vh;
}

.iq-sidebar-right-menu {
  position: fixed;
  top: 75px;
  right: -400px;
  width: 360px;
  background: #eef0f4;
  overflow: hidden;
  z-index: 99;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

.iq-sidebar-right-menu.film-side {
  right: 0;
}

.iq-sidebar-booking,
.iq-sidebar-payment {
  padding: 30px;
}

.iq-booking-ticket {
  background: #ffffff;
  border-radius: 4px;
  padding: 30px 25px 30px;
  position: relative;
}

.iq-booking-ticket:after {
  content: "";
  position: absolute;
  bottom: 100px;
  left: -10px;
  height: 20px;
  width: 20px;
  background: #0560fd;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
}

.iq-booking-ticket:before {
  content: "";
  position: absolute;
  bottom: 100px;
  right: -10px;
  height: 20px;
  width: 20px;
  background: #0560fd;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
}

/*---------------------------------------------------------------------
FAQ
-----------------------------------------------------------------------*/
.iq-accordion.career-style.faq-style .iq-accordion-block .accordion-title span {
  padding-left: 0px;
}

.iq-accordion.career-style .iq-accordion-block {
  margin-bottom: 30px;
}

.iq-accordion.career-style.faq-style .accordion-title:before {
  display: inline-block;
  cursor: pointer;
  content: "\f218";
  font-family: "Ionicons";
  position: absolute;
  top: -8px;
  right: 15px;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  -webkit-transition: all 0.25s ease-in-out 0s;
  -moz-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
  border-radius: 4px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.iq-accordion.career-style.faq-style .iq-accordion-block .accordion-details p {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.iq-accordion.career-style .iq-accordion-block .accordion-title span {
  font-size: 16px;
  display: table-cell;
  color: #212529;
}

.iq-accordion.career-style.faq-style .iq-accordion-block.accordion-active .accordion-title:before {
  content: "\f209";
  display: inline-block;
}

.iq-accordion.career-style.faq-style .active-faq .row {
  background: transparent;
  padding: 10px 0px;
}

.iq-accordion.career-style.faq-style .iq-accordion-block {
  padding: 10px 5px;
  border-radius: 8px;
}

.iq-accordion.career-style.faq-style .iq-accordion-block.accordion-active .accordion-title:before,
.iq-accordion.career-style.faq-style .accordion-title:before {
  background-image: -moz-linear-gradient(0deg, rgb(109, 115, 239) 0%, rgb(163, 120, 252) 100%);
  border-radius: 90px;
}

/*---------------------------------------------------------------------
Table
-----------------------------------------------------------------------*/
table tr th img {
  width: 35px;
  height: 35px;
  border-radius: 90px;
}

.progress-animate {
  animation: progress-bar-stripes 1s linear infinite;
}

img.summary-image-top {
  width: 35px;
}

.iq-subject-info img {
  width: 80px;
}

/*---------------------------------------------------------------------
Animation Card
-----------------------------------------------------------------------*/
.animation-card {
  position: relative;
}

.animation-card .an-img {
  margin-top: -60px;
  text-align: right;
  display: inline-block;
  width: 100%;
}

.animation-card .an-img .bodymovin {
  width: 162%;
  margin-bottom: -57px;
  margin-left: -50px;
}

.animation-card .an-text {
  position: absolute;
  width: 180px;
  display: inline-block;
  top: 75px;
  left: 30px;
}

.animation-card .an-text h2 {
  margin-top: 0;
}

/*------------*/
.an-img-two {
  width: 700px;
  position: absolute;
  right: -35%;
  top: -175px;
  z-index: -1;
}

.iq-card.bg-primary.sb-top-banner-card {
  z-index: 1;
  position: relative;
}

.bodymovin {
  z-index: -1;
}

/*------------*/
.offer-an-img {
  position: absolute;
  top: -183px;
  width: 695px;
  left: -100px;
}

/*---------------------------------------------------------------------
Footer
-----------------------------------------------------------------------*/
.iq-footer {
  padding: 15px;
  margin-left: 260px;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  -moz-box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.iq-footer ul li a {
  color: #666;
}

.iq-footer ul li a:hover {
  color: #0560fd;
  text-decoration: none;
}

.bg-banner-image.style-three {
  height: 150px;
  background: url('../images/bg-03.jpg');
}

.iq-mt--70 {
  margin-top: -70px
}

.banner-main-text {
  position: absolute;
  left: 15%;
  bottom: 13%;
}

.nav.nav-pills.style-three .nav-link.active:before,
.nav.nav-pills.style-three .nav-link:hover:before {
  display: inline-block;
  width: 100%;
  height: 3px;
  background: #0560fd;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}

.nav.nav-pills.style-three .nav-link:before {
  width: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  height: 3px;
  background: #19beec;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0px;
}

.iq-card-body.banner-box {
  padding-bottom: 0;
}

.nav.nav-pills.style-three {
  margin-top: 10px;
}

.nav.nav-pills.style-three a:last-child {
  margin-right: 0px;
}

.nav.nav-pills.style-three a {
  background: transparent;
  position: relative;
  margin-right: 30px;
  padding: 10px 0px;
}

/*---------------------------------------------------------------------
Map Profile
-----------------------------------------------------------------------*/
.dash-tracking-icon {
  height: 80px;
}

.map-profile-card {
  margin-right: 30px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
}

.track {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 20px;
}

/*---------------------------------------------------------------------
Mail Inbox
-----------------------------------------------------------------------*/
.email-app-details.show {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.email-app-details {
  position: absolute;
  display: block;
  z-index: 6;
  visibility: hidden;
  opacity: 0;
  top: 0;
  width: calc(100% - 15px);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  height: 100%;
  left: auto;
  right: 0;
  background-color: #fff;
}

.attegement ul {
  padding: 0;
  list-style: none;
}

.send-panel a {
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#compose-email-popup {
  position: fixed;
  max-width: 700px;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  padding: 0 !important;
  height: auto;
  width: 100%;
}

#compose-email-popup .iq-card {
  border-radius: 5px 5px 0 0;
}

button.close-popup {
  background: transparent;
  color: #000;
  border: none;
  font-size: 20px;
  line-height: 20px;
}

.compose-bottom {
  border-top: 1px solid #666;
}

.select2-container--default .select2-selection--multiple {
  background: #e9edf4;
  border: 0px solid #d7dbda;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  color: #333333;
  background-color: #e5f2ff;
  box-shadow: none;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox.custom-checkbox-color input.bg-primary~label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-primary~label::before,
.custom-radio.custom-radio-color input.bg-primary~label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-primary~label::before,
.custom-switch.custom-switch-color input:checked.bg-primary~label::before {
  background-color: #0560fd !important;
  border-color: #0560fd !important;
}

.custom-checkbox.custom-checkbox-color input.bg-success~label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-success~label::before,
.custom-radio.custom-radio-color input.bg-success~label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-success~label::before,
.custom-switch.custom-switch-color input:checked.bg-success~label::before {
  background-color: #00ca00 !important;
  border-color: #00ca00 !important;
}

.custom-checkbox.custom-checkbox-color input.bg-danger~label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-danger~label::before,
.custom-radio.custom-radio-color input.bg-danger~label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-danger~label::before,
.custom-switch.custom-switch-color input:checked.bg-danger~label::before {
  background-color: #e64141 !important;
  border-color: #e64141 !important;
}

.custom-checkbox.custom-checkbox-color input.bg-warning~label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-warning~label::before,
.custom-radio.custom-radio-color input.bg-warning~label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-warning~label::before,
.custom-switch.custom-switch-color input:checked.bg-warning~label::before {
  background-color: #ffd400 !important;
  border-color: #ffd400 !important;
}

.custom-checkbox.custom-checkbox-color input.bg-dark~label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-dark~label::before,
.custom-radio.custom-radio-color input.bg-dark~label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-dark~label::before,
.custom-switch.custom-switch-color input:checked.bg-dark~label::before {
  background-color: #374948 !important;
  border-color: #374948 !important;
}

.custom-checkbox.custom-checkbox-color input.bg-info~label::before,
.custom-checkbox.custom-checkbox-color-check input:checked.bg-info~label::before,
.custom-radio.custom-radio-color input.bg-info~label::before,
.custom-radio.custom-radio-color-checked input:checked.bg-info~label::before,
.custom-switch.custom-switch-color input:checked.bg-info~label::before {
  background-color: #00d0ff !important;
  border-color: #00d0ff !important;
}

/* checkbos with icon */
.custom-checkbox.checkbox-icon label i {
  position: absolute;
  top: 6px;
  left: -21px;
  font-size: 10px;
  transform: scale(0);
  -webkit-transform: scale(0);
  -webkit-transition: all .15s ease-in;
  transition: all .15s ease-in;
}

.custom-checkbox.checkbox-icon input:checked~label i {
  transform: scale(1);
  -webkit-transform: scale(1);
  color: #fff;
}

.custom-checkbox.checkbox-icon input:checked~label::after {
  display: none;
}

/* switch with icon */
.custom-switch.custom-switch-icon label .switch-icon-left,
.custom-switch.custom-switch-icon label .switch-icon-right,
.custom-switch.custom-switch-text label .switch-icon-left,
.custom-switch.custom-switch-text label .switch-icon-right {
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: -3px;
}

.custom-switch.custom-switch-icon label .switch-icon-left i,
.custom-switch.custom-switch-icon label .switch-icon-right i {
  font-size: 10px;
}

.custom-switch.custom-switch-icon label .switch-icon-left,
.custom-switch.custom-switch-text label .switch-icon-left {
  left: 8px;
  right: auto;
  color: #fff;
}

.custom-switch.custom-switch-icon label .switch-icon-right,
.custom-switch.custom-switch-text label .switch-icon-right {
  right: 5px;
  left: auto;
}

.custom-switch.custom-switch-icon,
.custom-switch.custom-switch-text {
  padding-left: 0;
}

.custom-switch.custom-switch-icon .custom-control-label {
  height: 20px;
  width: 42px;
  padding: 0;
}

.custom-switch.custom-switch-text .custom-control-label {
  width: 46px;
}

.custom-switch.custom-switch-icon .custom-control-label::before,
.custom-switch.custom-switch-text .custom-control-label::before {
  height: 20px;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  user-select: none;
  top: 0;
  left: 1px;
  width: 2.75rem;
}

.custom-switch.custom-switch-text .custom-control-label::before {
  width: 100%;
}

.custom-switch.custom-switch-icon .custom-control-label:after,
.custom-switch.custom-switch-text .custom-control-label:after {
  position: absolute;
  top: 1px;
  left: 2px;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  user-select: none;
  height: 18px;
  width: 18px
}

.custom-switch.custom-switch-icon .custom-control-input:checked~.custom-control-label::after {
  -webkit-transform: translateX(1.4rem);
  -ms-transform: translateX(1.4rem);
  transform: translateX(1.4rem);
}

.custom-switch.custom-switch-text .custom-control-input:checked~.custom-control-label::after {
  -webkit-transform: translateX(1.6rem);
  -ms-transform: translateX(1.6rem);
  transform: translateX(1.6rem);
}

.custom-switch-inner {
  display: block;
  text-align: center;
}

.custom-switch.custom-switch-text input:checked~label::before {
  color: #fff;
  content: attr(data-on-label);
  text-align: left;
}

.custom-switch.custom-switch-text label::before {
  color: #000;
  content: attr(data-off-label);
  width: 2.85rem;
  height: 20px;
  padding: 0 4px;
  font-size: 11px;
  line-height: 18px;
  text-align: right;
}

.custom-switch.custom-switch-text label:after {
  position: absolute;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  user-select: none;
  height: 16px;
  width: 16px;
}

.custom-switch.custom-switch-text input:checked~label.custom-control-label::after {
  transform: translateX(1.6rem);
  -webkit-transform: translateX(1.6rem);
}

.form-label-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  padding: .6rem;
  color: #475F7B;
  font-size: .85rem;
  opacity: 0;
}

.counter-value {
  color: #FFF;
  padding: 1px 6px;
  font-size: .6rem;
  border-radius: 0 0 5px 5px;
  margin-right: 1rem;
}

.vector-map.h-500 {
  width: 100%;
  height: 500px;
}

/*---------------------------------------------------------------------
Calender
-----------------------------------------------------------------------*/
.fc-unthemed .fc-toolbar .fc-button {
  background: #f9fcfe;
  color: #0560fd;
  border: none;
  text-transform: capitalize;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.fc-unthemed .fc-toolbar .fc-button.fc-button-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
  color: #fff;
  background-color: #0560fd;
}

.job-classification li,
.today-schedule li {
  list-style-type: none;
  margin-bottom: 10px;
}

.job-classification li>i,
.today-schedule li i {
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
}

.schedule-text span {
  display: block;
}

.calender-small .flatpickr-calendar.animate.inline {
  width: 100%;
  box-shadow: none;
}

.calender-small .dayContainer {
  width: 100%;
  min-width: auto;
}

.calender-small .flatpickr-days {
  width: 100%;
}

.calender-small .flatpickr-day.today {
  color: #fff;
  border-color: #0560fd;
  background: #0560fd;
}

.calender-small .flatpickr-day.today:hover,
.calender-small .flatpickr-day.today:focus {
  color: #fff;
  border-color: #0560fd;
  background: #0560fd
}

.calender-small .flatpickr-months {
  margin-bottom: 10px;
}

.calender-small .flatpickr-current-month .flatpickr-monthDropdown-months {
  font-size: 16px;
}

.calender-small .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: transparent;
  color: #0560fd;
}

.calender-small .flatpickr-current-month input.cur-year {
  font-size: 16px;
}

.calender-small .numInputWrapper:hover {
  background: transparent;
}

.calender-small .flatpickr-months .flatpickr-prev-month,
.calender-small .flatpickr-months .flatpickr-next-month {
  padding: 7px 10px;
}

.calender-small .flatpickr-day.selected,
.calender-small .flatpickr-day.startRange,
.calender-small .flatpickr-day.endRange,
.calender-small .flatpickr-day.selected.inRange,
.calender-small .flatpickr-day.startRange.inRange,
.calender-small .flatpickr-day.endRange.inRange,
.calender-small .flatpickr-day.selected:focus,
.calender-small .flatpickr-day.startRange:focus,
.calender-small .flatpickr-day.endRange:focus,
.calender-small .flatpickr-day.selected:hover,
.calender-small .flatpickr-day.startRange:hover,
.calender-small .flatpickr-day.endRange:hover,
.calender-small .flatpickr-day.selected.prevMonthDay,
.calender-small .flatpickr-day.startRange.prevMonthDay,
.calender-small .flatpickr-day.endRange.prevMonthDay,
.calender-small .flatpickr-day.selected.nextMonthDay,
.calender-small .flatpickr-day.startRange.nextMonthDay,
.calender-small .flatpickr-day.endRange.nextMonthDay {
  color: #fff;
  border-color: #0560fd;
  background: #0560fd;
}

.calender-small .flatpickr-day.inRange,
.calender-small .flatpickr-day.prevMonthDay.inRange,
.calender-small .flatpickr-day.nextMonthDay.inRange,
.calender-small .flatpickr-day.today.inRange,
.calender-small .flatpickr-day.prevMonthDay.today.inRange,
.calender-small .flatpickr-day.nextMonthDay.today.inRange,
.calender-small .flatpickr-day:hover,
.calender-small .flatpickr-day.prevMonthDay:hover,
.calender-small .flatpickr-day.nextMonthDay:hover,
.calender-small .flatpickr-day:focus,
.calender-small .flatpickr-day.prevMonthDay:focus,
.calender-small .flatpickr-day.nextMonthDay:focus {
  background: #f9fcfe;
  color: #0560fd;
  border-color: transparent;
}


/*---------------------------------------------------------------------
Simple From Wizard
-----------------------------------------------------------------------*/
#form-wizard1,
#form-wizard2,
#form-wizard3 {
  position: relative;
}

#form-wizard1 input[type="file"] {
  line-height: 27px;
}

#form-wizard1 fieldset:not(:first-of-type) {
  display: none
}

.card {
  z-index: 0;
  border: none;
  position: relative
}

.steps {
  font-size: 20px;
  color: #666;
  font-weight: normal;
  text-align: right
}

#top-tab-list {
  margin: 0 -10px 60px;
  overflow: hidden;
  color: #666;
}

#top-tab-list .active {
  color: #0560fd;
}

#top-tab-list li {
  list-style-type: none;
  font-size: 18px;
  width: 25%;
  float: left;
  text-align: left;
  position: relative;
  font-weight: 400
}

#top-tab-list li i {
  display: inline-block;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 50%;
  margin: 0 15px 0 0;
  color: #ffffff;
  background: #0560fd;
}

#top-tab-list li.active a {
  color: #fff;
  background: #0560fd;
}

#top-tab-list li a {
  background: #f9fcfe;
  color: #0560fd;
  display: block;
  padding: 15px;
  margin: 0 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  text-decoration: none;
}

#top-tab-list li.active i {
  background-color: #fff;
  color: #0560fd;
}

.fit-image {
  width: 100%;
  object-fit: cover
}

/*---------------------------------------------------------------------
validate form Wizard
-----------------------------------------------------------------------*/
#form-wizard2 fieldset:not(:first-of-type) {
  display: none
}

#top-tabbar {
  overflow: hidden;
  color: #666;
  margin: 0 -20px 60px;
}

#top-tabbar .active {
  color: #0560fd;
}

#top-tabbar li {
  list-style-type: none;
  font-size: 18px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400
}

#top-tabbar li i {
  display: block;
  text-align: center;
  height: 65px;
  width: 65px;
  line-height: 65px;
  font-size: 25px;
  border-radius: 50%;
  margin: 0 auto 20px;
  color: #ffffff;
  background: #0560fd;
}

#top-tabbar li#user i {
  background: #f9fcfe;
  color: #0560fd;
}

#top-tabbar li#document i {
  background: #fcecec;
  color: #e64141;
}

#top-tabbar li#bank i {
  background: #e5fae5;
  color: #00ca00;
}

#top-tabbar li#confirm i {
  background: #fffbe5;
  color: #ffd400;
}

#top-tabbar li.active a {
  border-color: transparent;
}

#top-tabbar #user.active a {
  background: #f9fcfe;
}

#top-tabbar #document.active a {
  background: #fcecec;
}

#top-tabbar #bank.active a {
  background: #e5fae5;
}

#top-tabbar #confirm.active a {
  background: #fffbe5;
}

#top-tabbar li.active i {
  background: #fff !important;
}

#top-tabbar li a {
  background: #fff;
  color: #333333;
  display: block;
  padding: 50px 15px;
  margin: 10px 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  text-decoration: none;
  border: 1px solid #f2edff;
}

#top-tabbar li.active i {
  background-color: #fff;
  color: #0560fd;
}

/*---------------------------------------------------------------------
Vertical form Wizard
-----------------------------------------------------------------------*/
#form-wizard3 fieldset:not(:first-of-type) {
  display: none
}

#top-tabbar-vertical {
  overflow: hidden;
  color: #666;
  margin: 0 -20px 60px;
}

#top-tabbar-vertical .active {
  color: #0560fd;
}

#top-tabbar-vertical li {
  list-style-type: none;
  font-size: 18px;
  width: 100%;
  float: left;
  position: relative;
  font-weight: 400
}

#top-tabbar-vertical li i {
  display: inline-block;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 50%;
  margin: 0 20px 0 0;
}

#top-tabbar-vertical li#personal i {
  background: #f9fcfe;
  color: #0560fd;
}

#top-tabbar-vertical li#contact i {
  background: #fcecec;
  color: #e64141;
}

#top-tabbar-vertical li#official i {
  background: #e5fae5;
  color: #00ca00;
}

#top-tabbar-vertical li#payment i {
  background: #fffbe5;
  color: #ffd400;
}

#top-tabbar-vertical li.active a {
  border-color: transparent;
}

#top-tabbar-vertical li a {
  background: #fff;
  color: #000;
  display: block;
  padding: 20px 15px;
  margin: 10px 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  text-decoration: none;
  border: 1px solid #f2edff;
}

#top-tabbar-vertical li.active i {
  background-color: #fff;
  color: #0560fd;
}

#top-tabbar-vertical #personal.active a {
  background: #f9fcfe;
}

#top-tabbar-vertical #contact.active a {
  background: #fcecec;
}

#top-tabbar-vertical #official.active a {
  background: #e5fae5;
}

#top-tabbar-vertical #payment.active a {
  background: #fffbe5;
}

#top-tabbar-vertical li.active i {
  background: #fff !important;
}

/*---------------------------------------------------------------------
Chat
-----------------------------------------------------------------------*/
.chat-page img {
  border-radius: 50%;
}

#chat-search {
  padding-left: 40px;
  background: #f9fcfe;
}

.form-group.chat-search-data>i {
  position: absolute;
  top: 8px;
  left: 15px;
  color: #0560fd;
  font-weight: 600;
  font-size: 16px;
}

.chat-sidebar-name {
  width: 100%;
}

.chat-msg-counter {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  line-height: 22px;
  margin: 0 auto;
  font-size: 10px;
}

.chat-meta span {
  font-size: 10px;
}

span.avatar-status {
  position: absolute;
  bottom: -5px;
  top: auto;
  left: auto;
  right: 0;
}

.iq-chat-ui li a {
  text-decoration: none;
  display: block;
  padding: 20px 15px;
  border-bottom: 1px solid #f1f2f1;
}

.iq-chat-ui li a.active {
  border-color: transparent;
}

.nav-pills li a.active {
  color: #0560fd;
  background-color: #e5f2ff;
}

.iq-chat-ui li:last-child a {
  border: none;
}

.iq-chat-ui li:nth-child(2) a,
.iq-chat-ui li:nth-child(6) a {
  border: none;
}

.chat-sidebar-channel {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 450px;
  margin-bottom: 15px;
}

.chat-data {
  background: url(../images/page-img/100.jpg) no-repeat scroll center center;
  background-size: cover;
}

.chat-header-icons .dropdown-toggle:empty::after {
  display: none;
}

.chat-header-icons>span>i {
  width: 40px;
  background: #f9fcfe;
  height: 40px;
  font-size: 20px;
  margin: 0 5px 0 0;
  color: #0560fd;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
}

.chat-content {
  position: relative;
  height: calc(100vh - 10rem);
  text-align: center;
  padding: 1.4rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-user {
  float: right;
  text-align: center;
}

.chat-user span.chat-time {
  display: block;
  font-size: 12px;
  font-weight: 500;
}

.chat-detail {
  overflow: hidden;
}

.chat-message {
  position: relative;
  float: right;
  text-align: right;
  padding: .75rem 1rem;
  margin: 0 1.33rem 1.33rem .3rem;
  clear: both;
  word-break: break-word;
  color: #FFF;
  background: #0560fd;
  border-radius: .267rem;
  box-shadow: 0 2px 4px 0 rgba(90, 141, 238, .6);
}

.chat-message>p {
  margin: 0;
}

.chat-message::after {
  position: absolute;
  content: "";
  top: 14px;
  bottom: 0;
  left: auto;
  right: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 7px;
  border-color: transparent transparent transparent #0560fd;
}

.chat-left .chat-message::after {
  right: auto;
  left: -7px;
  border-width: 5px 7px 5px 0;
  border-color: transparent #fff transparent transparent;
}

.chat-content .chat-left .chat-message {
  text-align: left;
  float: left;
  margin: 0 .3rem 1.8rem 1.3rem;
  color: #727E8C;
  background-color: #fff;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
}

.chat-sidebar-channel .avatar,
.avatar {
  position: relative;
}

.chat-start {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 10rem);
  flex-direction: column;
}

span.iq-start-icon {
  font-size: 50px;
  background: #fff;
  width: 100px;
  text-align: center;
  border-radius: 50%;
}

.chat-attagement {
  font-size: 20px;
}

.chat-detail .chat-message .chat-time {
  position: absolute;
  bottom: -20px;
  right: 0;
  color: #828D99;
  font-size: .8rem;
  white-space: nowrap;
}

.chat-left .chat-user {
  float: left;
}

.chat-content .chat-body .chat-message p {
  margin-bottom: 0;
}

.chat-data .tab-content {
  position: relative;
}

.chat-searchbar {
  position: relative;
}

#chat-user-detail-popup {
  position: absolute;
  top: 0;
  width: 360px;
  background: #fff;
  right: 0;
  overflow: hidden;
  left: auto;
  z-index: 9;
  height: 100%;
  opacity: 0;
  transform: translateX(110%);
  -webkit-transform: translateX(110%);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  border-radius: 5px;
  visibility: hidden;
}

#chat-user-detail-popup.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
}

.chatuser-detail .title {
  font-weight: 500;
}

.chatuser-detail {
  padding: 0 35px;
}

.user-profile button.close-popup {
  display: block;
  font-size: 24px;
}

ul.user-status {
  list-style: none;
}

#user-detail-popup {
  position: absolute;
  top: 0;
  width: 310px;
  background: #fff;
  right: 0;
  overflow: hidden;
  left: auto;
  z-index: 9;
  height: 100%;
  opacity: 0;
  transform: translateX(-90%);
  -webkit-transform: translateX(-90%);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
  box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.06);
  border-radius: 5px;
  visibility: hidden;
}

#user-detail-popup.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  transition: all 0.45s ease 0s;
  -webkit-transition: all 0.45s ease 0s;
}

#user-detail-popup .user-profile button.close-popup {
  text-align: right;
  width: 100%;
}

.chat-sidebar-channel h5 {
  border-bottom: 1px solid #f1f2f1;
  padding-bottom: 10px;
}

.chat-sidebar-name h6 {
  font-size: 1.2em;
}

.chat-sidebar-name span {
  color: #666;
}

button.close-btn-res {
  display: none;
}

div#sidebar-toggle {
  display: none;
}

/*---------------------------------------------------------------------
Profile Page
-----------------------------------------------------------------------*/
.profile-img img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, .1);
}

.cover-container {
  position: relative;
}

ul.header-nav {
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 20px;
}

ul.header-nav li {
  list-style: none;
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: #0560fd;
  font-size: 18px;
  margin: 0 15px 0 0;
}

.profile-img {
  margin-top: -80px;
}

.profile-feed-items li {
  list-style: none;
  background: #f9fcfe;
  color: #0560fd;
  padding: 10px 20px;
  margin: 0 20px 0 0;
  border-radius: 5px;
  font-weight: 500;
}

.profile-feed-items li:last-child {
  margin: 0;
}

.profile-feed-items li a {
  text-decoration: none;
  text-transform: capitalize;
}

.news-icon {
  font-size: 18px;
  margin-right: 20px;
}

ul.profile-img-gallary {
  list-style: none;
  margin: 0 -1px 0 -4px !important;
}

.twit-feed p span i {
  background: #0560fd;
  color: #fff;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  font-size: 10px;
}

.twit-feed .twit-meta-tag {
  color: #0560fd;
}

.twit-feed .media-support-user-img img {
  height: 50px;
}

ul.media-story img {
  border: 1px solid rgba(0, 0, 0, .1);
  padding: 2px;
}

ul.media-story li.active img {
  border-color: #0560fd;
}

.add-suggestion {
  border: 1px solid #0560fd;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
  color: #0560fd;
}

.suggestions-lists .btn {
  display: block;
}

.feather-icon span,
.message-icon span {
  font-size: 18px;
  font-weight: 300;
}

ul.post-comments {
  list-style: none;
}

.comment-activity>a {
  margin-right: 10px;
  text-transform: capitalize;
  color: #0560fd;
  text-decoration: none;
}

form.comment-text {
  position: relative;
}

.comment-attagement {
  position: absolute;
  left: auto;
  right: 0;
  font-size: 20px;
}

/*---------------------------------------------------------------------
Profile Edit Page
-----------------------------------------------------------------------*/
.profile-pic {
  max-width: 100%;
  display: block;
}

.profile-img-edit {
  position: relative;
  display: inline-block;
  height: 100px;
  width: 100px;
}

.file-upload {
  display: none;
}

.p-image {
  position: absolute;
  top: 40%;
  right: -30px;
  color: #666666;
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
}

.upload-button {
  font-size: 1.2em;
}

/*******************************************Employee page*******************************/
tr.mat-header-row {
  height: auto !important;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;
  border-radius: 6px;
  color: #828285;
  margin-bottom: 15px;
}

.table thead th.tble_hdr {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  border-bottom: none !important;
  font-weight: bold;
  white-space: nowrap;
  color: #000;
  text-align: left !important;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  vertical-align: middle;
}

.iq-card .iq-card-header {
  background-color: #e5f2ff;
  border-color: #e5f2ff;
}

.iq-header-title .card-title {
  color: #0560fd;
}

.mat-table tbody {
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  border-radius: 6px;
  color: #828285;
  margin-bottom: 15px;
}

.mat-table tbody tr td,
tr.mat-header-row td {
  border-bottom: 1px solid #e7e7ef;
  line-height: 30px;
  min-height: 48px;
  color: #333;
  padding: 0px;
  text-transform: none;

}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: rgba(0, 0, 0, .87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0560fd;
}

body .iq-sidebar-logo img.mini_logo,
body.sidebar-main .iq-sidebar:hover .iq-sidebar-logo img.mini_logo {
  width: 0;
  transition: all 0.1s linear;
  height: 38px;
}

body.sidebar-main .iq-sidebar:hover .iq-sidebar-menu .iq-menu .iq-menu-title span {
  display: block;
}

body.sidebar-main .iq-sidebar-logo img.mini_logo {
  width: auto;
  transition: all 0.1s linear;
}

body.sidebar-main .iq-sidebar-logo img.max_logo {
  width: 0;
  transition: all 0.1s linear;
}

body.sidebar-main .iq-sidebar:hover .iq-sidebar-logo img.max_logo {
  width: auto;
  transition: all 0.1s linear;
}

.iq-submenu {
  background-color: #ecf3fc;
  padding-left: 0px;
  font-size: 12px;
  border-radius: 15px;
  margin: 1px 0px;
}

.iq-sidebar-menu .iq-menu li ul li a {
  font-size: 12px;
}

.navbar-list li .user_img img {
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.notifi_bell {
  font-size: 22px;
  vertical-align: middle;
}

.mat-form-field.mat-focused .mat-form-field-label,
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: transparent
}

.mat-calendar-body-selected,
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0560fd;
}

.prev_next {
  display: inline-block;
  font-size: 25px;
  vertical-align: middle;
  margin-top: -10px;
  background: #fff;
  height: 45px;
  width: 35px;
  text-align: center;
  line-height: 45px;
}

.iq-sidebar-menu .iq-menu li a {
  cursor: pointer;
  text-transform: uppercase;
}

.iq-sidebar-menu .iq-menu .iq-submenu li a {
  text-transform: uppercase
}

.mat-button-wrapper {
  display: block;
}

.no_data {
  font-size: 25px;
  margin-top: 10px;
  text-transform: uppercase;
}

.profle_img {
  width: 180px;
  margin-top: 20px;
}

.dash_img img {
  width: 300px;
}

.dash_img h4 {
  font-size: 30px;
}

.badge_txt {
  font-size: 10px;
}

.change_tag {
  width: 135px
}

.text_transform_none {
  text-transform: none !important;
}

.border_top_none {
  border-top: none !important;
}

.emp_name {
  cursor: pointer;
}

.emp_name:hover {
  color: #0560fd;
}

input.form-control,
.custom-select {
  background: white;
  border: 1px solid #0560fd !important;
}

input::selection {
  outline: none !important;
}

.text_small {
  font-size: 11px;
}

.side_menu {
  margin-top: 0;
  border-top: none;
  padding-top: 10px;
  width: 240px;
}

.w_90 {
  width: 90px
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: #ddd;
}

.dark_theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: #000;
}

.worktime_div {
  border: 2px solid #0560fd;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px
}

.span_label {
  position: absolute;
  top: -10px;
  background: #fff;
  padding: 0 5px;
  font-size: 11px;
}

.monitor_div {
  padding: 15px;
  box-shadow: 0 0 3px #0560fd;
  text-align: center;
  min-width: auto;
  min-height: 199px;
  font-size: 12px;
  padding-top: 35px;
}

.gallery_div fcsro-horizontal-calendar .mat-form-field-infix {
  width: 60px !important;
}

.gallery_div fcsro-horizontal-calendar .mat-icon-button {
  width: 25px !important;
}

.empgallery fcsro-focus-spread {
  float: right;
}

fcsro-horizontal-calendar .tableplatformlist {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 0px;
  padding-right: 0px;
}

fcsro-horizontal-calendar .timeline_div .hours.days {
  padding-left: 0;
  padding-right: 0;
  width: 40px;
}

.fnt_sz12 {
  font-size: 12px;
}

.blur_div {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.mt_20 {
  margin-top: 20px;
}

.clear_btn span.mat-button-wrapper {
  display: inline-block;
  vertical-align: super;
}

.recaptcha_div div {
  margin: 0px auto 20px;
}

.outline-none {
  outline: none !important;
}

.month_chose .mat-form-field-wrapper {
  padding-bottom: 0px;
}

@-moz-document url-prefix() {
  .clear_btn span.mat-button-wrapper i {
    margin-top: 8px;
  }
}

.mat-table.org_table tbody tr td,
.org_table tr.mat-header-row td {
  line-height: unset !important;
}

.mat-form-field-label {
  font-size: 14px !important;
  color: #333 !important;
  font-family: 'Roboto', Verdana, sans-serif !important;
  font-weight: 400;
}

.mat-input-element {
  font-family: 'Roboto', Verdana, sans-serif !important;
  font-size: 12px !important;
}

.iq-sidebar-menu .iq-menu li.menu-open>a:hover {
  background: rgb(213 212 212 / 54%);
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

.ng-trigger-transformPanelWrap>div {
  min-width: 100px !important;
}

/*DARK THEME*/
.dark_theme .iq-submenu {
  /* background-color: #131517; */
  background-color: rgba(0, 0, 0, 0.5);
}

.dark_theme .iq-sidebar-menu .iq-menu li li a.grand-child .iq-arrow-right {
  color: #9f9f9f;
}

.dark_theme .iq-sidebar-menu .iq-menu li ul li.active>a {
  background: rgb(5 96 253);
}

.dark_theme .iq-sidebar-menu .iq-menu li ul li.active>a .iq-arrow-right {
  color: #fff;
}

.dark_theme .iq-sidebar-menu .iq-menu li.active li a.grand-child .iq-arrow-right {
  transform: unset !important;
  color: #9f9f9f;
}

.dark_theme input.form-control {
  background: transparent;
}

/*DARK THEME*/


@media screen and (max-width: 480px) {
  .iq-top-navbar .iq-sub-dropdown {
    width: 250px;
    top: 55px;
    right: 10px;
  }
}